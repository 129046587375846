<div class="radial-progress">
  <div class="pie-size" [ngStyle]="{ 'font-size': size + 'px' }">
    <div class="pie-wrapper">
      <div class="pie {{ percent >= 50 ? 'more' : '' }}">
        <!-- <div class="border-green hidden"></div> -->
        <div
          class="left-side half-circle border-{{ loadingColor }}"
          [ngStyle]="{ 'border-width': loadingSize + 'px' }"
          [style.transform]="'rotate(' + percentageToDegrees(percent, 360) + 'deg)'"
        ></div>
        <div
          class="right-side half-circle border-{{ loadingColor }}"
          [ngStyle]="{ 'border-width': loadingSize + 'px' }"
          [style.transform]="'rotate(' + percentageToDegrees(percent, 180) + 'deg)'"
        ></div>
      </div>
      <div
        class="label absolute bottom-[5px] left-[5px] right-[5px] top-[5px] block overflow-hidden rounded-full bg-white"
      >
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  </div>
</div>
