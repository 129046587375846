import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PaginationService } from 'thkee-common';
import { AssignmentsService } from '../../services';
import { AssignmentsActions } from './assignments.actions';

@Injectable()
export class InstructorAssignmentsEffects {
  constructor(
    private actions$: Actions,
    private assignmentsService: AssignmentsService,
    private paginationService: PaginationService
  ) {}

  // Assignments And Overview tab
  loadAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.loadInstructorAssignments),
      switchMap((params) => {
        return this.assignmentsService.getAssignments(params.params).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return AssignmentsActions.loadInstructorAssignmentsSuccess(data);
          }),
          catchError((error) => of(AssignmentsActions.loadInstructorAssignmentsFail({ error })))
        );
      })
    )
  );
  // Assignments by course
  loadAssignmentsByCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.loadAssignmentsByCourse),
      switchMap((params) => {
        return this.assignmentsService.getCoursesAssignments(params.params, params.courseId).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return AssignmentsActions.loadAssignmentsByCourseSuccess(data);
          }),
          catchError((error) => of(AssignmentsActions.loadAssignmentsByCourseFail({ error })))
        );
      })
    )
  );
  // Student Assignments Group By Date
  loadAssignmentsGroupByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.loadAssignmentsGroupByDate),
      switchMap((params) => {
        return this.assignmentsService.getGroupByDateStudentsAssignments(params.params, params.assignmentId).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return AssignmentsActions.loadAssignmentsGroupByDateSuccess(data);
          }),
          catchError((error) => of(AssignmentsActions.loadAssignmentsGroupByDateFail({ error })))
        );
      })
    )
  );
  // Student Assignments Review
  loadAssignmentsReviewDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.loadAssignmentsReview),
      switchMap((params) => {
        return this.assignmentsService.getAssignmentsReviews(params.assignmentId).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return AssignmentsActions.loadAssignmentsReviewSuccess(data);
          }),
          catchError((error) => of(AssignmentsActions.loadAssignmentsReviewFail({ error })))
        );
      })
    )
  );
}
