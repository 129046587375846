import { createAction, props } from '@ngrx/store';
import { PayoutOverview, PayoutType } from 'thkee-common';

export const PayoutActions = {
  loadOverview: createAction('[Payout] Load Overview'),
  loadOverviewSuccess: createAction('[Payout] Load Overview Success', props<{ payload: { overview: PayoutOverview } }>()),
  updatePayoutType: createAction('[Payout] Update Payout Type', props<{ payload: { instructorId: number, type: PayoutType } }>()),
  updatePayoutTypeSuccess: createAction('[Payout] Update Payout Type Success'),
  ignore: createAction('[Payout] ignore'),
};

