import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectActiveSubsection } from '@shared';
import { distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-learning-lab-activity-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class LearninglabArticleComponent implements OnInit {
  // @Input() data?: PartialEntity<LectureDetailV2>;
  @Input() hidePrev: boolean = false;
  @Input() hideNext: boolean = false;
  @Output() event = new EventEmitter<{ action: string; data: any }>();

  title: string = '';
  content: any = '';
  fullScreen: boolean = false;
  inputValue: number = 110;

  constructor(private store: Store) {}

  ngOnInit(): void {
    // this.store
    //   .select(selectActiveSubsectionId)
    //   .pipe(
    //     switchMap((activeSubsectionId) => this.store.select(selectSubsection(activeSubsectionId))),
    //     filter((subsection) => subsection?.type === SubsectionType.LECTURE),
    //     filter(isDefined),
    //     switchMap((subsection) => this.store.select(selectLecture(subsection.lecture ?? ''))),
    //     filter(isDefined),
    //     distinctUntilChanged(),
    //     untilDestroyed(this)
    //   )
    //   .subscribe((lecture) => {
    //     this.title = lecture?.title ?? '';
    //     this.content = lecture?.article ?? 'No Content';
    //   });

    this.store
      .select(selectActiveSubsection)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((res) => {
        this.title = res?.lecture?.title ?? '';
        this.content = res?.lecture?.article ?? $localize`No Content`;
      });
  }

  emitEvent(action: string, data: any = {}) {
    this.event.emit({ action, data });
    if (action === 'zoom-in' || action === 'zoom-out') {
      this.zoom(action);
    }
  }

  // validateInput() {
  //   if (this.inputValue < 50) {
  //     this.inputValue = 50; // Minimum value
  //   } else if (this.inputValue > 150) {
  //     this.inputValue = 150; // Maximum value
  //   }
  // }

  zoom(action: 'zoom-in' | 'zoom-out') {
    if (action === 'zoom-in' && this.inputValue !== 150) {
      this.inputValue = this.inputValue + 10;
    }
    if (action === 'zoom-out' && this.inputValue !== 50) {
      this.inputValue = this.inputValue - 10;
    }
  }

  // TODO: Update this when editor is selected and finalize
  formatArticle(articleContent: string): string {
    // Split the content into paragraphs based on double line breaks ("\n\n").
    const paragraphs = articleContent.split('\n\n');

    // Define a function to format each paragraph.
    function formatParagraph(paragraph: string): string {
      // Remove leading and trailing whitespace.
      const trimmedParagraph = paragraph.trim();

      // Add any additional formatting logic you want here.
      // For example, you can wrap paragraphs in <p> tags.
      return `<p>${trimmedParagraph}</p>`;
    }

    // Format each paragraph and join them back together.
    const formattedParagraphs = paragraphs.map(formatParagraph).join('\n');

    // You can add additional formatting to the entire article here if needed.

    return formattedParagraphs;
  }
}
