import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ObjectiveV2, PartialEntity } from '../../models';

export const objectiveAdapter: EntityAdapter<PartialEntity<ObjectiveV2>> = createEntityAdapter<
  PartialEntity<ObjectiveV2>
>({
  selectId: (e) => e.id,
});

export const initialObjectiveState = objectiveAdapter.getInitialState();

export type ObjectiveEntityState = EntityState<PartialEntity<ObjectiveV2>>;
