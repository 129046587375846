import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface CheckboxProps extends FormlyFieldProps {
  formCheck?: 'default' | 'inline' | 'switch' | 'inline-switch' | 'nolabel';
  indeterminate?: boolean;
}

export interface FormlyCheckboxFieldConfig extends FormlyFieldConfig<CheckboxProps> {
  type: 'checkbox' | Type<FormlyFieldCheckbox>;
}

@Component({
  selector: 'formly-field-checkbox',
  template: `
    <div
      class="input-check"
      [ngClass]="{
        'form-check-inline': props.formCheck === 'inline' || props.formCheck === 'inline-switch',
        'form-switch': props.formCheck === 'switch' || props.formCheck === 'inline-switch'
      }"
    >
      <input
        type="checkbox"
        [class.is-invalid]="showError"
        class="input-check"
        [class.position-static]="props.formCheck === 'nolabel'"
        [indeterminate]="props.indeterminate && formControl.value == null"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
      <label *ngIf="props.formCheck !== 'nolabel'" [for]="id" class="form-check-label ar:ml-0 ar:mr-1">
        {{ props.label }}
        <!-- <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span> -->
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldCheckbox extends FieldType<FieldTypeConfig<CheckboxProps>> {
  override defaultOptions = {
    props: {
      indeterminate: true,
      hideLabel: true,
      formCheck: 'default' as const,
    },
  };
}
