import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Geolocation } from '@shared/models/geolocation';
import { BehaviorSubject, catchError, filter, of } from 'rxjs';
import { LocalStorage, Logger } from 'thkee-common';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private geolocation$ = new BehaviorSubject<Partial<Geolocation> | undefined>(undefined);
  private httpService = new HttpClient(this.handler);
  private isLoading = false;

  constructor(private handler: HttpBackend) {
    const savedGeo = LocalStorage.getObject<Geolocation>('geolocation');
    if (!savedGeo) {
      this.loadGeo();
      return;
    }

    const isExpired = (LocalStorage.getObject<number>('geo_exp') ?? 0) < Date.now();

    isExpired ? this.loadGeo() : this.geolocation$.next(savedGeo);
  }

  get geolocation() {
    return this.geolocation$.pipe(filter(Boolean));
  }

  init() {
    if (this.isLoading) {
      return;
    }

    const savedGeo = LocalStorage.getObject<Geolocation>('geolocation');
    if (!savedGeo) {
      this.loadGeo();
      return;
    }

    const isExpired = (LocalStorage.getObject<number>('geo_exp') ?? 0) < Date.now();
    isExpired ? this.loadGeo() : this.geolocation$.next(savedGeo);
  }

  private loadGeo() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    return this.httpService
      .get<Geolocation>(environment.geolocationUrl)
      .pipe(
        catchError((err) => {
          new Logger('localStorage manager').debug(err.message);
          return of(null);
        })
      ).subscribe(geo => {
        this.isLoading = false;
        this.geolocation$.next(geo || {});
        // only cached if already fetch geo successfully, if not, we will refetch it in the next time
        if (geo) {
          const expiredDateMs = 30 * 24 * 60 * 60 * 1000; // 30 days
          LocalStorage.setValue('geo_exp', Date.now() + expiredDateMs);
          LocalStorage.setValue('geolocation', geo);
        }
      });
  }
}
