import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-RepeatButton',
  template: `
    <!-- Delete Template -->
    <ng-container *ngIf="tempalate === 'delete'">
      <div class="pl-2">
        <button class="btn border-none px-2" type="button" (click)="emitClickEvent('delete', id)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="btn-icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </button>
      </div>
    </ng-container>

    <!-- Add Template -->
    <ng-container *ngIf="tempalate === 'add'">
      <button class="btn border-none" type="button" (click)="emitClickEvent('add')">
        <span class="btn-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
          </svg>
        </span>
        <span *ngIf="field.props?.['addText']">{{ field.props?.['addText']}}</span>
        <span *ngIf="!field.props?.['addText']" i18n>Add New</span>
      </button>
    </ng-container>
  `,
})
export class FormlyRepeatButtonComponent implements OnInit {
  @Input() id: string | number = '';
  @Input() tempalate: 'delete' | 'add' = 'add';
  @Input() field!: FormlyFieldConfig;
  @Output() clickEvent = new EventEmitter<{ action: string; id: string | number }>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string, id: string | number = this.id) {
    this.clickEvent.emit({ action, id });
  }
}
