import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormlyFieldProps as CoreFormlyFieldProps, FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';

export interface FormlyCourseFeedbackProps extends CoreFormlyFieldProps {
  hideLabel?: boolean;
  hideRequiredMarker?: boolean;
  labelPosition?: 'floating';
  wrapClass?: string;
  labelClass?: string;
  tips?: string;
  addonLeft?: string;
  addonRight?: string;
  addonLeftOnClick?: any;
  addonRightOnClick?: any;
  feedback?: number;
  openFeedback?: (field: FormlyFieldConfig) => void;
}

@Component({
  selector: 'formly-wrapper-course-feedback',
  template: `
    <!-- count - {{ haveDelete | async }} -->
    <ng-template #labelTemplate>
      <label
        *ngIf="props.label && props.hideLabel !== true"
        [attr.for]="id"
        [ngClass]="{
          'text-sm': !props.labelClass,
          'flex items-center gap-3': props.tips
        }"
        [class]="props.labelClass ? props.labelClass : ''"
      >
        {{ props.label }}
        <ng-container *ngIf="props.tips">
          <svg-icon
            src="@assets/images/icons/question-mark-solid.svg"
            class="block h-5 w-5"
            tooltip="{{ props.tips }}"
          ></svg-icon>
        </ng-container>
      </label>
    </ng-template>
    <div
      class="relative"
      [class.form-floating]="props.labelPosition === 'floating'"
      [class.has-error]="showError"
      [ngClass]="props.wrapClass ? props.wrapClass : 'input-group mb-3'"
    >
      <ng-container *ngIf="props.labelPosition !== 'floating'">
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="!props.addonLeft && !props.addonRight; else inGroup">
        <ng-container *ngIf="!props.feedback; else fieldFeedback">
          <ng-template #fieldComponent></ng-template>
        </ng-container>
      </ng-container>

      <ng-template #inGroup>
        <div class="group relative w-full">
          <div
            #matPrefix
            (click)="addonLeftClick($event)"
            class="prefix"
            *ngIf="props.addonLeft"
            [innerHTML]="props.addonLeft"
          >
            {{ props.addonLeft }}
          </div>
          <!-- <ng-template #fieldComponent></ng-template> -->
          <ng-template #fieldComponent></ng-template>
          <div
            #matSuffix
            (click)="addonRightClick($event)"
            class="suffix"
            *ngIf="props.addonRight"
            [innerHTML]="props.addonRight"
          >
            {{ props.addonRight }}
          </div>
          <div class="feedback absolute right-0 top-0" *ngIf="props.feedback">
            <ng-container [ngTemplateOutlet]="feedbackTemplate"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="props.labelPosition === 'floating'">
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </ng-container>

      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>

      <ng-template #fieldFeedback>
        <div class="relative grid w-full">
          <ng-template #fieldComponent></ng-template>
          <div class="feedback absolute right-0 top-0">
            <ng-container [ngTemplateOutlet]="feedbackTemplate"></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #feedbackTemplate>
        <div class="relative">
          <div class="absolute left-4 top-[6px]" [class.left-[55px]]="haveDelete | async">
            <div
              class="btn btn-light relative rounded-full border-none p-2 text-slate-500"
              (click)="openFeedback($event)"
              *ngIf="isFeedbackNumber()"
            >
              <svg-icon
                src="@assets/images/icons/exclamation-triangle-solid.svg"
                class="btn-icon flex h-4 w-4"
              ></svg-icon>
              <span
                class="absolute -right-2 -top-2 flex h-[18px] w-[18px] items-center justify-center truncate rounded-full bg-[#FFEDD5] text-[10px] text-[#F97316]"
                >{{ props.feedback }}</span
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: ['.input-group { @apply gap-1; }'],
})
export class FormlyWrapperCourseFeedback extends FieldWrapper<FormlyFieldConfig<FormlyCourseFeedbackProps>> {
  @ViewChild('matPrefix', { static: true }) matPrefix!: TemplateRef<any>;
  @ViewChild('matSuffix', { static: true }) matSuffix!: TemplateRef<any>;

  haveDelete: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor() {
    super();
  }

  ngOnInit() {
    const haveRepeat = this.findClosestParentWithType(this.field, 'repeat');
    if (haveRepeat) {
      haveRepeat.props.fieldChanges.subscribe((data: any) => {
        this.haveDelete.next(data.isMin() ? false : true);
      });
    }
  }

  openFeedback($event: any) {
    if (this.props.openFeedback) {
      // this.props.openFeedback(this.field, this, $event);
      this.props.openFeedback(this.field);
    }
  }

  isFeedbackNumber(): boolean {
    return typeof this.props.feedback === 'number';
  }

  addonLeftClick($event: any) {
    if (this.props.addonLeftOnClick) {
      this.props.addonLeftOnClick(this.field, this, $event);
    }
  }

  addonRightClick($event: any) {
    if (this.props.addonRightOnClick) {
      this.props.addonRightOnClick(this.field, this, $event);
    }
  }

  findClosestParentWithType(obj: any, targetType: string): any {
    if (obj.type === targetType) {
      return obj;
    }
    if (obj.parent) {
      return this.findClosestParentWithType(obj.parent, targetType);
    }
    return null;
  }
}
