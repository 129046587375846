import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LearnerV2, PartialEntity } from '../../models';

export const learnerAdapter: EntityAdapter<PartialEntity<LearnerV2>> = createEntityAdapter<PartialEntity<LearnerV2>>({
  selectId: (e) => e.id,
});

export const initialLearnerState = learnerAdapter.getInitialState();

export type LearnerEntityState = EntityState<PartialEntity<LearnerV2>>;
