import { createSelector } from '@ngrx/store';
import { authFeature } from './auth.reducer';

export const {
  selectAuthState,
  selectUser,
  selectIsAuthenticated,
} = authFeature;

export const selectIsInstructor = createSelector(
  selectAuthState,
  state => {
    if (typeof state.isAuthenticated === 'boolean') {
      return state.isAuthenticated && state.user?.is_instructor
    }

    return;
  }
);
export const selectUserId = createSelector(selectUser, user => user?.id || '');
export const selectAuthCart = createSelector(selectUser, user => user?.cart);

