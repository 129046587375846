import { v4 as uuidv4 } from 'uuid';

export const generateUuid = (): string => uuidv4();

export const stringComparer = (a: string | undefined, b: string | undefined): number => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  } else {
    return a.localeCompare(b);
  }
};

export const numberComparer = (a: number | undefined, b: number | undefined): number => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  } else {
    return a - b;
  }
};
