
export enum PayoutType {
  monthly = 'monthly_payout',
  onDemand = 'ondemand_payout'
}

export type TransactionType = 'sale' | 'refund';

export type PayoutTransactionStatus =
  'pending' |
  'available' |
  'requested' |
  'ready' |
  'paid' |
  'failed' |
  'on_hold' |
  'refunded';

export type PayoutRequestStatus =
  'requested' |
  'processing' |
  'paid' |
  'failed' |
  'ready' |
  'deactivated';

export interface PayoutOverview {
  payout_period_id: number;
  status: string;
  payout_type: PayoutType;
  start_date: string;
  end_date: string;
  due_date: string;
  available_balance: number;
  pending_earnings: number;
  expected_total_earnings: number;
  instructor_id: number;
  currency_symbol: string;
  last_payout_type_updated: string;



  payout_pay_active?: false
  profile_verification_status?: 'approve' | 'pending';
  reason?: string;
}

export interface PayoutTransactionsQuery {
  page?: number;
  page_size?: number;
  event?: string[];
}

export interface PayoutTransaction {
  id: number;
  transaction_id: string;
  date_created: string;
  instructor_share: string;
  payout_status: string;
  is_refunded: false,
  currency_code: string;
}

export interface PayoutTransactionDetail {
  transaction_id: string;
  date_created: string;
  course: string;
  customer: string;
  channel_type: string;
  coupon: string,
  platform: string;
  sale_price: number;
  gateway_tax: number;
  net_amount: number;
  instructor_share: string;
  source_currency: string;
  exchange_rate: string;
  currency_code: string;
  currency_symbol: string;
}

export interface PayoutActivity {
  id: number;
  event: string;
  event_message: string;
  currency_symbol: string;
  amount: number;
  date_created: string;
}

export type PayoutMethodName = 'payoneer' | 'paypal' | 'BankAccount';

export interface PayoutMethod {
  id: number;
  payout_method: {
    id: number;
    name: PayoutMethodName;
  };
  is_active: boolean;
  is_connected: boolean;
  date_created: string;
  date_updated: string;
  bank_details: {
    address?: string;
    bank_name?: string;
    routing_number?: string;
    account_number?: string;
    beneficiary_name?: string;
  }
}

export interface PayoutWithdrawDetail {
  available_amount: number;
  payout_type: string;
  payment_methods: PayoutMethod[]
}

export interface PayoutRequestBasic {
  request_id: string;
  payout_type: string;
  payout_method: string;
  status: string;
  date_created: string;
  amount: number;
  currency_symbol: string;
}

export interface PayoutRequest extends PayoutRequestBasic {
  id: number;
  transactions_count: number;
}

export interface WithdrawDetail {
  currency_symbol: string;
  available_amount: number;
  payout_type: string;
  payment_methods: PayoutMethod[];
}
