<button *ngIf="type === 'facebook'" (click)="share()" class="btn w-10 h-10 bg-slate-100 rounded-full border-0">
   <svg-icon src="@assets/images/icons/facebook.svg" class="w-4"></svg-icon>
</button>
<button *ngIf="type === 'twitter'" (click)="share()" class="btn w-10 h-10 bg-slate-100 rounded-full border-0">
   <svg-icon src="@assets/images/icons/twitter.svg" class="w-4"></svg-icon>
</button>
<button *ngIf="type === 'linkedin'" (click)="share()" class="btn w-10 h-10 bg-slate-100 rounded-full border-0">
   <svg-icon src="@assets/images/icons/linkedin.svg" class="w-4"></svg-icon>
</button>
<button *ngIf="type === 'pinterest'" (click)="share()" class="btn w-10 h-10 bg-slate-100 rounded-full border-0">
   <svg-icon src="@assets/images/icons/pinterest.svg" class="w-4"></svg-icon>
</button>
<button *ngIf="type === 'instagram'" (click)="share()" class="btn w-10 h-10 bg-slate-100 rounded-full border-0">
   <svg-icon src="@assets/images/icons/instagram.svg" class="w-4"></svg-icon>
</button>