import { AfterViewInit, ContentChild, Directive, ElementRef, HostListener, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { combineLatestWith, Subscription } from 'rxjs';
import { DropdownMenuDirective } from './dropdown-menu.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

/**
 * place + direction
 * - `br`: `bottom` and `go to` the `right`
 * - `rb`: `right` and `go to` the `bottom`
 */
type Placement = 'br' | 'rb';

@Directive({
  selector: '[thkDropdown]',
  exportAs: 'thkDropdown'
})
export class DropdownDirective implements AfterViewInit, OnDestroy {
  @Input() placement: Placement = 'br';
  @Input() container?: 'body' = 'body';
  @ContentChild(DropdownMenuDirective) private menu?: DropdownMenuDirective;
  @ContentChild(DropdownToggleDirective) private toggleBtn!: DropdownToggleDirective;

  @Input() trigger: 'hover' | 'click' = 'click';

  showing = false;
  private subscription!: Subscription;

  @HostListener('mouseleave', ['$event'])
  private handleMouseLeave() {
    if (this.trigger === 'hover') {
      this.close();
    }
  }
  @HostListener('document:click', ['$event'])
  private closeOnClickingOutside(event: MouseEvent): void {
    if (!this.showing) {
      return;
    }

    const clickedInside = this.isInteractingInside(event);
    if (!clickedInside && this.showing) {
      this.close();
    }
  }

  constructor(
    private elRef: ElementRef<HTMLElement>,
    @Optional() @SkipSelf() private parentDropdown: DropdownDirective,
  ) {
    this.elRef.nativeElement.classList.add('relative');
  }

  private isInteractingInside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    return this.elRef.nativeElement.contains(targetElement);
  }

  ngAfterViewInit(): void {
    if (!this.toggleBtn) {
      throw 'Dropdown need a thkDropdownToggle directive as a content child to works'
    }
    this.updateMenuPosition();
  }

  open() {
    this.showing = true;
    this.menu?.show();
  }

  close(includedParent?: boolean) {
    this.showing = false;
    this.menu?.hide();
    if (includedParent && this.parentDropdown) {
      this.parentDropdown.close(true);
    }
  }

  toggle() {
    if (this.showing) {
      return this.close();
    }

    this.open();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  processToggleBtnEvent(event: MouseEvent) {
    if (this.trigger === 'hover') {
      if (event.type === 'mouseenter') {
        this.open();
      }
      return;
    }

    if (event.type === 'click' && this.trigger === 'click') {
      this.toggle()
      return;
    }
  }

  handleItemClick() {
    this.close(true);
  }

  private updateMenuPosition() {
    if (!this.menu) {
      return;
    }

    this.subscription = this.menu.rect.pipe(
      combineLatestWith(this.toggleBtn.rect$)
    ).subscribe(([menuRect]) => {
      this.menu?.updatePosition(this.getMenuPosition(menuRect))
    });
  }

  private getMenuPosition(menuRect: DOMRect): Partial<CSSStyleDeclaration> {
    const PADDING_BOTTOM = 32;

    switch (this.placement) {
      case 'br':
        return {
          top: (this.fixedPosition.top + this.toggleBtn.rect.height) + 'px',
          left: this.fixedPosition.left + 'px',
          maxHeight: `calc(100vh - ${this.toggleBtn.rect.y + this.toggleBtn.rect.height + PADDING_BOTTOM}px)`,
          overflowY: 'auto',
          // ...this.container === 'body' && { overflowY: 'auto' },
        };

      case 'rb':
        /**
         * we expect the dropdow menu will need at least 200px in height
         */
        const MIN_MENU_HEIGHT = 200; /** px */

        // FIXME:: the placement this should be dynamic base on the input,
        // currently, I made this right-bottom to be right-top
        // if the bottom space is not enough to show the menu
        const tooBottom = this.toggleBtn.rect.bottom + MIN_MENU_HEIGHT > window.innerHeight;

        return {
          top: this.fixedPosition.top + 'px',
          // left: 0 + 'px',
          left: (this.fixedPosition.left + this.toggleBtn.rect.width) + 'px',
          maxHeight: `calc(100vh - ${this.toggleBtn.rect.y + PADDING_BOTTOM}px)`,
          overflowY: 'auto',
          // ...this.container === 'body' && { overflowY: 'auto' },

          ...tooBottom && {
            transform: 'translateY(-100%)',
            maxHeight: this.toggleBtn.rect.y + 'px',
            top: (this.fixedPosition.top + this.toggleBtn.rect.height) + 'px'
          },
        };
    }

    return {};
  }

  private get fixedPosition() {
    if (this.container === 'body') {
      return { top: this.toggleBtn.rect.y, left: this.toggleBtn.rect.x };
    }

    return { top: 0, left: 0 }
  }
}
