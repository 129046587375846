import { ChangeDetectionStrategy, Component, NgZone, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
  stayPlaceholder?: boolean;
  stylish?: boolean;
  showSelected?: boolean;
  selectedDisplayText?: (options: any[]) => string;
  inputAppendClass?: string[];
  compareWith: (o1: any, o2: any) => boolean; // Make it required
}

export interface FormlySelectFieldConfig extends FormlyFieldConfig<SelectProps> {
  type: 'select' | Type<FormlyFieldSelect>;
}

@Component({
  selector: 'app-formly-field-select',
  template: `
    <ng-template #fieldTypeTemplate>
      <ng-container *ngIf="!props.stylish; else customSelect">
        <ng-container *ngIf="!props.multiple; else multiSelect">
          <ng-container *ngTemplateOutlet="singleSelect"></ng-container>
        </ng-container>
      </ng-container>

      <ng-template #customSelect>
        <ng-container *ngIf="!props.multiple; else multiCustomSelect">
          <ng-container *ngTemplateOutlet="singleCustomSelect"></ng-container>
        </ng-container>
      </ng-template>

      <ng-template #singleSelect>
        <select
          class="input-select"
          [formControl]="formControl"
          [compareWith]="props.compareWith"
          [class.is-invalid]="showError"
          [formlyAttributes]="field"
        >
          <option *ngIf="props.placeholder" [ngValue]="undefined">{{ props.placeholder }}</option>
          <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
                {{ opt.label }}
              </option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
      </ng-template>

      <ng-template #multiSelect>
        <select
          class="input-select"
          multiple
          [formControl]="formControl"
          [compareWith]="props.compareWith"
          [class.is-invalid]="showError"
          [formlyAttributes]="field"
        >
          <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
                {{ opt.label }}
              </option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
      </ng-template>

      <ng-template #singleCustomSelect>
        <div class="select2 relative w-full" (ClickOutside)="handleClickOutside()">
          <button
            type="button"
            (click)="showDropdown = !showDropdown"
            class=""
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            [disabled]="props.disabled"
            [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
            class="rtl:text-right"
          >
            <div class="selected-item truncate rtl:text-right" *ngIf="selectedLabel">{{ selectedLabel }}</div>
            <div class="selected-item placeholder truncate text-slate-500" *ngIf="!selectedLabel">
              {{ props.placeholder }}
            </div>
            <svg
              class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400 transition-all rtl:left-0"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              [ngClass]="{ 'rotate-180': showDropdown }"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </button>
          <!-- Dropdown Items -->
          <ul
            [ngClass]="showDropdown ? 'h-auto opacity-100' : 'h-0 opacity-0'"
            class="select-items"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
              <ng-container *ngFor="let opt of opts">
                <li
                  class="item"
                  id="listbox-{{ opt.value }}"
                  role="option"
                  (click)="selectDropdown(opt.value, opt.label)"
                >
                  {{ opt.label }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <div class="hidden"><ng-container *ngTemplateOutlet="singleSelect"></ng-container></div>
        </div>
      </ng-template>

      <ng-template #multiCustomSelect>
        <div class="select2 multiple relative w-full" (ClickOutside)="handleClickOutside()">
          <div class="relative">
            <button
              type="button"
              (click)="showDropdown = !showDropdown"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              [disabled]="props.disabled"
              [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
              class="rtl:text-right"
            >
              <div class="selected-items inline" *ngIf="select2Multi.length">
                <ng-container *ngIf="props.selectedDisplayText; else defaultDisplayText">
                  {{props.selectedDisplayText!(select2Multi)}}
                </ng-container>
                <ng-template #defaultDisplayText>
                  <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
                    <ng-container *ngFor="let opt of opts">
                      <div class="selected-item" [class.!hidden]="!isSelected(opt.value)">
                        <div class="flex items-center gap-1">
                          <div class="truncate">{{ opt.label }}</div>
                          <div (click)="removeMultiDropdown(opt.value)" [class.hidden]="props.disabled">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="h-3 w-3"
                            >
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>

              <div
                class="selected-item placeholder inline truncate text-slate-500"
                *ngIf="!select2Multi.length && !props.stayPlaceholder"
              >
                {{ props.placeholder }}
              </div>

              <div
                class="selected-item placeholder inline inline truncate text-neutral-500"
                [class.leading-8]="select2Multi.length"
                [class.hidden]="(props.disabled && select2Multi.length) || select2Multi.length == totalOptions"
                *ngIf="props.stayPlaceholder"
              >
                {{ props.placeholder }}
              </div>
              <svg
                class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400 transition-all rtl:left-0"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': showDropdown }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="showDropdown ? 'h-auto opacity-100' : 'h-0 opacity-0'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
                <ng-container *ngFor="let opt of opts">
                  <li
                    class="item"
                    id="listbox-option-0"
                    role="option"
                    (click)="selectMultiDropdown(opt.value)"
                    [class.hidden]="!props.showSelected && isSelected(opt.value)"
                  >
                    <div class="w-full relative" [ngClass]="props.showSelected ? 'ltr:pl-5 rtl:pr-5' : ''">
                      <span *ngIf="props.showSelected && isSelected(opt.value)" class="absolute ltr:left-0 rtl:right-0 top-[50%] transform translate-y-[-50%] text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                      </span>
                      {{ opt.label }}
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="hidden"><ng-container *ngTemplateOutlet="multiSelect"></ng-container></div>
        </div>
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig<SelectProps>> {
  showDropdown: boolean = false;
  selectedLabel: string = '';
  totalOptions: number = 0;

  override defaultOptions = {
    props: {
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
    },
  };

  // workaround for https://github.com/angular/angular/issues/10010
  /**
   * TODO: Check if this is still needed
   */
  @ViewChild(SelectControlValueAccessor) set selectAccessor(s: any) {
    if (!s) {
      return;
    }

    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }

    s.writeValue = (value: any) => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable
          .asObservable()
          .pipe(take(1))
          .subscribe(() => {
            if (
              id !== s._idCounter &&
              s._getOptionId(value) === null &&
              s._elementRef.nativeElement.selectedIndex !== -1
            ) {
              writeValue(value);
            }
          });
      }
    };
  }

  constructor(private ngZone: NgZone, hostContainerRef: ViewContainerRef) {
    super(hostContainerRef);
    this.props.stylish = true;
  }

  ngOnInit(): void {
    // this.props.stylish = true;
    this.totalOptions = _.size(this.props.options);
    if (this.formControl.value) {
      let selectedOption = _.find(this.props.options, { value: this.formControl.value });
      if (selectedOption) this.selectedLabel = selectedOption['label'];
      this.select2Multi = this.formControl.value;
    }

    this.field.formControl.valueChanges.subscribe((value) => {
      if (!value || value == '') {
        // Update selected label state to blank if value is set to null or blank
        this.selectedLabel = '';
      }
    });
  }

  selectDropdown(value: any, label?: string) {
    this.selectedLabel = label ? label : '';
    this.formControl.setValue(value);
    this.showDropdown = false;
  }

  select2Multi: any[] = [];
  isSelected(value?: string) {
    if (value) {
      return _.includes(this.select2Multi, value);
    }
    return false;
  }

  selectMultiDropdown(value?: string) {
    if (value) {
      if (this.select2Multi.includes(value)) {
        return this.removeMultiDropdown(value);
      }
      this.select2Multi = [...this.select2Multi, value];
    }
    this.select2Multi = _.uniq(this.select2Multi);
    this.formControl.setValue(this.select2Multi);
    this.showDropdown = true;
  }

  removeMultiDropdown(value?: string) {
    if (value) {
      try {
        this.select2Multi = _.remove(this.select2Multi, (q, w) => {
          return q != value;
        });
      } catch (error) {
        const indexToRemove = this.select2Multi.indexOf(value);
        this.select2Multi = _.values(_.omit(this.select2Multi, [indexToRemove]));
      }
    }
    this.formControl.setValue(this.select2Multi);
    this.showDropdown = true;
  }

  handleClickOutside() {
    this.showDropdown = false;
  }
}
