import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { STUDENT_TAB, TabItem } from '@shared/models/tabs-constant';
import { selectUrl } from 'thkee-common';

@Component({
  selector: 'app-student-header',
  templateUrl: './student-header.component.html',
  styleUrls: ['./student-header.component.scss'],
})
export class StudentHeaderComponent implements OnInit {
  @Input() title: string = 'My Classes';
  @Input() tabItems: TabItem[] = STUDENT_TAB;
  activeRoute$ = this.store.select(selectUrl);

  constructor(private readonly store: Store) {}

  ngOnInit(): void {}
}
