import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoursesDetailsService } from '@shared/services/public/courses.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { CourseDetailsActions } from './course.actions';

@Injectable()
export class CourseDetailsEffects {
  constructor(private actions$: Actions, private coursesService: CoursesDetailsService) {}

  loadQNAGroupByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseDetailsActions.loadCourseDetails),
      switchMap((params) => {
        return this.coursesService.getCourseData(params.slug).pipe(
          map((data) => {
            return CourseDetailsActions.loadCourseDetailsSuccess(data);
          }),
          catchError((error) => of(CourseDetailsActions.loadCourseDetailsFail({ error })))
        );
      })
    )
  );
}
