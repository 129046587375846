import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectLectureResourcesState } from './course.selectors';

export const selectLectureResources = createSelector(selectLectureResourcesState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectLectureResource = (lectureResourceId: string) =>
  createSelector(selectLectureResourcesState, (state) => state.entities[lectureResourceId]);

export const selectLectureResoucesByLectureId = (lectureId: string) =>
  createSelector(selectLectureResources, (lectureResources) =>
    lectureResources.filter((lr) => lr.lecture === lectureId)
  );

export const selectLectureResourceEntities = createSelector(selectLectureResourcesState, (state) => state.entities);
