import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { GeolocationService } from '@shared/services/public/geolocation.service';
import { AuthActions } from '@shared/store';
import { uniqBy } from 'lodash';
import { map, take } from 'rxjs';
import { ActionTrackerService, Logger, NotificationMessage } from 'thkee-common';

const log = new Logger('Shared/app-form-signup');
const signupErrors = {
  invalid: $localize`Email is already in use`,
  password_too_short: $localize`This password is too short. It must contain at least 8 characters.`,
  password_too_common: $localize`This password is too common.`,
  password_entirely_numeric: $localize`This password is entirely numeric.`,
  default: $localize`There are something wrong. Please try again!`,
  unique: $localize`User with this email already exists.`,
}

@UntilDestroy()
@Component({
  selector: 'app-form-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class FormSignupComponent implements OnInit {
  messages = <NotificationMessage[]>[];
  isSubmitting = false;
  readonly form = new FormGroup({});
  readonly model: any = {};
  readonly fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: $localize`Name`,
        placeholder: $localize`Enter your full name`,
        required: true,
      },
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: $localize`Email`,
        placeholder: $localize`Enter your email`,
        required: true,
      },
      validators: {
        validation: ['email'],
      },
    },
    {
      key: 'password',
      type: 'input-password',
      props: {
        label: $localize`Password`,
        placeholder: $localize`Enter your password`,
        required: true,
        type: 'password',
        minLength: 8,
      },
      validation: {
        messages: {
          required: $localize`Password is required!`,
        },
      }
    },
  ];

  private readonly SIGNUP_CONTEXT_ID = ActionTrackerService.uniqToken();

  constructor(
    private readonly store: Store,
    private actionTracker: ActionTrackerService,
    private geoService: GeolocationService
  ) {}

  ngOnInit(): void {
    this.actionTracker.getFailed(this.SIGNUP_CONTEXT_ID).pipe(untilDestroyed(this)).subscribe(record => {
      this.isSubmitting = false;
      this.form.enable();
      const messages = (record.metadata?.messages || [])
        .map(m => ({ ...m, message: signupErrors[m.message] || signupErrors.default }));
      this.messages = uniqBy(messages, 'message');
    });
  }

  onSignup(): void {
    log.debug(this.model);
    if (this.form.valid) {
      this.messages = [];
      this.isSubmitting = true;
      this.form.disable();
      this.getGeoInformation().pipe(take(1)).subscribe(geo => {
        this.store.dispatch(
            AuthActions.signup({
              ...this.model,
              geo,
              contextId: this.SIGNUP_CONTEXT_ID
            })
          );
        }
      );
    }
  }

  private getGeoInformation() {
    return this.geoService.geolocation.pipe(
      map(geo => {
        return {
          country: geo.country,
          currency: geo.currency
        };
      })
    );
  }
}
