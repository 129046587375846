import { createFeature, createReducer, on } from '@ngrx/store';
import { InstructorQNAActions } from './qna.actions';
import { initialQNAState } from './qna.model';

export const instructorQNAReducer = createReducer(
  initialQNAState,
  // QNA Group By Date
  on(InstructorQNAActions.loadInstructorQNASuccess, (state, data) => {
    return {
      ...state,
      qnaGroupByDate: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),
  // QNA By Course
  on(InstructorQNAActions.loadInstructorQNAByCourseSuccess, (state, data) => {
    return {
      ...state,
      qnaCourses: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),
  // QNA Reply
  on(InstructorQNAActions.loadInstructorQNAReplySuccess, (state, data) => {
    return {
      ...state,
      qnaCoursesReply: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  })
  // // Assignment by course
  // on(AssignmentsActions.loadAssignmentsByCourseSuccess, (state, data) => {
  //   return {
  //     ...state,
  //     courses_assignments: data.results,
  //     next: data.next,
  //     previous: data.previous,
  //     count: data.count,
  //   };
  // }),
  // // Student Assignments Group By Date
  // on(AssignmentsActions.loadAssignmentsGroupByDateSuccess, (state, data) => {
  //   return {
  //     ...state,
  //     assignments_group: data.results,
  //     next: data.next,
  //     previous: data.previous,
  //     count: data.count,
  //   };
  // }),
  // // Student Assignments Review
  // on(AssignmentsActions.loadAssignmentsReviewSuccess, (state, data) => {
  //   return {
  //     ...state,
  //     assignments_review: data.results,
  //     next: data.next,
  //     previous: data.previous,
  //     count: data.count,
  //   };
  // })
);

export const instructorQNAFeature = createFeature({
  name: 'instructorQNA',
  reducer: instructorQNAReducer,
});
