import { Coupon, PartialEntity } from 'thkee-common';

export const cartKey = 'thk_cart';

export type CartItemData = {
  id: string;
  courseId: string;
  code: string;
};

export type CartState = {
  cartId: string;
  courses: (string | number)[];
  coursesData: PartialEntity<cartItem>[] | cartItem[] | any[]; //TODO Fix the type
  coupon: Coupon[];
  credit: number;
  total: number;
  availableCredit: number;
  error: object;
  // guestData: CartState | null;
  coursePrices: { [key: string]: cartPriceItem };
  currency: string;
  symbol: string;
  subtotal: number;
  total_discount_amount: number;
  total_discount_percentage: number;
  final_total_amount: number;
};

// item.interface.ts
export type cartPriceItem = {
  price: number;
  discount_price: number | null;
  currency: string;
  price_string: string;
};

export const initialCartState: CartState = {
  cartId: '',
  courses: [],
  coursesData: [],
  coupon: [],
  credit: 0,
  total: 0,
  availableCredit: 0,
  error: {},
  coursePrices: {},
  currency: '',
  symbol: '',
  subtotal: 0,
  total_discount_amount: 0,
  total_discount_percentage: 0,
  final_total_amount: 0,
};

export interface cartItem {
  id: string;
  course_id?: string;
  code?: string;
  name: string;
  image?: string;
  image_url?: string;
  price: number | string;
  sale?: number | string;
  sale_duration?: string;
  sale_left?: string;
  author: string;
  rate: number | string;
  star: number;
  review: number | string;
  favorite?: boolean;
}
