<div class="grid grid-cols-12 gap-8">
  <!-- Filter -->
  <div
    [ngClass]="showFilter?'translate-x-0 opacity-100 bg-white':'-translate-x-full opacity-0 md:translate-x-0 md:opacity-100'"
    class="block absolute w-60 z-30 transition-all md:w-auto md:relative md:col-span-4 lg:col-span-3">
    <ng-container *ngTemplateOutlet="filter"></ng-container>
    <button class="btn btn-primary px-2 hover:shadow absolute top-0 right-[-46px] md:hidden"
      (click)="showFilter = !showFilter">
      <svg-icon src="@assets/images/icons/x-mark.svg" class="btn-icon"></svg-icon>
    </button>
  </div>
  <div class="col-span-12 md:col-span-8 lg:col-span-9 overflow-hidden">
    <!-- Short -->
    <div class="heading mb-6 flex items-center justify-between px-0 md:px-4 lg:px-4 xl:px-4">
      <div class="font-medium" i18n>{{ totalCoursesAmount }} Course found</div>
      <div class="action flex items-center gap-4">
        <button class="btn btn-primary px-2 hover:shadow md:hidden" (click)="showFilter = !showFilter">
          <svg-icon src="@assets/images/icons/adjustments-horizontal.svg" class="btn-icon"></svg-icon>
        </button>
        <div class="text-sm font-medium" i18n>Sort By:</div>
        <div class="sortby relative w-40">
          <form [formGroup]="sortByForm">
            <formly-form [form]="sortByForm" [fields]="fieldsFilter" [(model)]="sortByModel"></formly-form>
          </form>
        </div>
      </div>
    </div>
    <div class="course-items relative">
      <ng-container *ngIf="!totalCoursesAmount && coursesLoaded">
        <ng-container *ngTemplateOutlet="emptyCourses"></ng-container>
      </ng-container>
      <div
        class="course-grid grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-8 xl:grid-cols-3 xl:gap-8 px-0 md:px-4 lg:px-4 xl:px-4">
        <div *ngFor="let course of courses" class="fadeIn">
          <app-course-card [loadingPrices]="filterCoursesLoader.loadingPrices" [isCardLoader]="true"
            [courseData]="course" [disabledHover]="false" [mobileView]="true"></app-course-card>
        </div>
      </div>
      <div class="loader py-6" #loadMore>
        <svg *ngIf="!coursesLoaded" class="text-primary m-auto h-8 w-8 animate-spin"
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </div>
    </div>
  </div>
</div>

<ng-template #filter>
  <div class="search mb-6">
    <div class="input-group w-full max-w-[400px]">
      <div class="group">
        <svg-icon src="@assets/images/icons/search.svg" svgClass="!text-primary"></svg-icon>
        <input (keyup.enter)="searchCourses()" [(ngModel)]="searchKeyword" i18n-placeholder placeholder="Search Courses..." />
      </div>
    </div>
  </div>
  <ng-container>
    <div class="filter-options flex flex-col gap-6 rounded-lg border border-slate-200 bg-white px-3 py-6">
      <div class="heading flex items-center justify-between">
        <div class="text-lg font-medium leading-[18px]" i18n>Filters</div>
        <div class="text-red cursor-pointer text-xs font-medium underline underline-offset-4" (click)="
            clearFilter()
          " i18n>Clear Filter</div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['price'] || []).length" class="price">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['price'] = !hideFilter['price']">
            <div class="font-medium uppercase text-xs" i18n>Price</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['price']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['price']">
            <app-filter-option key="price" [options]="formattedFilterOptions['price']"
              [defaultValue]="initialFilterValue['price']" (onEvent)="filterHandler($event)"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['subcategory'] || []).length" class="sub-category" [class.hidden]="!(formattedFilterOptions['subcategory'] || []).length">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['subcategory'] = !hideFilter['subcategory']">
            <div class="font-medium uppercase text-xs" i18n>Sub Category</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['subcategory']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['subcategory']">
            <app-filter-option key="subcategory" [options]="formattedFilterOptions['subcategory']"
              [defaultValue]="initialFilterValue['subcategory']" (onEvent)="filterHandler($event)"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['courselength'] || []).length" class="course-length">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['courselength'] = !hideFilter['courselength']">
            <div class="font-medium uppercase text-xs" i18n>Course Length</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['courselength']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['courselength']">
            <app-filter-option key="courselength" [options]="formattedFilterOptions['courselength']"
              [defaultValue]="initialFilterValue['courselength']" (onEvent)="filterHandler($event)"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['rating'] || []).length" class="rating">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['rating'] = !hideFilter['rating']">
            <div class="font-medium uppercase text-xs" i18n>Rating</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['rating']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['rating']">
            <app-filter-option key="rating" [options]="formattedFilterOptions['rating']"
              [defaultValue]="initialFilterValue['rating']" (onEvent)="filterHandler($event)"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['level'] || []).length" class="level">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['level'] = !hideFilter['level']">
            <div class="font-medium uppercase text-xs" i18n>Level</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['level']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['level']">
            <app-filter-option key="level" [options]="formattedFilterOptions['level']"
              [defaultValue]="initialFilterValue['level']" (onEvent)="filterHandler($event)"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['language'] || []).length" class="language">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['language'] = !hideFilter['language']">
            <div class="font-medium uppercase text-xs" i18n>Language</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['language']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['language']">
            <app-filter-option key="language" [options]="formattedFilterOptions['language']"
              [defaultValue]="initialFilterValue['language']" (onEvent)="filterHandler($event)" [limit]="4"></app-filter-option>
          </div>
        </div>
      </div>
      <div [class.hidden]="!(formattedFilterOptions['topic'] || []).length" class="topic">
        <div class="filter-box">
          <div class="heading mb-4 flex cursor-pointer items-center justify-between bg-slate-50 rounded py-2 px-3"
            (click)="hideFilter['topic'] = !hideFilter['topic']">
            <div class="font-medium uppercase text-xs" i18n>Topics</div>
            <div>
              <svg-icon [class.rotate-180]="hideFilter['topic']" class="btn-icon block transition-all"
                src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
          <div class="filter-options mb-0 px-4" [class.hide]="hideFilter['topic']">
            <app-filter-option key="topic" [options]="formattedFilterOptions['topic']"
              [defaultValue]="initialFilterValue['topic']" (onEvent)="filterHandler($event)" [limit]="6"></app-filter-option>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyCourses>
  <div class="flex p-16 text-center">
    <div class="m-auto">
      <svg-icon src="@assets/images/empty-cart.svg" class="m-auto mb-6 block h-auto w-56"></svg-icon>
      <p class="mb-8 text-slate-700" i18n>No courses was found.</p>
    </div>
  </div>
</ng-template>
