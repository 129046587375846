import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { QnAReply, selectUser } from '@shared/store';

@Component({
  selector: 'app-qa-a-card',
  templateUrl: './qa-a-card.component.html',
  styleUrls: ['./qa-a-card.component.scss'],
})
export class QaACardComponent implements OnInit {
  authUser$ = this.store.select(selectUser);
  @Input() data!: QnAReply;
  @Output() selectDropDownEvent = new EventEmitter<Object>();

  constructor(private store: Store) {}

  ngOnInit(): void {}

  emitClickEvent(action: string, id: string, comment: string) {
    if (action === 'edit-comment') {
      this.selectDropDownEvent.emit({ action: action, id: id, comment: comment });
    } else {
      this.selectDropDownEvent.emit({ action: action, id: id });
    }
  }
}
