import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssignmentQuestionV2, PartialEntity } from '../../models';

export const assignmentQuestionAdapter: EntityAdapter<PartialEntity<AssignmentQuestionV2>> = createEntityAdapter<
  PartialEntity<AssignmentQuestionV2>
>({
  selectId: (e) => e.id,
});

export const initialAssignmentQuestionState = assignmentQuestionAdapter.getInitialState();

export type AssignmentQuestionEntityState = EntityState<PartialEntity<AssignmentQuestionV2>>;
