import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ModalData = {
  title: string;
  message: string;
  confirmText?: string;
  confirmColor?: 'primary' | 'secondary' | 'red' | 'green' | 'dark';
  hasCancel?: boolean;
  onConfirm?: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modal$ = new BehaviorSubject<ModalData | undefined>(undefined);
  public modal$ = this._modal$.asObservable();

  constructor() {}

  confirm(data: ModalData) {
    this._modal$.next({
      ...data,
      confirmText: data.confirmText ?? 'Confirm',
      confirmColor: data.confirmColor ?? 'primary',
      hasCancel: true,
    });
  }

  message(data: ModalData) {
    this._modal$.next({
      ...data,
      confirmText: data.confirmText ?? 'OK',
      confirmColor: data.confirmColor ?? 'primary',
      hasCancel: false,
    });
  }
}
