import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: unknown): string {
    if (typeof value !== 'string') {
      return '';
    }

    const date = moment.utc(value as string);
    const timeUnits: any[] = ['year', 'month', 'day', 'hour', 'minute'];
    const diffs = timeUnits.map((unit) => moment().diff(date, unit));
    const index = diffs.findIndex((diff) => diff > 0);

    if (index !== -1) {
      const diff = diffs[index];
      const unit = timeUnits[index];
      return `${diff} ${unit}${diff === 1 ? ' ago' : 's ago'}`;
    } else {
      return 'Just now';
    }
  }
}
