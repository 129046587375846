import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import {
  Category,
  CategoryV2,
  Course,
  CourseAssignmentAnswer,
  CourseDataV2,
  CourseListPayload,
  CourseQnA,
  CourseQnAPayload,
  CourseQnAReply,
  CourseQnAReplyPayload,
  CourseQnAResponse,
  CourseQuizAnswer,
  CourseQuizAnswerPayload,
  FilterOption,
  Pagination,
  SubCategoryV2,
  TopicV2,
} from '../../models';
import {
  API_CONSTANTS_LANGUAGES,
  API_CONSTANTS_SKILL_LEVELS,
  API_COURSES,
  API_COURSES_ASSIGNMENT,
  API_COURSES_CATEGORIES,
  API_COURSES_DETAILS_GET_PUBLIC,
  API_COURSES_GET_QNA,
  API_COURSES_PUBLIC_SUBCATEGORIES,
  API_COURSES_PUBLIC_TOPICS,
  API_COURSES_QNA,
  API_COURSES_QNA_ADDREPLY,
  API_COURSES_QUIZ,
  API_COURSES_TOPCATEGORIES,
  API_COURSES_TOPICS,
  API_PUBLISHED_COURSES,
} from '../../models/constants';
import { encodeURL } from '../../utils';
import { HttpService } from '../http';
import { Logger } from '../logger';

const log = new Logger('CoursesService');

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(private readonly http: HttpService) {}

  getCourseData(courseId: string): Observable<CourseDataV2> {
    return this.http.get<CourseDataV2>(API_COURSES_DETAILS_GET_PUBLIC.replace('<COURSE_ID>', courseId));
    // return this.http.get<CourseDataV2>(API_COURSES_DATA_GET_PUBLIC.replace('<COURSE_ID>', courseId));
  }

  getCourses(params = {} as CourseListPayload): Observable<Pagination<Partial<Course>>> {
    log.debug('API REQUEST getCourses - ', encodeURL(API_PUBLISHED_COURSES, params));
    return this.http.get<Pagination<Partial<Course>>>(encodeURL(API_PUBLISHED_COURSES, params));
  }

  getLanguages(params = {} as object): Observable<FilterOption[]> {
    log.debug('API REQUEST getLanguages - ', encodeURL(API_CONSTANTS_LANGUAGES, params));
    return this.http.get<FilterOption[]>(encodeURL(API_CONSTANTS_LANGUAGES, params));
  }

  getSkillLevels(params = {} as object): Observable<FilterOption[]> {
    log.debug('API REQUEST getSkillLevels - ', encodeURL(API_CONSTANTS_SKILL_LEVELS, params));
    return this.http.get<FilterOption[]>(encodeURL(API_CONSTANTS_SKILL_LEVELS, params));
  }

  getStudentCourses(params = {} as object): Observable<Pagination<Partial<Course>>> {
    log.debug('API REQUEST getCourses - ', encodeURL(API_COURSES, params));
    //return this.http.get<Course[]>(encodeURL(API_COURSES, params));
    const dummyData: Partial<Course>[] = [
      {
        progress: 20,
        article_count: 3,
        slug: 'ultimate-aws-certified-developer-associate-20-20',
        id: 'bb415ca2-4ae5-49a3-94b4-7d826052fd28',
        code: 'bb415ca2-4ae5-49a3-94b4-7d826052fd28',
        user: {
          id: '46',
          first_name: 'Administration',
          last_name: 'Thkee',
          bio: '{"des":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sitaspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet", "skills":"Javascript, Front-End"}',
          language: 'en',
          email: 'admin@thkee.com',
        },
        title: 'Ultimate AWS Certified Developer Associate 20-20',
        subtitle: 'html other details',
        description: '',
        pricing: {
          name: '',
          price_tier_status: false,
        },
        currency: 'USD',
        image:
          'https://s3.me-south-1.amazonaws.com/green-assets/users/46/courses/bb415ca2-4ae5-49a3-94b4-7d826052fd28/Screenshot_from_2023-04-28_15-12-49.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY5WUWOFQ6LUEMNXH%2F20230803%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230803T145802Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c1a33594154474bab576d7834d078ad0df95762ede0f77c6d76984db40cd4f7a',
        desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sitaspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,',
        promo_video:
          'https://s3.me-south-1.amazonaws.com/green-assets/users/46/courses/bb415ca2-4ae5-49a3-94b4-7d826052fd28/sample-5s.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY5WUWOFQ6LUEMNXH%2F20230803%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230803T145803Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4ec4dc5908dc28eee8c7aa16edc2b9e39b944eeacc707fd3bae52d8a88deabb9',
        skill_level: 'All',
        video_info: {
          size: 44502063.0,
          status: 'completed',
          duration: 1098.118067,
        },
        is_favorite: false,
        is_in_cart: false,
        owned: true,
        duration: 160.397199,
        price: 99,
        sale_price: 100,
        sections_total: 3,
        is_rated: false,
        language: 'ar',
        date_updated: '2023-07-18T17:00:50.710938Z',
        lecture_total: 10,
        student_total: 18,
        total_rate: 2.7,
        total_reviews: 3,
        is_bestseller: false,
        objectives: [
          'Students with basic understanding of tech pdf',
          'Professionals',
          'Students with basic understanding of tech',
          'Putting other details for testing',
        ],
      },
      {
        progress: 66,
        article_count: 5,
        slug: 'learn-react-redux',
        id: '8f3a1b3a-17d3-4c78-97fc-c9e585ab0c4d',
        code: '8f3a1b3a-17d3-4c78-97fc-c9e585ab0c4d',
        user: {
          id: '123',
          first_name: 'John',
          last_name: 'Doe',
          headline: 'Full-stack Developer',
          bio: '{"des":"I am a passionate full-stack developer with expertise in building web applications using modern technologies.", "skills":"JavaScript, React, Node.js, MongoDB"}',
          language: 'en',
          email: 'john.doe@example.com',
          photo: 'https://example.com/profiles/john-doe.jpg',
        },
        title: 'Learn React & Redux',
        subtitle: 'Build modern web applications',

        pricing: {
          price: 49.99,
          name: 'Standard',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/learn-react-redux.jpg',
        desc: 'I am a passionate full-stack developer with expertise in building web applications using modern technologies.',
        promo_video: 'https://example.com/videos/learn-react-redux-promo.mp4',
        skill_level: 'Intermediate',
        video_info: {
          size: 29874653.0,
          status: 'completed',
          duration: 820.45,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/123/courses/8f3a1b3a-17d3-4c78-97fc-c9e585ab0c4d/dest/mpeg-dash/learn-react-redux-video.mp4/playlist.mpd',
          },
        },
        is_favorite: false,
        is_in_cart: false,
        owned: true,
        duration: 48.32,
        price: 49.99,
        sale_price: 59.99,
        sections_total: 4,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T10:45:30.482013Z',
        lecture_total: 25,
        student_total: 150,
        total_rate: 4.8,
        total_reviews: 45,
        is_bestseller: true,
        objectives: [
          'Build modern web applications using React and Redux',
          'Learn how to manage state with Redux',
          'Create reusable React components',
          'Use Redux middleware for asynchronous actions',
        ],
      },
      {
        progress: 37,
        article_count: 8,
        slug: 'introduction-to-python',
        id: 'e64f89f2-2453-47c9-b55e-e9a152bd52a1',
        code: 'e64f89f2-2453-47c9-b55e-e9a152bd52a1',
        user: {
          id: '567',
          first_name: 'Jane',
          last_name: 'Smith',
          headline: 'Data Scientist',
          bio: '{"des":"As a data scientist, I enjoy teaching Python programming to beginners and helping them kickstart their coding journey.", "skills":"Python, Data Analysis, Machine Learning"}',
          language: 'en',
          email: 'jane.smith@example.com',
          photo: 'https://example.com/profiles/jane-smith.jpg',
        },
        title: 'Introduction to Python Programming',
        subtitle: 'Kickstart your coding journey',
        pricing: {
          price: 29.99,
          name: 'Standard',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/introduction-to-python.jpg',
        desc: 'As a data scientist, I enjoy teaching Python programming to beginners and helping them kickstart their coding journey.',
        promo_video: 'https://example.com/videos/introduction-to-python-promo.mp4',
        skill_level: 'Beginner',
        video_info: {
          size: 15678953.0,
          status: 'completed',
          duration: 340.25,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/567/courses/e64f89f2-2453-47c9-b55e-e9a152bd52a1/dest/mpeg-dash/introduction-to-python-video.mp4/playlist.mpd',
          },
        },
        is_favorite: true,
        is_in_cart: false,
        owned: true,
        type: 'Paid',
        is_promote: true,
        duration: 20.5,
        price: 29.99,
        sections_total: 3,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T11:30:40.882819Z',
        lecture_total: 15,
        student_total: 80,
        total_rate: 4.5,
        total_reviews: 12,
        is_bestseller: false,
        objectives: [
          'Learn Python syntax and data types',
          'Understand control structures and loops',
          'Build simple Python programs',
        ],
      },
      {
        article_count: 12,
        slug: 'photography-masterclass',
        id: 'c2b0e0dd-71e3-4a9c-aa20-5f783c4ab87d',
        code: 'c2b0e0dd-71e3-4a9c-aa20-5f783c4ab87d',
        user: {
          id: '789',
          first_name: 'Michael',
          last_name: 'Johnson',
          headline: 'Professional Photographer',
          bio: '{"des":"I am a professional photographer with over 15 years of experience, and I\'m excited to share my knowledge and passion for photography in this masterclass.", "skills":"Photography, Adobe Lightroom, Composition"}',
          language: 'en',
          email: 'michael.johnson@example.com',
          photo: 'https://example.com/profiles/michael-johnson.jpg',
        },
        title: 'Photography Masterclass',
        subtitle: 'Unlock your photography skills',
        pricing: {
          price: 79.99,
          name: 'Premium',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/photography-masterclass.jpg',
        desc: "I am a professional photographer with over 15 years of experience, and I'm excited to share my knowledge and passion for photography in this masterclass.",
        promo_video: 'https://example.com/videos/photography-masterclass-promo.mp4',
        skill_level: 'Intermediate',
        video_info: {
          size: 40789534.0,
          status: 'completed',
          duration: 120.75,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/789/courses/c2b0e0dd-71e3-4a9c-aa20-5f783c4ab87d/dest/mpeg-dash/photography-masterclass-video.mp4/playlist.mpd',
          },
        },
        is_favorite: false,
        is_in_cart: false,
        owned: false,
        type: 'Paid',
        is_promote: true,
        duration: 45.25,
        price: 79.99,
        sale_price: 59.99,
        sections_total: 5,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T12:15:20.990225Z',
        lecture_total: 20,
        student_total: 240,
        total_rate: 4.9,
        total_reviews: 35,
        is_bestseller: true,
        objectives: [
          'Master composition techniques',
          'Understand lighting and exposure',
          'Learn post-processing with Adobe Lightroom',
        ],
      },
      {
        progress: 100,
        article_count: 6,
        slug: 'financial-planning-101',
        id: 'd2657dd2-8c02-4df5-bbff-4d6b7b8b5e90',
        code: 'd2657dd2-8c02-4df5-bbff-4d6b7b8b5e90',
        user: {
          id: '321',
          first_name: 'Emily',
          last_name: 'Brown',
          headline: 'Certified Financial Planner',
          bio: '{"des":"As a certified financial planner, I aim to help individuals understand the basics of financial planning, budgeting, and investing for a secure future.", "skills":"Financial Planning, Budgeting, Investment"}',
          language: 'en',
          email: 'emily.brown@example.com',
          photo: 'https://example.com/profiles/emily-brown.jpg',
        },
        title: 'Financial Planning 101',
        subtitle: 'Secure your financial future',
        pricing: {
          price: 59.99,
          name: 'Standard',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/financial-planning-101.jpg',
        desc: 'As a certified financial planner, I aim to help individuals understand the basics of financial planning, budgeting, and investing for a secure future.',
        promo_video: 'https://example.com/videos/financial-planning-101-promo.mp4',
        skill_level: 'Beginner',
        video_info: {
          size: 27896121.0,
          status: 'completed',
          duration: 200.15,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/321/courses/d2657dd2-8c02-4df5-bbff-4d6b7b8b5e90/dest/mpeg-dash/financial-planning-101-video.mp4/playlist.mpd',
          },
        },
        is_favorite: true,
        is_in_cart: false,
        owned: true,
        type: 'Paid',
        is_promote: true,
        duration: 12.75,
        price: 59.99,
        sections_total: 4,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T13:00:15.372205Z',
        lecture_total: 8,
        student_total: 120,
        total_rate: 4.6,
        total_reviews: 20,
        is_bestseller: false,
        objectives: [
          'Create a budget and manage finances effectively',
          'Understand different investment options',
          'Plan for retirement and emergencies',
        ],
      },
      {
        article_count: 9,
        slug: 'digital-marketing-fundamentals',
        id: 'a2b4b6d3-6b26-47ae-b75c-8f9a3d3e580f',
        code: 'a2b4b6d3-6b26-47ae-b75c-8f9a3d3e580f',
        user: {
          id: '999',
          first_name: 'Alex',
          last_name: 'Davis',
          headline: 'Digital Marketing Expert',
          bio: '{"des":"As a digital marketing expert, I aim to help beginners understand the core concepts of digital marketing and its applications in today\'s competitive landscape.", "skills":"Digital Marketing, SEO, Social Media Marketing"}',
          language: 'en',
          email: 'alex.davis@example.com',
          photo: 'https://example.com/profiles/alex-davis.jpg',
        },
        title: 'Digital Marketing Fundamentals',
        subtitle: 'Master online marketing strategies',
        pricing: {
          price: 39.99,
          name: 'Standard',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/digital-marketing-fundamentals.jpg',
        desc: "As a digital marketing expert, I aim to help beginners understand the core concepts of digital marketing and its applications in today's competitive landscape.",
        promo_video: 'https://example.com/videos/digital-marketing-fundamentals-promo.mp4',
        skill_level: 'Beginner',
        video_info: {
          size: 31682156.0,
          status: 'completed',
          duration: 180.92,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/999/courses/a2b4b6d3-6b26-47ae-b75c-8f9a3d3e580f/dest/mpeg-dash/digital-marketing-fundamentals-video.mp4/playlist.mpd',
          },
        },
        is_favorite: true,
        is_in_cart: false,
        owned: false,
        type: 'Paid',
        is_promote: true,
        duration: 8.25,
        price: 39.99,
        sale_price: 50,
        sections_total: 5,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T14:20:05.440781Z',
        lecture_total: 10,
        student_total: 160,
        total_rate: 4.7,
        total_reviews: 25,
        is_bestseller: true,
        objectives: [
          'Understand digital marketing channels',
          'Implement effective SEO strategies',
          'Create engaging social media campaigns',
        ],
      },
      {
        article_count: 7,
        slug: 'artificial-intelligence-essentials',
        id: '12b8cf3e-9e36-4a95-aa0e-bb0d6877b7e5',
        code: '12b8cf3e-9e36-4a95-aa0e-bb0d6877b7e5',
        user: {
          id: '111',
          first_name: 'Daniel',
          last_name: 'Lee',
          headline: 'AI Researcher',
          bio: '{"des":"As an AI researcher, I am enthusiastic about simplifying complex AI concepts for beginners and providing hands-on experience in building AI applications.", "skills":"Artificial Intelligence, Machine Learning, Python"}',
          language: 'en',
          email: 'daniel.lee@example.com',
          photo: 'https://example.com/profiles/daniel-lee.jpg',
        },
        title: 'Artificial Intelligence Essentials',
        subtitle: 'Learn the basics of AI',
        pricing: {
          price: 69.99,
          name: 'Premium',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/artificial-intelligence-essentials.jpg',
        desc: 'As an AI researcher, I am enthusiastic about simplifying complex AI concepts for beginners and providing hands-on experience in building AI applications.',
        promo_video: 'https://example.com/videos/artificial-intelligence-essentials-promo.mp4',
        skill_level: 'Intermediate',
        video_info: {
          size: 37294562.0,
          status: 'completed',
          duration: 240.35,
          playback: {
            url: 'https://packaged-assets.s3.amazonaws.com/private/users/111/courses/12b8cf3e-9e36-4a95-aa0e-bb0d6877b7e5/dest/mpeg-dash/artificial-intelligence-essentials-video.mp4/playlist.mpd',
          },
        },
        is_favorite: false,
        is_in_cart: false,
        owned: false,
        duration: 18.5,
        price: 53,
        sale_price: 69.99,
        sections_total: 4,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T15:10:30.995412Z',
        lecture_total: 12,
        student_total: 180,
        total_rate: 4.8,
        total_reviews: 30,
        is_bestseller: true,
        objectives: [
          'Learn the fundamentals of machine learning algorithms',
          'Understand natural language processing concepts',
          'Explore ethical considerations in AI development',
        ],
      },
      {
        article_count: 5,
        slug: 'creative-writing-workshop',
        id: 'f35f9df0-6722-42ad-a1ae-cf6db253e62e',
        code: 'f35f9df0-6722-42ad-a1ae-cf6db253e62e',
        user: {
          id: '222',
          first_name: 'Sarah',
          last_name: 'Miller',
          headline: 'Author and Writing Coach',
          bio: '{"des":"As an accomplished author and writing coach, I love guiding aspiring writers to unleash their creativity and craft compelling stories through this creative writing workshop.", "skills":"Creative Writing, Fiction, Storytelling"}',
          language: 'en',
          email: 'sarah.miller@example.com',
          photo: 'https://example.com/profiles/sarah-miller.jpg',
        },
        title: 'Creative Writing Workshop',
        subtitle: 'Unleash your storytelling talent',
        pricing: {
          price: 59.99,
          name: 'Standard',
          price_tier_status: true,
          currency: 'USD',
        },
        currency: 'USD',
        image: 'https://example.com/images/creative-writing-workshop.jpg',
        desc: 'As an accomplished author and writing coach, I love guiding aspiring writers to unleash their creativity and craft compelling stories through this creative writing workshop.',
        promo_video: 'https://example.com/videos/creative-writing-workshop-promo.mp4',
        skill_level: 'Beginner',
        video_info: {
          size: 27890765.0,
          status: 'completed',
          duration: 150.15,
        },
        is_favorite: false,
        is_in_cart: false,
        owned: false,
        duration: 10.75,
        price: 59.99,
        sale_price: undefined,
        sections_total: 3,
        is_rated: true,
        language: 'en',
        date_updated: '2023-08-03T16:25:18.891662Z',
        lecture_total: 6,
        student_total: 100,
        total_rate: 4.7,
        total_reviews: 15,
        is_bestseller: false,
        objectives: [
          'Develop your creative writing skills',
          'Learn storytelling elements and structure',
          'Create compelling characters and settings',
        ],
      },
    ];
    return of({
      count: 8,
      results: dummyData,
      allCount: 8,
    });
  }

  getCategories(params = {} as object): Observable<CategoryV2[]> {
    log.debug('API REQUEST getCategories - ', encodeURL(API_COURSES_CATEGORIES, params));
    return this.http.get<CategoryV2[]>(encodeURL(API_COURSES_CATEGORIES, params));
  }

  getTopics(params = {} as object): Observable<TopicV2[]> {
    log.debug('API REQUEST getTopics - ', encodeURL(API_COURSES_TOPICS, params));
    return this.http.get<TopicV2[]>(encodeURL(API_COURSES_PUBLIC_TOPICS, params));
  }

  getCategoryPageTopics(params = {} as object): Observable<TopicV2[]> {
    log.debug('API REQUEST getCategoryPageTopics - ', encodeURL(API_COURSES_TOPICS, params));
    return this.http.get<TopicV2[]>(encodeURL(API_COURSES_TOPICS, params));
  }

  getSubcategories(params = {} as object): Observable<SubCategoryV2[]> {
    log.debug('API REQUEST getSubctegories - ', encodeURL(API_COURSES_PUBLIC_SUBCATEGORIES, params));
    return this.http.get<SubCategoryV2[]>(encodeURL(API_COURSES_PUBLIC_SUBCATEGORIES, params));
  }

  getTopCategories(params = {} as object): Observable<Category[]> {
    log.debug('API REQUEST getTopCategories - ', encodeURL(API_COURSES_TOPCATEGORIES, params));
    //return this.http.get<Category[]>(encodeURL(API_COURSES_CATEGORIES, params));
    const dummyCategories: Category[] = [
      {
        id: 202,
        name: 'implement 24/7 e-commerce',
        icon: '',
        slug: 'implement-247-e-commerce',
        totalcourse: 23,
        subcategories: [
          {
            id: 215,
            name: 'Audiological scientist',
            slug: 'audiological-scientist',
            category: 202,
            totalcourse: 12,
            related_topics: [
              {
                name: 'expect',
                id: 81,
                slug: 'expect',
                courses_count: 4,
              },
              {
                name: 'form',
                id: 82,
                slug: 'form',
                courses_count: 7,
              },
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
        ],
      },
      {
        id: 203,
        name: 'orchestrate integrated e-markets',
        icon: '',
        slug: 'orchestrate-integrated-e-markets',
        totalcourse: 6,
        subcategories: [
          {
            id: 204,
            name: 'Financial controller',
            slug: 'financial-controller',
            category: 203,
            totalcourse: 1,
            related_topics: [
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
          {
            id: 211,
            name: 'Community education officer',
            slug: 'community-education-officer',
            category: 203,
            totalcourse: 1,
            related_topics: [
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
            ],
          },
        ],
      },
      {
        id: 204,
        name: 'streamline clicks-and-mortar channels',
        icon: '',
        slug: 'streamline-clicks-and-mortar-channels',
        totalcourse: 3,
        subcategories: [],
      },
      {
        id: 202,
        name: 'implement 24/7 e-commerce',
        icon: '',
        slug: 'implement-247-e-commerce',
        totalcourse: 23,
        subcategories: [
          {
            id: 215,
            name: 'Audiological scientist',
            slug: 'audiological-scientist',
            category: 202,
            totalcourse: 12,
            related_topics: [
              {
                name: 'expect',
                id: 81,
                slug: 'expect',
                courses_count: 4,
              },
              {
                name: 'form',
                id: 82,
                slug: 'form',
                courses_count: 7,
              },
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
        ],
      },
      {
        id: 208,
        name: 'synthesize dynamic interfaces',
        icon: '',
        slug: 'synthesize-dynamic-interfaces',
        totalcourse: 1,
        subcategories: [],
      },
      {
        id: 209,
        name: 'enable seamless platforms',
        icon: '',
        slug: 'enable-seamless-platforms',
        totalcourse: 0,
        subcategories: [
          {
            id: 209,
            name: 'Air cabin crew',
            slug: 'air-cabin-crew',
            category: 209,
            totalcourse: 1,
            related_topics: [
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
            ],
          },
        ],
      },
      {
        id: 210,
        name: 'monetize dot-com partnerships',
        icon: '',
        slug: 'monetize-dot-com-partnerships',
        totalcourse: 1,
        subcategories: [],
      },
      {
        id: 202,
        name: 'implement 24/7 e-commerce',
        icon: '',
        slug: 'implement-247-e-commerce',
        totalcourse: 23,
        subcategories: [
          {
            id: 215,
            name: 'Audiological scientist',
            slug: 'audiological-scientist',
            category: 202,
            totalcourse: 12,
            related_topics: [
              {
                name: 'expect',
                id: 81,
                slug: 'expect',
                courses_count: 4,
              },
              {
                name: 'form',
                id: 82,
                slug: 'form',
                courses_count: 7,
              },
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
        ],
      },
      {
        id: 203,
        name: 'orchestrate integrated e-markets',
        icon: '',
        slug: 'orchestrate-integrated-e-markets',
        totalcourse: 6,
        subcategories: [
          {
            id: 204,
            name: 'Financial controller',
            slug: 'financial-controller',
            category: 203,
            totalcourse: 1,
            related_topics: [
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
          {
            id: 211,
            name: 'Community education officer',
            slug: 'community-education-officer',
            category: 203,
            totalcourse: 1,
            related_topics: [
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
            ],
          },
        ],
      },
      {
        id: 204,
        name: 'streamline clicks-and-mortar channels',
        icon: '',
        slug: 'streamline-clicks-and-mortar-channels',
        totalcourse: 3,
        subcategories: [],
      },
      {
        id: 202,
        name: 'implement 24/7 e-commerce',
        icon: '',
        slug: 'implement-247-e-commerce',
        totalcourse: 23,
        subcategories: [
          {
            id: 215,
            name: 'Audiological scientist',
            slug: 'audiological-scientist',
            category: 202,
            totalcourse: 12,
            related_topics: [
              {
                name: 'expect',
                id: 81,
                slug: 'expect',
                courses_count: 4,
              },
              {
                name: 'form',
                id: 82,
                slug: 'form',
                courses_count: 7,
              },
              {
                name: 'discover',
                id: 83,
                slug: 'discover',
                courses_count: 10,
              },
              {
                name: 'next',
                id: 84,
                slug: 'next',
                courses_count: 9,
              },
              {
                name: 'course',
                id: 87,
                slug: 'course',
                courses_count: 10,
              },
            ],
          },
        ],
      },
      {
        id: 208,
        name: 'synthesize dynamic interfaces',
        icon: '',
        slug: 'synthesize-dynamic-interfaces',
        totalcourse: 1,
        subcategories: [],
      },
    ];
    return of(dummyCategories);
  }

  // Question and Answers
  getCourseAllQnA(courseId: string): Observable<CourseQnAResponse> {
    return this.http.get<CourseQnAResponse>(API_COURSES_QNA.replace('<COURSE_ID>', courseId) + '/?page_size=999');
    // let dummyData: CourseQnA[] = [
    //   {
    //     id: '30a9f097-f1ba-410e-b3c0-1633f3d534f1',
    //     user: {
    //       id: 6,
    //       first_name: 'Ahsan',
    //       last_name: 'Ahmad',
    //       email: 'ahsan.ahmad@thkee.com',
    //       bio: '',
    //       language: '',
    //     },
    //     reply_count: 4,
    //     upvoted_users_count: 3,
    //     lecture: null,
    //     date_created: '2024-03-19T06:34:25.023449Z',
    //     title: 'First QnA',
    //     description: 'First desc',
    //     approved: false,
    //     not_relevant: true,
    //     subsection: null,
    //     project: '14b62b75-7367-47d3-9859-8079f3daee8b',
    //   },
    //   {
    //     id: '2ab33ed6-bd8b-4c1a-8534-ae03e6f9c181',
    //     user: {
    //       id: 6,
    //       first_name: 'Ahsan',
    //       last_name: 'Ahmad',
    //       email: 'ahsan.ahmad@thkee.com',
    //       bio: '',
    //       language: '',
    //     },
    //     reply_count: 2,
    //     upvoted_users_count: 2,
    //     lecture: {
    //       id: 'aa86551d-3cd8-4ffa-92d8-13c9dd222286',
    //       type: 'lecture',
    //       title: 'Demo Lecture',
    //     },
    //     date_created: '2024-03-19T06:35:20.773655Z',
    //     title: 'Test QnA',
    //     description: 'desc',
    //     approved: false,
    //     not_relevant: true,
    //     subsection: 'bf093db4-8a79-4927-9377-5c22232fbf92',
    //     project: '14b62b75-7367-47d3-9859-8079f3daee8b',
    //   },
    // ];
    // return of(dummyData);
  }

  getCourseQnA(courseId: string, qnaId: string): Observable<CourseQnA> {
    let endpoint = API_COURSES_GET_QNA.replace('<COURSE_ID>', courseId);
    endpoint = endpoint.replace('<QNA_ID>', qnaId);
    // return this.http.get<any>(endpoint).pipe(map((response) => response.results)); // TODO: Update type
    return this.http.get<any>(endpoint);
  }

  addQnAQuestion(courseId: string, payload: CourseQnAPayload): Observable<CourseQnA> {
    return this.http.post<CourseQnA>(API_COURSES_QNA.replace('<COURSE_ID>', courseId) + '/', payload);
  }

  addQnAReply(qnaId: string, payload: CourseQnAReplyPayload): Observable<CourseQnAReply> {
    return this.http.post<CourseQnAReply>(API_COURSES_QNA_ADDREPLY.replace('<QNA_ID>', qnaId), payload);
  }

  // Assignment
  startAssignment(courseId: string, assignId: string): Observable<any> {
    const endpoint = API_COURSES_ASSIGNMENT.replace('<COURSE_ID>', courseId).replace('<ASSIGNMENT_ID>', assignId);
    return this.http.post<any>(endpoint, {});
  }

  getAssignmentAnswers(courseId: string, assignId: string): Observable<CourseAssignmentAnswer[]> {
    const endpoint = API_COURSES_ASSIGNMENT.replace('<COURSE_ID>', courseId).replace('<ASSIGNMENT_ID>', assignId);
    return this.http.get<CourseAssignmentAnswer[]>(endpoint).pipe(
      catchError((error) => {
        if (error.status === 404) {
          // Return an empty array if the API returns a 404 error
          return of([]);
        } else {
          // If it's another error, you can handle it accordingly
          return throwError(error);
        }
      })
    );
  }

  submitAssignmentAnswers(
    courseId: string,
    assignId: string,
    payload: CourseAssignmentAnswer[]
  ): Observable<CourseAssignmentAnswer[]> {
    const endpoint = API_COURSES_ASSIGNMENT.replace('<COURSE_ID>', courseId).replace('<ASSIGNMENT_ID>', assignId);
    return this.http.post<CourseAssignmentAnswer[]>(endpoint, payload);
  }

  // Quiz
  getQuizAnswer(courseId: string, quizId: string) {}

  getQuizAnswers(courseId: string, quizId: string): Observable<CourseQuizAnswer[]> {
    const endpoint = `${API_COURSES_QUIZ.replace('<COURSE_ID>', courseId).replace('<QUIZ_ID>', quizId)}`;
    // return this.http.get<CourseAssignmentAnswer[]>(endpoint);
    return this.http.get<CourseQuizAnswer[]>(endpoint).pipe(
      catchError((error) => {
        if (error.status === 404) {
          // Return an empty array if the API returns a 404 error
          return of([]);
        } else {
          return throwError(error);
        }
      })
    );

    // const dummyData: CourseQuizAnswer[] = [
    //   {
    //     id: 'q1c4b9a2e3-ac6c-428e-b8b1-f9f2b7e6a548',
    //     date_created: '2024-03-29T09:07:28.289Z',
    //     date_updated: '2024-03-29T09:07:28.289Z',
    //     question_chain_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     answer_chain_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     question: 'c4b9a2e3-ac6c-428e-b8b1-f9f2b7e6a548',
    //     answer: 'd3936f27-b43c-4481-b966-3a9f6920c97d',
    //   },
    //   {
    //     id: 'q24314c04e-4a35-45e1-b22a-33b09e766f06',
    //     date_created: '2024-03-29T09:07:28.289Z',
    //     date_updated: '2024-03-29T09:07:28.289Z',
    //     question_chain_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     answer_chain_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     question: '4314c04e-4a35-45e1-b22a-33b09e766f06',
    //     answer: '3d455f71-4784-47eb-92c8-600a3caa53c8',
    //   },
    // ];
    // console.log('getQuizAnswers--', dummyData);
    // return of(dummyData);
  }
  submitQuizAnswers(
    courseId: string,
    quizId: string,
    payload: CourseQuizAnswerPayload
  ): Observable<CourseQuizAnswer[]> {
    const endpoint = `${API_COURSES_QUIZ.replace('<COURSE_ID>', courseId).replace('<QUIZ_ID>', quizId)}`;
    return this.http.post<CourseQuizAnswer[]>(endpoint, payload);

    // let dummyData: CourseQuizAnswer[] = [];
    // payload.forEach((answer) => {
    //   const dummyID = generateUuid();
    //   dummyData.push({
    //     id: dummyID,
    //     date_created: '2024-03-29T09:07:28.289Z',
    //     date_updated: '2024-03-29T09:07:28.289Z',
    //     question_chain_id: dummyID,
    //     answer_chain_id: dummyID,
    //     question: answer.question_id,
    //     answer: answer.answer_id,
    //   });
    // });

    // console.log('answers after submit--', dummyData);
    // return of(dummyData);
  }
}
