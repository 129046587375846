<div class="relative">
  <video id="my-video" class="vjs-matrix video-js" controls preload="auto" data-setup="{}"></video>
  <div class="navigation text-white">
    <div class="nav-item prev absolute left-0 z-50" *ngIf="!hidePrev">
      <div class="flex cursor-pointer items-center gap-2" (click)="emitEvent('prev', { subsectionId: data?.section })">
        <div class="bg-slate-900 px-1 py-1.5">
          <svg-icon src="@assets/images/icons/chevron-left.svg" class="h-7 w-7"></svg-icon>
        </div>
        <div class="nav-label rounded-[4px] bg-slate-900 px-3 py-2">{{ prevData.title }}</div>
      </div>
    </div>
    <div class="nav-item next absolute right-0 z-50" *ngIf="!hideNext">
      <div class="flex cursor-pointer items-center gap-2" (click)="emitEvent('next', { subsectionId: data?.section })">
        <div class="nav-label rounded-[4px] bg-slate-900 px-3 py-2">{{ nextData.title }}</div>
        <div class="bg-slate-900 px-1 py-1.5">
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="h-7 w-7"></svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading && !hideNext" class="flex items-center justify-center absolute w-full h-full-30 top-0">
    <div class="flex flex-col items-center gap-2 z-50">
      <h2 class="text-2xl text-white">{{ nextData.title }}</h2>
      <div class="w-52 h-1 bg-gray-200 overflow-x-auto relative rounded-lg bg-primary-100">
        <div class="absolute inset-0 bg-primary-500 animate-scroll rounded-lg">
        </div>
      </div>
      <span [ngClass]="isLoadingPlayer?'opacity-100':'opacity-0'"
        (click)="emitEvent('next', { subsectionId: data?.section })" class=" cursor-pointer">
        <svg-icon src="@assets/images/icons/play-circle-solid.svg"
          class="h-20 w-20 text-white opacity-50 hover:opacity-100"></svg-icon>
      </span>
    </div>
  </div>
</div>