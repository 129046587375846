<ng-container *ngIf="{
    isInstructor: isInstructor$ | async
  } as vm">
  <div class="instructor-header new-course flex w-full bg-slate-800 py-2 pl-2 pr-8 text-white">
    <div class="flex w-full max-w-[250px] items-center pl-6">
      <div class="logo">
        <a routerLink="/">
          <svg-icon src="@assets/images/logo-white.svg" class="block h-7 w-auto"></svg-icon>
        </a>
      </div>
    </div>
    <div class="flex grow justify-between">
      <div class="left-content"></div>
      <div class="right-content">
        <div class="back">
          <a routerLink="/instructor/courses" *ngIf="vm.isInstructor">
            <button class="btn btn-dark pl-2">
              <svg-icon src="@assets/images/icons/chevron-left.svg" class="ar:rotate-180 h-4 w-4"></svg-icon>
              <span i18n>Back to Dashboard</span>
            </button>
          </a>
          <a routerLink="/" *ngIf="!vm.isInstructor">
            <button class="btn btn-dark pl-2">
              <svg-icon src="@assets/images/icons/chevron-left.svg" class="h-4 w-4"></svg-icon>
              <span i18n>Exit</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
