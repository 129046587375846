import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_DELETE_INSTRUCTOR_COURSE_SETTINGS_DELETE,
  API_GET_INSTRUCTOR_COURSE_SETTINGS,
  API_POST_INSTRUCTOR_COURSE_SETTINGS_LISTING,
  HttpService,
} from 'thkee-common';

export interface CourseSettings {
  title: string;
  status: string;
  is_unlisted: boolean;
  can_be_delete: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CourseSettingsService {
  constructor(private readonly http: HttpService) {}

  getCourseSettings(course_id: string): Observable<CourseSettings> {
    return this.http.get<CourseSettings>(API_GET_INSTRUCTOR_COURSE_SETTINGS.replace('<COURSE_ID>', course_id));
  }

  courseSettingsListing(course_id: string): Observable<void> {
    return this.http.post(API_POST_INSTRUCTOR_COURSE_SETTINGS_LISTING.replace('<COURSE_ID>', course_id), {});
  }

  courseSettingsDelete(course_id: string): Observable<void> {
    return this.http.delete(API_DELETE_INSTRUCTOR_COURSE_SETTINGS_DELETE.replace('<COURSE_ID>', course_id));
  }
}
