import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-imageplaceholder',
  template: `
    <div class="image-placeholder flex h-full w-full flex-col">
      <div class="mx-auto mb-2 mt-auto flex h-20 w-20 rounded-full bg-white shadow">
        <img src="assets/images/image-placeholder.svg" class="m-auto h-11 w-11" alt="" defaultImage />
      </div>
      <span class="mx-auto mb-auto text-sm font-medium text-neutral-500">{{ text }}</span>
    </div>
  `,
})
export class FormlyImgPlaceholderComponent implements OnInit {
  @Input() text: string = 'Not available';

  constructor() {}

  ngOnInit(): void {}
}
