import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TopicEntityState, topicAdapter } from './topic.entity';

export const selectTopicState = createFeatureSelector<TopicEntityState>('topic');

export const selectTopics = createSelector(selectTopicState, topicAdapter.getSelectors().selectAll);

export const selectTopicEntities = createSelector(selectTopicState, topicAdapter.getSelectors().selectEntities);

export const selectTopicById = (topicId: string) =>
  createSelector(selectTopicEntities, (entities) => entities[topicId]);
