import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationMessage, NotificationMessageAction } from '..';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'thk-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, AfterViewInit {
  @Output() rect = new EventEmitter<DOMRect>();
  wrapperClasses = {
    error: 'bg-red-50 text-sm normal-case text-red-800 dark:bg-gray-800 dark:text-red-400',
    success: 'bg-green-50 text-sm normal-case text-green-800 dark:bg-gray-800 dark:text-green-400',
    warning: 'text-primary bg-primary-50 text-sm normal-case',
    info: 'bg-slate-800 text-sm normal-case text-slate-50'
  };
  messages = this.notificationService.messages;
  processing: Record<string, boolean> = {};

  constructor(
    private notificationService: NotificationsService,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.calculateNotificationHeight();
  }

  ngAfterViewInit(): void {
    this.rect.emit(this.elementRef.nativeElement.getClientRects()[0]);
  }

  close(message: NotificationMessage) {
    if (message.id) {
      this.notificationService.remove(message.id);
    }
  }

  clickAction(message: NotificationMessage, action: NotificationMessageAction) {
    if (message.id) {
      this.processing[message.id] = true;
    }
    action.onClick(message).then(() => {
      if (message.id) {
        this.processing[message.id] = false;
      }
    })
  }

  getChild(selector: string) {
    return this.elementRef.nativeElement.querySelector(selector);
  }

  private calculateNotificationHeight() {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.rect.emit(entry.contentRect)
      }
    });

    resizeObserver.observe(this.elementRef.nativeElement);
  }
}
