import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, selectIsInstructor } from '@shared';
import { Observable, map } from 'rxjs';
import { Logger, skipUndefined } from 'thkee-common';

const log = new Logger('InstructorGuard');

@Injectable({
  providedIn: 'root',
})
export class InstructorGuard implements CanActivate {
  constructor(private router: Router, private readonly store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectIsInstructor).pipe(
      skipUndefined(),
      map(isInstructor => {
        // const is_alreadyInstructor = await firstValueFrom(this.store.select(selectIsInstructor));
        if (isInstructor) {
          return true; // Allow navigation
        } else {
          this.router.navigate(['/home']);
          return false; // Prevent navigation
        }
      })
    )
  }
}
