import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface InputProps extends FormlyFieldProps {
  addonLeft: string;
  addonRight: string;
  allowedTypes?: string[];
  inputAppendClass?: string[];
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<FormlyFieldInput>;
}

@Component({
  selector: 'formly-field-input',
  template: `
    <ng-template #fieldTypeTemplate>
      <input
        *ngIf="type !== 'number'; else numberTmp"
        [type]="type"
        [formControl]="formControl"
        [class.input-field]="!props.addonLeft && !props.addonRight"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [accept]="props.allowedTypes"
        [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
        [placeholder]="props.placeholder"
        [pattern]="noInitialSpacePattern"
      />
      <ng-template #numberTmp>
        <input
          type="number"
          [formControl]="formControl"
          [class.input-field]="!props.addonLeft && !props.addonRight"
          [formlyAttributes]="field"
          [class.is-invalid]="showError"
        />
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInput extends FieldType<FieldTypeConfig<InputProps>> {
  get type() {
    return this.props.type || 'text';
  }

  noInitialSpacePattern = '^(?! ).*$';
}
