import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface TextAreaProps extends FormlyFieldProps {
  cols?: number;
  rows?: number;
  maxHeight?: string;
  minHeight?: string;
}

export interface FormlyTextAreaFieldConfig extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'formly-field-textarea',
  template: `
    <ng-template #fieldTypeTemplate>
      <textarea
        [formControl]="formControl"
        [cols]="props.cols"
        [rows]="props.rows"
        class="input-field"
        [class.is-invalid]="showError"
        [formlyAttributes]="field"
        [style.maxHeight]="props.maxHeight"
        [style.minHeight]="props.minHeight"
        [pattern]="noInitialSpacePattern"
      >
      </textarea>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldTextArea extends FieldType<FieldTypeConfig<TextAreaProps>> {
  override defaultOptions = {
    props: {
      cols: 1,
      rows: 1,
    },
  };

  // noInitialSpacePattern = '^(?! ).*$';
  noInitialSpacePattern = '^(?! |\\n)[\\s\\S]*$';
}
