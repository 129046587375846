<ng-container *ngIf="isLoading; else courseCard">
  <ng-container *ngTemplateOutlet="skeletonCard"></ng-container>
</ng-container>

<ng-template #courseCard>
  <!-- Standard with Hover -->
  <ng-container *ngIf="!isFeatured; else featuredCard">
    <div class="course-card transition-all relative" [class.hover:scale-105]="disabledHover"
      [ngClass]="{ 'hidden md:flex': mobileView }">
      <ng-container *ngIf="myClassView">
        <div class="absolute right-4 top-4 z-20">
          <app-navigation position="right" contentClass="!min-w-[140px] w-[200px]">
            <ng-container button>
              <button class="btn btn-sm p-1.5 h-8 border-0">
                <span class="flex flex-row gap-1">
                  <span class="w-1 h-1 bg-slate-500 rounded-full"></span>
                  <span class="w-1 h-1 bg-slate-500 rounded-full"></span>
                  <span class="w-1 h-1 bg-slate-500 rounded-full"></span>
                </span>
              </button>
            </ng-container>
            <ng-container content>
              <ul class="flex flex-col gap-1 py-2">
                <!-- Note: Do not remove this code -->
                <!-- <li class="px-3 py-1 text-slate-600 flex items-center gap-2 cursor-pointer" i18n>
                  <svg-icon src="@assets/images/icons/share.svg" class="h-4 w-4 text-slate-400"></svg-icon> Share
                </li> -->
                <!-- <li *ngIf="!courseData.owned" (click)="toggleFavorite()" class="px-3 py-1 flex items-center gap-2 cursor-pointer text-slate-600"
                  i18n>
                  <svg-icon
                    [src]="courseData.favorite? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                    [ngClass]="courseData.favorite ? 'text-primary-600' : 'text-slate-400'" class="h-4 w-4"></svg-icon>
                  Favorite
                </li> -->
                <li (click)="toggleArchive()" class="px-3 py-1 flex items-center gap-2 cursor-pointer text-slate-600">
                  <svg-icon src="@assets/images/icons/archive-box.svg"
                    [ngClass]="courseData.archived ? 'text-primary-600' : 'text-slate-400'" class="h-4 w-4"></svg-icon>
                  <ng-container *ngIf="courseData.archived" i18n>Un Archive</ng-container>
                  <ng-container *ngIf="!courseData.archived" i18n>Archive</ng-container>
                </li>
              </ul>
            </ng-container>
          </app-navigation>
        </div>
        <div (click)="goToLearningLab()" class="absolute w-full h-full z-10 cursor-pointer"></div>
      </ng-container>
      <div class="coursec-imagewarp">
        <ng-container *ngIf="featuredImage; else imgplaceholder">
          <div class="coursec-featured-image bg-slate-500"
            [ngStyle]="{ 'background-image': 'url(\'' + featuredImage + '\')' }"></div>
          <!-- <div class="coursec-featured-image bg-slate-500" [ngStyle]="{ 'background-image': 'url(\'' + demoImgUrl + '\')' }">
          </div> -->
        </ng-container>
        <div class="coursec-bestseller" *ngIf="isBestSeller">
          <span i18n>Bestseller</span>
        </div>
      </div>
      <div class="coursec-info">
        <div class="coursec-meta">
          <div class="flex items-center gap-1">
            <div class="starts">
              <app-star-rating [data]="totalRate" [size]="'sm'">
              </app-star-rating>
              <!-- <div *ngFor="let i of totalStar">
                <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
              </div> -->
            </div>
            <div class="coursec-rate">
              <span class="total">{{ totalRate }}</span>
              <span class="review">({{ totalReviews }})</span>
            </div>
          </div>
          <div class="coursec-author" i18n>By {{ author }}</div>
        </div>
        <div *ngIf="title" class="coursec-title">{{ title | truncate:46:'...'}}</div>

        <div class="coursec-footer">
          <div class="w-full flex items-center" *ngIf="!hasProgress">
            <div class="grow">
              <div class="flex items-center gap-1">
                <svg-icon src="@assets/images/icons/clock.svg" class="relative h-4 w-4 text-slate-300"></svg-icon>
                <div class="duration">{{ duration | formatDuration }}</div>
              </div>
            </div>
            <div *ngIf="!isCardLoader" class="amount">
              <div class="old" *ngIf="salePrice">{{ salePrice | price }}</div>
              <div class="new" *ngIf="price">{{ price | price }}</div>
            </div>
            <!-- Price loader -->
            <div *ngIf="isCardLoader && !courseData.owned" class="amount">
              <ng-container *ngIf="courseData.course_id">
                <ng-container *ngIf="(loadingPrices[courseData.course_id] | async); else coursesPriceLoaded">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
                </ng-container>
                <ng-template #coursesPriceLoaded>
                  <div [ngClass]="courseData.priceDetails?.discount_price?.amount > 0 ? 'old' : 'new !text-slate-700'">
                    {{ courseData.priceDetails?.list_price?.price_string }}
                  </div>
                  <div *ngIf="courseData.priceDetails?.discount_price?.amount > 0" class="new">
                    {{ courseData.priceDetails?.price?.price_string }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="hasProgress">
            <ng-container *ngTemplateOutlet="progressBar; context: { percent: progress }"></ng-container>
          </ng-container>
        </div>
      </div>
      <div class="course-card-hover transition-all" *ngIf="!disabledHover">
        <div class="flex items-center gap-3">
          <div *ngIf="title" class="coursec-title excerpt">{{ title | truncate:46:'...' }}</div>
          <button *ngIf="!isOwned" (click)="toggleFavorite()" class="coursec-like">
            <ng-container *ngIf="{favorite: (favorite$ | async)} as vm">
              <svg-icon
                [src]="courseData.favorite? '@assets/images/icons/heart-solid.svg' : '@assets/images/icons/heart.svg'"
                class="block m-auto h-6 w-6">
              </svg-icon>
            </ng-container>
          </button>
        </div>
        <div class="date">Updated on {{ date | formatDate : 'll' }}</div>
        <div *ngIf="description" class="text-sm text-slate-400">{{ description | truncate:50:'...' }}</div>
        <div class="course-meta">
          <div class="" *ngIf="lectureTotal">
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/document-text.svg"></svg-icon>
            <div>{{ lectureTotal }} lectures</div>
          </div>
          <div>
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/clock.svg"></svg-icon>
            <div>{{ duration | formatDuration }}</div>
          </div>
          <div *ngIf="course?.skill_level">
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/chart-bar.svg"></svg-icon>
            <div>{{ course?.skill_level }}</div>
          </div>
        </div>
        <div class="list h-auto" *ngIf="objectives">
          <ul>
            <li *ngFor="let objective of objectives">
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>{{ objective | truncate:35:'...' }}</span>
            </li>
          </ul>
        </div>

        <div class="author">
          <div class="flex grow gap-2 truncate">
            <thk-avatar sizeClasses="h-5 w-5 text-[10px]" [title]="finalCourse.user?.fullname" [url]="finalCourse.user.photo_url"></thk-avatar>
            <!-- <div class="avatar">
            </div> -->
            <div class="name truncate">{{ author }}</div>
          </div>
          <div *ngIf="!isCardLoader" class="amount">
            <div class="old" *ngIf="salePrice">{{ salePrice | price }}</div>
            <div class="new" *ngIf="price">{{ price | price }}</div>
          </div>
          <!-- Price loader -->
          <ng-container *ngIf="!courseData.owned">
            <div *ngIf="isCardLoader" class="amount">
              <ng-container *ngIf="courseData.course_id">
                <ng-container *ngIf="(loadingPrices[courseData.course_id] | async); else coursesPriceLoaded">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
                </ng-container>
                <ng-template #coursesPriceLoaded>
                  <div [ngClass]="courseData.priceDetails?.discount_price?.amount > 0 ? 'old' : 'new !text-slate-50'">
                    {{ courseData.priceDetails?.list_price?.price_string }}
                  </div>
                  <div *ngIf="courseData.priceDetails?.discount_price?.amount > 0" class="new">
                    {{ courseData.priceDetails?.price?.price_string }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="cart-footer">
          <ng-container *ngIf="courseData.owned">
            <button class="btn btn-primary btn-sm grow" (click)="goToLearningLab()">
              <span i18n>Go to Course</span>
              <svg-icon src="@assets/images/icons/play-circle.svg" class="h-5 w-5"></svg-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!courseData.owned">
            <ng-container *ngIf="!inCart">
              <button class="btn btn-primary btn-sm grow" (click)="addToCart()">
                <span i18n>Add to Cart</span>
                <svg-icon src="@assets/images/icons/cart.svg" class="h-5 w-5"></svg-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="inCart">
              <button class="btn btn-green btn-sm grow">
                <svg-icon src="@assets/images/icons/check.svg" class="btn-icon"></svg-icon>
                <span i18n>Added to Cart</span>
              </button>
            </ng-container>
          </ng-container>

          <a [routerLink]="permalink">
            <button class="btn btn-outline-primary text-primary hover:text-white">
              <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5 rtl:hidden"></svg-icon>
              <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5 ltr:hidden"></svg-icon>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="course-card-mobile flex md:hidden relative" *ngIf="mobileView">
      <ng-container *ngIf="myClassView">
        <a (click)="goToLearningLab()" class="absolute w-full h-full z-10 cursor-pointer"></a>
      </ng-container>
      <div class="imagewarp">
        <ng-container *ngIf="featuredImage; else imgplaceholder">
          <div [ngStyle]="{ 'background-image': 'url(\'' + featuredImage + '\')' }" class="featured-image bg-slate-500">
          </div>
        </ng-container>
        <div *ngIf="isBestSeller" class="bestseller">
          <span i18n>Bestseller</span>
        </div>
      </div>
      <div class="flex flex-col gap-3 text-sm">
        <div class="rating flex items-center gap-1 text-xs">
          <svg-icon src="@assets/images/icons/star-solid.svg" class="text-primary h-3 w-3"></svg-icon>
          <span class="text-secondary-700 font-bold">{{totalReviews}}</span>
          <span class="font-medium text-slate-400">({{totalRate}})</span>
        </div>
        <div *ngIf="title" class="font-medium text-slate-800">{{title | truncate:46:'...'}}</div>
        <div class="text-secondary-700 text-xs" i18n>By {{ author }}</div>
        <div class="prices flex items-center gap-2">
          <ng-container *ngIf="courseData.course_id && !courseData.owned">
            <ng-container *ngIf="(loadingPrices[courseData.course_id] | async); else coursesPriceLoaded">
              <ng-container *ngTemplateOutlet="loader"></ng-container>
            </ng-container>
            <ng-template #coursesPriceLoaded>
              <div [ngClass]="courseData.priceDetails?.discount_price?.amount > 0 ? 'old' : 'new !text-slate-700'">
                {{ courseData.priceDetails?.list_price?.price_string }}
              </div>
              <div *ngIf="courseData.priceDetails?.discount_price?.amount > 0" class="new">
                {{ courseData.priceDetails?.price?.price_string }}
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Featured -->
  <ng-template #featuredCard>
    <div class="course-card-featured">
      <div class="flex items-center gap-3">
        <div *ngIf="title" class="coursec-title">{{ title | truncate:46:'...'}}</div>
        <button *ngIf="!isOwned" (click)="toggleFavorite()" class="coursec-like">
          <ng-container *ngIf="{favorite: (favorite$ | async)} as vm">
            <svg-icon
              [src]="courseData.favorite? '@assets/images/icons/heart-solid.svg' : '@assets/images/icons/heart.svg'"
              class="block m-auto h-6 w-6">
            </svg-icon>
          </ng-container>
        </button>
      </div>
      <div class="date" i18n>Updated on {{ date | formatDate : 'll' }}</div>
      <div class="excerpt">{{ description }}</div>
      <div class="course-meta">
        <div class="" *ngIf="lectureTotal">
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/document-text.svg"></svg-icon>
          <div i18n>{{ lectureTotal }} lectures</div>
        </div>
        <div>
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/clock.svg"></svg-icon>
          <div>{{ duration | formatDuration }}</div>
        </div>
        <div *ngIf="course?.skill_level">
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/chart-bar.svg"></svg-icon>
          <div>{{ course?.skill_level }}</div>
        </div>
      </div>
      <!-- TODO JHJ !!! -->
      <!-- <div class="list" *ngIf="courseData.objectives?.length">
        <ul>
          <li *ngFor="let objective of courseData.objectives">
            <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon> <span>{{ objective }}</span>
          </li>
        </ul>
      </div> -->
      <div class="author">
        <div class="flex grow gap-2 truncate">
          <thk-avatar sizeClasses="h-5 w-5 text-[10px]" [title]="finalCourse.user?.fullname" [url]="finalCourse.user.photo_url"></thk-avatar>
          <!-- <div class="avatar">
          </div> -->
          {{course?.user?.fullname}}
          <div class="name truncate">{{ author }}</div>
        </div>
        <div class="amount">
          <div class="old" *ngIf="salePrice">{{ salePrice | price }}</div>
          <div class="new" *ngIf="price">{{ price | price }}</div>
        </div>
      </div>
      <div class="cart-footer">
        <button class="btn btn-primary btn-sm grow">
          <span>Add to Cart</span>
          <svg-icon src="@assets/images/icons/cart.svg" class="h-5 w-5"></svg-icon>
        </button>
        <button class="btn btn-outline-primary text-primary hover:text-white">
          <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>

<!-- Skeleton -->
<ng-template #skeletonCard>
  <div class="course-card">
    <div class="coursec-imagewarp animate-pulse !bg-slate-200"></div>
    <div class="coursec-info animate-pulse">
      <div class="coursec-meta rounded bg-slate-200">&nbsp;</div>
      <div class="coursec-title h-12 w-60 rounded bg-slate-200 font-semibold text-slate-200"></div>
      <div class="coursec-footer">
        <div class="flex h-4 shrink grow basis-0 items-center justify-start gap-2">
          <div class="flex items-center justify-start gap-1 rounded bg-slate-200 text-slate-200">
            <div class="h-4 w-4"></div>
            <div class="text-xs text-slate-200" i18n>{{ 0 }} hours</div>
          </div>
        </div>
        <div class="flex items-center justify-start gap-1 rounded bg-slate-200 text-slate-200">
          <div class="text-sm text-slate-200 line-through">$000.00</div>
          <div class="text-lg font-semibold text-slate-200">$000</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #imgplaceholder>
  <div class="coursec-image-placeholder">
    <div class="m-auto flex h-20 w-20 rounded-full bg-white shadow">
      <svg-icon src="@assets/images/image-placeholder.svg" class="m-auto h-11 w-11"></svg-icon>
    </div>
    <span class="font-bold" i18n>Image not available</span>
  </div>
</ng-template>

<ng-template #progressBar let-percent="percent">
  <div class="flex w-full flex-col gap-2">
    <div class="progress-track h-1 overflow-hidden rounded-full bg-slate-100">
      <div class="progres bg-primary h-1 max-w-full rounded-full" [style.width.%]="percent"></div>
    </div>
    <div class="labels flex justify-between text-sm">
      <div class="text-slate-900" i18n>{{ percent }}% Completed</div>
      <div class="text-slate-500" i18n>100%</div>
    </div>
  </div>
</ng-template>

<!-- Price loader -->
<ng-template #loader>
  <div class="items-center justify-center w-full">
    <div class="loader w-12 pt-2">
      <svg overflow="visible" class="text-primary" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 50'
        enable-background="new 0 0 200 50">
        <circle height="100%" fill='currentColor' stroke='currentColor' stroke-width='4' r='15' cx='40' cy='25'>
          <animate attributeName='opacity' calcMode='spline' dur='1.6' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1'
            repeatCount='indefinite' begin='-.4'></animate>
        </circle>
        <circle fill='currentColor' stroke='currentColor' stroke-width='4' r='15' cx='100' cy='25'>
          <animate attributeName='opacity' calcMode='spline' dur='1.6' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1'
            repeatCount='indefinite' begin='-.2'></animate>
        </circle>
        <circle fill='currentColor' stroke='currentColor' stroke-width='4' r='15' cx='160' cy='25'>
          <animate attributeName='opacity' calcMode='spline' dur='1.6' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1'
            repeatCount='indefinite' begin='0'></animate>
        </circle>
      </svg>

      <!-- Do not remove it -->
      <!-- <svg class="text-primary m-auto h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg> -->
    </div>
  </div>
</ng-template>
