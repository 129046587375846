import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FilterOption } from 'thkee-common';
export interface FilterOptionComponentEventOutput {
  action: 'change' | 'reset';
  data: { [key: string]: Record<string, boolean> };
}

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
})
export class FilterOptionComponent implements OnInit, OnChanges {
  @Input() defaultValue: any = {};
  @Input() key: string = String(Date.now());
  @Input() options: FilterOption[] | null = [];
  @Input() limit: number = 0;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEvent = new EventEmitter<FilterOptionComponentEventOutput>();

  form = new FormGroup({});
  optionsF: FormlyFormOptions = {};
  fields: FormlyFieldConfig;

  maxHeight: number = 0;
  seeMore: boolean = false;

  constructor() {
    // Initialize fields here to ensure they are defined before ngOnInit and ngOnChanges
    this.fields = {
      key: this.key,
      type: 'multicheckbox',
      // FIXME:: we might not need this setting, please consider to delete it
      defaultValue: this.defaultValue,
      props: {
        itemClass: 'mb-2.5 capitalize',
        options: this.options ?? [],
        change: (field, $event) => {
          this.emitEvent({ action: 'change', data: this.form.value });
        },
      },
    };
  }

  ngOnInit(): void {
    this.updateFieldProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.updateFieldProperties();
    }
  }

  updateFieldProperties() {
    if (this.key) {
      this.fields.key = this.key;
    }
    if (this.options && this.fields && this.fields.props) {
      this.fields.props['options'] = this.options;
    }
    if (this.limit) {
      this.maxHeight = this.limit * 32;
    }
    if (this.defaultValue) {
      this.fields.defaultValue = this.defaultValue;
    }
  }

  reset() {
    this.optionsF && this.optionsF.resetModel && this.optionsF.resetModel();
    this.form.setValue({ [this.key]: {} })
    this.emitEvent({ action: 'reset', data: this.form.value });
  }

  private emitEvent(event: FilterOptionComponentEventOutput) {
    this.onEvent.emit(event);
  }
}
