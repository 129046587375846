import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentCard } from '@shared/models/payment-card';
import { of, switchMap, take } from 'rxjs';
import { API_GET_SAVED_CARDS, HttpService } from 'thkee-common';
import { selectPaymentCards } from './payment.selectors';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpService,
    private store: Store
  ) { }

  load() {
    return this.store.select(selectPaymentCards).pipe(
      take(1),
      switchMap(cards => {
        if (cards) {
          return of(cards);
        }

        return this.http.get<PaymentCard[]>(API_GET_SAVED_CARDS)
      }))
  }
}
