import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import Annotation from 'chartjs-plugin-annotation';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

interface DoughnutChartData {
  labels: string;
  data: number;
  backgroundColor: string;
}

@Component({
  selector: 'app-common-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() chartStyle: string = '';

  private newLabel? = 'New label';

  constructor() {
    Chart.register(Annotation);
  }

  ngOnInit(): void {
    this.lineChartData.datasets = this.inputLineChartData;
    this.barChartData.datasets = this.inputBarChartData;

    // Donut chart
    function transformData(labels: any[], data: any[], bg: any[]): DoughnutChartData[] {
      const transformedData: DoughnutChartData[] = [];
      const minLength = Math.min(labels.length, data.length, bg.length);
      for (let i = 0; i < minLength; i++) {
        transformedData.push({
          labels: labels[i],
          data: data[i],
          backgroundColor: bg[i],
        });
      }
      return transformedData;
    }

    this.doughnutChartInfo = transformData(
      this.inputDoughnutChartLabels,
      this.inputDoughnutChartData,
      this.inputDoughnutChartBG
    );
    this.doughnutChartData.datasets = [
      {
        data: this.inputDoughnutChartData,
        label: 'Revenue',
        backgroundColor: this.inputDoughnutChartBG,
      },
    ];
  }

  // ----------------------------
  // Chart Type : Linechart
  public lineChartType: ChartType = 'line';
  @ViewChild('linechart') chartRef!: ElementRef;
  @Input() tickYValues: string[] = ['$0.00', '$250.00', '$500.00', '$750.00'];
  @Input() tickYMin: number = 0;
  @Input() tickYMax: number = 750;
  @Input() tickYStepSize: number = 250;
  @Output() chartFilter: EventEmitter<string> = new EventEmitter();
  @Input() inputLineChartData: any[] = [];
  // Filter by day
  formFilterByDay = new FormGroup({});
  modelFilterByDay = { filterByDay: 'all' };
  fieldsFilterByDayForm: FormlyFieldConfig[] = [
    {
      key: 'filterByDay',
      type: 'select',
      props: {
        options: [
          { label: 'All time', value: 'all' },
          { label: 'Last Month', value: '1' },
          { label: 'Last 6 Months', value: '6' },
          { label: 'Last Year', value: '12' },
        ],
        label: '',
        hideLabel: true,
        stylish: true,
        wrapClass: 'block mb-0 w-30',
      },
      hooks: {
        onInit: (field) => {
          if (field.formControl) {
            field.formControl.valueChanges.subscribe((value) => {
              if (value) {
                this.chartFilter.emit(value);
                this.lineChartData.labels = this.generateMonthArray(value);
              }
            });
          }
        },
      },
    },
  ];

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [],
    labels: this.generateMonthArray(12),
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      x: {
        position: 'left',
        grid: {
          color: (context: any) => 'rgba(0, 0, 0, 0.1)', // Color of x-axis grid lines
          tickWidth: 1,
          tickColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        position: 'left',
        min: this.tickYMin,
        max: this.tickYMax,
        ticks: {
          stepSize: this.tickYStepSize,
          color: '#475569',
          callback: (tickValue: string | number, index: number, ticks: any[]) => {
            if (index < this.tickYValues.length) {
              return this.tickYValues[index];
            } else {
              return '';
            }
          },
          // // Hide every 2nd tick label
          // callback: function (val: any, index) {
          //   return index % 2 === 0 ? this.getLabelForValue(val) : '';
          // },
        },
      },
    },
    plugins: {
      legend: { display: false },
      annotation: {
        annotations: [],
      },
    },
  };

  generateMonthArray(numOfMonths: number): string[] {
    const currentDate = new Date(); // Get current date
    const monthsArray: string[] = [];
    for (let i = 0; i < numOfMonths; i++) {
      const month = currentDate.getMonth() - i;
      let year = currentDate.getFullYear();
      if (month < 0) {
        year--;
      }
      const adjustedMonth = (month + 12) % 12;
      const formattedMonth = `${new Date(year, adjustedMonth).toLocaleString('default', { month: 'short' })} ${year}`;
      monthsArray.unshift(formattedMonth); // Using unshift to add at the beginning of the array
    }
    return monthsArray;
  }

  // Toggle visibility of the dataset based on its index
  toggleDatasetVisibility(event: any, datasetIndex: number): void {
    const chart: any = Chart.getChart(this.chartRef.nativeElement);
    if (event.target.checked) {
      chart.data.datasets[datasetIndex].hidden = false;
    } else {
      chart.data.datasets[datasetIndex].hidden = true;
    }
    chart.update();
  }

  // ----------------------------
  // Bar Chart
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  @Input() barChartTickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  @Input() barChartTickYMin: number = 0;
  @Input() barChartTickYMax: number = 1000;
  @Input() barChartTickYStepSize: number = 200;
  @Input() inputBarChartData: any[] = [];

  public barChartData: ChartData<'bar'> = {
    datasets: [],
    labels: this.generateMonthArray(12),
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {
        position: 'left',
        grid: {
          color: (context: any) => 'rgba(0, 0, 0, 0.1)', // Color of x-axis grid lines
          tickWidth: 1,
          tickColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        position: 'left',
        min: this.barChartTickYMin,
        max: this.barChartTickYMax,
        ticks: {
          stepSize: this.barChartTickYStepSize,
          color: '#475569',
          callback: (tickValue: string | number, index: number, ticks: any[]) => {
            if (index < this.barChartTickYValues.length) {
              return this.barChartTickYValues[index];
            } else {
              return '';
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  // ----------------------------
  // Pie Chart
  public pieChartType: ChartType = 'pie';
  public pieChartData: ChartData<'pie'> = {
    labels: ['Thkee Earning', 'Instructor Earning'],
    datasets: [
      {
        data: [40, 60],
        label: 'Data',
        backgroundColor: ['#323232', '#CCCCCC'],
      },
    ],
  };

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: '',
      },
      datalabels: {
        display: true,
        align: 'bottom',
        backgroundColor: '#ccc',
        borderRadius: 20,
        font: {
          size: 12,
        },
      },
    },
  };

  // ----------------------------
  // Doughnut Chart
  doughnutChartInfo: DoughnutChartData[] = [];
  @ViewChild('doughnutchart') doughnutChartRef!: ElementRef;
  @Input() inputDoughnutChartLabels: string[] = ['Thkee Earning', 'Instructor Earning', 'Test'];
  @Input() inputDoughnutChartData: number[] = [40, 40, 20];
  @Input() inputDoughnutChartBG: string[] = ['#323232', '#CCCCCC', '#E3E3E3'];
  @Input() doughnutChartTitle: string = '';
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.inputDoughnutChartLabels,
    datasets: [],
  };

  public doughnutChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: '',
      },
    },
  };

  // Toggle visibility of the dataset based on its index
  toggleDonatDatasetVisibility(event: any, data: DoughnutChartData, datasetIndex: number): void {
    const chart: any = Chart.getChart(this.doughnutChartRef.nativeElement);
    const meta = chart.getDatasetMeta(0); // Assuming you have only one dataset
    const currentVisibility = meta.data[datasetIndex].hidden;
    meta.data[datasetIndex].hidden = !currentVisibility;
    chart.update();
  }

  /************************************
   *******Circle Progressbar*******
   *************************************/
  @Input() outerStrokeLinecap: any = 'round'; // 'round' || 'butt'
  @Input() outerStrokeColor: any = '#3941FE';
  @Input() innerStrokeColor: any = '#CCCCCC';
  @Input() percent: number = 0;
  @Input() radius: number = 30;
  @Input() outerStrokeWidth: number = 8;
  @Input() innerStrokeWidth: number = 4;
  @Input() space: number = -5;
  @Input() animationDuration: number = 300;
  @Input() backgroundPadding: number = 0;
  @Input() showTitle: boolean = false;
  @Input() showSubtitle: boolean = false;
  @Input() showUnits: boolean = false;
  @Input() responsive: boolean = false;

  /************************************
   *******Chart common functions*******
   *************************************/
  @ViewChild(BaseChartDirective) chartLine?: BaseChartDirective;

  // Linechart hover
  public chartHovered({ event, active }: { event?: ChartEvent; active?: object[] }): void {
    // console.log(event, active);
  }

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.datasets.length; i++) {
      for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
        this.lineChartData.datasets[i].data[j] = ChartComponent.generateNumber(i);
      }
    }
    this.chartLine?.update();
  }

  // Chart click events
  public chartClicked({ event, active }: { event?: ChartEvent; active?: object[] }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chartLine?.isDatasetHidden(1);
    this.chartLine?.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.datasets.forEach((x, i) => {
      const num = ChartComponent.generateNumber(i);
      x.data.push(num);
    });
    this.lineChartData?.labels?.push(`Label ${this.lineChartData.labels.length}`);

    this.chartLine?.update();
  }

  public changeColor(): void {
    this.lineChartData.datasets[2].borderColor = 'green';
    this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chartLine?.update();
  }

  public changeLabel(): void {
    const tmp = this.newLabel;
    this.newLabel = this.lineChartData.datasets[2].label;
    this.lineChartData.datasets[2].label = tmp;

    this.chartLine?.update();
  }
}
