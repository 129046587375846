import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-common-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {
  @ViewChild('modal')
  modal!: ModalComponent;

  signUp: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }
}
