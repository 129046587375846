import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CourseQuizAnswer, PartialEntity } from '../../models';

// export const studentQuizAnswerAdapter: EntityAdapter<PartialEntity<StudentQuizAnswerV2>> = createEntityAdapter<
//   PartialEntity<StudentQuizAnswerV2>
// >({
//   selectId: (e) => e.id,
// });

// export const initialStudentQuizAnswerState = studentQuizAnswerAdapter.getInitialState();

// export type StudentQuizAnswerEntityState = EntityState<PartialEntity<StudentQuizAnswerV2>>;

// -------

export const studentQuizAnswerAdapter: EntityAdapter<PartialEntity<CourseQuizAnswer>> = createEntityAdapter<
  PartialEntity<CourseQuizAnswer>
>({
  selectId: (e: PartialEntity<CourseQuizAnswer>) => e.question_id || '', // Handle the case where question_id is undefined
});

export const initialStudentQuizAnswerState = studentQuizAnswerAdapter.getInitialState();

export type StudentQuizAnswerEntityState = EntityState<PartialEntity<CourseQuizAnswer>>;
