import { Injectable } from '@angular/core';
import { ResponseData } from '@shared/store';
import { Observable } from 'rxjs';
import {
  API_INSTRUCTOR_MARK_NOT_RELEVANT,
  API_INSTRUCTOR_QNA_ALL,
  API_INSTRUCTOR_QNA_GROUP,
  API_INSTRUCTOR_QNA_REPLY,
  API_INSTRUCTOR_QNA_REPLY_ALL,
  API_INSTRUCTOR_QNA_REPLY_UPDATE,
  API_INSTRUCTOR_REPORT_QNA,
  API_INSTRUCTOR_REPORT_QNA_REPLY,
  HttpService,
  encodeURL,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class QaService {
  constructor(private readonly http: HttpService) {}

  getQnAGroupByDate(params = {} as object): Observable<ResponseData> {
    return this.http.get<ResponseData>(encodeURL(API_INSTRUCTOR_QNA_GROUP, params));
  }

  getAllQnA(params = {} as object, course_id: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(encodeURL(API_INSTRUCTOR_QNA_ALL.replace('<COURSE_ID>', course_id), params));
  }

  reportQnAAll(qna_id: string, body: any): Observable<ResponseData> {
    return this.http.post<ResponseData>(API_INSTRUCTOR_REPORT_QNA.replace('<QNA_ID>', qna_id), body);
  }

  reportQnAReply(qna_id: string, body: any): Observable<ResponseData> {
    return this.http.post<ResponseData>(API_INSTRUCTOR_REPORT_QNA_REPLY.replace('<QNA_REPLY_ID>', qna_id), body);
  }

  markNotRelevant(qna_id: string): Observable<ResponseData> {
    return this.http.put<ResponseData>(API_INSTRUCTOR_MARK_NOT_RELEVANT.replace('<QNA_ID>', qna_id), {});
  }

  getQnAReply(qna_id: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_INSTRUCTOR_QNA_REPLY_ALL.replace('<QNA_ID>', qna_id));
  }

  postQnAReply(body: any, qna_id: string): Observable<ResponseData> {
    return this.http.post<ResponseData>(API_INSTRUCTOR_QNA_REPLY.replace('<QNA_ID>', qna_id), body);
  }

  putQnAReplyUpdate(body: any, qna_id: string, reply_id: string): Observable<ResponseData> {
    return this.http.put<ResponseData>(
      API_INSTRUCTOR_QNA_REPLY_UPDATE.replace('<QNA_ID>', qna_id).replace('<REPLY_ID>', reply_id),
      body
    );
  }
}
