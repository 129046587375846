import { createAction, props } from '@ngrx/store';

export const errorActions = {
  clientError: createAction(
    '[Error] Client error request',
    props<{ section: string; status: number; error: any }>()
  ),
  serverError: createAction(
    '[Error] Server side error request',
    props<{ section: string; status: number; error: any }>()
  ),
};
