import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@shared/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-assignments-accordion',
  templateUrl: './assignments-accordion.component.html',
  styleUrls: ['./assignments-accordion.component.scss'],
})
export class AssignmentsAccordionComponent implements OnInit {
  @Input() assignments: Observable<Response> | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  isCollapse: boolean[] = [];
  collapse(i: any) {
    this.isCollapse[i] = !this.isCollapse[i];
  }
  seeSubmission(event: any, data: any) {
    event.stopPropagation();
    this.router.navigate(['/instructor/assignments/courses-assignment/' + data.course_id]);
  }
}
