import { Component, Input, OnInit } from '@angular/core';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-instructor-sidebar',
  templateUrl: './instructor-sidebar.component.html',
  styleUrls: ['./instructor-sidebar.component.scss'],
})
export class InstructorSidebarComponent implements OnInit {
  @Input() fullSize: boolean = false;
  activeRoute: string = '/instructor/courses';

  constructor(private routerStoreService: RouterStoreService) {
    this.routerStoreService.getUrl().subscribe((data) => {
      this.activeRoute = data;
      console.log('activeRoute--', this.activeRoute);
    });
  }

  ngOnInit(): void {}

  isActive(route = 'courses') {
    return this.activeRoute.endsWith(route) ? true : false;
  }

  isActiveChild(route = '/instructor/assignments/course-list', slice: number) {
    const parts = this.activeRoute.split('/');
    const firstTwoParts = parts.slice(0, slice).join('/');
    return firstTwoParts.endsWith(route) ? true : false;
  }
}
