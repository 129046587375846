import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '@env/environment';

interface Address {}
interface Card {
  id: string;
  object: string;
  address: Address;
  funding: string;
  fingerprint: string;
  brand: string;
  scheme: string;
  category: string;
  exp_month: number;
  exp_year: number;
  last_four: string;
  first_six: string;
  first_eight: string;
  name: string;
}
interface TokenInfo {
  id: string;
  created: number;
  object: string;
  live_mode: boolean;
  type: string;
  source: string;
  used: boolean;
  card: Card;
  client_ip: string;
}
interface TokenResponse {
  success: boolean;
  result: TokenInfo;
}

@Component({
  selector: 'app-form-tap-payment',
  templateUrl: './tap-payment.component.html',
  styleUrls: ['./tap-payment.component.scss'],
})
export class FormTapPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('formRef') formRef!: NgForm;
  @Output() token = new EventEmitter<string>();

  tapLoaded: boolean = false;
  error_Message: string = '';

  inputValue: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit(): void {
    console.log('FormTapPaymentComponent Init');
    this.loadScript('tap-bluebird', 'https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js');
    this.loadScript('tap-sdk', 'https://secure.gosell.io/js/sdk/tap.min.js');
    setTimeout(() => {
      this.initForm();
    }, 200);
  }

  ngOnDestroy(): void {
    console.log();
    this.unloadScript('tap-bluebird');
    this.unloadScript('tap-sdk');
  }

  private loadScript(id: string, src: string): void {
    if (!document.getElementById(id)) {
      const script = this.renderer.createElement('script');
      script.id = id;
      script.src = src;
      this.renderer.appendChild(document.head, script);
    }
  }

  private unloadScript(id: string): void {
    const script = document.getElementById(id);
    if (script) {
      this.renderer.removeChild(document.head, script);
    }
  }
  iniTapSdk() {}

  initForm() {
    var form = document.getElementById('form-container');
    if (form) {
      const key = environment.tapPublicKey ? environment.tapPublicKey : '';
      //pass your public key from tap's dashboard
      let tap: any = null;
      try {
        tap = (window as any).Tapjsli(key);
      } catch (err) {
        setTimeout(() => {
          this.initForm();
        }, 200);
      }

      if (!tap) {
        return;
      }
      let elements = tap.elements({});
      let style = {
        base: {
          color: '#535353',
          lineHeight: '18px',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#94A3B8',
            fontSize: '15px',
          },
        },
        invalid: {
          color: 'red',
        },
      };
      // input labels/placeholders
      let labels = {
        cardNumber: $localize`**** **** **** 0000`,
        expirationDate: $localize`MM/YY`,
        cvv: $localize`CVV`,
        cardHolder: $localize`Enter your card name`,
      };
      //payment options
      let paymentOptions = {
        currencyCode: ['KWD', 'USD', 'SAR'],
        labels: labels,
        TextDirection: 'ltr',
      };
      //create element, pass style and payment options
      let card = elements.create('card', { style: style }, paymentOptions);
      //mount element
      card.mount('#element-container');
      let mainClass = this;

      // Handle form submission
      var checkCard: any = false;
      let token: TokenResponse;
      card.addEventListener('change', function (event: any) {
        mainClass.error_Message = '';
        if (event.BIN) {
          console.log(event.BIN);
        }
        if (event.loaded) {
          mainClass.tapLoaded = true;
        }
        if (event.error) {
          mainClass.error_Message = event.error.message;
        } else {
          mainClass.error_Message = '';
        }
        // Submit the form after 3 seconds
        if (event.code == 200) {
          if (form && mainClass.tapLoaded) {
            clearTimeout(checkCard);
            checkCard = setTimeout(() => {
              if (!token) {
                tap.createToken(card).then(function (result: any) {
                  if (result.error) {
                    mainClass.error_Message = result.error.message;
                  } else {
                    if (result.id) {
                      token = result.id;
                      mainClass.error_Message = '';
                      mainClass.token.emit(result.id);
                    }
                  }
                });
              }
            }, 2000); // 3000 milliseconds = 3 seconds
          }
        }
      });
    }
  }
}
