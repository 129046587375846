<ng-container>
   <div class="flex gap-[2px] items-center">
      <svg-icon
         [ngClass]="{'!text-primary-500':data>=1,'w-4 h-4':size==='sm', 'w-5 h-5':size==='md', 'w-7 h-7':size==='lg'}"
         src="@assets/images/icons/star-solid.svg" class="text-slate-200">
      </svg-icon>
      <svg-icon
         [ngClass]="{'!text-primary-500':data>=2,'w-4 h-4':size==='sm', 'w-5 h-5':size==='md', 'w-7 h-7':size==='lg'}"
         src="@assets/images/icons/star-solid.svg" class="text-slate-200">
      </svg-icon>
      <svg-icon
         [ngClass]="{'!text-primary-500':data>=3,'w-4 h-4':size==='sm', 'w-5 h-5':size==='md', 'w-7 h-7':size==='lg'}"
         src="@assets/images/icons/star-solid.svg" class="text-slate-200">
      </svg-icon>
      <svg-icon
         [ngClass]="{'!text-primary-500':data>=4,'w-4 h-4':size==='sm', 'w-5 h-5':size==='md', 'w-7 h-7':size==='lg'}"
         src="@assets/images/icons/star-solid.svg" class="text-slate-200">
      </svg-icon>
      <svg-icon
         [ngClass]="{'!text-primary-500':data>=5,'w-4 h-4':size==='sm', 'w-5 h-5':size==='md', 'w-7 h-7':size==='lg'}"
         src="@assets/images/icons/star-solid.svg" class="text-slate-200">
      </svg-icon>
   </div>
</ng-container>

<!-- 
<ng-container *ngIf="size==='sm'">
   <div class="flex gap-[2px] items-center">
      <svg-icon [ngClass]="data>=1?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="data>=2?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="data>=3?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="data>=4?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="data>=5?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
   </div>
</ng-container>
<ng-container *ngIf="size==='md'">
   <div class="flex gap-[2px] items-center">
      <svg-icon [ngClass]="data>=1?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-5 h-5">
      </svg-icon>
      <svg-icon [ngClass]="data>=2?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-5 h-5">
      </svg-icon>
      <svg-icon [ngClass]="data>=3?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-5 h-5">
      </svg-icon>
      <svg-icon [ngClass]="data>=4?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-5 h-5">
      </svg-icon>
      <svg-icon [ngClass]="data>=5?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-5 h-5">
      </svg-icon>
   </div>
</ng-container>
<ng-container *ngIf="size==='lg'">
   <div class="flex gap-[2px] items-center">
      <svg-icon [ngClass]="data>=1?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-7 h-7">
      </svg-icon>
      <svg-icon [ngClass]="data>=2?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-7 h-7">
      </svg-icon>
      <svg-icon [ngClass]="data>=3?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-7 h-7">
      </svg-icon>
      <svg-icon [ngClass]="data>=4?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-7 h-7">
      </svg-icon>
      <svg-icon [ngClass]="data>=5?'text-primary-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-7 h-7">
      </svg-icon>
   </div>
</ng-container> -->