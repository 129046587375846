import { createFeature, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { CourseAssignmentAnswer, CourseQnA, CourseQnAReply, CourseQuizAnswer } from '../../models';
import { courseQnAAdapter } from './course-qna.entity';
import { LearningLabState, initialLearningLabState } from './learning-lab-state.model';
import { LearningLabActions } from './learning-lab.actions';
import { studentAssignmentAnswerAdapter } from './student-assignment-answer.entity';
import { studentQuizAnswerAdapter } from './student-quiz-answer.entity';

export const learningLabReducer = createReducer(
  initialLearningLabState,
  on(LearningLabActions.init, (state, { courseDetail }) => {
    return {
      ...state,
      courseDetail,
    };
  }),
  on(LearningLabActions.selectActivity, (state, { sectionId, subsectionId }): LearningLabState => {
    return {
      ...state,
      activeSectionId: sectionId,
      activeSubsectionId: subsectionId,
    };
  }),
  on(LearningLabActions.selectSection, (state, { sectionId, subsectionId }): LearningLabState => {
    return {
      ...state,
      activeSectionId: sectionId,
      activeSubsectionId: subsectionId,
    };
  }),
  on(LearningLabActions.nextActivity, (state) => {
    const activeSection = state.courseDetail.sections?.find((s) => s.id === state.activeSectionId);
    const lastSubsection = _.last(activeSection?.subsections);
    return {
      ...state,
      completedActivity: {
        ...state.completedActivity,
        [state.activeSectionId]: state.activeSubsectionId === lastSubsection?.id,
        [state.activeSubsectionId]: true,
      },
    };
  }),
  on(LearningLabActions.prevActivity, (state) => {
    return state;
  }),
  on(LearningLabActions.loadCourseQnASuccess, (state, { data }): LearningLabState => {
    const emptyState = initialLearningLabState;
    return {
      ...state,
      courseQnA: courseQnAAdapter.upsertMany(data.results as CourseQnA[], emptyState.courseQnA),
    };
  }),
  on(LearningLabActions.addCourseQnASuccess, (state, { data }): LearningLabState => {
    return {
      ...state,
      courseQnA: courseQnAAdapter.upsertOne({ ...data }, state.courseQnA),
    };
  }),
  on(LearningLabActions.getCourseQnASuccess, (state, { data }): LearningLabState => {
    return {
      ...state,
      selectedQnA: data,
    };
  }),
  on(LearningLabActions.replyCourseQnASuccess, (state, { data }): LearningLabState => {
    const existingReplies = state.selectedQnA ? state.selectedQnA.replies : []; // Default to empty array if replies are undefined
    const updatedReplies = [...(existingReplies as CourseQnAReply[]), data]; // Append new reply to replies array
    const updatedSelectedQnA: CourseQnA = {
      ...state.selectedQnA!,
      reply_count: state.selectedQnA.reply_count + 1,
      replies: updatedReplies,
    }; // Use non-null assertion operator to ensure selectedQnA is defined
    return {
      ...state,
      selectedQnA: updatedSelectedQnA,
    };
  }),
  on(LearningLabActions.getAssignmentAnswersSuccess, (state, { data }): LearningLabState => {
    console.log('getAssignmentAnswersSuccess--', data);
    return {
      ...state,
      studentAssignmentAnswer: studentAssignmentAnswerAdapter.upsertMany(
        data as CourseAssignmentAnswer[],
        state.studentAssignmentAnswer
      ),
    };
  }),
  on(LearningLabActions.submitAssignmentSuccess, (state, { data }): LearningLabState => {
    return {
      ...state,
      studentAssignmentAnswer: studentAssignmentAnswerAdapter.upsertMany(
        data as CourseAssignmentAnswer[],
        state.studentAssignmentAnswer
      ),
    };
  }),
  on(LearningLabActions.getQuizAnswersSuccess, (state, { data }): LearningLabState => {
    return {
      ...state,
      studentQuizAnswer: studentQuizAnswerAdapter.upsertMany(data as CourseQuizAnswer[], state.studentQuizAnswer),
    };
  })
);

export const learningLabFeature = createFeature({
  name: 'learningLab',
  reducer: learningLabReducer,
});
