<ng-container *ngIf="{ feedbackType: feedbackType$ | async, annotation: annotation$ | async } as vm">
  <div *ngIf="!vm.annotation" i18n>Invalid Feedback data</div>
  <div class="feedback-item" *ngIf="vm.annotation">
    <div class="flex items-center justify-between text-base">
      <div>
        <div class="mb-1 cursor-pointer font-medium hover:underline" (click)="gotoFeedback(vm.annotation)">
          {{ formatTitle(vm.annotation.field) }}
        </div>
        <div class="text-xs text-slate-500">{{ vm.annotation.date_updated | date : 'h:mm a, MMM d' }}</div>
      </div>
      <div class="action">
        <button class="btn btn-sm btn-white text-slate-400" *ngIf="!vm.annotation.is_resolved" (click)="onResolve()">
          <svg-icon src="@assets/images/icons/check-circle.svg" class="btn-icon"></svg-icon>
          <span i18n>Mark as solved</span>
        </button>
        <button class="btn btn-sm btn-transparent text-green-600" *ngIf="vm.annotation.is_resolved"
          (click)="onUnresolve()">
          <svg-icon src="@assets/images/icons/check-circle-solid.svg" class="btn-icon"></svg-icon>
          <span i18n>Solved</span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="vm.feedbackType === 'danger'">
      <ng-container *ngTemplateOutlet="danger"></ng-container>
    </ng-container>
    <ng-container *ngIf="vm.feedbackType === 'warning'">
      <ng-container *ngTemplateOutlet="warning"></ng-container>
    </ng-container>
    <ng-container *ngIf="vm.feedbackType === 'normal'">
      <ng-container *ngTemplateOutlet="normal"></ng-container>
    </ng-container>
  </div>

  <ng-template #danger>
    <div class="mt-3 rounded-[4px] border border-red-100 bg-red-50 p-2.5 text-sm text-red-600">
      {{ vm.annotation?.feedback }}
    </div>
  </ng-template>
  <ng-template #warning>
    <div class="mt-3 rounded-[4px] border border-[#FFEDD5] bg-[#FFF7ED] p-2.5 text-sm text-[#EA580C]">
      {{ vm.annotation?.feedback }}
    </div>
  </ng-template>
  <ng-template #normal>
    <div class="mt-3 rounded-[4px] border border-slate-100 bg-slate-50 p-2.5 text-sm text-slate-500">
      {{ vm.annotation?.feedback }}
    </div>
  </ng-template>
</ng-container>