import { User as CommonUser } from 'thkee-common';

// Initial State
export const initialCourseDetailsState: CourseDetails = {
  id: '',
  title: '',
  owned: false,
  favorite: false,
  code: '',
  user: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    is_active: false,
    is_superuser: false,
  },
  slug: '',
  // type: '',
  currency: 0,
  pricing: '',
  subtitle: '',
  desc: '',
  description: '',
  image: undefined,
  promo_video: undefined,
  video_info: {},
  is_promote: false,
  skill_level: '',
  category: {
    id: '',
    name: '',
  },
  subcategory: {
    id: '',
    name: '',
  },
  language: '',
  topics: [],
  status: '',
  begin_msg: undefined,
  completion_msg: undefined,
  price: '',
  included_in_promo: false,
  date_published: undefined,
  is_deleted: false,
  image_url: undefined,
  promo_video_url: undefined,
  project: {
    id: '',
    published_course: '',
    unpublished_course: '',
    state: '',
    has_substantial_changes: false,
    user: 0,
    courses: [],
    date_created: '',
    date_updated: '',
  },
  version: '',
  date_updated: '',
  date_created: '',
  objectives: [],
  learners: [],
  requirements: [],
  image_info: undefined,
  duration: 0,
  currency_code: '',
  quiz_count: 0,
  assignment_count: 0,
  article_count: 0,
  resources_count: 0,
  total_reviews: 0,
  total_rate: 0,
  total_assignments: 0,
  total_submissions: 0,
  is_editable: false,
  video_length: 0,
  listed_price: 0,
  sale_price: 0,
  lectures: [],
  resource_mode: '',
  needed_for_review: {
    intended_learners: {
      objectives: false,
      requirements: false,
      learners: false,
    },
    details: {
      title: false,
      subtitle: false,
      topics: false,
      description: false,
      skill_level: false,
      language: false,
      category: false,
      subcategory: false,
      image: false,
    },
    curriculum: {
      video_length: false,
      lectures: false,
    },
    pricing: {
      price: false,
    },
    annotations: {
      resolved_all_required: false,
      annotations_required_unresolved_count: 0,
    },
  },
  is_valid_for_review: false,
  orders_this_month: [],
  is_bestseller: false,
  minute_watched: 0,
  median_completion_times: [],
  sold_courses: 0,
  course_topics: {
    total_sections: 0,
    total_lectures: 0,
    total_assignments: 0,
    total_quiz: 0,
  },
  enrollments: 0,
  average_rating: 0,
  course_id: '',
  unlisted: false,
  highest_rated: false,
  is_in_cart: false,
  is_pending_for_review: false,
  priceDetails: {},
};

export interface CourseDetails {
  id: string;
  title: string;
  owned: boolean;
  favorite: boolean;
  code: string;
  user: User;
  slug: string;
  // type: string;
  currency: number;
  pricing: string;
  subtitle: string;
  desc: string;
  description: string;
  image: any;
  promo_video: any;
  video_info: VideoInfo;
  is_promote: boolean;
  skill_level: string;
  category: Category;
  subcategory: Category;
  language: string;
  topics: Topic[];
  status: string;
  begin_msg: any;
  completion_msg: any;
  price: string;
  included_in_promo: boolean;
  date_published: any;
  is_deleted: boolean;
  image_url: any;
  promo_video_url: any;
  project: Project;
  version: string;
  date_updated: string;
  date_created: string;
  objectives: string[];
  learners: string[];
  requirements: string[];
  image_info: any;
  duration: number;
  currency_code: string;
  quiz_count: number;
  assignment_count: number;
  article_count: number;
  resources_count: number;
  total_reviews: number;
  total_rate: number;
  total_assignments: number;
  total_submissions: number;
  is_editable: boolean;
  video_length: number;
  listed_price: number;
  sale_price: number;
  lectures: string[];
  resource_mode: string;
  needed_for_review: NeededForReview;
  is_valid_for_review: boolean;
  is_pending_for_review: boolean;
  orders_this_month: any[];
  is_bestseller: boolean;
  highest_rated: boolean;
  minute_watched: number;
  median_completion_times: any[];
  sold_courses: number;
  course_topics: {
    total_sections: number;
    total_lectures: number;
    total_assignments: number;
    total_quiz: number;
  };
  enrollments: number;
  average_rating: number;
  course_id: string;
  unlisted: boolean;
  is_in_cart: boolean;
  priceDetails: any;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  fullname?: string;
  photo?: string;
  language?: string;
  bio?: string;
  is_instructor?: boolean;
}

export interface Category {
  id: string;
  name: string;
  slug?: string;
  category?: string;
}

export interface Topic {
  id: string;
  name: string;
  slug: string;
}

export interface VideoInfo {}

export interface Project {
  id: string;
  published_course: string;
  unpublished_course: string;
  state: string;
  has_substantial_changes: boolean;
  user: number;
  courses: string[];
  date_created: string;
  date_updated: string;
}

export interface NeededForReview {
  intended_learners: {
    objectives: boolean;
    requirements: boolean;
    learners: boolean;
  };
  details: {
    title: boolean;
    subtitle: boolean;
    topics: boolean;
    description: boolean;
    skill_level: boolean;
    language: boolean;
    category: boolean;
    subcategory: boolean;
    image: boolean;
  };
  curriculum: {
    video_length: boolean;
    lectures: boolean;
  };
  pricing: {
    price: boolean;
  };
  annotations: {
    resolved_all_required: boolean;
    annotations_required_unresolved_count: number;
  };
}

// Instructor
export interface CoursesInstructor {
  id: number;
  user: CommonUser;
  group_name: string;
  ratings: number;
  students: number;
  courses: number;
}

// Course Content
export interface Lecture {
  id: string;
  video_url: string | null;
  duration: number;
  preview: boolean;
  title: string;
}
export interface Subsection {
  id: string;
  position: number;
  lecture: Lecture;
}
export interface Section {
  id: string;
  title: string;
  position: number;
  duration: number;
  subsections: Subsection[];
  total_previews: number;
}

// Reviews
interface Review {
  id: string;
  rate: number;
  user: CommonUser;
  content: string;
  likes_count: number;
  dislikes_count: number;
  reported_count: number;
  date_created: string;
  replies: any[]; // Assuming replies might be of any type or another structure which isn't detailed in the provided data
}

interface ReviewStatistics {
  five_star_percentage: number;
  four_star_percentage: number;
  three_star_percentage: number;
  two_star_percentage: number;
  one_star_percentage: number;
}

export interface ProductReviewsResponse {
  id: string;
  review_statistics: ReviewStatistics;
  reviews: Review[];
  total_reviews: number;
  average_rating: number;
}

export interface SeeMoreOptions {
  feedback?: boolean;
  overview?: boolean;
  content?: boolean;
}
