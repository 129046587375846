import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryV2 } from 'thkee-common';

export const categoryAdapter: EntityAdapter<CategoryV2> = createEntityAdapter<CategoryV2>({
  selectId: (category) => category.id ?? ''
});

export const initialCategoryState = categoryAdapter.getInitialState();

export type CategoryEntityState = EntityState<CategoryV2>;
