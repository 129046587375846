import { createAction, props } from '@ngrx/store';
import { CartSummary } from 'thkee-common';
import { cartItem, cartPriceItem } from './cart-state.model';

export const CartActions = {
  initUserCart: createAction('[Cart] Init User Cart'),
  initUserCartSuccess: createAction('[Cart] Init User Success Cart'),
  loadCart: createAction('[Cart] Load Cart'),
  loadCartSuccess: createAction('[Cart] Load Cart Success', props<any>()),
  loadCartFail: createAction('[Cart] Load Cart Fail', props<{ error: unknown }>()),
  // addToCart: createAction('[Cart] Add to cart', props<{ courseId: string; data?: Course | CourseV2 }>()),
  addToCart: createAction('[Cart] Add to cart', props<any>()),
  addToCartSuccess: createAction('[Cart] Add to cart Success', props<any>()),
  removeToCart: createAction('[Cart] Remove to cart', props<{ id: string }>()),
  removeToCartSuccess: createAction('[Cart] Remove to cart Success', props<any>()),
  applyCoupon: createAction('[Cart] Apply coupon to cart', props<{ code: string }>()),
  applyCouponSuccess: createAction('[Cart] Apply coupon to cart Success', props<any>()),
  applyCouponFail: createAction('[Cart] Apply coupon to cart fail', props<any>()),
  applyCredit: createAction('[Cart] Apply credit to cart', props<{ credit: number }>()),
  applyCreditSuccess: createAction('[Cart] Apply credit to cart Success', props<any>()),
  computeCart: createAction('[Cart] Compute Cart'),
  computeCartSuccess: createAction('[Cart] Compute Cart Success', props<any>()),
  computeCartFail: createAction('[Cart] Comnpute Cart Fail', props<{ error: unknown }>()),
  checkout: createAction('[Cart] Checkout'),
  checkoutSuccess: createAction('[Cart] Checkout Success', props<any>()),
  checkoutFailed: createAction('[Cart] Checkout Fail', props<{ error: unknown }>()),
  loadCartItems: createAction('[Cart] Load Cart Items'),
  loadCartItemsSuccess: createAction('[Cart] Load Cart Items Success', props<{ cartItems: any }>()),
  loadCartPrice: createAction('[Cart] Load Cart Items Prices', props<{ ids: string[] }>()),
  loadCartPriceSuccess: createAction(
    '[Cart] Load Cart Prices Success',
    props<{ data: { [key: string]: cartPriceItem } }>()
  ),
  updateCartFavorite: createAction('[Cart] Update Cart Favorite', props<{ item: cartItem }>()),
  updateCartSummary: createAction('[Cart] Update Cart Summary', props<{ summary: CartSummary }>()),
};
