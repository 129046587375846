import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContentType, PartialEntity } from '../../models';

export const contentTypeAdapter: EntityAdapter<PartialEntity<ContentType>> = createEntityAdapter<
  PartialEntity<ContentType>
>({
  selectId: (e) => e.id,
});

export const initialContentTypeState = contentTypeAdapter.getInitialState();

export type ContentTypeEntityState = EntityState<PartialEntity<ContentType>>;
