import { Params } from '@angular/router';
import { SerializedRouterStateSnapshot, getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

export const {
  selectUrl,
  selectRouteParams,
  selectRouteParam,
  selectCurrentRoute,
  selectFragment,
  selectQueryParam,
  selectQueryParams,
  selectRouteData,
  selectTitle,
} = getSelectors();

export const selectRouter = createSelector(
  (state: any) => state.router,
  (router) => router as { state: SerializedRouterStateSnapshot }
);

export const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params[param]);
