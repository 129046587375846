import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-fileDashboardInfo',
  template: `
    <div class="flex flex-col gap-4" *ngIf="!field.props?.disabled">
      <div class="flex flex-col items-start justify-start gap-4">
        <label class="font-semibold !text-base">{{ field.props?.label }}</label>
        <div class="text-slate-500 !text-base">{{ field.props?.placeholder }}</div>
      </div>
      <div class="inline-flex gap-4">
        <input type="button" class="btn btn-primary" value="Browse File" (click)="emitClickEvent('open')" />
        <input type="button" class="btn border-white" value="Reset" (click)="emitClickEvent('reset')" />
      </div>
    </div>
    <div class="file-metadata flex flex-col gap-1" *ngIf="field.props?.disabled">
      <div i18n>File name here</div>
      <div class="text-neutral-600">
        <div i18n>file metadata here</div>
      </div>
    </div>
  `,
})
export class FormlyFileDashboardInfoComponent implements OnInit {
  @Input() field!: FormlyFieldConfig;
  @Output() clickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
  }
}
