import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CHECKOUT_INIT } from '../../models';
import { HttpService } from '../http';
import { Logger } from '../logger';

const log = new Logger('CartAPIService');

@Injectable({
  providedIn: 'root',
})
export class CartAPIService {
  constructor(private readonly http: HttpService) {}

  checkout(payload = {} as object): Observable<any> {
    return this.http.post<any>(API_CHECKOUT_INIT, payload);
    // return of({
    //   href: 'https://www.sandbox.paypal.com/checkoutnow?token=8VX067692E5770922',
    //   rel: 'payer-action',
    //   method: 'GET',
    //   status: 200,
    //   order: 31,
    // });
  }

  // verifyToken(token: string): Observable<any> {
  //     log.debug('API REQUEST verifyToken - ', `${API_PAYMENT_VERIFY_TOKEN}${token}/`);
  //     return this.http.get(`${API_PAYMENT_VERIFY_TOKEN}${token}/`);
  // }
}
