<!-- Header -->
<header class="fixed z-50 w-full bg-white shadow">
  <div class="container flex max-h-16 items-center gap-4 py-4">
    <div class="logo relative h-7 w-24">
      <svg-icon src="logo" class="block w-24"></svg-icon>
    </div>
    <div class="inline-flex shrink grow basis-0 items-center justify-center gap-4 border-x border-slate-200 px-4">
      <!-- Menu -->
      <div class="category-menu">
        <div class="menu-dropdown">
          <button class="btn btn-sm border-slate-100" type="button" (click)="select.selectMenu = !select.selectMenu">
            <span i18n>Category</span>
            <svg-icon
              class="btn-icon"
              src="@assets/images/icons/chevron-down"
              [ngClass]="{ 'rotate-180': select.selectMenu }"
            ></svg-icon>
          </button>
          <ul [ngClass]="select.selectMenu ? 'active' : 'hidden'" class="dropdown-items">
            <li class="relative">
              <a uisref="marketplace" href="/search?category__slug=syndicate-front-end-deliverables"
                >Syndicate Front-end Deliverables</a
              >
            </li>
            <li class="group/subItem relative">
              <a uisref="marketplace" href="/search?category__slug=implement-247-e-commerce"
                >Implement 24/7 E-commerce
                <svg-icon src="@assets/images/icons/chevron-right" class="ml-auto"></svg-icon>
              </a>
              <ul
                class="dropdown-subitems lg:group-hover/subItem:visible lg:group-hover/subItem:translate-y-0 lg:group-hover/subItem:opacity-100"
              >
                <li class="group/subChild relative">
                  <a uisref="marketplace" href="/search?subcategory__slug=audiological-scientist"
                    >Audiological Scientist
                    <svg-icon src="@assets/images/icons/chevron-right" class="ml-auto"></svg-icon>
                  </a>
                  <ul
                    class="dropdown-subitems lg:group-hover/subChild:visible lg:group-hover/subChild:translate-y-0 lg:group-hover/subChild:opacity-100"
                  >
                    <li>
                      <div class="heading">Related Topics</div>
                    </li>
                    <li>
                      <a uisref="marketplace" href="/search?topics__slug=expect">
                        <div>
                          <span>Expect</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a uisref="marketplace" href="/search?topics__slug=form">
                        <div>
                          <span>Form</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        uisref="marketplace"
                        class="hover:bg-primary flex items-center p-4 transition-all duration-200 ease-linear hover:text-white"
                        href="/search?topics__slug=discover"
                      >
                        <div>
                          <span>Discover</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a uisref="marketplace" href="/search?topics__slug=next">
                        <div>
                          <span>Next</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a uisref="marketplace" href="/search?topics__slug=course">
                        <div>
                          <span>Course</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!-- Search -->
      <div class="flex grow justify-center">
        <div class="input-group input-group-sm w-full max-w-[400px]">
          <div class="group">
            <svg-icon src="@assets/images/icons/search"></svg-icon>
            <input placeholder="Search courses" />
          </div>
        </div>
      </div>
      <!-- Left Buttons -->
      <div class="flex items-start justify-start gap-3">
        <button class="btn btn-sm border-transparent" routerLink="/account/signup">
          <span>Teach on Thkee</span>
        </button>
        <button class="btn btn-sm btn-outline color-gray" routerLink="/account/signup">
          <span>Create Account</span>
        </button>
        <button class="btn btn-primary btn-sm" routerLink="/account/login">
          <span>Login</span>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-start gap-2">
      <div class="flex items-center justify-start gap-3">
        <a href="#" class="hover:text-primary p-1.5">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/cart"></svg-icon>
        </a>
        <a href="#" class="hover:text-primary rounded border border-slate-200 p-1.5">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/globe"></svg-icon>
        </a>
      </div>
    </div>
  </div>
</header>

<div class="content as-body pt-16">
  <!-- Top Heading -->
  <div class="top-heading bg-secondary">
    <div class="inline-flex h-10 w-full items-center justify-center gap-2.5 p-2.5">
      <div class="text-sm font-medium leading-snug text-white">Get 20% Off of all courses for 1 month only!</div>
      <a href="#">
        <span class="btn btn-xs btn-secondary bg-secondary-800 h-auto py-1">
          <span>Browse Courses</span>
          <svg-icon src="@assets/images/icons/arrow-right" class="btn-icon h-3.5 w-3.5"></svg-icon>
        </span>
      </a>
    </div>
  </div>

  <!-- Heading -->
  <section class="top-header from-primary-50 bg-gradient-to-r">
    <div class="container">
      <div class="content_part py-10 lg:py-[72px]">
        <div class="block gap-8 sm:grid sm:grid-cols-2">
          <div class="left-content flex flex-col gap-2">
            <p class="text-secondary flex items-center font-medium tracking-wide">
              <span class="mr-2">👍</span> <span> 100% SATISFACTION GUARANTEE</span>
            </p>
            <h1 class="text-3xl leading-normal xl:text-5xl xl:leading-[65px]">
              <span>Providing the Best Online</span><br />
              <span>learning Experience</span>
            </h1>
            <p class="mb-3 text-sm">
              <span>Students can access anytime his course and support from instructor. You can</span><br />
              <span>search courses at any time</span>.
            </p>
            <div class="search-input shadow-primary-200/50 flex w-full shadow-xl">
              <div class="input-group input-group-lg">
                <div class="!border-primary group !rounded-r-none">
                  <svg-icon src="@assets/images/icons/search"></svg-icon>
                  <input placeholder="What do you want to loearn?" />
                </div>
              </div>
              <button class="btn btn-lg btn-primary rounded-l-none text-base"><span>Search</span></button>
            </div>
            <div class="block py-2 xl:flex">
              <span class="mr-2">Trending:</span>
              <div class="flex gap-2">
                <span class="badge badge-primary max-w-[200px] truncate">Orchestrate Integrated E-mail</span>
                <span class="badge badge-primary max-w-[200px] truncate">Default</span>
                <span class="badge badge-primary max-w-[200px] truncate">Default</span>
                <span class="badge badge-primary max-w-[200px] truncate">Default</span>
              </div>
            </div>
            <div class="play_video flex items-center gap-2 text-lg">
              <svg-icon src="play-circle" class="h-14 w-14 animate-pulse"></svg-icon>
              <a class="font-medium">See how it works!</a>
            </div>
          </div>
          <div class="right-content flex">
            <div class="m-auto w-full max-w-[500px]">
              <svg-icon src="heading-image"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Most Popular Courses -->
  <section class="most-part box_auto bg-slate-50 py-12 xl:py-16">
    <div class="container">
      <div class="heading_part">
        <h2 class="mb-2">Most popular Courses</h2>
        <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
      </div>
      <!-- app-COURSE-CARD -->
      <div class="relative mt-4">
        <div class="carousel relative">
          <div>
            <swiper [config]="courseSlider" class="!px-4 !py-5">
              <ng-template swiperSlide class="!h-full" *ngFor="let course of topCourses">
                <app-course-card [courseData]="course"></app-course-card>
              </ng-template>
            </swiper>
          </div>
          <app-common-carouselnav></app-common-carouselnav>
        </div>
      </div>
    </div>
  </section>

  <!-- Middle Line -->
  <section class="bg-secondary-800 text-sm text-white">
    <div class="container flex max-w-6xl items-center justify-between py-6">
      <div class="flex items-center gap-4">
        <svg-icon src="human-brain" class="h-12 w-12"></svg-icon>
        <div class="max-w-[140px]">Learn The Essential Things</div>
      </div>
      <div class="flex items-center gap-4">
        <svg-icon src="certificate" class="h-12 w-12"></svg-icon>
        <div class="max-w-[140px]">Earn Certificates And Degrees</div>
      </div>
      <div class="flex items-center gap-4">
        <svg-icon src="career-promotion" class="h-12 w-12"></svg-icon>
        <div class="max-w-[140px]">Get Ready for The Next Career</div>
      </div>
      <div class="flex items-center gap-4">
        <svg-icon src="user-star" class="h-12 w-12"></svg-icon>
        <div class="max-w-[140px]">Master at Different Areas</div>
      </div>
    </div>
  </section>

  <!-- Desire Courses -->
  <section class="desire_courses box_auto py-12 xl:py-16">
    <div class="container">
      <div class="heading_part">
        <h2 class="mb-2">Choose your desire Courses</h2>
        <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
      </div>
      <div class="relative mt-4">
        <div class="carousel relative">
          <div>
            <swiper [config]="categorySlider">
              <ng-template swiperSlide class="!w-auto" *ngFor="let category of categoriesList">
                <button class="btn btn-outline-primary">
                  <span class="capitalize">{{ category.name }}</span>
                </button>
              </ng-template>
            </swiper>
          </div>
          <app-common-carouselnav classPrev="prevCtg" classNext="nextCtg"></app-common-carouselnav>
        </div>
        <div class="related course-category my-8">
          <app-course-grid [courseData]="coursesData"></app-course-grid>
        </div>
      </div>
    </div>
  </section>

  <!-- Spacer -->
  <section class="relative bg-slate-100 py-12">
    <div class="inside-images">
      <div class="absolute left-6 top-6"><svg-icon src="sm-flag"></svg-icon></div>
      <div class="absolute left-[40%] top-6"><svg-icon src="sm-line"></svg-icon></div>
      <div class="absolute right-3 top-6"><svg-icon src="sm-rectangle"></svg-icon></div>
      <div class="absolute bottom-6 left-[30%]"><svg-icon src="sm-pin"></svg-icon></div>
    </div>
    <div class="container flex">
      <div class="max-w-[250px]">
        <h2>Join in on Something Big</h2>
      </div>
      <div class="items text-secondary-600 flex grow items-center justify-end gap-32 text-center">
        <div>
          <div class="text-4xl"><strong>1000+</strong></div>
          <div class="text-xl">Students</div>
        </div>
        <div>
          <div class="text-4xl"><strong>150+</strong></div>
          <div class="text-xl">Instructors</div>
        </div>
        <div>
          <div class="text-4xl"><strong>20+</strong></div>
          <div class="text-xl">Countries</div>
        </div>
      </div>
    </div>
  </section>

  <!-- Viewing Courses -->
  <section class="viweing_courses box_auto bg-slate-50 py-12 xl:py-16">
    <div class="container">
      <div class="heading_part">
        <h2 class="mb-2">Students are viewing</h2>
        <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
      </div>
      <div class="relative mt-4">
        <div class="related course-category my-8">
          <app-course-grid [courseData]="coursesData.slice(2, 6)"></app-course-grid>
        </div>
      </div>
    </div>
  </section>

  <!-- Top Category -->
  <section class="top-category box_auto relative border-t border-slate-200 py-12 xl:py-16">
    <div class="from-primary-50 absolute left-0 top-0 z-10 h-full w-2/4 bg-gradient-to-r"></div>
    <div class="absolute left-0 top-0 z-10 h-full w-2/4 bg-gradient-to-t from-white"></div>
    <div class="absolute right-0 top-0 z-20 h-full w-full">
      <svg-icon src="line-wave"></svg-icon>
    </div>
    <div class="container relative z-30">
      <div class="heading_part">
        <h2 class="mb-2">Top Categories</h2>
        <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
      </div>
      <div class="relative mt-4">
        <div class="related top-category my-8">
          <div class="course-grid grid grid-cols-4 gap-x-8 gap-y-4">
            <div *ngFor="let category of topCategoriesList">
              <div class="item border-primary flex h-full gap-4 rounded border bg-white px-4 py-3 hover:bg-slate-50">
                <div class="icon flex h-auto w-10 items-center">
                  <svg-icon src="image-placeholder"></svg-icon>
                </div>
                <div class="info flex flex-col self-center">
                  <div class="name font-medium capitalize text-slate-800">{{ category.name }}</div>
                  <div class="count text-xs text-slate-700">256 <span>Courses</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer h-40"></div>
      <!-- Review -->
      <div class="z-30">
        <div class="grid grid-cols-3 gap-8">
          <div>
            <div class="heading_part">
              <h2 class="mb-2">People says about Thkee</h2>
              <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
            </div>
          </div>
          <div class="col-span-2 flex gap-6">
            <div class="comment-box flex flex-col gap-6 rounded border border-slate-300 bg-slate-50">
              <div class="mt-6 px-6"><svg-icon src="quote" class="w-9"></svg-icon></div>
              <div class="comment px-6">
                This platform has taught me some tricks and techniques. My IELTS Reading score is what I wanted.
              </div>
              <div class="author inline-flex items-center justify-start gap-4 border-t border-slate-300 px-6 py-3">
                <img class="h-8 w-8 rounded-full" src="https://via.placeholder.com/32x32" />
                <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center">
                  <div class="self-stretch text-base font-medium leading-normal text-slate-800">Marvin McKinney</div>
                  <div class="w-16 text-xs font-normal leading-none tracking-tight text-slate-500">Student</div>
                </div>
              </div>
            </div>
            <div class="comment-box flex flex-col gap-6 rounded border border-slate-300 bg-slate-50">
              <div class="mt-6 px-6"><svg-icon src="quote" class="w-9"></svg-icon></div>
              <div class="comment px-6">
                This platform has taught me some tricks and techniques. My IELTS Reading score is what I wanted.
              </div>
              <div class="author inline-flex items-center justify-start gap-4 border-t border-slate-300 px-6 py-3">
                <img class="h-8 w-8 rounded-full" src="https://via.placeholder.com/32x32" />
                <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center">
                  <div class="self-stretch text-base font-medium leading-normal text-slate-800">Marvin McKinney</div>
                  <div class="w-16 text-xs font-normal leading-none tracking-tight text-slate-500">Student</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Students -->
  <section class="student-block relative bg-slate-50 py-12 xl:py-16">
    <div class="container">
      <div class="grid grid-cols-2 gap-20">
        <div>
          <img src="assets/images/students.png" alt="" />
        </div>
        <div class="flex">
          <div class="content my-auto">
            <h2 class="mb-4">Our Students</h2>
            <p>
              Sed aliquam elit ut urna fermentum, a lacinia dui ultricies. Nulla sed finibus est. In id pharetra massa, nec eleifend orci. Nullam justo tortor, venenatis at felis eget, blandit congue velit. Curabitur posuere fringilla tincidunt. Cras dictum posuere urna tristique fermentum. Quisque tristique odio nulla, quis blandit quam varius semper.
            </p>
            <button class="btn btn-primary">Find Out Now</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- FAQ -->
  <section class="fqa-block relative py-12 xl:py-16">
    <div class="inside-images z-10">
      <div class="absolute left-[5%] top-[40%]"><svg-icon src="sm-subtract"></svg-icon></div>
      <div class="absolute right-[4%] top-[18%]"><svg-icon src="sm-subtract1"></svg-icon></div>
      <div class="absolute bottom-[18%] right-[6%]"><svg-icon src="sm-polygon"></svg-icon></div>
    </div>
    <div class="container z-20">
      <div class="mx-auto max-w-3xl">
        <div class="heading_part">
          <h2 class="mb-2">Frequently Asked Questions(FAQ)</h2>
          <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
        </div>
        <div id="accordion-collapse" data-accordion="collapse">
          <div class="item mb-4">
            <div
              class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3"
              (click)="faq.one = !faq.one"
            >
              <h5 [ngClass]="faq.one ? 'font-bold' : 'font-normal'">Can i get refund if i not continue this course</h5>
              <svg-icon
                src="@assets/images/icons/plus"
                class="ml-auto h-6 w-6 text-slate-600"
                *ngIf="!faq.one"
              ></svg-icon>
              <svg-icon
                src="@assets/images/icons/minus"
                class="ml-auto h-6 w-6 text-slate-600"
                *ngIf="faq.one"
              ></svg-icon>
            </div>
            <div class="content px-4 pt-4 text-slate-500" [ngClass]="faq.one ? 'block' : 'hidden'">
              <p>
                Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
                weeks for
              </p>
            </div>
          </div>
          <div class="item mb-4">
            <div class="title flex items-center rounded-md bg-slate-100 px-4 py-3">
              <h5 class="font-normal">Can i get refund if i not continue this course</h5>
              <svg-icon src="@assets/images/icons/plus" class="ml-auto h-6 w-6 text-slate-600"></svg-icon>
            </div>
            <div class="content px-4 pt-4 text-slate-500" [ngClass]="faq.two ? 'block' : 'hidden'">
              <p>
                Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
                weeks for
              </p>
            </div>
          </div>
          <div class="item mb-4">
            <div class="title flex items-center rounded-md bg-slate-100 px-4 py-3">
              <h5 class="font-normal">Can i get refund if i not continue this course</h5>
              <svg-icon src="@assets/images/icons/plus" class="ml-auto h-6 w-6 text-slate-600"></svg-icon>
            </div>
            <div class="content px-4 pt-4 text-slate-500" [ngClass]="faq.three ? 'block' : 'hidden'">
              <p>
                Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
                weeks for
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Newsletter -->
  <section class="newsletter relative bg-slate-50 py-12 xl:py-16">
    <div class="container">
      <div class="flex justify-between">
        <div>
          <div class="heading_part">
            <h2 class="mb-2">Sign up for our newsletter</h2>
            <p class="text-slate-600">Here you will find the top featured courses presented by category.</p>
          </div>
        </div>
        <div class="form">
          <div class="mb-3 flex gap-3">
            <div class="grow">
              <input placeholder="Enter your full name" class="input-field input-lg w-full" />
            </div>
            <div>
              <button class="btn btn-lg btn-dark">
                <span>Notify me</span>
              </button>
            </div>
          </div>
          <div class="text-sm text-slate-500">
            We care about the protection of your data. Read our&nbsp;<a href="#" class="underline">Privacy Policy</a>.
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Main Footer -->
  <div class="footer bg-slate-900 pb-4 pt-12 text-slate-400">
    <div class="container">
      <div class="grid grid-cols-3 gap-4">
        <div class="flex max-w-xs flex-col gap-5">
          <svg-icon src="logo-plain" class="w-36 text-white"></svg-icon>
          <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
          <div class="language">
            <div class="select2">
              <div class="relative">
                <button
                  type="button"
                  (click)="select.selectLanguage = !select.selectLanguage"
                  class="flex !w-auto items-center gap-3 !bg-transparent !text-white"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <svg-icon class="h-5 w-5" src="@assets/images/icons/globe"></svg-icon>
                  <div class="selected-item truncate">English</div>
                  <svg
                    class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    [ngClass]="{ 'rotate-180': select.selectLanguage }"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </button>
                <!-- Dropdown Items -->
                <ul
                  [ngClass]="select.selectLanguage ? 'show' : 'hidden'"
                  class="select-items"
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3"
                >
                  <li
                    class="item"
                    id="listbox-option-0"
                    role="option"
                    (click)="select.selectLanguage = !select.selectLanguage"
                  >
                    English
                  </li>
                  <li
                    class="item"
                    id="listbox-option-0"
                    role="option"
                    (click)="select.selectLanguage = !select.selectLanguage"
                  >
                    Arabic
                  </li>
                  <li
                    class="item"
                    id="listbox-option-0"
                    role="option"
                    (click)="select.selectLanguage = !select.selectLanguage"
                  >
                    Tagalog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="flex justify-end gap-24">
            <div class="min-w-[200px]">
              <h3 class="mb-6 font-medium" >Company</h3>
              <ul class="flex flex-col gap-3 text-slate-100">
                <li><a href="#">About us</a></li>
                <li><a href="#">Contact us</a></li>
              </ul>
            </div>
            <div class="min-w-[200px]">
              <h3 class="mb-6 font-medium">Service</h3>
              <ul class="flex flex-col gap-3 text-slate-100">
                <li><a href="#">Help & support</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Terms of Service</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
            <div class="min-w-[200px]">
              <h3 class="mb-6 font-medium">Social</h3>
              <ul class="mb-6 flex items-center gap-3 text-slate-100">
                <li>
                  <a href="#"><svg-icon src="@assets/images/icons/facebook" class="w-4"></svg-icon></a>
                </li>
                <li>
                  <a href="#"><svg-icon src="@assets/images/icons/twitter" class="w-4"></svg-icon></a>
                </li>
                <li>
                  <a href="#"><svg-icon src="@assets/images/icons/linkedin" class="w-3.5"></svg-icon></a>
                </li>
                <li>
                  <a href="#"><svg-icon src="@assets/images/icons/youtube" class="w-5"></svg-icon></a>
                </li>
              </ul>
              <ul class="flex items-center gap-2 text-slate-100">
                <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
                  <a href="#"><svg-icon src="apple-pay" class="w-full"></svg-icon> </a>
                </li>
                <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
                  <a href="#"><svg-icon src="paypal" class="w-full"></svg-icon></a>
                </li>
                <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
                  <a href="#"><svg-icon src="visa" class="w-full"></svg-icon> </a>
                </li>
                <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
                  <a href="#"><svg-icon src="master-card" class="w-full"></svg-icon> </a>
                </li>
                <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
                  <a href="#"><svg-icon src="other-card" class="w-full"></svg-icon> </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright mt-8 flex justify-between border-t border-slate-800 py-3 text-sm text-slate-500">
        <div>2022 thkee.com</div>
        <div>All rights reserved.</div>
      </div>
    </div>
  </div>
</div>
