import { createSelector } from '@ngrx/store';
import { selectAnnotationHistoryState, selectCourseHistoryState } from './course.selectors';

export const selectCourseHistory = createSelector(selectCourseHistoryState, (state) => {
  return state.ids.map((id) => state.entities[id]!);
});

export const selectCourseHistoryEntities = createSelector(selectCourseHistoryState, (state) => {
  return state.entities;
});

export const selectAnnotationHistory = createSelector(selectAnnotationHistoryState, (state) => {
  return state.ids.map((id) => state.entities[id]!);
});
