import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PaymentActions } from './payment.actions';
import { PaymentService } from './payment.service';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private paymentService: PaymentService,
  ) {}

  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.loadPaymentCards),
      switchMap(() =>
        this.paymentService.load().pipe(
          map((cards) => PaymentActions.loadPaymentCardsSuccess({ cards })),
          catchError((error) => of(PaymentActions.loadPaymentCardsFailed({ error })))
        )
      )
    )
  );
}
