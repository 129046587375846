import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-share-button',
  templateUrl: './social-share-button.component.html',
  styleUrls: ['./social-share-button.component.scss'],
})
export class SocialShareButtonComponent implements OnInit {
  @Input() type: string = 'facebook' || 'twitter' || 'linkedin' || 'pinterest' || 'instagram';
  @Input() shareUrl: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() image: string = '';
  navUrl: string = '';

  constructor() {}

  ngOnInit() {
    this.createNavigationUrl();
  }

  private createNavigationUrl() {
    let searchParams = new URLSearchParams();
    switch (this.type) {
      case 'facebook':
        searchParams.set('u', this.shareUrl);
        searchParams.set('quote', this.description);
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + searchParams;
        break;
      case 'twitter':
        searchParams.set('url', this.shareUrl);
        searchParams.set('text', this.title);
        this.navUrl = 'https://twitter.com/share?' + searchParams;
        break;
      case 'linkedin':
        searchParams.set('url', this.shareUrl);
        searchParams.set('title', this.title);
        searchParams.set('summary', this.description);
        searchParams.set('source', this.image); // LinkedIn uses the image URL as source
        this.navUrl = 'https://www.linkedin.com/sharing/share-offsite/?' + searchParams;
        break;
      case 'pinterest':
        searchParams.set('url', this.shareUrl);
        searchParams.set('media', this.image);
        searchParams.set('description', this.description);
        this.navUrl = 'https://pinterest.com/pin/create/button/?' + searchParams;
        break;
      case 'instagram':
        searchParams.set('url', this.shareUrl);
        this.navUrl = 'https://instagram.com/';
        break;
    }
  }
  public share() {
    return window.open(this.navUrl, '_blank');
  }
}
