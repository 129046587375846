import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-common-carouselnav',
  templateUrl: './carousel-nav.component.html',
  styleUrls: ['./carousel-nav.component.scss'],
})
export class CarouselNavComponent implements OnInit {
  @Input() classPrev: string = 'nextEl'; // Switch value
  @Input() classNext: string = 'prevEl'; // Switch value

  @Output() paginationEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  navigation(type: string) {
    this.paginationEvent.emit(type);
  }
}
