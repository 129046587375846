<ng-container *ngIf="{ activeRoute: activeRoute$ | async, categories: categories$ | async } as vm">
  <div class="border-b border-slate-200 bg-slate-50">
    <div class="container">
      <!-- <div class="title py-6 pb-4 md:pb-6">
        <h3 class="font-medium">My Classes</h3>
      </div> -->
      <div class="heading_part ar:text-right py-10 pb-0 text-center md:text-left">
        <ng-container *ngIf="vm.categories">
          <h2 class="mb-2 text-xl sm:text-2xl md:text-4xl">
            <div *ngIf="sectionTitle === ''; else showCategory">
              <span class="capitalize"
               i18n="selected_category_page_title|The name of the selected category followed by the word 'Courses'">
               {{selectedCategory?.name}} Courses</span>
            </div>
            <ng-template #showCategory>
              <span class="capitalize" i18n>{{sectionTitle}} Course</span>
            </ng-template>
          </h2>
          <p class="text-sm text-slate-600 md:text-base">
            {{selectedCategory?.description}}
          </p>
        </ng-container>
      </div>
      <div class="tab-items">
        <ul class="flex flex-wrap text-center text-sm font-medium text-slate-500">
          <li *ngFor="let item of tabItems">
            <a (click)="onClickTab(item.route)" class="cursor-pointer inline-block border-b-2 px-6 py-3"
              [ngClass]="item.route === selectedTab ? 'border-primary' : 'border-transparent'">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
