import { createAction, props } from '@ngrx/store';
import { Params, Response } from './assignment.model';

export const AssignmentsActions = {
  // Overview and Assignments TAB
  loadInstructorAssignments: createAction(
    '[Instructor Assignments] Load Instructor Assignments',
    props<{ params: Params }>()
  ),
  loadInstructorAssignmentsSuccess: createAction(
    '[Instructor Assignments] Load Instructor Assignments Success',
    props<Response>()
  ),
  loadInstructorAssignmentsFail: createAction(
    '[Instructor Assignments] Load Instructor Assignments Fail',
    props<{ error: unknown }>()
  ),

  // Assignments by course
  loadAssignmentsByCourse: createAction(
    '[Assignments By Course] Load Assignments By Course',
    props<{ params: Params; courseId: string }>()
  ),
  loadAssignmentsByCourseSuccess: createAction(
    '[Assignments By Course] Load Assignments By Course Success',
    props<Response>()
  ),
  loadAssignmentsByCourseFail: createAction(
    '[Assignments By Course] Load Assignments By Course Fail',
    props<{ error: unknown }>()
  ),

  // Student Assignments Group By Date
  loadAssignmentsGroupByDate: createAction(
    '[Group By Date Assignments] Load Group By Date Assignments',
    props<{ params: Params; assignmentId: string }>()
  ),
  loadAssignmentsGroupByDateSuccess: createAction(
    '[Group By Date Assignments] Load Group By Date Assignments Success',
    props<Response>()
  ),
  loadAssignmentsGroupByDateFail: createAction(
    '[Group By Date Assignments] Load Group By Date Assignments Fail',
    props<{ error: unknown }>()
  ),

  // Student Assignments Review
  loadAssignmentsReview: createAction(
    '[Assignments Review] Load Assignments Review',
    props<{ params: Params; assignmentId: string }>()
  ),
  loadAssignmentsReviewSuccess: createAction('[Assignments Review] Load Assignments Review Success', props<Response>()),
  loadAssignmentsReviewFail: createAction(
    '[Assignments Review] Load Assignments Review Fail',
    props<{ error: unknown }>()
  ),
};
