<div class="instructor-header default flex bg-slate-800 py-2 ltr:pl-2 ltr:pr-8 rtl:pr-2 rtl:pl-8 text-white">
  <div class="flex w-full max-w-[250px] items-center pl-6" *ngIf="fullSize">
    <div class="logo">
      <a routerLink="/">
        <svg-icon src="@assets/images/logo-white.svg" class="block h-7 w-auto"></svg-icon>
      </a>
    </div>
  </div>
  <div class="flex grow justify-between">
    <div class="left-content">
      <!-- <ng-container *ngTemplateOutlet="defaultLeft"></ng-container> -->
    </div>
    <div class="right-content">
      <ng-container *ngTemplateOutlet="defaultRight">
        <ng-content select="[right]"></ng-content>
      </ng-container>
    </div>
  </div>
</div>

<!-- <ng-template #defaultLeft>
  <div class="back">
    <a routerLink="/">
      <button class="btn btn-dark pl-2">
        <svg-icon src="@assets/images/icons/chevron-left.svg" class="ar:rotate-180 h-4 w-4"></svg-icon>
        <span i18n>Back</span>
      </button>
    </a>
  </div>
</ng-template> -->

<ng-template #defaultRight>
  <div class="userinfo">
    <!-- Account Buttons -->
    <div class="flex items-center justify-start gap-3">
      <a>
        <button (click)="switchToStudent()" class="btn btn-dark hover:bg-slate-700">
          <span i18n>Switch to Student</span>
        </button>
      </a>
      <a href="#" class="btn btn-dark px-2">
        <svg-icon class="h-5 w-5" src="@assets/images/icons/bell.svg"></svg-icon>
      </a>

      <!-- Menu -->
      <div class="account-menu">
        <app-navigation position="right">
          <ng-container button>
            <thk-avatar class="ml-2" [title]="user?.fullname" [url]="user?.photo_url"></thk-avatar>
          </ng-container>
          <ng-container header>
            <div class="heading flex items-center gap-4 p-4">
              <thk-avatar [title]="user?.fullname" [url]="user?.photo_url"></thk-avatar>
              <div>
                <div class="name font-medium">{{user?.fullname}}</div>
                <div class="email text-xs text-slate-500">{{user?.email}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container content>
            <div class="content max-h-[400px] overflow-y-auto border-y border-y-slate-300">
              <ul class="account-items border-b border-b-slate-300">
                <li class="relative">
                  <a routerLink="/student/dashboard" i18n>Student</a>
                </li>
              </ul>
              <ul class="account-items">
                <li class="relative">
                  <a routerLink="/instructor-profile/{{user?.id}}" i18n>Public profile</a>
                </li>
                <li class="relative">
                  <a routerLink="/student/account/profile" i18n>Edit profile</a>
                </li>
              </ul>
            </div>
          </ng-container>
          <ng-container footer>
            <div class="footer">
              <ul class="account-items">
                <li class="relative">
                  <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Help</a>
                </li>
                <li class="relative">
                  <a href="#" class="rounded-b-lg" (click)="logout()" i18n>Logout</a>
                </li>
              </ul>
            </div>
          </ng-container>
        </app-navigation>
      </div>
    </div>
  </div>
</ng-template>
