import { createSelector } from '@ngrx/store';
import { QuizAnswerV2, QuizQuestionDetailV2, QuizQuestionV2 } from '../../models';
import { isDefined } from '../../utils';
import { selectQuizQuestionsState } from './course.selectors';
import { selectQuizAnswers } from './quiz-answer.selectors';

export const selectQuizQuestion = (quizQuestionId: string) =>
  createSelector(selectQuizQuestionsState, (state) => state.entities[quizQuestionId]);

export const selectQuizQuestions = createSelector(selectQuizQuestionsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectQuizQuestionEntities = createSelector(selectQuizQuestionsState, (state) => state.entities);

export const selectQuizQuestionDetailsByQuizId = (quizId: string) =>
  createSelector(selectQuizQuestions, selectQuizAnswers, (quizQuestions, quizAnswers) =>
    quizQuestions
      .filter((quizQuestion) => quizQuestion.quiz === quizId)
      .map<QuizQuestionDetailV2>((quizQuestion) => ({
        ...(quizQuestion as QuizQuestionV2),
        answers: quizAnswers
          .filter((quizAnswer) => quizAnswer?.quiz_question === quizQuestion.id)
          .map((quizAnswer) => quizAnswer as QuizAnswerV2),
      }))
  );
