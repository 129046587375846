import { createAction, props } from '@ngrx/store';
import {
  CourseAssignmentAnswer,
  CourseDetailV2,
  CourseQnA,
  CourseQnAPayload,
  CourseQnAReply,
  CourseQnAReplyPayload,
  CourseQnAResponse,
  CourseQuizAnswer,
  CourseQuizAnswerPayload,
} from '../../models';

export const LearningLabActions = {
  init: createAction('[LearningLab] Init', props<{ courseDetail: CourseDetailV2 }>()),
  selectActivity: createAction('[LearningLab] Select Activity', props<{ sectionId: string; subsectionId: string }>()),
  selectSection: createAction('[LearningLab] Select Section', props<{ sectionId: string; subsectionId: string }>()),
  selectSubsection: createAction('[LearningLab] Select Subsection', props<{ subsectionId: string }>()),
  nextActivity: createAction('[LearningLab] Next Activity'),
  prevActivity: createAction('[LearningLab] Prev Activity'),
  markSubsectionComplete: createAction('[LearningLab] Mark Subsection Complete', props<{ subsectionId: string }>()),
  markSubsectionIncomplete: createAction('[LearningLab] Mark Subsection Incomplete', props<{ subsectionId: string }>()),
  // Learning Lab QnA
  loadCourseQnA: createAction('[LearningLab_QnA] Load Course QnA', props<{ courseId: string }>()),
  loadCourseQnASuccess: createAction('[LearningLab_QnA] Load Course QnA Success', props<{ data: CourseQnAResponse }>()),
  loadCourseQnASError: createAction('[LearningLab_QnA] Load Course Error', props<{ error: unknown }>()),
  // Add questions on QnA
  addCourseQnA: createAction(
    '[LearningLab_QnA] Add Course QnA',
    props<{ courseId: string; payload: CourseQnAPayload }>()
  ),
  addCourseQnASuccess: createAction('[LearningLab_QnA] Add Course QnA Success', props<{ data: CourseQnA }>()),
  addCourseQnASError: createAction('[LearningLab_QnA] Add Course Error', props<{ error: unknown }>()),
  // Get specific QnA by Course id and Question Id
  getCourseQnA: createAction('[LearningLab_QnA] Get Course QnA', props<{ courseId: string; qnaId: string }>()),
  getCourseQnASuccess: createAction('[LearningLab_QnA] Get Course QnA Success', props<{ data: CourseQnA }>()),
  getCourseQnASError: createAction('[LearningLab_QnA] Get Course Error', props<{ error: unknown }>()),
  // Reply on a QnA
  replyCourseQnA: createAction(
    '[LearningLab_QnA] Reply Course QnA',
    props<{ qnaId: string; payload: CourseQnAReplyPayload }>()
  ),
  replyCourseQnASuccess: createAction('[LearningLab_QnA] Reply Course QnA Success', props<{ data: CourseQnAReply }>()),
  replyCourseQnASError: createAction('[LearningLab_QnA] Reply Course Error', props<{ error: unknown }>()),
  // Assignment Start
  startAssignment: createAction(
    '[LearningLab_Assignment] Start Assignment',
    props<{ courseId: string; assignId: string }>()
  ),
  startAssignmentSuccess: createAction(
    '[LearningLab_Assignment] Start Assignment Success',
    props<{ data: CourseAssignmentAnswer[] }>()
  ),
  startAssignmentError: createAction('[LearningLab_Assignment] Start Error', props<{ error: unknown }>()),
  // Assignment Answers
  getAssignmentAnswers: createAction(
    '[LearningLab_Assignment] Get Assignment Answers',
    props<{ courseId: string; assignId: string }>()
  ),
  getAssignmentAnswersSuccess: createAction(
    '[LearningLab_Assignment] Get Assignment Answers Success',
    props<{ data: CourseAssignmentAnswer[] }>()
  ),
  getAssignmentAnswersError: createAction(
    '[LearningLab_Assignment] Get Assignment Answers Error',
    props<{ error: unknown }>()
  ),
  // Assignment Submit
  submitAssignment: createAction(
    '[LearningLab_Assignment] Submit Assignment',
    props<{ courseId: string; assignId: string; payload: CourseAssignmentAnswer[] }>()
  ),
  submitAssignmentSuccess: createAction(
    '[LearningLab_Assignment] Submit Assignment Success',
    props<{ data: CourseAssignmentAnswer[] }>()
  ),
  submitAssignmentError: createAction('[Course] Submit Assignment Error', props<{ error: unknown }>()),
  // Quiz Submission
  getQuizAnswers: createAction(
    '[LearningLab_Quiz] Get Course Quiz Answers',
    props<{ courseId: string; quizId: string }>()
  ),
  getQuizAnswersSuccess: createAction(
    '[LearningLab_Quiz] Get Course Quiz Answers Success',
    props<{ data: CourseQuizAnswer[] }>()
  ),
  getQuizAnswersSError: createAction('[LearningLab_Quiz] Get Course Quiz Answers Error', props<{ error: unknown }>()),
  // Assignment Submit
  submitQuiz: createAction(
    '[LearningLab_Assignment] Submit Quiz',
    props<{ courseId: string; quizId: string; payload: CourseQuizAnswerPayload }>()
  ),
  submitQuizSuccess: createAction(
    '[LearningLab_Assignment] Submit Quiz Success',
    props<{ data: CourseQuizAnswer[] }>()
  ),
  submitQuizError: createAction('[Course] Submit Quiz Error', props<{ error: unknown }>()),
};
