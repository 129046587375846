import { createAction, props } from '@ngrx/store';
import { Params } from '../assignments';
import { ResponseData } from './qna.model';

export const InstructorQNAActions = {
  // All QNA Group By Date
  loadInstructorQNA: createAction('[Instructor QNA] Load Instructor QNA', props<{ params: Params }>()),
  loadInstructorQNASuccess: createAction('[Instructor QNA] Load Instructor QNA Success', props<ResponseData>()),
  loadInstructorQNAFail: createAction('[Instructor QNA] Load Instructor QNA Fail', props<{ error: unknown }>()),
  // All QNA By Course
  loadInstructorQNAByCourse: createAction(
    '[Instructor QNA] Load Instructor QNA By Course',
    props<{ params: Params; course_id: string }>()
  ),
  loadInstructorQNAByCourseSuccess: createAction(
    '[Instructor QNA] Load Instructor QNA By Course Success',
    props<ResponseData>()
  ),
  loadInstructorQNAByCourseFail: createAction(
    '[Instructor QNA] Load Instructor QNA By Course Fail',
    props<{ error: unknown }>()
  ),
  // Get QNA Reply
  loadInstructorQNAReply: createAction('[Instructor QNA] Load Instructor QNA Reply', props<{ qna_id: string }>()),
  loadInstructorQNAReplySuccess: createAction(
    '[Instructor QNA] Load Instructor QNA Reply Success',
    props<ResponseData>()
  ),
  loadInstructorQNAReplyFail: createAction(
    '[Instructor QNA] Load Instructor QNA Reply Fail',
    props<{ error: unknown }>()
  ),
};
