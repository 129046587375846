
export interface TabItem {
  id: number;
  title: string;
  route: string;
}
export interface ReviewsTab {
  id: number;
  label: string;
  value: number;
}

export const STUDENT_TAB: TabItem[] = [
  {
    id: 1,
    title: $localize`All Courses`,
    route: '/student/dashboard',
  },
  {
    id: 2,
    title: $localize`Wishlist`,
    route: '/student/wishlist',
  },
  {
    id: 3,
    title: $localize`Archived`,
    route: '/student/archived',
  },
];

export const PURCHASE_TAB: TabItem[] = [
  {
    id: 1,
    title: $localize`History`,
    route: '/student/purchase/history',
  },
  {
    id: 2,
    title: $localize`Refunds`,
    route: '/student/purchase/refunds',
  },
];

export const LEARNINGLAB_TAB: TabItem[] = [
  {
    id: 0,
    title: $localize`Course content`,
    route: 'course-content',
  },
  {
    id: 1,
    title: $localize`Overview`,
    route: 'overview',
  },
  {
    id: 2,
    title: $localize`Q&A`,
    route: 'qa',
  },
  {
    id: 3,
    title: $localize`Notes`,
    route: 'notes',
  },
  {
    id: 4,
    title: $localize`Announcements`,
    route: 'announcements',
  },
  {
    id: 5,
    title: $localize`Reviews`,
    route: 'reviews',
  },
];

// Question and Answer
export const QUESTION_AND_ANSWER_TAB: TabItem[] = [
  {
    id: 1,
    title: $localize`All`,
    route: '/instructor/question-answer',
  },
  {
    id: 2,
    title: $localize`Answerd`,
    route: '/instructor/question-answer/answered',
  },
  {
    id: 3,
    title: $localize`Not Answerd`,
    route: '/instructor/question-answer/not-answered',
  },
];
