import { ElementRef, Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { distinctUntilChanged, ReplaySubject } from 'rxjs';

@Injectable()
export class DomRectService {
  private rect = new ReplaySubject<DOMRect>(1);
  private calculating = false;

  constructor(private elRef: ElementRef) { }

  get rectChanges() {
    if (!this.calculating) {
      this.calculating = true;
      this.calculateRect();
    }

    return this.rect.pipe(distinctUntilChanged(isEqual));
  }

  private calculateRect() {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.rect.next(entry.contentRect)
      }
    });

    resizeObserver.observe(this.elRef.nativeElement);
  }
}
