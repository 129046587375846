import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectSubsectionsState } from './course.selectors';

export const selectSubsections = createSelector(selectSubsectionsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectSubsection = (subsectionId: string) =>
  createSelector(selectSubsectionsState, (state) => state.entities[subsectionId]);
