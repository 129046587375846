import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import {
  UppyAngularDashboardModalModule,
  UppyAngularDashboardModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularStatusBarModule,
} from '@uppy/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgChartsModule } from 'ng2-charts';
import { MarkdownModule } from 'ngx-markdown';
import { SwiperModule } from 'swiper/angular';
import {
  CategoryEffects,
  ClickOutsideDirective,
  DefaultImageDirective,
  ErrorEffects,
  FormlyComponents,
  FormlySetup,
  MessagesComponent,
  PricePipe,
  ThkeeCommonModule,
  TooltipDirective,
  TopicEffects,
  UiSnippetsComponent,
  VideoPlayerComponent,
  categoryFeature,
  errorFeature,
  topicFeature,
} from 'thkee-common';
import {
  AccordionComponent,
  AssignmentsAccordionComponent,
  BreadcurmbsComponent,
  CarouselComponent,
  CarouselNavComponent,
  CategoryHeaderComponent,
  ChartComponent,
  CourseCardComponent,
  CourseGridComponent,
  CourseHeaderComponent,
  CourseSidebarComponent,
  FeedbackComponent,
  FilterOptionComponent,
  FormLoginComponent,
  FormSignupComponent,
  FormTapPaymentComponent,
  FormlyDeleteConfirmationComponent,
  FormlyFileDashboardInfoComponent,
  FormlyFileDefaultInfoComponent,
  FormlyFileDragDropInfoComponent,
  FormlyImgPlaceholderComponent,
  FormlyRepeatButtonComponent,
  LearninglabArticleComponent,
  LearninglabAssignmentComponent,
  LearninglabHeaderComponent,
  LearninglabQuizComponent,
  LearninglabSidebarComponent,
  LearninglabTabMenuComponent,
  LearninglabVideoComponent,
  ModalComponent,
  ModalConfirmComponent,
  ModalLoginComponent,
  NavigationComponent,
  NewCourseHeaderComponent,
  PaginationComponent,
  ProgressBarComponent,
  ProgressCircleComponent,
  QaACardComponent,
  QaClassCardComponent,
  QaEditorCardComponent,
  QaQCardComponent,
  QaTabMenuComponent,
  ScrollableContentComponent,
  SocialShareButtonComponent,
  StarRatingComponent,
  StudentHeaderComponent,
  SvgComponent,
  TabMenuComponent,
  ToastComponent,
} from './components';
import {
  CartEffects,
  CheckoutEffects,
  InstructorAssignmentsEffects,
  InstructorQNAEffects,
  InstructorReviewsEffects,
  authFeature,
  cartFeature,
  checkoutFeature,
  instructorAssignmentsFeature,
  instructorQNAFeature,
  instructorReviewsFeature,
} from './store';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { CoursesFilterComponent } from './components/courses-filter/courses-filter.component';
import { ScrollActionDirective } from './directives/scroll-action.directive';
import { CourseDetailsEffects, courseDetailsFeature } from './store/courses';
import { PaymentEffects, paymentFeature } from './store/payment';
import { PayoutEffects, payoutFeature } from './store/payout';

const UppyModules = [
  UppyAngularDashboardModule,
  UppyAngularStatusBarModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularDashboardModalModule,
];

const sharedModules = [ThkeeCommonModule, SwiperModule];

const sharedComponents = [
  SvgComponent,
  TabMenuComponent,
  AssignmentsAccordionComponent,
  BreadcurmbsComponent,
  CourseCardComponent,
  CarouselComponent,
  CarouselNavComponent,
  CourseGridComponent,
  MessagesComponent,
  NavigationComponent,
  AccordionComponent,
  NewCourseHeaderComponent,
  CourseHeaderComponent,
  StudentHeaderComponent,
  CategoryHeaderComponent,
  CourseSidebarComponent,
  ModalComponent,
  ModalConfirmComponent,
  ModalLoginComponent,
  FormlyImgPlaceholderComponent,
  FormlyFileDashboardInfoComponent,
  FormlyFileDragDropInfoComponent,
  FormlyFileDefaultInfoComponent,
  FormlyDeleteConfirmationComponent,
  FormlyRepeatButtonComponent,
  LearninglabHeaderComponent,
  LearninglabSidebarComponent,
  LearninglabVideoComponent,
  LearninglabArticleComponent,
  LearninglabQuizComponent,
  LearninglabAssignmentComponent,
  LearninglabTabMenuComponent,
  ProgressBarComponent,
  ProgressCircleComponent,
  ScrollableContentComponent,
  FeedbackComponent,
  PaginationComponent,
  FilterOptionComponent,
  FormLoginComponent,
  FormSignupComponent,
  FormTapPaymentComponent,
  QaTabMenuComponent,
  QaClassCardComponent,
  QaQCardComponent,
  QaACardComponent,
  QaEditorCardComponent,
  StarRatingComponent,
  ToastComponent,
  ChartComponent,
  SocialShareButtonComponent,
  VideoPlayerComponent,
  CoursesFilterComponent,
  UiSnippetsComponent,
];

const ThkeeDirectives = [TooltipDirective, ClickOutsideDirective, DefaultImageDirective, ScrollActionDirective];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ...sharedModules,
    FormlySelectModule,
    ReactiveFormsModule,
    FormsModule,
    FormlySetup,
    ...UppyModules,
    StoreModule.forFeature('router', routerReducer),
    StoreModule.forFeature(authFeature),
    StoreModule.forFeature(categoryFeature),
    StoreModule.forFeature(topicFeature),
    StoreModule.forFeature(cartFeature),
    StoreModule.forFeature(checkoutFeature),
    StoreModule.forFeature(errorFeature),
    StoreModule.forFeature(instructorAssignmentsFeature),
    StoreModule.forFeature(instructorQNAFeature),
    StoreModule.forFeature(instructorReviewsFeature),
    StoreModule.forFeature(courseDetailsFeature),
    StoreModule.forFeature(payoutFeature),
    StoreModule.forFeature(paymentFeature),
    EffectsModule.forFeature([
      ErrorEffects,
      CategoryEffects,
      TopicEffects,
      CartEffects,
      CheckoutEffects,
      InstructorAssignmentsEffects,
      InstructorQNAEffects,
      InstructorReviewsEffects,
      CourseDetailsEffects,
      PayoutEffects,
      PaymentEffects
    ]),
    AngularSvgIconModule.forRoot(),
    MarkdownModule.forRoot(),
    NgChartsModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  declarations: [
    ...sharedComponents,
    ...FormlyComponents,
    ...ThkeeDirectives,
    QaTabMenuComponent,
    QaClassCardComponent,
    QaQCardComponent,
    QaACardComponent,
    QaEditorCardComponent,
  ],
  exports: [
    ...sharedComponents,
    ...sharedModules,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    MarkdownModule,
    ...ThkeeDirectives,
  ],
  providers: [TitleCasePipe, PricePipe],
})
export class SharedModule {}
