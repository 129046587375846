/**
 * convertableFields are the fields that need to convert from object with boolean values to be a list of its property
 * for example:
 * `{ a: 3, b: { k: true, c: true } }` => `{ a: 3, b: ['k', 'c']}` and vice versa
 */
export class QueryFormConverter<T> {
  constructor(private convertableFields: (keyof T)[] = []) { }

  toForm(query: T) {
    const convertData = this.convertableFields.reduce((pre, field) => {
      const value = query[field];

      if (!value) {
        return pre;
      }

      if (Array.isArray(value)) {
        // return pre;
        return {
          ...pre,
          [field]: (value as string[]).reduce(
            (obj, cur) => Object.assign(obj, { [cur]: true }),
            {} as any
          )
        }
      }

      if (value) {
        return {
          ...pre,
          [field]: { [value as any]: true }
        }
      }

      return pre;
    }, {});

    return { ...query, ...convertData };
  }

  toQuery(formValue: Record<keyof T, any>) {
    const convertableData = this.convertableFields.reduce((query, field) => {
      const formFieldValue = formValue[field];
      if (!formFieldValue) {
        return query;
      }

      if (Array.isArray(formFieldValue)) {
        return { ...query, [field]: formFieldValue };
      }

      if (typeof formFieldValue === 'object') {
        try {
          if (Object.keys(formFieldValue as any).length) {
            const queryValue = Object.entries(formFieldValue as any).filter(([_, v]) => v).map(([k]) => k);
            return { ...query, [field]: queryValue.length ? queryValue : undefined };
          }
        } catch (error) { }
      }

      return query;
    }, {});

    return { ...formValue, ...convertableData };
  }
}
