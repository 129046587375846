import { Auditable, User as CommonUser } from 'thkee-common';

// Initial State
export const initialCourseContentState: CoursesContent = {
  next: null,
  previous: null,
  count: 0,
  results: [],
  subsections: [],
  sectionId: '',
  subsectionId: '',
  completedActivity: {},
  courseOrerview: {
    product_type: 'Individual',
    unlisted: false,
    average_rating: 0,
    highest_rated: false,
    is_bestseller: false,
    certificate: null,
    progress: 0,
    id: '',
    code: '',
    slug: '',
    title: '',
    subtitle: '',
    enrollments: 0,
    duration: 0,
    class_id: 0,
    last_recorded: {
      id: '',
      watched_duration: 0,
      position: 0,
      is_completed: false,
      subsection_chain_id: '',
      date_updated: '',
      section_chain_id: '',
    },
    course_stats: {
      total_sections: 0,
      total_lectures: 0,
      total_assignments: 0,
      total_quiz: 0,
    },
    instructor: {
      first_name: '',
      last_name: '',
      bio: '',
      photo: '',
      public_profile: '',
      email: '',
      rating: 0,
    },
    category: {
      id: '',
      name: '',
      slug: '',
    },
    subcategory: {
      id: '',
      name: '',
      category: '',
    },
    desc: '',
    skill_level: '',
    language: '',
  },
  certificate: {
    is_purchased: false,
    certificate_url: undefined,
    certificate_number: 0,
    progress: 0,
    subsections_total: 0,
    completed_subsections: 0,
    user: 0,
    completed_at: '',
    duration: 0,
  },
};

// Model
export interface CoursesContent {
  next: null | number;
  previous: null | number;
  count: number;
  results: CourseSection[];
  subsections: CourseSubSection[];
  sectionId: string;
  subsectionId: string;
  completedActivity: { [sectionOrSubsectionId: string]: boolean };
  courseOrerview: CourseOverview;
  certificate: Certificate;
}

export interface CourseSection {
  id: string;
  chain_id: string;
  title: string;
  duration: number;
  position: number;
  subsections: CourseSubSection[];
}

export interface CourseSubSection {
  id: string;
  chain_id: string;
  position: number;
  type: 'lecture' | 'quiz' | 'assignment'; // Define other types as needed
  progress: number;
  is_completed: boolean;
  lecture?: LectureDetails;
  quiz?: QuizDetails;
  assignment?: AssignmentDetails;
}

interface LectureDetails {
  id: string;
  title: string;
  resources: any[]; // Define resource type if known
  date_updated: string;
  video?: string | null;
  article?: string | null;
  type: 'video' | 'article'; // Define other types as needed
  position: number;
  duration: number;
}

interface QuizDetails {
  id: string;
  date_created: string;
  date_updated: string;
  chain_id: string;
  title: string;
  source: string;
  section: string;
  student_quiz: any[]; // Define student quiz details if needed
}

interface AssignmentDetails {
  id: string;
  date_created: string;
  date_updated: string;
  chain_id: string;
  title: string;
  source: string;
  section: string;
  student_assignment: any[]; // Define student assignment details if needed
}

// Overviews
export interface CourseOverview {
  product_type: 'Individual' | 'Group'; // Assuming product_type is one of these values
  unlisted: boolean;
  average_rating: number;
  highest_rated: boolean;
  is_bestseller: boolean;
  certificate: string | null;
  progress: number;
  last_recorded: CurrentProgress;
  id: string;
  code: string;
  slug: string;
  title: string;
  subtitle: string;
  description?: Record<string, any>; // Adjust based on actual description content
  desc: string;
  course_stats: CourseStats;
  enrollments: number;
  duration: number;
  instructor: Instructor;
  category: Category;
  subcategory: Subcategory;
  class_id: number;
  skill_level: string;
  language: string;
  user?: CommonUser;
}

export interface ActionPayload {
  product_id: string;
  preview?: boolean;
}

interface CurrentProgress {
  id: string;
  watched_duration: number;
  position: number;
  is_completed: boolean;
  date_updated: string;
  section_chain_id: string;
  subsection_chain_id: string;
}

export interface LectureMarkedAsRead {
  watched_duration: number;
  position: number;
  is_completed: boolean;
  section_chain_id: string;
  subsection_chain_id: string;
}

interface CourseStats {
  total_sections: number;
  total_lectures: number;
  total_assignments: number;
  total_quiz: number;
}

interface Instructor {
  first_name: string;
  last_name: string;
  bio: string | null;
  photo: string | null;
  public_profile: string;
  email: string;
  rating: number;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface Subcategory {
  id: string;
  name: string;
  category: string;
}

// Quiz
export type CourseQuizAnswer = Auditable & {
  id?: string;
  date_created?: string;
  date_updated?: string;
  question_id: string;
  answer_id: string;
  is_correct?: boolean;
};

export type CourseQuizAnswerPayload = {
  content: CourseQuizAnswer[];
};

// Announcement
export interface Announcement {
  count: number | string;
  next: null | string;
  previous: null | string;
  results: Announcements[];
}
export interface Announcements {
  id: string;
  content: {
    title: string;
    description: string | '';
  };
  published_date: string;
  published_time: string;
  course: string;
}

// Reviews
export interface Review {
  count: number | string;
  next: null | string;
  previous: null | string;
  results: ReviewDetails;
}
export interface ReviewDetails {
  course_details: ReviewsCourseDetails;
  reviews: CoursesReviews[];
  is_submitted: boolean;
}
export interface ReviewsCourseDetails {
  id: string;
  average_rating: number;
  course_image: string;
  course_title: string;
  total_reviews: number;
}
export interface CoursesReviews {
  id: string;
  content: string;
  date_created: string;
  likes_count: number;
  dislikes_count: number;
  reported_count: number;
  rate: number;
  user: User;
  is_disliked: boolean;
  is_liked: boolean;
  is_editable: boolean;
  is_reported: boolean;
}
interface User {
  id: string;
  bio: string;
  fullname: string;
  language: string;
  photo: null | string;
}

// QNA
export interface MyClassQNA {
  count: number | string;
  next: null | string;
  previous: null | string;
  results: MyClassQNAResult[];
}
export interface MyClassQNAResult {
  approved: boolean;
  date_created: string;
  description: string;
  id: string;
  lecture: any;
  not_relevant: boolean;
  project: string;
  reply_count: number;
  subsection: any;
  title: string;
  upvoted_users_count: number;
  user: User;
  viewed: boolean;
  replies?: MyClassCourseQnAReply[];
}
export interface MyClassCourseQnAReply {
  id: string;
  user: User;
  reply_count: number;
  upvoted_users_count: number;
  date_created: string;
  comment: string;
  qna: string;
}
export type MyClassQnAReplyPayload = {
  comment: string;
};

export type MyClassesQnAPayload = {
  title: string;
  description: string;
  subsection_id?: string;
  approved?: boolean;
};

// Assignments
export type MyClassAssignmentAnswer = Auditable & {
  id?: string;
  question_id: string;
  answer: string;
};

// Certificate
export interface Certificate {
  user: number | CertificatesUser;
  is_purchased: boolean;
  certificate_url: undefined;
  certificate_number: number;
  completed_at: string;
  progress: number;
  subsections_total: number;
  completed_subsections: number;
  duration: number;
  course?: CertificatesCourse;
  certificate_grade?: CertificatesGrade;
}
export interface CertificatesUser {
  id: number;
  first_name: string;
  last_name: string | null;
  headline: string | null;
  bio: string;
  language: string;
  email: string;
  photo: string | null;
  photo_url?: string;
  is_instructor: boolean;
  fullname: string;
  stats: {
    courses: number;
    published_courses: number;
    students: number;
    ratings: number;
  };
}
export interface CertificatesCourse {
  favorite: boolean;
  archived: boolean;
  code: string;
  instructor: {
    first_name: string;
    last_name: string;
    bio: string | null;
    photo: string | null;
    public_profile: string;
  };
  title: string;
  total_rate: number;
  is_bestseller: boolean;
  image_url: string;
  image: string;
  user_class_id: number;
  id: string;
  course_id: string;
  slug: string;
  progress: number;
}

export interface CertificatesGrade {
  correct_quiz: number;
  total_quiz: number;
  total_assignment: number;
  correct_assignment: number;
  grade: string;
}

// Error
export interface ErrorRes {
  error: any;
  headers: any;
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}
