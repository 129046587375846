<ng-container *ngIf="limit && limit < (options || []).length; else oForm">
  <div class="view-limit" [style.max-height]="!seeMore ? maxHeight + 'px' : 'inherit'">
    <ng-container *ngTemplateOutlet="oForm"></ng-container>
  </div>
  <div
    class="text-primary relative z-20 -mt-3 mb-[22px] cursor-pointer bg-white text-sm underline underline-offset-4"
    (click)="seeMore = !seeMore"
  >
    <ng-container *ngIf="seeMore" i18n>See less</ng-container>
    <ng-container *ngIf="!seeMore" i18n>See more</ng-container>
  </div>
</ng-container>

<ng-template #oForm>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="[fields]" [options]="optionsF"></formly-form>
  </form>
</ng-template>
