import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, catchError, last, of, share, switchMap } from 'rxjs';
import { COOKIE_LOGGED_IN, GUEST_TOKEN_URL, STORAGE_CREDENTIALS_FIELD, STORAGE_REMEMBER_ME } from '../../constants';
import { API_REFRESH_TOKEN } from '../../models';
import { isExpiredIn } from '../../utils';
import { HttpService } from '../http';

export type Credentials = {
  refresh: string;
  token: string;
};

export type GuestToken = {
  guest_token: string;
  status: string;
};

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  // FIXME: could convert to the following format
  // private _credentials?: Credentials;
  private _credentials: Credentials | null = null;
  private refreshingToken?: Observable<string>;

  constructor(private http: HttpService, private cookieService: CookieService) {
    this._credentials = this.extractCredential();
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  hasCredentials(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      if (remember) {
        localStorage.setItem(STORAGE_REMEMBER_ME, '1');
      } else {
        this.cookieService.set(COOKIE_LOGGED_IN, '1', undefined, '/');
      }

      localStorage.setItem(STORAGE_CREDENTIALS_FIELD, JSON.stringify(credentials));
      return;
    }

    localStorage.removeItem(STORAGE_CREDENTIALS_FIELD);
    localStorage.removeItem(STORAGE_REMEMBER_ME);
    this.cookieService.delete(STORAGE_CREDENTIALS_FIELD);
  }

  getToken() {
    if (!this.credentials?.token) {
      return of('');
    }

    if (isExpiredIn(this.credentials.token) && this.credentials.refresh) {
      return this.refreshToken();
    }

    return of(this.credentials.token);
  }

  isLogged() {
    if (!this.credentials?.token) {
      return false;
    }

    if (isExpiredIn(this.credentials.token) && this.credentials.refresh) {
      return true;
    }

    return true;
  }

  private setToken(token: string) {
    this.setCredentials(
      { ...(this._credentials as Credentials), token: token },
      !!localStorage.getItem(STORAGE_REMEMBER_ME)
    );
    this._credentials = this.extractCredential();
  }

  private refreshToken() {
    // to restrict maximum 1 request for refreshing token when the current token will be expired soon
    if (!this.refreshingToken) {
      this.refreshingToken = this.http
        .post<{ access: string }>(API_REFRESH_TOKEN, { refresh: this.credentials?.refresh }, undefined, {
          publicResources: true,
        })
        .pipe(
          switchMap(({ access }) => {
            this.setToken(access);
            return of(access);
          }),
          catchError(() => of('')),
          share()
        );

      // Reset refreshingToken object for the next expired token fetching
      this.refreshingToken.pipe(last()).subscribe(() => {
        this.refreshingToken?.subscribe();
      });
    }

    return this.refreshingToken;
  }

  private extractCredential() {
    if (localStorage.getItem(STORAGE_REMEMBER_ME) || this.cookieService.get(COOKIE_LOGGED_IN)) {
      try {
        return JSON.parse(localStorage.getItem(STORAGE_CREDENTIALS_FIELD)!) as Credentials;
      } catch (error) {}
    }

    return null;
  }

  /**
   * Guest token.
   * @return This methods will return the guest token for guest cart.
   */
  getGuestToken(): Observable<GuestToken> {
    return this.http.get<GuestToken>(GUEST_TOKEN_URL);
  }
}
