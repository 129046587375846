import { CourseDetailV2, CourseQnA } from '../../models';
import { CourseQnAEntityState, initialcourseQnAState } from './course-qna.entity';
import {
  StudentAssignmentAnswerEntityState,
  initialStudentAssignmentAnswerState,
} from './student-assignment-answer.entity';
import { StudentAssignmentEntityState, initialStudentAssignmentState } from './student-assignment.entity';
import { StudentProgressEntityState, initialStudentProgressState } from './student-progress.entity';
import { StudentQuizAnswerEntityState, initialStudentQuizAnswerState } from './student-quiz-answer.entity';
import { StudentQuizEntityState, initialStudentQuizState } from './student-quiz.entity';

export type LearningLabState = {
  courseDetail: CourseDetailV2;
  activeCourseId: string;
  activeSectionId: string;
  activeSubsectionId: string;
  completedActivity: { [sectionOrSubsectionId: string]: boolean };
  studentProgress: StudentProgressEntityState;
  studentAssignment: StudentAssignmentEntityState;
  studentAssignmentAnswer: StudentAssignmentAnswerEntityState;
  studentQuiz: StudentQuizEntityState;
  studentQuizAnswer: StudentQuizAnswerEntityState;
  courseQnA: CourseQnAEntityState;
  selectedQnA: CourseQnA;
};

export const initialLearningLabState: LearningLabState = {
  courseDetail: {} as CourseDetailV2,
  activeCourseId: '',
  activeSectionId: '',
  activeSubsectionId: '',
  completedActivity: {},
  studentProgress: initialStudentProgressState,
  studentAssignment: initialStudentAssignmentState,
  studentAssignmentAnswer: initialStudentAssignmentAnswerState,
  studentQuiz: initialStudentQuizState,
  studentQuizAnswer: initialStudentQuizAnswerState,
  courseQnA: initialcourseQnAState,
  selectedQnA: {} as CourseQnA,
};
