<div class="activity-heading h-[60px]">
  <div class="flex h-full items-center justify-between bg-[#003C7B] px-4 py-2 text-white">
    <div class="grow truncate text-lg font-medium">{{ title }}</div>
    <div class="action flex items-center gap-4">
      <ng-container *ngIf="!quizState">
        <div class="text-sm">
          <span class="opacity-60" i18n>Total Question:</span>
          <span class="font-semibold">{{ data?.questions?.length }}</span>
        </div>
        <button class="btn btn-primary btn-sm" (click)="startQuiz()" i18n>Start Quiz</button>
      </ng-container>
      <ng-container *ngIf="quizState === 'started'">
        <div class="flex items-center gap-4 py-2 text-sm">
          <div>
            <span class="opacity-60" i18n>Skipped:</span> <span class="font-semibold">{{ skippedQuestion }}</span>
          </div>
          <div>
            <span class="opacity-60" i18n>Total Remaining:</span>
            <span class="font-semibold">{{ currentQuestion + 1 }}</span
            >/{{ data?.questions?.length }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="quizState === 'completed'">
        <button class="btn btn-white btn-sm" (click)="retryQuiz()" i18n>Retry Quiz</button>
        <button *ngIf="!hideNext" class="btn btn-primary btn-sm" (click)="nextLecture()" i18n>Next Lecture</button>
      </ng-container>
    </div>
  </div>
</div>
<div class="quiz-container flex max-h-[500px] min-h-[500px] flex-col bg-gradient-to-t from-[#013A76] to-[#0074F0] p-4">
  <div class="quiz-view relative flex h-full grow flex-col overflow-hidden rounded bg-white">
    <div class="flex h-full overflow-hidden">
      <app-scrollable-content>
        <div class="p-6">
          <div class="quiz_intro relative" *ngIf="!quizState">
            <p>
              <b><span i18n="Instructions label|Label for quiz instructions">**Instructions:**</span></b>
            </p>
            <ng-container i18n="Quiz instructions|Instructions for taking the quiz">
              <p>
                1. Click the "Start Quiz" button to begin your quiz journey.<br />
                2. Read each question carefully and select the best answer from the options provided.<br />
                3. You'll receive instant feedback on your answers, so you'll know how you're doing right away.<br />
                4. Enjoy the experience and learn from each question, whether you get it right or not.<br />
                5. Once you complete the quiz, share your results and challenge your friends to take the quiz too!<br />
              </p>
              <p>
                Are you ready to showcase your knowledge and embark on a journey of discovery? Let's get started and put your skills to the test! Click "Start Quiz" and let the adventure begin! 🚀🎉
              </p>
            </ng-container>
          </div>
          <div class="learninglab_quiz_questions" *ngIf="quizState === 'started'">
            <ng-container *ngTemplateOutlet="Question"></ng-container>
          </div>
          <div class="learninglab_quiz_questions" *ngIf="quizState === 'completed'">
            <ng-container *ngTemplateOutlet="scoreBoard"></ng-container>
          </div>
        </div>
      </app-scrollable-content>
    </div>
    <div class="quiz_action absolute bottom-4 right-4">
      <ng-container *ngIf="!quizState">
        <button *ngIf="!hideNext" class="btn btn-white shadow" (click)="skipQuiz()" i18n>Skip Quiz</button>
      </ng-container>
      <ng-container *ngIf="quizState === 'started'">
        <button class="btn btn-white btn-sm shadow" (click)="skipQuestion()" i18n>Skip</button>
        <button class="btn btn-primary btn-sm" (click)="nextQuestion()" i18n>Next Question</button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #Question>
  <div class="mb-6">
    <h3 class="mb-4 font-medium" i18n>Question {{ currentQuestion + 1 }}</h3>
    <div class="question_answers">
      <form [formGroup]="form" #qForm="ngForm">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #scoreBoard>
  <div
    class="mb-6 rounded-[4px] px-6 py-3 text-white"
    [class.bg-green-600]="totalScore.percentage.correct >= 80"
    [class.bg-primary-600]="totalScore.percentage.correct <= 79 && totalScore.percentage.correct >= 50"
    [class.bg-red-600]="totalScore.percentage.correct <= 49"
  >
    <div class="h4 mb-2" i18n="Quiz result|quiz result title">Quiz Results</div>
    <div class="flex items-center gap-2">
      <svg-icon class="h-5 w-5 text-white" src="@assets/images/icons/clock.svg"></svg-icon>
      <div i18n="Quiz result|Notification for the quiz result">You got {{ totalScore.correct }} Correct Answers out of {{ totalScore.total }} Answers.</div>
    </div>
  </div>
  <div class="questions max-w-[500px]">
    <ng-container *ngIf="scoreList">
      <div class="answer mb-6" *ngFor="let q of scoreList; let i = index">
        <div class="mb-3">{{ i + 1 }}. {{ q.question }}</div>
        <ng-container *ngIf="q.isCorrect === true">
          <div class="flex items-center gap-4 rounded-[4px] border-green-200 bg-green-50 p-2">
            <svg-icon class="text-green h-5 w-5" src="@assets/images/icons/check.svg"></svg-icon>
            <div i18n="Quiz feedback|Feedback for correct answer">Correct</div>
          </div>
        </ng-container>
        <ng-container *ngIf="q.isCorrect === 'skipped'">
          <div class="border-primary-200 bg-primary-50 flex items-center gap-4 rounded-[4px] p-2">
            <svg-icon class="text-primary h-5 w-5" src="@assets/images/icons/no-symbol.svg"></svg-icon>
            <div i18n="Quiz feedback|Feedback for skipped question">Skipped</div>
          </div>
        </ng-container>
        <ng-container *ngIf="q.isCorrect === false">
          <div class="flex items-center gap-4 rounded-[4px] border-red-200 bg-red-50 p-2">
            <svg-icon class="text-red h-5 w-5" src="@assets/images/icons/x-circle.svg"></svg-icon>
            <div i18n="Quiz feedback|Feedback for incorrect answer">Incorrect</div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
