import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectLecturesState } from './course.selectors';

export const selectLectures = createSelector(selectLecturesState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectLecture = (lectureId: string) =>
  createSelector(selectLecturesState, (state) => state.entities[lectureId]);

export const selectLectureEntities = createSelector(selectLecturesState, (state) => state.entities);
