<div class="menu-dropdown" (ClickOutside)="handleClickOutside()">
  <div class="button-container" (click)="showDropdown = !showDropdown">
    <ng-content select="[button]"></ng-content>
  </div>
  <div class="dropdown-container {{ position + '-0' }} {{ contentClass }} 
    {{ position === 'left' ? 'ar:right-0' : '' }} {{ position === 'right' ? 'ar:right-[inherit] ar:left-0' : '' }}"
    [ngClass]="showDropdown ? 'active' : 'not-active'">
    <ng-content select="[header]"></ng-content>
    <ng-content select="[content]"> </ng-content>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>