import { ComponentRef, Directive, ElementRef, EnvironmentInjector, Inject, InjectionToken, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, ViewContainerRef } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
const classes = {
  size: {
    xs: 'h-4 w-4',
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-10 w-10',
  },
  variant: {
    primary: 'text-primary',
    default: 'text-slate-800',
    white: 'text-white'
  }
}

export const SPINNER_ICON_SRC = new InjectionToken<string>('SPINNER_ICON_SRC_TOKEN');

@Directive({
  selector: '[thkLibProcessing]'
})
export class ProcessingDirective implements OnInit, OnChanges, OnDestroy {
  @Input() variant: 'primary' | 'default' | 'white' = 'default';
  @Input() size: 'sm' | 'lg' | 'xs' | 'md' = 'sm';
  @Input('thkLibProcessing') isProcessing?: boolean | null;

  private loaderRef?: ComponentRef<SvgIconComponent>;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef<HTMLElement>,
    private e: EnvironmentInjector,
    @Optional() @Inject(SPINNER_ICON_SRC) private spinnerSrc: string
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.processElement();
  }

  ngOnInit(): void {
    this.filterClickEvent();
  }

  ngOnDestroy(): void {
    this.nativeEl.removeEventListener('click', this.filterClickHandler);
  }

  private processElement() {
    if (this.isProcessing) {
      this.addLoader();
      return;
    }

    this.removeLoader();
  }

  private addLoader() {
    if (!this.spinnerSrc) {
      return;
    }
    const componentRef = this.viewContainerRef.createComponent(SvgIconComponent);
    componentRef.instance.svgClass = [
      classes.size[this.size || 'sm'],
      classes.variant[this.variant || 'default']
    ].join(' ');
    componentRef.instance.src = this.spinnerSrc;

    this.elementRef.nativeElement.appendChild(componentRef.location.nativeElement)
    this.loaderRef = componentRef;
  }

  private removeLoader() {
    if (this.loaderRef) {
      this.elementRef.nativeElement.removeChild(this.loaderRef?.location.nativeElement);
      this.loaderRef = undefined;
    }
  }

  private filterClickEvent() {
    this.nativeEl.addEventListener('click', this.filterClickHandler, { capture: true });
  }

  private get nativeEl() {
    return this.elementRef.nativeElement;
  }

  private filterClickHandler = (e: MouseEvent) => {
    if (!this.isProcessing) {
      return;
    }

    e.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
  };
}
