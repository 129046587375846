import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PaginationService } from 'thkee-common';
import { QaService } from '../../services';
import { InstructorQNAActions } from './qna.actions';

@Injectable()
export class InstructorQNAEffects {
  constructor(private actions$: Actions, private qaService: QaService, private paginationService: PaginationService) {}

  // All QNA Group By Date
  loadQNAGroupByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstructorQNAActions.loadInstructorQNA),
      switchMap((params) => {
        return this.qaService.getQnAGroupByDate(params.params).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return InstructorQNAActions.loadInstructorQNASuccess(data);
          }),
          catchError((error) => of(InstructorQNAActions.loadInstructorQNAFail({ error })))
        );
      })
    )
  );
  // All QNA By Course
  loadQNAByCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstructorQNAActions.loadInstructorQNAByCourse),
      switchMap((params) => {
        return this.qaService.getAllQnA(params.params, params.course_id).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return InstructorQNAActions.loadInstructorQNAByCourseSuccess(data);
          }),
          catchError((error) => of(InstructorQNAActions.loadInstructorQNAByCourseFail({ error })))
        );
      })
    )
  );
  // QNA Reply
  loadQNAReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstructorQNAActions.loadInstructorQNAReply),
      switchMap((params) => {
        return this.qaService.getQnAReply(params.qna_id).pipe(
          map((data) => {
            return InstructorQNAActions.loadInstructorQNAReplySuccess(data);
          }),
          catchError((error) => of(InstructorQNAActions.loadInstructorQNAReplyFail({ error })))
        );
      })
    )
  );
}
