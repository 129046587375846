import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-DeleteConfirmation',
  template: `
    <div class="p-6" i18n>Are you sure you want to remove this item?</div>
    <div class="flex justify-end gap-2 border-t border-slate-200 px-6 py-4">
      <button class="btn border-none" (click)="emitClickEvent('delete-cancel')">
        <span i18n>Cancel</span>
      </button>
      <button class="btn btn-primary" (click)="emitClickEvent('delete-confirm', id)">
        <span i18n>Confirm Remove</span>
      </button>
    </div>
  `,
})
export class FormlyDeleteConfirmationComponent implements OnInit {
  @Input() id: string | number = '';
  @Input() field!: FormlyFieldConfig;
  @Output() clickEvent = new EventEmitter<{ action: string; id: string | number }>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string, id: string | number = this.id) {
    this.clickEvent.emit({ action, id });
  }
}
