interface JwtPayload {
  exp: number,
  iat: number,
  [key: string]: unknown
}

const getJwtPayload = (jwt: string) => {
  try {
    const [_, payload] = jwt.split('.');
    return JSON.parse(atob(payload)) as JwtPayload;
  } catch (error: any) {
    console.debug('JWT:parse error', error?.message)
    return;
  }
}

/**
 * @param token current jwt token
 * @param second default is 60 seconds
 */
export const isExpiredIn = (token: string, second = 60) => {
  const payload =  getJwtPayload(token);
  if (!payload) {
    return true;
  }

  return payload.exp < Date.now() / 1000 + second;
}
