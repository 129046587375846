import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LectureResourceV2, PartialEntity } from '../../models';

export const lectureResourceAdapter: EntityAdapter<PartialEntity<LectureResourceV2>> = createEntityAdapter<
  PartialEntity<LectureResourceV2>
>({
  selectId: (e) => e.id,
});

export const initialLectureResourceState = lectureResourceAdapter.getInitialState();

export type LectureResourceEntityState = EntityState<PartialEntity<LectureResourceV2>>;
