import { Component, OnInit } from '@angular/core';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-common-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  defaultSwiperConfig = {
    slidesPerView: 1,
    spaceBetween: 32,
    grabCursor: false,
    speed: 1000,
    // navigation: true,
    navigation: {
      prevEl: '.nextEl',
      nextEl: '.prevEl',
    },
    breakpoints: {
      '640': {
        slidesPerView: 2,
      },
      '1024': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
      '1620': {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  };
}
