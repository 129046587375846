import { createAction, props } from '@ngrx/store';
import { Params } from '../assignments';
import { ReviewsResponse } from './reviews.model';

export const InstructorReviewsActions = {
  // All Courses Reviews
  loadAllCoursesReviews: createAction('[Instructor Reviews] Load All Courses Reviews', props<{ params: Params }>()),
  loadAllCoursesReviewsSuccess: createAction(
    '[Instructor Reviews] Load All Courses Reviews Success',
    props<ReviewsResponse>()
  ),
  loadAllCoursesReviewsFail: createAction(
    '[Instructor Reviews] Load All Courses Reviews Fail',
    props<{ error: unknown }>()
  ),
  // One Courses Reviews
  loadOneCoursesReviews: createAction(
    '[Instructor Reviews] Load One Courses Reviews',
    props<{ params: Params; id: string }>()
  ),
  loadOneCoursesReviewsSuccess: createAction(
    '[Instructor Reviews] Load One Courses Reviews Success',
    props<ReviewsResponse>()
  ),
  loadOneCoursesReviewsFail: createAction(
    '[Instructor Reviews] Load One Courses Reviews Fail',
    props<{ error: unknown }>()
  ),
};
