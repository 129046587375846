import { createFeature, createReducer, on } from '@ngrx/store';
import { cartItem, initialCartState } from './cart-state.model';
import { CartActions } from './cart.actions';

export const cartReducer = createReducer(
  initialCartState,
  on(CartActions.loadCartSuccess, (state, { cartItems }) => {
    return cartItems;
  }),
  on(CartActions.addToCartSuccess, (state, { cartItems }) => {
    return cartItems;
  }),
  on(CartActions.removeToCartSuccess, (state, { cartItems }) => {
    return cartItems;
  }),
  on(CartActions.applyCreditSuccess, (state, { cartItems }) => {
    return cartItems;
  }),
  on(CartActions.applyCouponSuccess, (state, { cartItems }) => {
    return cartItems;
  }),
  on(CartActions.applyCouponFail, (state, { error }) => {
    state = {
      ...state,
      error: {
        coupon: 'Coupon not found.',
      },
    };
    return state;
  }),
  on(CartActions.computeCartSuccess, (state, { cartItems }) => {
    // if (cartItems.coursesData) {
    //   const coursesData = cartItems.coursesData.map((course: any) => ({
    //     id: course.id,
    //     course_id: course.course_id,
    //     name: course.title,
    //     price: course.price ?? 0,
    //     sale: 0,
    //     sale_duration: '',
    //     author: typeof course.user === 'number' ? 'N/A' : `${course.user.first_name} ${course.user.last_name}`,
    //     rate: course.total_rate || '0',
    //     review: course.total_reviews || '0',
    //     star: Math.round(course.total_rate) || 0,
    //   }));
    //   return {
    //     ...cartItems,
    //     coursesData,
    //   };
    // } else {
    //   return state;
    // }

    return cartItems;
  }),
  on(CartActions.initUserCartSuccess, (state, {}) => {
    return initialCartState;
  }),
  on(CartActions.loadCartItemsSuccess, (state, { cartItems }) => {
    // Update the ngrx state
    const coursesData = cartItems.map((course: any) => {
      let author = course.author;
      if (course.user) {
        author = typeof course.user === 'number' ? 'N/A' : `${course.fullname}`;
      }
      return {
        id: course.id,
        course_id: course.course_id,
        name: course.title,
        code: course.code,
        price: course.price ?? 0,
        sale: 0,
        image_url: course.image_url,
        sale_duration: '',
        author: author,
        rate: course.total_rate || '0',
        review: course.total_reviews || '0',
        star: Math.round(course.total_rate) || 0,
        favorite: course.favorite,
      };
    });
    const courseIds = cartItems.map((course: any) => course.id);
    return {
      ...state,
      courses: courseIds,
      coursesData,
    };
  }),

  on(CartActions.loadCartPriceSuccess, (state, { data }) => {
    if (!data || state.coursesData.length === 0) {
      return state;
    }
    // Update the ngrx state
    const coursesData = state.coursesData.map((course) => {
      return {
        ...course,
        price: data[course.code]?.price || 0,
        price_string: data[course.code]?.price_string || '',
        currency: data[course.code]?.currency || '',
        sale: 0,
        sale_duration: '', //TODO Update
      };
    });
    return {
      ...state,
      coursesData,
      coursePrices: data,
    };
  }),

  // Update wishlist items
  on(CartActions.updateCartFavorite, (state, { item }) => {
    state = JSON.parse(JSON.stringify(state));
    let index = state.coursesData.findIndex((product: cartItem) => product.id === item.id);
    state.coursesData[index].favorite = item.favorite;
    return {
      ...state,
      coursesData: [...state.coursesData],
    };
  }),

  on(CartActions.updateCartSummary, (state, { summary }) => {
    return {
      ...state,
      currency: summary.currency,
      symbol: summary.symbol,
      subtotal: summary.subtotal,
      total_discount_amount: summary.total_discount_amount,
      total_discount_percentage: summary.total_discount_percentage,
      total: summary.final_total_amount,
    };
  })
);

export const cartFeature = createFeature({
  name: 'cart',
  reducer: cartReducer,
});
