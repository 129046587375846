import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoursesService } from 'thkee-common';
import { CategoryActions } from './category.actions';

@Injectable()
export class CategoryEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadCategories),
      switchMap(() =>
        this.coursesService.getCategories().pipe(
          map((categories) => CategoryActions.loadCategoriesSuccess({ categories })),
          catchError(() => of(CategoryActions.loadCategoriesSuccess({ categories: [] })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private coursesService: CoursesService) {}
}
