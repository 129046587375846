import { Directive, ElementRef, HostListener } from '@angular/core';
import { DomRectService } from '../../services/dom-rect.service';
import { DropdownDirective } from './dropdown.directive';

@Directive({
  selector: '[thkDropdownToggle]',
  providers: [DomRectService]
})
export class DropdownToggleDirective {
  @HostListener('click', ['$event'])
  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  private handleMouseEvent(event: MouseEvent) {
    this.dropdown.processToggleBtnEvent(event);
  }

  rect$ = this.domRectService.rectChanges;

  constructor(
    private dropdown: DropdownDirective,
    private domRectService: DomRectService,
    private elRef: ElementRef<HTMLElement>
  ) {}

  get rect() {
    return this.elRef.nativeElement.getBoundingClientRect();
  }
}
