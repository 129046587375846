<ng-container *ngIf="{ overviews: assignments | async } as ov">
   <div id="accordion-collapse" data-accordion="collapse" class="h-full">
      <ng-container *ngIf="ov.overviews?.assignments?.courses?.length; else emptyState">
         <div *ngFor="let assignment of ov.overviews?.assignments?.courses; let i=index"
            [ngClass]="isCollapse[i] ? 'border-slate-300' : 'border-slate-200 bg-white'"
            class="item mb-4 bg-slate-50 border rounded-lg overflow-hidden">
            <div (click)="collapse(i)" class="title flex justify-between cursor-pointer items-center p-4">
               <div class="flex items-center">
                  <div class="featured-image h-14 w-20 rounded-md flex items-center bg-slate-500 overflow-hidden me-8">
                     <img *ngIf="assignment.course_image" [src]="assignment.course_image"
                        [ngClass]="isCollapse[i] ? 'border border-primary-800' : 'border-none'" alt="">
                     <img *ngIf="!assignment.course_image"
                        [ngClass]="isCollapse[i] ? 'border border-primary-800' : 'border-none'"
                        src="https://placehold.co/400x600" alt="">
                  </div>
                  <div>
                     <h5 class="text-[14px] text-slate-900 leading-5 font-semibold"> {{assignment.course_title}} </h5>
                     <h5 class="text-[14px] text-slate-400 leading-6 font-normal"
                        i18n="new_assignments|Indicates the number of new assignments or unread assignments since the last visit">
                        <b>{{assignment.total_not_checked}}</b> unread assignments
                     </h5>
                  </div>
               </div>
               <div class="flex items-center">
                  <button (click)="seeSubmission($event, assignment)" class="btn btn-xs btn-outline-primary"
                     i18n="see all assignments|button to see all assignments">
                     View All Assignments
                  </button>
                  <span class="p-1 rounded-full ms-6 me-4 bg-white">
                     <button [ngClass]="!isCollapse[i] ? 'rotate-180' : 'rotate-0'" class="block">
                        <svg-icon src="@assets/images/icons/chevron-down.svg" class="ml-auto h-6 w-6 text-slate-600">
                        </svg-icon>
                     </button>
                  </span>
               </div>
            </div>
            <div class="content border-t border-slate-200 bg-white" [ngClass]="isCollapse[i] ? 'block' : 'hidden'">
               <div *ngFor="let detail of assignment?.student_assignments"
                  class="flex items-center justify-between p-4 border-b last:border-b-0 border-b-slate-100">
                  <div class="flex items-center">
                     <thk-avatar class="me-6" [url]="detail.user.photo_url" [title]="detail.user.fullname"></thk-avatar>
                     <div>
                        <div class="flex items-center gap-2 mt-1">
                           <h5 class="text-[12px] text-slate-700 leading-6 font-semibold">{{detail.user.fullname}}</h5>
                           <span class="w-1 h-1 rounded-full bg-slate-500"></span>
                           <h5 class="text-[12px] text-secondary-500 leading-6 font-medium">{{detail.assignment_title}}
                           </h5>
                        </div>
                        <h5 class="text-[12px] text-slate-400 leading-6 font-normal"
                           i18n="answered_questions|Shows the number of questions answered out of the total questions">
                           {{detail.answered}} answered out of {{detail.questions}} questions
                        </h5>
                     </div>
                  </div>
                  <button [routerLink]="'/instructor/assignments/review-assignment/'+detail.id"
                     class="btn brn-primarry border-none rounded-lg text-[14px] text-slate-800 bg-slate-50" i18n>Review
                  </button>
               </div>
            </div>
         </div>
      </ng-container>
      <!-- Empty State -->
      <ng-template #emptyState>
         <div class="flex w-full">
            <div class="m-auto mt-16 max-w-md text-center">
               <svg-icon class="w-72 block mx-auto mb-8" src="@assets/images/not-found-qanda.svg"></svg-icon>
               <h4 class="mb-3 text-slate-900 font-bold text-xl" i18n>No results</h4>
               <p class="text-slate-600 font-normal text-base mb-0" i18n>
                  Try a different filter or search
               </p>
            </div>
         </div>
      </ng-template>
   </div>
</ng-container>
