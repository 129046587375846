import { Injectable } from '@angular/core';
import { InstructorDetails, UserCoursesReview } from '@shared';
import { Observable } from 'rxjs';
import {
  API_GET_INSTRUCTOR_COURSE_REVIEWS,
  API_GET_INSTRUCTOR_PUBLIC_DETAILS,
  API_GET_INSTRUCTOR_PUBLISHED_COURSES,
  API_INSTRUCTOR_COURSES_REVIEWS,
  API_INSTRUCTOR_REPLIES,
  API_INSTRUCTOR_REPLIES_UPDATE,
  API_INSTRUCTOR_REVIEWS_BY_COURSE,
  Course,
  encodeURL,
  HttpService,
  Pagination,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  constructor(private readonly http: HttpService) {}

  getAllCoursesReviews(params = {} as object): Observable<any> {
    return this.http.get<any>(encodeURL(API_INSTRUCTOR_COURSES_REVIEWS, params));
  }

  getOneCoursesReviews(params = {} as object, course_id: string): Observable<any> {
    return this.http.get<any>(encodeURL(API_INSTRUCTOR_REVIEWS_BY_COURSE.replace('<COURSE_ID>', course_id), params));
  }

  replyCoursesReviews(body: any): Observable<any> {
    return this.http.post<any>(API_INSTRUCTOR_REPLIES, body);
  }

  replyCoursesReviewsUpdate(body: any, review_id: string): Observable<any> {
    return this.http.put<any>(API_INSTRUCTOR_REPLIES_UPDATE.replace('<REVIEW_ID>', review_id), body);
  }

  // Public instructor infos
  getInstructorPublicDetails(instructor_id: string): Observable<InstructorDetails> {
    return this.http.get<any>(API_GET_INSTRUCTOR_PUBLIC_DETAILS.replace('<INSTRUCTOR_ID>', instructor_id));
  }

  getInstructorPublishedCourse(instructor_id: string, params = {} as object): Observable<Pagination<Partial<Course>>> {
    return this.http.get<Pagination<Partial<Course>>>(
      encodeURL(API_GET_INSTRUCTOR_PUBLISHED_COURSES.replace('<INSTRUCTOR_ID>', instructor_id), params)
    );
  }

  getInstructorCourseReviews(
    instructor_id: string,
    params = {} as object
  ): Observable<Pagination<Partial<UserCoursesReview>>> {
    return this.http.get<Pagination<Partial<UserCoursesReview>>>(
      encodeURL(API_GET_INSTRUCTOR_COURSE_REVIEWS.replace('<INSTRUCTOR_ID>', instructor_id), params)
    );
  }
}
