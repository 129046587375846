import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { CartAPIService } from 'thkee-common';
import { AuthActions } from '../auth';
import { CheckoutService } from './checkout-store.service';
import { CheckoutActions } from './checkout.actions';
@Injectable()
export class CheckoutEffects {
  constructor(
    private actions$: Actions,
    private checkoutService: CheckoutService,
    private cartAPIService: CartAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  // Checkout related
  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.loadPaymentMethods),
      switchMap(() =>
        this.checkoutService.getPaymentMethods().pipe(
          map((data) => CheckoutActions.loadPaymentMethodsSuccess({ data })),
          catchError((error) => of(CheckoutActions.loadPaymentMethodsFail({ error })))
        )
      )
    )
  );

  // TODO: Remove this when confirmed the checkout working properly
  processCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.processCheckout),
      switchMap((cart) =>
        this.cartAPIService.checkout(cart).pipe(
          map((result) => {
            return CheckoutActions.processCheckoutSuccess({ data: result });
          }),
          catchError((error) => of(CheckoutActions.processCheckoutFail({ error })))
        )
      )
    )
  );

  verifyPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.verifyPayment),
      switchMap((data) =>
        this.checkoutService.verifyPayment(data.token).pipe(
          map((data) => CheckoutActions.verifyPaymentSuccess({ data })),
          catchError((error) => of(CheckoutActions.verifyPaymentFail({ error })))
        )
      )
    )
  );

  verifyPaymentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.verifyPaymentSuccess),
        tap((data) => {
          // Login After successful signup
          this.store.dispatch(AuthActions.userLoad());
        })
      ),
    { dispatch: false }
  );

  // TODO: Remove this when confirmed the checkout working properly
  // New effect to handle URL redirection
  processCheckoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.processCheckoutSuccess),
        tap(({ data }) => {
          if (data && data.href) {
            window.location.href = data.href;
          } else {
            this.router.navigate(['checkout/thankyou'], {
              queryParams: { order: data.order },
            });
          }
        }) // Perform the actual redirection
      ),
    { dispatch: false } // Set dispatch to false as this effect does not dispatch an action
  );

  loadOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.loadOrder),
      switchMap((payload) => {
        return this.checkoutService.getOrder(payload.orderId).pipe(
          map((data) => CheckoutActions.loadOrderSuccess({ order: data })),
          catchError((error) => of(CheckoutActions.loadOrderFail({ error })))
        );
      })
    )
  );

  paypalCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.paypalCallback),
      switchMap((data) => {
        return this.checkoutService.paypalCallback(data.token).pipe(
          map((data) => CheckoutActions.paypalCallbackSuccess({ order: data })),
          catchError((error) => of(CheckoutActions.paypalCallbackFail({ error })))
        );
      })
    )
  );

  paypalCallbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.paypalCallbackSuccess),
        tap(({ order }) => {
          this.router.navigate(['checkout/thankyou'], {
            queryParams: { order: order.id },
          });
        }) // Perform the actual redirection
      ),
    { dispatch: false } // Set dispatch to false as this effect does not dispatch an action
  );

  tapCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.tapCallback),
      switchMap((data) => {
        return this.checkoutService.paypalCallback(data.tap_id).pipe(
          map((data) => CheckoutActions.tapCallbackSuccess({ order: data })),
          catchError((error) => of(CheckoutActions.tapCallbackFail({ error })))
        );
      })
    )
  );

  tapCallbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.tapCallbackSuccess),
        tap(({ order }) => {
          this.router.navigate(['checkout/thankyou'], {
            queryParams: { order: order.id },
          });
        })
      ),
    { dispatch: false }
  );
}
