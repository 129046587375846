<common-modal #modal [title]="" customClass="text-base sm:w-full sm:max-w-md">
  <ng-container content>
    <div class="p-8">
      <ng-container *ngIf="!signUp">
        <app-form-login></app-form-login>
        <div class="mt-8 inline-flex w-full items-center justify-center gap-1 px-4 py-6">
          <div class="text-sm text-slate-400">
            <span i18n>Don't have an account yet?</span>
            <a class="cursor-pointer font-medium text-slate-900" (click)="signUp = !signUp" i18n>Sign up</a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="signUp">
        <app-form-signup></app-form-signup>
        <div class="mt-8 inline-flex w-full items-center justify-center gap-1 px-4 py-6">
          <div class="text-sm text-slate-400" i18n>
            Already have an account?
            <a class="cursor-pointer font-medium text-slate-900" (click)="signUp = !signUp">Sign in</a>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</common-modal>
