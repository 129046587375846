import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationMessage } from '../components';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private messages$ = new BehaviorSubject<NotificationMessage[]>([]);
  constructor() { }

  add(message: NotificationMessage) {
    this.messages$.next(this.validMessages.concat(message));
  }

  remove(messageId: string) {
    this.messages$.next(this.validMessages.filter(m => m.id !== messageId))
  }

  clear() {
    this.messages$.next([]);
  }

  get messages() {
    return this.messages$.asObservable();
  }

  private get validMessages() {
    return this.messages$.getValue().filter(m => !m.expiredAt || m.expiredAt > Date.now())
  }
}
