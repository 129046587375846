<div #scrollableDiv (scroll)="onScroll()" class="view-body h-full w-full grow overflow-y-auto scroll-smooth">
  <ng-content></ng-content>
</div>
<div
  class="scroll-control bg-primary-100 absolute bottom-4 right-4 z-20 rounded-full shadow-lg"
  (click)="scrollTop()"
  [class.active]="scrollPercentage >= 20"
>
  <app-progress-circle
    [percent]="scrollPercentage"
    [size]="40"
    [loadingSize]="4"
    loadingTrack="primary"
    loadingColor="primary"
  >
    <ng-container content>
      <div class="z-30 flex cursor-pointer">
        <svg-icon src="@assets/images/icons/arrow-up.svg" class="mx-auto my-1 h-5 w-5"></svg-icon>
      </div>
    </ng-container>
  </app-progress-circle>
</div>
