<ng-container *ngIf="{
    activeSectionId: activeSectionId$ | async,
    activeSubsectionId: activeSubsectionId$ | async,
    completedActivity: (completedActivity$ | async) ?? {}
  } as vm">
  <div class="heading relative border-b border-slate-200 px-6 py-2.5">
    <div class="font-bold" i18n>Course Content</div>
  </div>
  <div class="search-box bg-slate-50 px-6 py-4">
    <div class="input-group">
      <div class="group">
        <svg-icon src="@assets/images/icons/search.svg" class="w-5"></svg-icon>
        <input [(ngModel)]="searchText" placeholder="Search here" />
      </div>
    </div>
  </div>
  <div class="section-list min-h-[300px]">
    <ng-container *ngIf="isLoading; else loadedCoursesList">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
    </ng-container>
    <ng-template #loadedCoursesList>
      <ng-container *ngIf="sectionsData.length; else noItems">
        <ng-container *ngTemplateOutlet="courseLists"></ng-container>
      </ng-container>
      <ng-template #noItems>
        <ng-container *ngTemplateOutlet="emptyList"></ng-container>
      </ng-template>
    </ng-template>

    <ng-template #courseLists>
      <ng-container *ngFor="let section of sectionsData | thkLibCourseFilter: searchText; let i = index">
        <ng-container *ngTemplateOutlet="sectionItem; context: { id: i, section }"></ng-container>
      </ng-container>
    </ng-template>
    <ng-template #emptyList>
      <h3 class="text-center text-lg text-slate-500 mt-10">No courses found</h3>
    </ng-template>
  </div>

  <ng-template #sectionItem let-id="id" let-section="section">
    <!-- <a (click)="getUrl(section.chain_id, '', $event)"> -->
    <div [id]="'section_' + section.chain_id"
      class="section-item hover:bg-primary-50 flex h-[60px] items-center gap-4 px-6 py-4"
      (click)="sectionExpandedMap[section.chain_id] = !sectionExpandedMap[section.chain_id]"
      [class.active]="section.chain_id === vm.activeSectionId"
      [class.completed]="vm.completedActivity[section.chain_id]">
      <div class="status w-5">
        <!-- <svg-icon *ngIf="vm.completedActivity[section.chain_id]" src="@assets/images/icons/check.svg"
          class="text-green w-5"></svg-icon>
        <div *ngIf="!vm.completedActivity[section.chain_id]"
          class="circle-status h-5 w-5 rounded-full border-2 border-slate-300 bg-white"></div>-->
        <svg-icon *ngIf="isCompleted(section)" src="@assets/images/icons/check.svg" class="text-green w-5"></svg-icon>
        <div *ngIf="!isCompleted(section)"
          class="circle-status h-5 w-5 rounded-full border-2 border-slate-300 bg-white"></div>
      </div>
      <div class="section-title grow font-medium text-slate-700">{{ section.title }}</div>
      <div class="action transition-all" [class.rotate-180]="sectionExpandedMap[section.chain_id]"
        *ngIf="section?.subsections?.length">
        <svg-icon src="@assets/images/icons/chevron-down.svg" class="w-6"></svg-icon>
      </div>
    </div>
    <!-- </a> -->
    <div class="subsection-list" [class.active]="sectionExpandedMap[section.chain_id]"
      *ngIf="section?.subsections.length">
      <ng-container *ngFor="let subsection of section.subsections | thkLibCourseFilter: searchText; let i = index">
        <ng-container *ngTemplateOutlet="
            subsectionItem;
            context: {
              id: i,
              section,
              subsectionId: subsection.chain_id,
              type: subsection.type,
              subsection: subsection[subsection.type],
              data:subsection
            }
          "></ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #subsectionItem let-id="id" let-type="type" let-section="section" let-subsectionId="subsectionId"
    let-subsection="subsection" let-data="data">
    <a (click)="getUrl(section.chain_id, subsectionId, $event)">
      <!-- <a> -->
      <div [id]="'subsection_' + subsectionId"
        class="subsection-item hover:bg-primary-50 flex max-h-[55px] gap-4 px-6 text-sm"
        [class.active]="vm.activeSubsectionId === subsectionId" [class.completed]="data.is_completed">
        <div class="relative w-5">
          <div (click)="marked(data, $event)"
            class="circle-status h-5 w-5 rounded-full border-2 border-slate-300 bg-white cursor-pointer">
          </div>
          <ng-container *ngIf="!data.is_completed">
            <app-progress-circle (click)="marked(data, $event)" [size]="20" [percent]="data?.progress"
              class="absolute flex flex-col justify-center h-full z-50 cursor-pointer">
            </app-progress-circle>
          </ng-container>
          <div class="line mx-[9px] h-full w-[2px] bg-slate-300 cursor-pointer"></div>
        </div>
        <div class="flex grow items-center gap-5 py-2">
          <div (click)="selectActivity(section.chain_id, subsectionId, data)" class="title grow cursor-pointer">
            {{ subsection.title }}
          </div>
          <div class="text-xs">
            <div class="mb-1 flex gap-1">
              <ng-container *ngIf="type === 'lecture'">
                <ng-container *ngIf="subsection.type === 'video'">
                  <div>
                    <svg-icon src="@assets/images/icons/play-circle-solid.svg" class="w-4 text-slate-400"></svg-icon>
                  </div>
                  <div class="whitespace-nowrap" i18n>{data?.duration, plural, =1 {1 min} other { {{formatDuration(data?.duration)}}
                     }}</div>
                </ng-container>
                <ng-container *ngIf="subsection.type === 'article'">
                  <div>
                    <svg-icon src="@assets/images/icons/book-open.svg" class="w-4 text-slate-400"></svg-icon>
                  </div>
                  <div class="whitespace-nowrap" i18n>Article</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="type === 'quiz'">
                <div>
                  <svg-icon src="@assets/images/icons/puzzle-piece.svg" class="w-4 text-slate-400"></svg-icon>
                </div>
                <div class="whitespace-nowrap" i18n>{{ subsection?.questions?.length }} quiz.</div>
              </ng-container>
              <ng-container *ngIf="type === 'assignment'">
                <div>
                  <svg-icon src="@assets/images/icons/clipboard.svg" class="w-4 text-slate-400"></svg-icon>
                </div>
                <div class="whitespace-nowrap" i18n>{{ subsection?.questions?.length }} asgmt.</div>
              </ng-container>
            </div>
            <div *ngIf="subsection?.resources?.length">
              <svg-icon (click)="onClickResource(subsection?.resources, $event)"
                src="@assets/images/icons/paper-clip.svg" class="w-4 text-[#FB923C] cursor-pointer"></svg-icon>
              <!-- <app-navigation position="right" contentClass="!min-w-[200px] w-[300px]">
                <ng-container button>
                  <svg-icon src="@assets/images/icons/paper-clip.svg" class="w-4 text-[#FB923C]"></svg-icon>
                </ng-container>
                <ng-container content>
                  <div class="flex flex-col" *ngFor="let resource of subsection?.resources">
                    <span class="w-full p-2 border-b border-b-slate-200">{{resource.date_created}}</span>
                    <span class="w-full p-2 border-b border-b-slate-200">{{resource.date_created}}</span>
                    <span class="w-full p-2 border-b border-b-slate-200">{{resource.date_created}}</span>
                  </div>
                </ng-container>
              </app-navigation> -->

            </div>
          </div>
        </div>
      </div>
    </a>
  </ng-template>
</ng-container>


<!-- Download resources -->
<common-modal #resourceDialog i18n-title title="Resources" customClass="sm:w-full sm:max-w-[620px]">
  <ng-container content *ngIf="resourceDialog.isOpened">
    <div class="flex flex-col gap-4">
      <div class="flex flex-row gap-3 p-4 border-b border-b-slate-200 cursor-pointer">
        <span class="w-10 h-10 bg-slate-200 rounded-full flex items-center justify-center">
          <svg-icon src="@assets/images/icons/arrow-down-tray.svg" class="h-5 w-5 text-slate-600"></svg-icon>
        </span>
        <span *ngFor="let resource of resources_list" class="w-full flex-1">
          <a [href]="resource.file" [download]="'file.mp4'">{{resource.file}}</a>
        </span>
      </div>
    </div>
  </ng-container>
</common-modal>

<!-- Loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full min-h-[200px] h-full absolute bg-black bg-opacity-0 z-[99]">
    <div class="loader w-12 pt-2">
      <svg class="text-primary m-auto h-10 w-10 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg>
    </div>
  </div>
</ng-template>
