import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CourseContentActions } from '@shared';
import { distinctUntilChanged } from 'rxjs';
import { RouterStoreService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-learninglab-certificate-shell',
  templateUrl: './learninglab-certificate-shell.component.html',
  styleUrls: ['./learninglab-certificate-shell.component.scss'],
})
export class LearninglabCertificateShellComponent implements OnInit {
  params: any = {};
  constructor(private store: Store, private routerStore: RouterStoreService) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
      });
    if (Object.keys(this.params).length) {
      this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
    }
  }
}
