import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PRICING_LIST, PricingTierList } from '../../../models';
import { encodeURL } from '../../../utils';
import { HttpService } from '../../http';
import { Logger } from '../../logger';

const log = new Logger('PricingTierAPIService');

@Injectable({
    providedIn: 'root',
})
export class PricingTierAPIService {
    constructor(private readonly http: HttpService) { }

    list(params = {} as object): Observable<any> {
        log.debug('API REQUEST pricing tier - ', encodeURL(API_PRICING_LIST, params));
        return this.http.get<PricingTierList[]>(encodeURL(API_PRICING_LIST, params));
    }
}