import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  Certificate,
  CourseContentActions,
  ROUTE_HOME,
  selectCourseContent,
  selectCoursesCertificate,
  selectUser,
} from '@shared';
import { distinctUntilChanged } from 'rxjs';
import { RouterStoreService, User } from 'thkee-common';
@UntilDestroy()
@Component({
  selector: 'app-learninglab-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class LearninglabHeaderComponent implements OnInit {
  homeUrl: string = ROUTE_HOME;

  course$ = this.store.select(selectCourseContent);
  courseCertificate$ = this.store.select(selectCoursesCertificate);

  certificateDetail!: Certificate;
  params: Params = {};
  user?: User;
  isPreviewCourse: boolean = false;
  queryParams: Params = {};

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
      });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.queryParams = params;
      if (params['preview'] === 'true') {
        this.isPreviewCourse = true;
      } else {
        this.isPreviewCourse = false;
        this.getCertificateInfos();
      }
    });

    this.loadUser();
  }

  private loadUser() {
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });
  }

  private getCertificateInfos() {
    this.store.dispatch(CourseContentActions.loadCourseCertificate({ product_id: this.params['courseId'] }));
    this.store
      .select(selectCoursesCertificate)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((data) => {
        this.certificateDetail = data;
      });
  }

  get progress(): number {
    if (this.certificateDetail) {
      return this.certificateDetail?.progress;
    }
    return 0;
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  back() {
    this.location.back();
  }
}
