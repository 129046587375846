import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, map } from 'rxjs';
import { ActionTrackerService } from '../action-tracker.service';
import { TRACKER_ID } from './http.service';
import { LoadingService, endpointKeys } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoadingService,
    private actionTrackerService: ActionTrackerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const contextRequestId = req.context.get(TRACKER_ID);
    if (contextRequestId) {
      this.actionTrackerService.start(contextRequestId);
    }
    let endpointId = this.getEndpointId(req);
    const urlOnly = endpointId.split('?')[0] as (typeof endpointKeys)[number];

    let patternEqual = false;
    endpointKeys.forEach((ep) => {
      if (ep.includes('<') && ep.includes('>')) {
        if (this.checkPatternEqual(ep, urlOnly)) {
          patternEqual = true;
          endpointId = ep;
        }
      }
    });

    if (endpointKeys.includes(urlOnly) || patternEqual) {
      this.loadingService.show(endpointId);
      return next.handle(req).pipe(
        map((response: any) => {
          if (response.status) {
            this.loadingService.hide(endpointId); // False the loading if existing status code and finalize did not work
          }
          return response;
        }),
        finalize(() => {
          this.loadingService.hide(endpointId); // Hide loading after request completes
        })
      );
    }
    return next.handle(req);
  }

  private getEndpointId(req: HttpRequest<any>): string {
    const baseUrlRegex = /^https?:\/\/[^/]+/i;
    const modifiedUrl = req.url.replace(baseUrlRegex, '');
    // You can generate an ID based on URL, method, or any other criteria
    // Here's a simple example using URL and method
    return `${modifiedUrl}`;
  }

  checkPatternEqual(pattern1: string, pattern2: string): boolean {
    const regex = /<\w+>/g;
    const formattedPattern1 = pattern1.replace(regex, '\\w+').replace(/\//g, '\\/');
    const formattedPattern2 = pattern2.replace(regex, '\\w+').replace(/\//g, '\\/');

    const regexPattern = new RegExp(`^${formattedPattern1}$`);

    return regexPattern.test(pattern2) || regexPattern.test(formattedPattern2);
  }
}
