import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TabItem } from '@shared/models/tabs-constant';
import {
  CategoryV2,
  RouterStoreService,
  SubCategoryV2,
  TopicV2,
  getCategoryBySlug,
  getTopicBySlug,
  selectCategories,
  selectTopics,
  selectUrl,
} from 'thkee-common';
@UntilDestroy()
@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss'],
})
export class CategoryHeaderComponent implements OnInit {
  @Input() tabItems: TabItem[] = [
    {
      id: 1,
      title: $localize`Most Popular`,
      route: 'popular',
    },
    {
      id: 2,
      title: $localize`New`,
      route: 'new',
    },
    {
      id: 3,
      title: $localize`For Beginner`,
      route: 'beginner',
    },
  ];
  @Input() selectedTab: string = 'popular';
  @Input() sectionTitle: string = '';
  @Output() selectTab: EventEmitter<string> = new EventEmitter<string>();

  activeRoute$ = this.store.select(selectUrl);
  categories$ = this.store.select(selectCategories);
  topics$ = this.store.select(selectTopics);
  selectedCategory: CategoryV2 | SubCategoryV2 | TopicV2 | null = null;
  selectedTopic: TopicV2 | null = null;
  params: any;

  constructor(private readonly store: Store, private routerStore: RouterStoreService) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
        if (!params['tab'] || params['tab'] === undefined) {
          this.params['tab'] = '';
        }
        if (this.params['topicId']) {
          this.topics$.pipe(untilDestroyed(this)).subscribe((data) => {
            this.selectedCategory = getTopicBySlug(this.params['topicId'], data);
          });
        } else {
          this.categories$.pipe(untilDestroyed(this)).subscribe((data) => {
            if (this.params['category_slug']) {
              this.selectedCategory = getCategoryBySlug(this.params['category_slug'], data);
            }
            if (this.params['subcategory_slug']) {
              this.selectedCategory = getCategoryBySlug(this.params['subcategory_slug'], data);
            }
          });
        }
      });
  }

  onClickTab(item: string) {
    this.selectedTab = item;
    this.selectTab.emit(item);
  }
}
