import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  // { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  Shell.childRoutesForAccount([
    { path: '', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
  ]),
  Shell.childRoutesForStudent([
    {
      path: '',
      loadChildren: () => import('./student/student.module').then((m) => m.StudentModule),
    },
  ]),
  Shell.childRoutesForLearningLab([
    {
      path: '',
      loadChildren: () => import('./learning-lab/learning-lab.module').then((m) => m.LearningLabModule),
    },
  ]),
  Shell.childRoutesForLearningLabCertificate([
    {
      path: '',
      loadChildren: () =>
        import('./learning-lab-certificate/learning-lab-certificate.module').then(
          (m) => m.LearningLabCertificateModule
        ),
    },
  ]),
  {
    path: 'instructor',
    loadChildren: () => import('./instructor/instructor.module').then((m) => m.InstructorModule),
  },
  Shell.childRoutesForPublic([
    {
      path: '',
      loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
    },
  ]),
  // For dev purposes
  // { path: '', loadChildren: () => import('./screens/screens.module').then((m) => m.ScreensModule) },
  // HACK:: workaround for routing language paths, need to check our routing mechanism again,
  // because we don't need this such config in a normal angular app
  { path: 'ar', redirectTo: 'home', pathMatch: 'full' },
  { path: 'ar/:id', redirectTo: ':id' },
  { path: 'en', redirectTo: 'home', pathMatch: 'full' },
  { path: 'en/:id', redirectTo: ':id' },

  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
