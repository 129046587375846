import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value?: number | string, ...args: unknown[]): string {
    // Convert the number to a fixed format with two decimal points
    const formattedValue = typeof value === 'string' ? (parseFloat(value) ?? 0).toFixed(2) : (value ?? 0).toFixed(2);
    // Add the currency symbol and any additional formatting if needed
    const currencySymbol = '$'; // Change this to the desired currency symbol
    return currencySymbol + formattedValue;
  }
}
