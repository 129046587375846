import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { I18nModule } from '@app/i18n';
import { LearninglabActivityComponent } from '@app/learning-lab/activity/activity.component';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { AccountShellComponent } from './account-shell/account-shell.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InstructorHeaderComponent } from './components/instructor-header/instructor-header.component';
import { InstructorSidebarComponent } from './components/instructor-sidebar/instructor-sidebar.component';
import { FeaturedCoursesComponent } from './featured-courses/featured-courses.component';
import { GeneralShellComponent } from './general-shell/general-shell.component';
import { InstructorShellComponent } from './instructor-shell/instructor-shell.component';
import { LearninglabShellComponent } from './learninglab-shell/learninglab-shell.component';
import { StudentShellComponent } from './student-shell/student-shell.component';
import { LearninglabCertificateShellComponent } from './learninglab-certificate-shell/learninglab-certificate-shell.component';

@NgModule({
  imports: [CommonModule, TranslateModule, I18nModule, RouterModule, SharedModule, SwiperModule],
  declarations: [
    HeaderComponent,
    StudentShellComponent,
    InstructorShellComponent,
    FooterComponent,
    AccountShellComponent,
    FeaturedCoursesComponent,
    GeneralShellComponent,
    InstructorHeaderComponent,
    InstructorSidebarComponent,
    LearninglabShellComponent,
    LearninglabActivityComponent,
    LearninglabCertificateShellComponent,
  ],
})
export class ShellModule {}
