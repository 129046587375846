import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScreensRoutingModule } from './screen-routing.module';

// Pages
import { SharedModule } from '@shared';
import { ComponentsComponent } from './components/components.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

import { ReactiveFormsModule } from '@angular/forms';
import { PagesComponent } from './pages/pages/pages.component';

@NgModule({
  declarations: [ComponentsComponent, LandingPageComponent, PagesComponent],
  imports: [CommonModule, ScreensRoutingModule, ReactiveFormsModule, SharedModule],
})
export class ScreensModule { }
