import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { isDefined } from '../../utils';
import { selectAssignmentQuestionsState } from './course.selectors';

export const selectAssignmentQuestions = createSelector(selectAssignmentQuestionsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectAssignmentQuestion = (assignmentQuestionId: string) =>
  createSelector(selectAssignmentQuestionsState, (state) => state.entities[assignmentQuestionId]);

export const selectAssignmentQuestionEntities = createSelector(
  selectAssignmentQuestionsState,
  (state) => state.entities
);

export const selectAssignmentQuestionsByAssignmentId = (assignmentId: string) =>
  createSelector(selectAssignmentQuestionsState, (state) =>
    _.values(state.entities).filter((assignmentQuestion) => assignmentQuestion?.assignment === assignmentId)
  );
