<div id="accordion-collapse" data-accordion="collapse">
  <div class="item mb-4">
    <div class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3" (click)="faq.one = !faq.one">
      <h5 [ngClass]="faq.one ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        Can i get refund if i not continue this course
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.one"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.one"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.one ? 'block' : 'hidden'"
    >
      <p i18n>
        Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2 weeks
        for
      </p>
    </div>
  </div>
  <div class="item mb-4">
    <div class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3" (click)="faq.two = !faq.two">
      <h5 [ngClass]="faq.two ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        Can i get refund if i not continue this course
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.two"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.two"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.two ? 'block' : 'hidden'"
    >
      <p i18n>
        Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2 weeks
        for
      </p>
    </div>
  </div>
  <div class="item mb-4">
    <div
      class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3"
      (click)="faq.three = !faq.three"
    >
      <h5 [ngClass]="faq.three ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        Can i get refund if i not continue this course
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.three"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.three"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.three ? 'block' : 'hidden'"
    >
      <p i18n>
        Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2 weeks
        for
      </p>
    </div>
  </div>
</div>
