import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CourseContentActions, selectIsAuthenticated } from '@shared';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { CoursesService, Logger, RouterStoreService, skipUndefined } from 'thkee-common';
const log = new Logger('LearninglabShellComponent');

@UntilDestroy()
@Component({
  selector: 'app-learninglab-shell',
  templateUrl: './learninglab-shell.component.html',
  styleUrls: ['./learninglab-shell.component.scss'],
})
export class LearninglabShellComponent implements OnInit, OnDestroy {
  fullScreen: boolean = true;
  params: any = {};
  courseContentSubscription = new Subscription();
  isAuth$ = this.store.select(selectIsAuthenticated);
  isLogged$ = this.store.select(selectIsAuthenticated).pipe(skipUndefined());

  constructor(
    private routerStore: RouterStoreService,
    private store: Store,
    private router: Router,
    private coursesService: CoursesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
        // this.store.dispatch(CourseActions.loadCourse({ courseId }));
      });
    if (Object.keys(this.params).length) {
      this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
        if (params['preview'] === 'true') {
          this.store.dispatch(
            CourseContentActions.loadCourseContent({ product_id: this.params['courseId'], preview: true })
          );
          this.store.dispatch(
            CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'], preview: true })
          );
        } else {
          this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
          this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
        }
        this.selectActivity(this.params['section'], this.params['subsection']);
      });
    }

    // Do not remove it **
    // setTimeout(() => {
    //   this.reload();
    // }, 200);
  }

  ngOnDestroy(): void {
    this.courseContentSubscription.unsubscribe();
  }

  // TODO: move this to /learning-lab/main.component.ts if ngrx is integrated
  activityHandler(event: any) {
    if (event.action == 'full-screen') {
      this.fullScreen = !this.fullScreen;
    }
  }

  selectActivity(sectionId: string, subsectionId: string) {
    this.store.dispatch(
      CourseContentActions.selectActivity({
        sectionId,
        subsectionId,
      })
    );
  }

  // Reload
  reload() {
    let url = `/learning-lab/${this.params['courseId']}/overview/${this.params['section']}/${this.params['subsection']}`;
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
}
