import { createFeature, createReducer, on } from '@ngrx/store';
import { PayoutOverview } from 'thkee-common';
import { PayoutActions } from './payout.actions';

export interface PayoutState {
  overview: PayoutOverview | undefined;
}
export const payoutReducer = createReducer<PayoutState>(
  { overview: undefined },
  on(PayoutActions.loadOverviewSuccess, (state, { payload: { overview } }) => ({ ...state, overview: overview })),
);

export const payoutFeature = createFeature({
  name: 'payout',
  reducer: payoutReducer
});
