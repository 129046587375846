<div class="container py-8">
  <svg-icon src="@assets/images/logo.svg" class="block w-[200px]"></svg-icon>
  <div class="text-3xl font-bold">UI Components</div>

  <div class="buttons">
    <div>
      <section>
        <div class="buttons p-4">
          <div>Base:</div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-transparent">
              <span>Login</span>
            </button>
            <button class="btn btn-transparent">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-transparent">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-transparent">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-transparent">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-transparent">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-transparent">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-light">
              <span>Login</span>
            </button>
            <button class="btn btn-light">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-light">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-light">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-light">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-light">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-light">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn">
              <span>Login</span>
            </button>
            <button class="btn">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline">
              <span>Login</span>
            </button>
            <button disabled class="btn">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-primary">
              <span>Login</span>
            </button>
            <button class="btn btn-primary">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-primary">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-primary">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-primary">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-primary">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline primary">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-secondary">
              <span>Login</span>
            </button>
            <button class="btn btn-secondary">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-secondary">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-secondary">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-secondary">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-secondary">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-secondary">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-green">
              <span>Login</span>
            </button>
            <button class="btn btn-green">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-green">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-green">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-green">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-green">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-green">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-red">
              <span>Login</span>
            </button>
            <button class="btn btn-red">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-red">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-red">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-red">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-red">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-red">
              <span>Login</span>
            </button>
          </div>
          <div class="mb-4 flex gap-4">
            <button class="btn btn-dark">
              <span>Login</span>
            </button>
            <button class="btn btn-dark">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span>Login</span>
            </button>
            <button class="btn btn-dark">
              <span>Login</span>
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-dark">
              <svg
                class="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
            <button class="btn btn-outline-dark">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-dark">
              <span>Login</span>
            </button>
            <button disabled class="btn btn-outline-dark">
              <span>Login</span>
            </button>
          </div>
        </div>
        <div class="buttons p-4">
          <div>Size:</div>
          <div class="">
            <button class="btn btn-xs">
              <span>Login</span>
            </button>
            <button class="btn btn-sm">
              <span>Login</span>
            </button>
            <button class="btn btn-md">
              <span>Login</span>
            </button>
            <button class="btn btn-lg">
              <span>Login</span>
            </button>
            <button class="btn btn-xl">
              <span>Login</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="inputs">
    <div>
      <section class="p-4">
        <div class="border-b p-5">
          <div>Input:</div>
          <div class="mb-4 flex gap-4">
            <input class="input-field" placeholder="Enter your full name" />
            <input class="input-field" disabled value="Disabled" placeholder="Disabled" />
            <input class="input-field" readonly value="Readonly" placeholder="Readonly" />
            <input class="input-field" type="email" value="123@" placeholder="Email Address" />
          </div>
          <div>Input Size:</div>
          <div class="mb-4">
            <input class="input-field input-xs" placeholder="Enter your full name" />
            <input class="input-field input-sm" placeholder="Enter your full name" />
            <input class="input-field input-md" placeholder="Enter your full name" />
            <input class="input-field input-lg" placeholder="Enter your full name" />
            <input class="input-field input-xl" placeholder="Enter your full name" />
          </div>
          <div>File Upload Size:</div>
          <div class="mb-4">
            <input type="file" class="input-field input-xs" placeholder="Enter your full name" />
            <input type="file" class="input-field input-sm" placeholder="Enter your full name" />
            <input type="file" class="input-field input-md" placeholder="Enter your full name" />
            <input type="file" class="input-field input-lg" placeholder="Enter your full name" />
            <input type="file" class="input-field input-xl" placeholder="Enter your full name" />
          </div>
        </div>

        <div class="border-b p-5">
          <div>Input Group:</div>
          <div class="mb-3 flex gap-4">
            <div class="input-group">
              <label>Label</label>
              <input class="input-field" placeholder="Enter your full name" />
            </div>
            <div class="input-group">
              <label>Label</label>
              <div class="group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
            </div>
            <div class="input-group">
              <label>Label</label>
              <div class="group">
                <input placeholder="Enter your full name" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="mb-3 flex gap-4">
            <div class="input-group input-group-xs">
              <label>Label</label>
              <input class="input-field" placeholder="Enter your full name" />
            </div>
            <div class="input-group input-group-sm">
              <label>Label</label>
              <div class="group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
            </div>
            <div class="input-group input-group-md">
              <label>Label</label>
              <div class="group">
                <input placeholder="Enter your full name" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
            </div>
            <div class="input-group input-group-lg">
              <label>Label</label>
              <div class="group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
            </div>
            <div class="input-group input-group-xl">
              <label>Label</label>
              <div class="group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
            </div>
          </div>

          <div class="mb-3 flex gap-4">
            <div class="input-group input-group-xs">
              <label>Label</label>
              <input type="email" value="123@" class="input-field" placeholder="Enter your full name" />
              <div class="error-msg">Invalid Message</div>
            </div>
            <div class="input-group input-group-sm">
              <label>Label</label>
              <div class="invalid group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
                <div class="error-msg">Invalid Message</div>
              </div>
              <div class="error-msg">Invalid Message</div>
            </div>
            <div class="input-group input-group-md">
              <label>Label</label>
              <div class="invalid group">
                <input placeholder="Enter your full name" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
              <div class="error-msg">Invalid Message</div>
            </div>
            <div class="input-group input-group-lg">
              <label>Label</label>
              <div class="invalid group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
              <div class="error-msg">Invalid Message</div>
            </div>
            <div class="input-group input-group-xl">
              <label>Label</label>
              <div class="invalid group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <input placeholder="Enter your full name" />
              </div>
              <div class="error-msg">Invalid Message</div>
            </div>
          </div>
        </div>

        <div class="mt-8 max-w-lg">
          <div class="mb-3 flex gap-3">
            <div class="grow">
              <input placeholder="Enter your full name" class="input-field input-sm w-full" />
            </div>
            <div>
              <button class="btn btn-sm btn-dark">
                <span>Notify me</span>
              </button>
            </div>
          </div>
          <div class="mb-3 flex gap-3">
            <div class="grow">
              <input placeholder="Enter your full name" class="input-field w-full" />
            </div>
            <div>
              <button class="btn btn-dark">
                <span>Notify me</span>
              </button>
            </div>
          </div>
          <div class="mb-3 flex gap-3">
            <div class="grow">
              <input placeholder="Enter your full name" class="input-field input-lg w-full" />
            </div>
            <div>
              <button class="btn btn-lg btn-dark">
                <span>Notify me</span>
              </button>
            </div>
          </div>
          <div class="mb-3 flex gap-3">
            <div class="grow">
              <div class="input-group input-group-lg">
                <div class="group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    ></path>
                  </svg>
                  <input placeholder="Enter your full name" />
                </div>
              </div>
            </div>
            <div>
              <button class="btn btn-lg btn-dark">
                <span>Notify me</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <section class="grid gap-2 p-4">
    <div class="checkbox">Checkbox:</div>
    <div class="input-check">
      <input disabled id="d-checkbox" type="checkbox" value="" />
      <label for="d-checkbox">Default checkbox</label>
    </div>
    <div class="input-check">
      <input id="default-checkbox" type="checkbox" value="" />
      <label for="default-checkbox">Default checkbox</label>
    </div>
    <div class="input-check">
      <input id="default2-checkbox" type="checkbox" value="" />
      <label for="default2-checkbox">Default checkbox <a href="#">Terms & Conditions</a>.</label>
    </div>
    <div class="checkbox">Radio:</div>
    <div class="input-radio">
      <input id="default1-radio" type="radio" value="" />
      <label for="default1-radio">Default checkbox <a href="#">Terms & Conditions</a>.</label>
    </div>
    <div class="input-radio">
      <input id="default1-radio" type="radio" value="" />
      <label for="default1-radio">Default checkbox <a href="#">Terms & Conditions</a>.</label>
    </div>
    <div class="input-radio">
      <input id="default2-radio" disabled type="radio" value="" />
      <label for="default2-radio">Default checkbox <a href="#">Terms & Conditions</a>.</label>
    </div>
  </section>

  <section class="p-4">
    <div>Select / Dropdown:</div>
    <div class="flex max-w-sm flex-col gap-4">
      <div class="input-select">
        <label for="countries">Default</label>
        <select id="countries" placeholder="Select your country">
          <option disabled selected>Select your country</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
      </div>
      <div class="input-select size-sm">
        <label for="countries">Small</label>
        <select id="countries" placeholder="Select your country">
          <option disabled selected>Select your country</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
      </div>

      <select class="size-sm" id="countries" placeholder="Select your country">
        <option disabled selected>Select your country</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
      </select>

      <div>
        <div class="select2">
          <label id="listbox-label">Style Two Default</label>
          <div class="relative">
            <button
              type="button"
              (click)="select.select2Default = !select.select2Default"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <div class="selected-item truncate">Tom Cook</div>
              <svg
                class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': select.select2Default }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="select.select2Default ? 'show' : 'hidden'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2Default = !select.select2Default"
              >
                Wade Cooper
              </li>
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2Default = !select.select2Default"
              >
                Wade Cooper
              </li>
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2Default = !select.select2Default"
              >
                Wade Cooper
              </li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="select2 size-sm">
          <label id="listbox-label">Style Two Small</label>
          <div class="relative">
            <button
              type="button"
              (click)="select.select2Small = !select.select2Small"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <div class="selected-item truncate">Tom Cook</div>
              <svg
                class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': select.select2Small }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="select.select2Small ? 'show' : 'hidden'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="select2">
          <label id="listbox-label">Style Two Default Disabled</label>
          <div class="relative">
            <button
              disabled
              type="button"
              (click)="select.select2DefaultDisabled = !select.select2DefaultDisabled"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <div class="selected-item truncate">Tom Cook</div>
              <svg
                class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': select.select2DefaultDisabled }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="select.select2DefaultDisabled ? 'show' : 'hidden'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2DefaultDisabled = !select.select2DefaultDisabled"
              >
                Wade Cooper
              </li>
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2DefaultDisabled = !select.select2DefaultDisabled"
              >
                Wade Cooper
              </li>
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                (click)="select.select2DefaultDisabled = !select.select2DefaultDisabled"
              >
                Wade Cooper
              </li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
              <li class="item" id="listbox-option-0" role="option">Wade Cooper</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="select2 multiple">
          <label id="listbox-label">Style Two Default Multi</label>
          <div class="relative">
            <button
              type="button"
              (click)="select.select2DefaultMulti = !select.select2DefaultMulti"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <div class="selected-item placeholder truncate text-slate-500" *ngIf="!select2DefaultMulti.length">
                Selecte Placeholder
              </div>
              <div class="selected-items" *ngIf="select2DefaultMulti.length">
                <div
                  class="selected-item"
                  *ngFor="let option of multioptions"
                  [class.!hidden]="!isSelected(option.value)"
                >
                  <div class="flex items-center gap-1">
                    <div class="truncate">{{ option.label }}</div>
                    <div (click)="removeMultiDropdown(option.value)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-3 w-3"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <svg
                class="pointer-events-none absolute inset-y-0 right-0 mx-3 flex h-full w-5 items-center text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': select.select2DefaultMulti }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="select.select2DefaultMulti ? 'show' : 'hidden'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li
                class="item"
                id="listbox-option-0"
                role="option"
                *ngFor="let option of multioptions"
                (click)="selectMultiDropdown(option.value)"
                [class.selected]="isSelected(option.value)"
                [class.hidden]="isSelected(option.value)"
              >
                {{ option.label }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>Custom Menu:</div>
      <div class="menu-dropdown">
        <button class="btn btn-sm border-slate-100" type="button" (click)="select.selectMenu = !select.selectMenu">
          <span>Category</span>
          <svg
            class="btn-icon"
            [ngClass]="{ 'rotate-180': select.selectMenu }"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </button>
        <div class="dropdown-container" [ngClass]="select.selectMenu ? 'active' : 'hidden'">
          <div class="content">
            <ul class="category-items">
              <li class="relative">
                <a class="rounded-t-lg" href="/search?category__slug=syndicate-front-end-deliverables"
                  >Syndicate Front-end Deliverables</a
                >
              </li>
              <li class="group/subItem relative">
                <a uisref="marketplace" href="/search?category__slug=implement-247-e-commerce"
                  >Implement 24/7 E-commerce
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </a>
                <ul
                  class="dropdown-subitems lg:group-hover/subItem:visible lg:group-hover/subItem:translate-y-0 lg:group-hover/subItem:opacity-100"
                >
                  <li class="group/subChild relative">
                    <a uisref="marketplace" href="/search?subcategory__slug=audiological-scientist"
                      >Audiological Scientist
                      <svg
                        class="ml-auto h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </a>
                    <ul
                      class="dropdown-subitems lg:group-hover/subChild:visible lg:group-hover/subChild:translate-y-0 lg:group-hover/subChild:opacity-100"
                    >
                      <li>
                        <div class="heading">Related Topics</div>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=expect">
                          <div>
                            <span>Expect</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=form">
                          <div>
                            <span>Form</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          uisref="marketplace"
                          class="hover:bg-primary flex items-center p-4 transition-all duration-200 ease-linear hover:text-white"
                          href="/search?topics__slug=discover"
                        >
                          <div>
                            <span>Discover</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=next">
                          <div>
                            <span>Next</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=course">
                          <div>
                            <span>Course</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="group/subItem relative">
                <a class="rounded-b-lg" href="/search?category__slug=implement-247-e-commerce"
                  >Implement 24/7 E-commerce
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </a>
                <ul
                  class="dropdown-subitems lg:group-hover/subItem:visible lg:group-hover/subItem:translate-y-0 lg:group-hover/subItem:opacity-100"
                >
                  <li class="group/subChild relative">
                    <a uisref="marketplace" href="/search?subcategory__slug=audiological-scientist"
                      >Audiological Scientist
                      <svg
                        class="ml-auto h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </a>
                    <ul
                      class="dropdown-subitems lg:group-hover/subChild:visible lg:group-hover/subChild:translate-y-0 lg:group-hover/subChild:opacity-100"
                    >
                      <li>
                        <div class="heading">Related Topics</div>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=expect">
                          <div>
                            <span>Expect</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=form">
                          <div>
                            <span>Form</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          uisref="marketplace"
                          class="hover:bg-primary flex items-center p-4 transition-all duration-200 ease-linear hover:text-white"
                          href="/search?topics__slug=discover"
                        >
                          <div>
                            <span>Discover</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=next">
                          <div>
                            <span>Next</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=course">
                          <div>
                            <span>Course</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="group/subChild relative">
                    <a uisref="marketplace" href="/search?subcategory__slug=audiological-scientist"
                      >Audiological Scientist
                      <svg
                        class="ml-auto h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </a>
                    <ul
                      class="dropdown-subitems lg:group-hover/subChild:visible lg:group-hover/subChild:translate-y-0 lg:group-hover/subChild:opacity-100"
                    >
                      <li>
                        <div class="heading">Related Topics</div>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=expect">
                          <div>
                            <span>Expect</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=form">
                          <div>
                            <span>Form</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          uisref="marketplace"
                          class="hover:bg-primary flex items-center p-4 transition-all duration-200 ease-linear hover:text-white"
                          href="/search?topics__slug=discover"
                        >
                          <div>
                            <span>Discover</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=next">
                          <div>
                            <span>Next</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a uisref="marketplace" href="/search?topics__slug=course">
                          <div>
                            <span>Course</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>Account Menu:</div>
      <div class="menu-dropdown">
        <div
          class="inline-flex h-8 w-8 items-center justify-center overflow-hidden rounded-3xl bg-white"
          (click)="select.selectAccount = !select.selectAccount"
        >
          <img src=".././../../../assets/images/static/avatar.png" class="h-full w-full" />
        </div>
        <div [ngClass]="select.selectAccount ? 'active' : 'hidden'" class="dropdown-container">
          <div class="heading flex items-center gap-4 p-4">
            <div
              class="inline-flex h-8 w-8 items-center justify-center overflow-hidden rounded-3xl bg-white"
              (click)="select.selectAccount = !select.selectAccount"
            >
              <img src=".././../../../assets/images/static/avatar.png" class="h-full w-full" />
            </div>
            <div>
              <div class="name">Abdullah Al Noman</div>
              <div class="email text-xs text-slate-500">demomail@gmail.com</div>
            </div>
          </div>
          <div class="content max-h-[400px] overflow-y-auto border-y border-y-slate-300">
            <ul class="account-items border-b border-b-slate-300">
              <li class="relative">
                <a routerLink="/student/dashboard">My learning</a>
              </li>
              <li class="relative">
                <a routerLink="/cart">My cart</a>
              </li>
              <li class="relative">
                <a routerLink="/student/wishlist">Wishlist</a>
              </li>
              <li class="relative">
                <a routerLink="/instructor/courses">Instructor dashboard</a>
              </li>
            </ul>
            <ul class="account-items border-b border-b-slate-300">
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Notifcations</a>
              </li>
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Messeges</a>
              </li>
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Account Settings</a>
              </li>
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Payment Methods</a>
              </li>
              <li class="relative">
                <a routerLink="/student/purchase/history">Purchase history</a>
              </li>
            </ul>
            <ul class="account-items">
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Public profile</a>
              </li>
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Edit profile</a>
              </li>
            </ul>
          </div>
          <div class="footer">
            <ul class="account-items">
              <li class="relative">
                <a href="/search?category__slug=syndicate-front-end-deliverables">Help</a>
              </li>
              <li class="relative">
                <a class="rounded-b-lg" href="/search?category__slug=syndicate-front-end-deliverables">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="inputs p-4">
    <div class="">Badge</div>

    <span class="badge badge-primary">Default</span>
    <span class="badge badge-solid-primary">Default</span>

    <span class="badge badge-secondary">Default</span>
    <span class="badge badge-solid-secondary">Default</span>

    <span class="badge badge-slate">Default</span>
    <span class="badge badge-solid-slate">Default</span>

    <span class="badge badge-green">Default</span>
    <span class="badge badge-solid-green">Default</span>

    <span class="badge badge-red">Default</span>
    <span class="badge badge-solid-red">Default</span>
  </section>

  <section class="my-8">
    <h2 class="text-2xl font-semibold">Typography</h2>
    <div>
      <div class="my-4 flex flex-col">
        <div class="m-2">
          <h1>
            <span class="font-bold">This is an H1 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 32px
          </h1>
        </div>
        <div class="m-2">
          <h2>
            <span class="font-bold">This is an H2 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 30px
          </h2>
        </div>
        <div class="m-2">
          <h3>
            <span class="font-bold">This is an H3 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 24px
          </h3>
        </div>
        <div class="m-2">
          <h4>
            <span class="font-bold">This is an H4 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 20px
          </h4>
        </div>
        <div class="m-2">
          <h5>
            <span class="font-bold">This is an H5 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 18px
          </h5>
        </div>
        <div class="m-2">
          <h6>
            <span class="font-bold">This is an H6 Heading</span> -
            <span class="font-medium">TailwindCSS</span> Components 16px
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section>
    <strong>Formly</strong>
    <form [formGroup]="form">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      <button type="submit" class="btn btn-default">Submit</button>
    </form>
  </section>

  <div class="mt-8">
    <h4>Course Card:</h4>
  </div>
  <div class="sample flex items-center gap-4">
    <!-- Standard with Hover -->
    <div class="course-card max-w-[280px]">
      <div class="coursec-imagewarp">
        <div class="coursec-image-placeholder hidden">
          <div class="m-auto flex h-20 w-20 rounded-full bg-white shadow">
            <svg-icon src="@assets/images/image-placeholder.svg" class="m-auto h-11 w-11"></svg-icon>
          </div>
          <span class="font-bold">Image not available</span>
        </div>
        <div
          class="coursec-featured-image"
          style="background-image: url('../../../../assets/images/static/course-card.jpeg')"
        ></div>
        <div class="coursec-bestseller">
          <span>Bestseller</span>
        </div>
      </div>

      <div class="coursec-info">
        <div class="coursec-meta">
          <div class="flex items-center justify-start gap-1">
            <div class="text-primary flex inline-flex items-start justify-start">
              <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
              <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
              <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
              <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
              <svg-icon src="@assets/images/icons/star-solid.svg" class="h-4 w-4"></svg-icon>
            </div>
            <div class="whitespace-nowrap text-xs leading-none">
              <span class="font-semibol text-slate-700">5.0</span>
              <span class="text-slate-300">(223)</span>
            </div>
          </div>
          <div class="truncate text-xs font-semibold text-slate-500">By JennyWilson JennyWilson</div>
        </div>
        <div class="coursec-title font-semibold">Complete Python Bootcamp From Zero to Hero in Python</div>
        <div class="coursec-footer">
          <div class="flex h-4 shrink grow basis-0 items-center justify-start gap-2">
            <div class="flex items-center justify-start gap-1">
              <svg-icon src="@assets/images/icons/clock.svg" class="relative h-4 w-4 text-slate-300"></svg-icon>
              <div class="text-xs text-slate-500">30 hours</div>
            </div>
          </div>
          <div class="flex items-center justify-start gap-1">
            <div class="text-sm text-slate-400 line-through">$284.99</div>
            <div class="text-lg font-semibold text-red-700">$110</div>
          </div>
        </div>
      </div>

      <div class="course-card-hover">
        <div class="flex items-center">
          <div class="coursec-title">Complete Python Bootcamp From Zero to Hero in Python</div>
          <div>
            <button href="#" class="coursec-like">
              <svg-icon class="m-auto h-6 w-6" src="@assets/images/icons/heart.svg"></svg-icon>
            </button>
          </div>
        </div>
        <div class="date">Updated on November 2020</div>
        <div class="excerpt">Introduction to UI/UX design: Define the difference between UI and UX design.</div>
        <div class="course-meta">
          <div class="">
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/document-text.svg"></svg-icon>
            <div>715 lectures</div>
          </div>
          <div>
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/clock.svg"></svg-icon>
            <div>65 hours</div>
          </div>
          <div>
            <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/chart-bar.svg"></svg-icon>
            <div>Beginner</div>
          </div>
        </div>
        <div class="list">
          <ul>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
            <li>
              <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
              <span>12 Hours on-demand video</span>
            </li>
          </ul>
        </div>
        <div class="author">
          <div class="flex grow gap-2">
            <div class="avatar">
              <img src=".././../../../assets/images/static/avatar.png" alt="" />
            </div>
            <div class="name">Jenny Wilson</div>
          </div>
          <div class="amount">
            <div class="old">$284.99</div>
            <div class="new">$110</div>
          </div>
        </div>
        <div class="cart-footer mt-2 flex gap-3">
          <button class="btn btn-primary btn-sm grow">
            <span>Add to Cart</span>
            <svg-icon src="@assets/images/icons/cart.svg" class="h-5 w-5"></svg-icon>
          </button>
          <button class="btn btn-outline-primary text-primary hover:text-white">
            <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- Featured -->
    <div class="course-card-featured max-w-[300px]">
      <div class="flex items-center">
        <div class="coursec-title">Complete Python Bootcamp From Zero to Hero in Python</div>
        <div>
          <button href="#" class="coursec-like">
            <svg-icon class="m-auto h-6 w-6" src="@assets/images/icons/heart.svg"></svg-icon>
          </button>
        </div>
      </div>
      <div class="date">Updated on November 2020</div>
      <div class="excerpt">Introduction to UI/UX design: Define the difference between UI and UX design.</div>
      <div class="course-meta">
        <div class="">
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/document-text.svg"></svg-icon>
          <div>715 lectures</div>
        </div>
        <div>
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/clock.svg"></svg-icon>
          <div>65 hours</div>
        </div>
        <div>
          <svg-icon class="h-3.5 w-3.5" src="@assets/images/icons/chart-bar.svg"></svg-icon>
          <div>Beginner</div>
        </div>
      </div>
      <div class="list">
        <ul>
          <li>
            <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
            <span>12 Hours on-demand video</span>
          </li>
          <li>
            <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
            <span>12 Hours on-demand video</span>
          </li>
          <li>
            <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
            <span>12 Hours on-demand video</span>
          </li>
          <li>
            <svg-icon class="h-4 w-4" src="@assets/images/icons/check.svg"></svg-icon>
            <span>12 Hours on-demand video</span>
          </li>
        </ul>
      </div>
      <div class="author">
        <div class="flex grow gap-2">
          <div class="avatar">
            <img src=".././../../../assets/images/static/avatar.png" alt="" />
          </div>
          <div class="name">Jenny Wilson</div>
        </div>
        <div class="amount">
          <div class="old">$284.99</div>
          <div class="new">$110</div>
        </div>
      </div>
      <div class="cart-footer mt-2 flex gap-3">
        <button class="btn btn-primary btn-sm grow">
          <span>Add to Cart</span>
          <svg-icon src="@assets/images/icons/cart.svg" class="h-5 w-5"></svg-icon>
        </button>
        <button class="btn btn-outline-primary text-primary hover:text-white">
          <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
        </button>
      </div>
    </div>

    <!-- Skeleton -->
    <div class="course-card max-w-[280px]">
      <div class="coursec-imagewarp animate-pulse !bg-slate-200"></div>
      <div class="coursec-info animate-pulse">
        <div class="coursec-meta rounded bg-slate-200">&nbsp;</div>
        <div class="coursec-title h-12 w-60 rounded bg-slate-200 font-semibold text-slate-200"></div>
        <div class="coursec-footer">
          <div class="flex h-4 shrink grow basis-0 items-center justify-start gap-2">
            <div class="flex items-center justify-start gap-1 rounded bg-slate-200 text-slate-200">
              <div class="h-4 w-4"></div>
              <div class="text-xs text-slate-200">00 hours</div>
            </div>
          </div>
          <div class="flex items-center justify-start gap-1 rounded bg-slate-200 text-slate-200">
            <div class="text-sm text-slate-200 line-through">$000.00</div>
            <div class="text-lg font-semibold text-slate-200">$000</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-8">
    <h4>Accordion:</h4>
    <div class="mt-4 max-w-3xl">
      <div id="accordion-collapse" data-accordion="collapse">
        <div class="item mb-4">
          <div
            class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3"
            (click)="accordion.one = !accordion.one"
          >
            <h5 [ngClass]="accordion.one ? 'font-bold' : 'font-normal'">
              Can i get refund if i not continue this course
            </h5>
            <svg-icon
              src="@assets/images/icons/plus.svg"
              class="ml-auto h-6 w-6 text-slate-600"
              *ngIf="!accordion.one"
            ></svg-icon>
            <svg-icon
              src="@assets/images/icons/minus.svg"
              class="ml-auto h-6 w-6 text-slate-600"
              *ngIf="accordion.one"
            ></svg-icon>
          </div>
          <div class="content px-4 pt-4 text-slate-500" [ngClass]="accordion.one ? 'block' : 'hidden'">
            <p>
              Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
              weeks for
            </p>
          </div>
        </div>
        <div class="item mb-4">
          <div class="title flex items-center rounded-md bg-slate-100 px-4 py-3">
            <h5 class="font-normal">Can i get refund if i not continue this course</h5>
            <svg-icon src="@assets/images/icons/plus.svg" class="ml-auto h-6 w-6 text-slate-600"></svg-icon>
          </div>
          <div class="content px-4 pt-4 text-slate-500" [ngClass]="accordion.two ? 'block' : 'hidden'">
            <p>
              Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
              weeks for
            </p>
          </div>
        </div>
        <div class="item mb-4">
          <div class="title flex items-center rounded-md bg-slate-100 px-4 py-3">
            <h5 class="font-normal">Can i get refund if i not continue this course</h5>
            <svg-icon src="@assets/images/icons/plus.svg" class="ml-auto h-6 w-6 text-slate-600"></svg-icon>
          </div>
          <div class="content px-4 pt-4 text-slate-500" [ngClass]="accordion.three ? 'block' : 'hidden'">
            <p>
              Yes you get refund , if you do not want to continue your course you can refund , but you have to wait 2
              weeks for
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
