import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthActions } from '../auth';
import { PaymentActions } from './payment.actions';

export const paymentReducer = createReducer(
  {},
  on(PaymentActions.loadPaymentCardsSuccess, (state, { cards }) => {
    return { ...state, cards };
  }),
  on(AuthActions.logout, (state) => {
    return { ...state, cards: undefined };
  })
);

export const paymentFeature = createFeature({
  name: 'payment',
  reducer: paymentReducer,
});
