import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './account-shell.component.html',
  styleUrls: ['./account-shell.component.scss'],
})
export class AccountShellComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
