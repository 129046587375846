<div class="flex justify-between">
  <div class="flex flex-row items-start gap-4">
    <thk-avatar [url]="data.user.photo_url" [title]="data.user.fullname"></thk-avatar>
    <div class="flex flex-col gap-1">
      <ng-container *ngIf="isTitleClickable; else notClickable">
        <a [routerLink]="'/instructor/question-answer/' + params + '/' + data.id + '/reply'">
          <h4 class="text-sm font-semibold text-slate-600">{{ data.title }}</h4>
        </a>
      </ng-container>
      <ng-template #notClickable>
        <h4 class="text-sm font-semibold text-slate-600">{{ data.title }}</h4>
      </ng-template>
      <p class="mb-0 text-sm font-normal text-slate-500">{{ data.description }}</p>
      <div class="mt-1 flex items-center gap-2">
        <h6 class="text-xs font-medium text-slate-800" i18n>
          {{ data.user.fullname }} <span class="font-normal text-slate-400">(Student)</span>
        </h6>
        <span *ngIf="data.lecture_title" class="h-1 w-1 rounded-full bg-slate-500"></span>
        <h6 *ngIf="data.lecture_title" class="text-xs font-normal text-slate-800">
          {{ data.lecture_title }}
        </h6>
        <span class="h-1 w-1 rounded-full bg-slate-500"></span>
        <h6 class="text-xs font-normal text-slate-800" i18n>Comment on {{ data.date_created | timeAgo }}</h6>
        <span *ngIf="isListView" class="h-1 w-1 rounded-full bg-slate-500"></span>
        <h6 *ngIf="isListView" class="text-xs font-normal text-slate-800" i18n>Upvote ({{ data.upvoted_users_count }})
        </h6>
        <ng-container *ngIf="params">
          <span class="h-1 w-1 rounded-full bg-slate-500"></span>
          <a [routerLink]="'/instructor/question-answer/' + params + '/' + data.id + '/reply'"
            class="cursor-pointer text-xs text-primary underline" i18n>Give Reply</a>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-between items-end">
    <app-navigation position="right" contentClass="!min-w-[200px] w-[200px]">
      <ng-container button>
        <button class="btn btn-transparent p-0">
          <svg-icon src="@assets/images/icons/ellipsis-vertical.svg" class="text-slate-700 w-6">
          </svg-icon>
        </button>
      </ng-container>
      <ng-container content>
        <div class="max-w-full flex flex-col">
          <span class="text-sm cursor-pointer border-b border-slate-200 w-full p-3">
            <ng-container *ngIf="data.reported_qna; else notReported">
              <span class="text-red-500 px-2 py-1 rounded-md bg-red-50"
                i18n="already_reported|Indicates that the issue has already been reported">Reported!</span>
            </ng-container>
            <ng-template #notReported>
              <span class="text-slate-600" (click)="onClickDropdown('report-abuse', data.id)"
                i18n="report_abuse|Prompt for the user to report an abuse">Report an Abuse</span>
            </ng-template>
          </span>
          <span class="text-sm cursor-pointer border-b border-slate-200 w-full p-3">
            <ng-container *ngIf="data.not_relevant; else notMarked">
              <span class="text-red-500 px-2 py-1 rounded-md bg-red-50"
                i18n="flagged_as_irrelevant|Indicates that the item has been flagged as irrelevant">Flagged as
                Irrelevant</span>
            </ng-container>
            <ng-template #notMarked>
              <span class="text-slate-600" (click)="onClickDropdown('not-relevant', data.id)"
                i18n="flag_as_irrelevant|Prompt for the user to flag the item as irrelevant">Flag as Irrelevant</span>
            </ng-template>
          </span>
        </div>
      </ng-container>
    </app-navigation>
    <div *ngIf="isListView" class="flex justify-center items-center gap-4">
      <span *ngIf="!data.viewed" class="w-3 h-3 bg-red-500 rounded-full"></span>
      <div class="flex items-center gap-1.5 cursor-pointer" [routerLink]="'/instructor/question-answer/' + params + '/' + data.id + '/reply'">
        <svg-icon src="@assets/images/icons/chat-bubble-left-right-solid.svg" class="text-slate-300 w-6"></svg-icon>
        <span class="text-slate-300 text-base font-normal">{{data.qna_replies_count}}</span>
      </div>
    </div>
  </div>
</div>

<!-- Report abuse -->
<common-modal #reportAbuseModal i18n-title title="Report Abuse?" customClass="sm:w-full sm:max-w-[620px]">
  <ng-container content>
    <form [formGroup]="reportAbuseForm" (submit)="onSubmitReportAbuse()">
      <div class="p-6 pb-4">
        <p class="text-slate-600 text-base" i18n>Flagged content is reviewed by Thkee staff to determine whether it
          violates our Terms of Service or Community Guidelines. If you have a question or technical issue, please
          contact our Support team.</p>
        <formly-form [form]="reportAbuseForm" [fields]="reportAbuseFormField" [model]="reportAbuseFormModel">
        </formly-form>
      </div>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-slate-200">
        <div class="flex gap-4">
          <button (click)="reportAbuseModal.close()" class="btn btn-sm btn-outline-primary">
            <span i18n>Cancel</span>
          </button>
          <button [disabled]="reportAbuseForm.invalid" type="submit" class="btn btn-sm btn-primary">
            <span i18n>Update</span>
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</common-modal>
