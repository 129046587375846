<thk-notifications #notificationContainer (rect)="handleNotificationViewRect($event)"></thk-notifications>
<div [ngStyle]="{ 'padding-top.px': notificationRect?.height }">
  <router-outlet> </router-outlet>
</div>

<!-- global common modal -->
<ng-container *ngIf="{ modal: modal$ | async } as vm">
  <app-common-modal-confirm #modalConfirm [title]="vm.modal?.title" [message]="vm.modal?.message ?? ''"
    [confirmText]="vm.modal?.confirmText" [hasCancel]="vm.modal?.hasCancel" (confirm)="vm.modal?.onConfirm?.()">
  </app-common-modal-confirm>
</ng-container>

<ng-container *ngIf="{ modalLogin: forceLogin$ | async } as vm">
  <!-- Login/Signup from Modal -->
  <app-common-modal-login #modalLogin></app-common-modal-login>
</ng-container>

<div class="z-[1000]" *ngIf="toast$">
  <ng-container *ngIf="{ toast: toast$ | async } as vm">
    <app-common-toast #toastComponent [type]="vm.toast?.type" [message]="vm.toast?.message ?? ''"></app-common-toast>
  </ng-container>
</div>

<!-- for loading before use -->
<div class="hidden">
  <svg-icon src="@assets/images/icons/spinner.svg"></svg-icon>
</div>