import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit {
  @Input() size: number = 32;
  @Input() loadingSize: number = 2;
  @Input() percent: number = 64;
  @Input() loadingColor: string = 'green';

  constructor() {}

  ngOnInit(): void {}

  percentageToDegrees(percentage: number, maxDegrees: number): number {
    percentage = Math.min(100, Math.max(0, percentage));
    let degrees = (percentage / 100) * 360;
    if (degrees >= maxDegrees) degrees = maxDegrees;
    return degrees;
  }
}
