import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'thkee-common';

@Component({
  selector: 'app-common-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() title?: string | boolean = '';
  @Input() isOpened: boolean = false;
  @Input() customClass: string = '';
  @Input() type?: 'message' | 'error' | 'info' | 'warning' = 'message';
  @Input() closeTimer: number = 3000;
  @Input() message: string = '';
  @Output() closeToast = new EventEmitter();

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {}

  open() {
    this.isOpened = true;
    setTimeout(() => {
      this.discard();
    }, this.closeTimer);
  }

  discard() {
    this.isOpened = false;
    this.closeToast.emit();
  }
}
