import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_GEO_LOCATION, GeoLocationList } from '../../../models';
import { encodeURL } from '../../../utils';
import { HttpService } from '../../http';
import { Logger } from '../../logger';

const log = new Logger('PricingGeoAPIService');

@Injectable({
  providedIn: 'root',
})
export class PricingGeoAPIService {
  constructor(private readonly http: HttpService) {}

  list(params = {} as object): Observable<any> {
    return this.http.get<GeoLocationList[]>(encodeURL(API_GEO_LOCATION, params));
  }
}
