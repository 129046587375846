import { Injectable } from '@angular/core';
import { ActionPayload, Announcement, Certificate, CourseOverview, CoursesContent, MyClassQNA, Review } from '@shared';
import { Observable } from 'rxjs';
import {
  API_COURSES_ASSIGNMENT,
  API_GET_CLASSES_ANNOUNCEMENT,
  API_GET_CLASSES_ANNOUNCEMENT_PREVIEW,
  API_GET_CLASSES_CONTENT,
  API_GET_CLASSES_CONTENT_PREVIEW,
  API_GET_CLASSES_QNA_FROM_SUBSECTION,
  API_GET_CLASSES_QNA_REPLIES,
  API_GET_CLASSES_REVIEW_PREVIEW,
  API_GET_CLASSES_TOGGLE_ARHHIVE,
  API_GET_COURSES_CERTIFICATE,
  API_GET_MY_CLASSES,
  API_GET_MY_CLASSES_OVERVIEW,
  API_GET_MY_CLASSES_OVERVIEW_PREVIEW,
  API_GET_MY_WISHLIST,
  API_GET_POST_CLASSES_QNA,
  API_GET_POST_CLASSES_REVIEW,
  API_POST_CLASSES_PROGRESS,
  API_POST_CLASSES_QNA_REPLIES,
  API_POST_CLASSES_QNA_REPORT_REPLIES,
  API_POST_CLASSES_REPORT_REVIEW,
  API_POST_QUIZ_ANSWER,
  API_PUT_CLASSES_DISLIKE_REVIEW,
  API_PUT_CLASSES_LIKE_REVIEW,
  API_PUT_CLASSES_QNA_REPLIES_UPVOT,
  API_PUT_CLASSES_QNA_UPVOT,
  API_PUT_CLASSES_REVIEW,
  API_PUT_DELETE_CLASSES_QNA_REPLIES,
  Course,
  CourseListPayload,
  encodeURL,
  HttpService,
  Logger,
  Pagination,
  UtilsService,
} from 'thkee-common';
const log = new Logger('CoursesService');

@Injectable({
  providedIn: 'root',
})
export class MyClassService {
  constructor(private readonly http: HttpService, private utilsService: UtilsService) {}

  getMyClasses(params = {} as CourseListPayload): Observable<Pagination<Course>> {
    log.debug('API REQUEST getMyClasses - ', encodeURL(API_GET_MY_CLASSES, params));
    return this.http.get<Pagination<Course>>(encodeURL(API_GET_MY_CLASSES, params));
  }

  getMyWishlist(params = {} as CourseListPayload): Observable<Pagination<Partial<Course>>> {
    log.debug('API REQUEST getMyWishlist - ', encodeURL(API_GET_MY_WISHLIST, params));
    return this.http.get<Pagination<Partial<Course>>>(encodeURL(API_GET_MY_WISHLIST, params));
  }

  getClassesContent(payload: ActionPayload, params = {} as CourseListPayload): Observable<CoursesContent> {
    log.debug('API REQUEST getClassesContent - ', encodeURL(API_GET_CLASSES_CONTENT, params));
    if (payload.preview) {
      return this.http.get<CoursesContent>(
        encodeURL(API_GET_CLASSES_CONTENT_PREVIEW.replace('<COURSE_ID>', payload.product_id), params)
      );
    } else {
      return this.http.get<CoursesContent>(
        encodeURL(API_GET_CLASSES_CONTENT.replace('<COURSE_ID>', payload.product_id), params)
      );
    }
  }

  markedAsRead(body: any, id: string): Observable<any> {
    return this.http.post<any>(API_POST_CLASSES_PROGRESS.replace('<COURSE_CODE>', id), body);
  }

  getClassesOverview(payload: ActionPayload, params = {} as CourseListPayload): Observable<CourseOverview> {
    log.debug('API REQUEST getClassesOverview - ', encodeURL(API_GET_MY_CLASSES_OVERVIEW, params));
    if (payload.preview) {
      return this.http.get<CourseOverview>(
        encodeURL(API_GET_MY_CLASSES_OVERVIEW_PREVIEW.replace('<COURSE_ID>', payload.product_id), params)
      );
    } else {
      return this.http.get<CourseOverview>(
        encodeURL(API_GET_MY_CLASSES_OVERVIEW.replace('<COURSE_ID>', payload.product_id), params)
      );
    }
  }

  // Quiz
  submitQuiz(courseId: string, quizId: string, payload: any): Observable<any> {
    return this.http.post<any>(
      API_POST_QUIZ_ANSWER.replace('<COURSE_ID>', courseId).replace('<QUIZ_ID>', quizId),
      payload
    );
  }

  // Assignment
  submitAssignmentAnswers(courseId: string, assignId: string, payload: any[]): Observable<any[]> {
    const endpoint = API_COURSES_ASSIGNMENT.replace('<COURSE_ID>', courseId).replace('<ASSIGNMENT_ID>', assignId);
    return this.http.post<any[]>(endpoint, payload);
  }

  // Announcement
  getClassesAnnouncements(courseId: string): Observable<Announcement> {
    return this.utilsService.get<Announcement>(API_GET_CLASSES_ANNOUNCEMENT.replace('<COURSE_ID>', courseId));
  }

  getPreviewClassesAnnouncements(courseId: string): Observable<Announcement> {
    return this.utilsService.get<Announcement>(API_GET_CLASSES_ANNOUNCEMENT_PREVIEW.replace('<COURSE_ID>', courseId));
  }

  // Reviews
  getClassesReview(courseId: string, is_reload: boolean): Observable<Review> {
    return this.http.get<Review>(API_GET_POST_CLASSES_REVIEW.replace('<COURSE_ID>', courseId));
  }
  getPreviewClassesReview(courseId: string): Observable<Review> {
    return this.http.get<Review>(API_GET_CLASSES_REVIEW_PREVIEW.replace('<COURSE_ID>', courseId));
  }
  postClassesReview(courseId: string, payload: any): Observable<any> {
    return this.http.post<any>(API_GET_POST_CLASSES_REVIEW.replace('<COURSE_ID>', courseId), payload);
  }
  updateClassesReview(courseId: string, reviewId: string, payload: any): Observable<any> {
    return this.http.put<any>(
      API_PUT_CLASSES_REVIEW.replace('<COURSE_ID>', courseId).replace('<REVIEW_ID>', reviewId),
      payload
    );
  }
  reportClassesReview(courseId: string, reviewId: string, payload: any): Observable<any> {
    return this.http.post<any>(
      API_POST_CLASSES_REPORT_REVIEW.replace('<COURSE_ID>', courseId).replace('<REVIEW_ID>', reviewId),
      payload
    );
  }
  likeClassesReview(courseId: string, reviewId: string): Observable<any> {
    return this.http.put<any>(
      API_PUT_CLASSES_LIKE_REVIEW.replace('<COURSE_ID>', courseId).replace('<REVIEW_ID>', reviewId),
      {}
    );
  }
  dislikeClassesReview(courseId: string, reviewId: string): Observable<any> {
    return this.http.put<any>(
      API_PUT_CLASSES_DISLIKE_REVIEW.replace('<COURSE_ID>', courseId).replace('<REVIEW_ID>', reviewId),
      {}
    );
  }

  // Q&A
  getClassesQNA(courseId: string, params = {} as object, is_reload: boolean): Observable<MyClassQNA> {
    return this.http.get<MyClassQNA>(encodeURL(API_GET_POST_CLASSES_QNA.replace('<COURSE_ID>', courseId), params));
    // if (is_reload) {
    // } else {
    //   return this.utilsService.get<MyClassQNA>(
    //     encodeURL(API_GET_POST_CLASSES_QNA.replace('<COURSE_ID>', courseId), params)
    //   );
    // }
  }
  getClassesQNABySubsection(courseId: string, subsectionId: string): Observable<any> {
    return this.http.get<any>(
      API_GET_CLASSES_QNA_FROM_SUBSECTION.replace('<COURSE_ID>', courseId).replace('<SUBSECTION_ID>', subsectionId)
    );
  }
  postClassesQNA(courseId: string, payload: any): Observable<any> {
    return this.http.post<any>(API_GET_POST_CLASSES_QNA.replace('<COURSE_ID>', courseId), payload);
  }
  getClassesQNAReply(qnaId: string): Observable<any> {
    return this.http.get<any>(API_GET_CLASSES_QNA_REPLIES.replace('<QNA_ID>', qnaId));
  }
  postClassesQNAReply(qnaId: string, payload: any): Observable<any> {
    return this.http.post<any>(API_POST_CLASSES_QNA_REPLIES.replace('<QNA_ID>', qnaId), payload);
  }
  updateClassesQNAReply(qnaReplyId: string, payload: any): Observable<any> {
    return this.http.put<any>(API_PUT_DELETE_CLASSES_QNA_REPLIES.replace('<QNA_REPLY_ID>', qnaReplyId), payload);
  }
  deleteClassesQNAReply(qnaReplyId: string): Observable<any> {
    return this.http.delete<any>(API_PUT_DELETE_CLASSES_QNA_REPLIES.replace('<QNA_REPLY_ID>', qnaReplyId));
  }
  reportClassesQNAReply(qnaReplyId: string, payload: any): Observable<any> {
    return this.http.post<any>(API_POST_CLASSES_QNA_REPORT_REPLIES.replace('<QNA_REPLY_ID>', qnaReplyId), payload);
  }
  upvotClassesQNA(qnaId: string): Observable<any> {
    return this.http.put<any>(API_PUT_CLASSES_QNA_UPVOT.replace('<QNA_ID>', qnaId), {});
  }
  upvotClassesQNAReply(qnaReplyId: string): Observable<any> {
    return this.http.put<any>(API_PUT_CLASSES_QNA_REPLIES_UPVOT.replace('<QNA_REPLY_ID>', qnaReplyId), {});
  }

  // Certificate
  getCoursesCertificatet(product_id: string, params = {}): Observable<Certificate> {
    return this.http.get<Certificate>(
      encodeURL(API_GET_COURSES_CERTIFICATE.replace('<COURSE_ID>', product_id), params)
    );
  }

  // Toggle Archived
  getClassesToggleArchive(id: string): Observable<Course> {
    log.debug('API REQUEST getClassesToggleArchive - ', API_GET_CLASSES_TOGGLE_ARHHIVE);
    return this.http.get(API_GET_CLASSES_TOGGLE_ARHHIVE.replace('<COURSE_ID>', id));
  }
}
