import { Component, OnInit } from '@angular/core';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentLang = this.i18nService.langName;
  supportLanguages = this.i18nService.supportedLanguageObjects;

  constructor(private i18nService: I18nService) {}

  ngOnInit(): void {}

  changeLanguage(language: string) {
    this.i18nService.language = language;
  }
}
