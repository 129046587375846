import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PaginationService } from 'thkee-common';
import { ReviewsService } from '../../services';
import { InstructorReviewsActions } from './reviews.actions';

@Injectable()
export class InstructorReviewsEffects {
  constructor(
    private actions$: Actions,
    private reviewsService: ReviewsService,
    private paginationService: PaginationService
  ) {}

  // All Courses Reviews
  allCoursesReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstructorReviewsActions.loadAllCoursesReviews),
      switchMap((params) => {
        return this.reviewsService.getAllCoursesReviews(params.params).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return InstructorReviewsActions.loadAllCoursesReviewsSuccess(data);
          }),
          catchError((error) => of(InstructorReviewsActions.loadAllCoursesReviewsFail({ error })))
        );
      })
    )
  );
  // One Courses Reviews
  oneCoursesReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstructorReviewsActions.loadOneCoursesReviews),
      switchMap((params) => {
        return this.reviewsService.getOneCoursesReviews(params.params, params.id).pipe(
          map((data) => {
            this.paginationService.getPager(data.count, params.params.page, params.params.page_size); // Total items, page, page_size
            return InstructorReviewsActions.loadOneCoursesReviewsSuccess(data);
          }),
          catchError((error) => of(InstructorReviewsActions.loadOneCoursesReviewsFail({ error })))
        );
      })
    )
  );
}
