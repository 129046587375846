// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

/* https://api.qa.thkee.dev | https://api.dev.thkeecdn.com | http://localhost:8000 */
// https://companion.dev.thkeecdn.com | https://companion.dev.thkee.dev | https://ripe-falcons-watch.loca.lt
export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  homeUrl: 'https://stage.thkee.dev',
  homeUrlSSL: 'https://stage.thkee.dev',
  serverUrl: 'https://api.stage.thkee.dev',
  uppyCompanionUrl: 'https://companion.stage.thkee.dev',
  defaultLanguage: 'en-US',
  isAdmin: false,
  geolocationUrl: 'https://geolocation.thkee.com',
  supportedLanguages: [
    { code: 'en-US', name: 'English' },
    { code: 'ar-SA', name: 'Arabic' },
  ],
  tapPublicKey: 'pk_test_q6Odm4txscnSGBMje3hYoPC5',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
