import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CourseAssignmentAnswer, PartialEntity } from '../../models';

// export const studentAssignmentAnswerAdapter: EntityAdapter<PartialEntity<StudentAssignmentAnswerV2>> =
//   createEntityAdapter<PartialEntity<StudentAssignmentAnswerV2>>({
//     selectId: (e) => e.id,
//   });
// export const initialStudentAssignmentAnswerState = studentAssignmentAnswerAdapter.getInitialState();

// export type StudentAssignmentAnswerEntityState = EntityState<PartialEntity<StudentAssignmentAnswerV2>>;

export const studentAssignmentAnswerAdapter: EntityAdapter<PartialEntity<CourseAssignmentAnswer>> = createEntityAdapter<
  PartialEntity<CourseAssignmentAnswer>
>({
  selectId: (e: PartialEntity<CourseAssignmentAnswer>) => e.question_id || '', // Handle the case where question_id is undefined
});

export const initialStudentAssignmentAnswerState = studentAssignmentAnswerAdapter.getInitialState();

export type StudentAssignmentAnswerEntityState = EntityState<PartialEntity<CourseAssignmentAnswer>>;
