import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration',
})
export class formatDurationPipe implements PipeTransform {
  transform(minutes: number = 0, ...args: unknown[]): string { // { hours: number; minutes: number; output: string } - Old output
    if (minutes < 0) {
      throw new Error('Minutes cannot be negative.');
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    let output = '';
    if (hours) output += hours + 'h ';
    if (remainingMinutes) output += remainingMinutes + 'm';
    output = output ? output : 'Not Available';

    return output;
    //return { hours, minutes: remainingMinutes, output }; Old return
  }
}
