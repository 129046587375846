<common-modal #modal [title]="title" customClass="text-base sm:w-full sm:max-w-md">
  <ng-container content>
    <div class="p-6 text-slate-600">{{ message }}</div>
    <div class="flex justify-end gap-2 border-t border-slate-200 px-6 py-4">
      <ng-container *ngIf="hasCancel">
        <button class="btn border-none" (click)="modal.close()">
          <span i18n>Cancel</span>
        </button>
      </ng-container>
      <button class="btn btn-{{ confirmColor }}" (click)="modal.close(); onConfirm()">
        <span>{{ confirmText }} </span>
      </button>
    </div>
  </ng-container>
</common-modal>
