import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ASSIGNMENTS_TAB, NavItem, ShellCom, TabItem } from '@shared/models/instructor-sidebar-constant';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { CourseActions, Logger, RouterStoreService } from 'thkee-common';

const log = new Logger('InstructorShellComponent');

@UntilDestroy()
@Component({
  templateUrl: './instructor-shell.component.html',
  styleUrls: ['./instructor-shell.component.scss'],
})
export class InstructorShellComponent implements OnInit, AfterViewInit {
  hideSidebar: boolean = false;
  openAccount: boolean = false;

  // 2nd
  hasDynamic: any = {
    header: false,
    sidebar: false,
  };
  dynamicComponents: any = {};
  @ViewChild('sidebarComponentContainer', { read: ViewContainerRef }) sidebarComponentContainer:
    | ViewContainerRef
    | undefined;
  @ViewChild('headerComponentContainer', { read: ViewContainerRef }) headerComponentContainer:
    | ViewContainerRef
    | undefined;

  constructor(
    private router: Router,
    private routerStoreService: RouterStoreService,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    this.initActive();
    this.checkComponent();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(async (event: any) => {
      this.initActive(event);
      this.checkComponent();
    });

    // load course data if shell is used for /instructor/courses/:courseId/* routes
    combineLatest([this.routerStoreService.getParam('courseId'), this.routerStoreService.getUrl()])
      .pipe(
        tap(([courseId, url]) => log.debug(`courseId[${courseId}] url[${url}]`)),
        filter(([courseId, url]) => url.startsWith('/instructor/course/') && !!courseId),
        map(([courseId]) => courseId),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((courseId) => {
        this.store.dispatch(CourseActions.loadCourse({ courseId }));
      });
  }

  checkComponent() {
    this.routerStoreService
      .getRouteData()
      .pipe(take(1))
      .subscribe((data) => {
        this.selectedNav = data;
        this.hideSidebar = data.hideSidebar ? data.hideSidebar : false;
        this.hasDynamic = { header: false, sidebar: false };
        if (!data.shellComponent) return;
        this.hasDynamic.header = data.shellComponent.header ? true : false;
        this.hasDynamic.sidebar = data.shellComponent.sidebar ? true : false;
        this.dynamicComponents = data.shellComponent;
        this.renderComponent();
      });
  }

  renderComponent() {
    if (this.hasDynamic.header) {
      const headerComponentContainer = this.componentFactoryResolver.resolveComponentFactory(
        this.dynamicComponents.header
      );
      if (this.headerComponentContainer) {
        this.headerComponentContainer.clear();
        this.headerComponentContainer.createComponent(headerComponentContainer);
      }
    }
    if (this.hasDynamic.sidebar) {
      const sidebarComponentContainer = this.componentFactoryResolver.resolveComponentFactory(
        this.dynamicComponents.sidebar
      );
      if (this.sidebarComponentContainer) {
        this.sidebarComponentContainer.clear();
        this.sidebarComponentContainer.createComponent(sidebarComponentContainer);
      }
    }
  }

  // TEST Code
  selectedNav: any = {};
  sidebarItems: TabItem[] = ASSIGNMENTS_TAB;
  activeItem: NavItem | null = null;
  activeId: string | number = '0';
  tabItems: TabItem[] = [];
  tabTitle: string = '';
  shellComponents: ShellCom = {};
  initActive(route: any = this.router) {
    // let URL = route.url;
    // let URL = route.url.replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/[^\/]+/, '/:courseId');
    let URL = route.url.replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/[^\/]+/, '');
    const active = this.findIndicesByRoute(this.sidebarItems, URL);
    this.activeId = active.item?.id;
    this.activeItem = active.item;
    if (active.item) {
      this.tabItems = active.item.tabMenu ? active.item.tabMenu : [];
      this.tabTitle = active.item.displayName;
    }

    // Get Active Tab
    if (this.activeItem?.shell) {
      this.shellComponents = this.activeItem?.shell;
    } else {
      const lastPatch = '/' + _.last(this.router.url.split('/'));
      const tabItem = _.find(this.tabItems, { route: lastPatch });
      this.shellComponents = tabItem?.shell ? tabItem?.shell : {};
    }
  }

  findIndicesByRoute(data: any, route: string, currentPath: any = [], indices: any = [], items: any = []) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const newPath = [...currentPath, i];
      if (item.route === route) {
        indices.push(newPath);
        items.push(item);
      }
      if (item.children) {
        this.findIndicesByRoute(item.children, route, newPath, indices, items);
      }
    }
    return { id: _.join(_.flatten(indices), '_'), item: _.last(items) as NavItem };
  }

  ngAfterViewInit(): void {
    this.renderComponent();
  }
}
