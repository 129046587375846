export interface TabItem {
  id: number;
  displayName: string;
  route: string;
  hasChanges?: boolean;
  shell?: ShellCom;
}

export const ASSIGNMENTS_TAB: TabItem[] = [
  {
    id: 1,
    displayName: 'Overview',
    route: '/instructor/assignments/overview',
  },
  {
    id: 2,
    displayName: 'Course List',
    route: '/instructor/assignments/course-list',
  },
];

export interface ShellCom {
  sidebar?: boolean;
  header?: boolean;
  tab?: boolean;
  breadcrumbs?: boolean;
}
export interface NavItem {
  displayName: string;
  iconName?: string;
  route?: string;
  children?: NavItem[];
  id: number | string;
  countItem?: number | null;
  hidden?: boolean;
  tabMenu?: TabItem[];
  shell?: ShellCom;
}
export const INSTRUCTOR_SIDEBAR_NAV: NavItem[] = [
  {
    id: 'instab-01',
    displayName: 'Course',
    iconName: '',
    route: '/courses',
  },
  {
    id: 'instab-02',
    displayName: 'Assignments',
    iconName: '',
    route: '/assignments',
    tabMenu: ASSIGNMENTS_TAB,
    children: [
      {
        id: 'instab-02-01',
        displayName: 'Assignments',
        iconName: '',
        route: '/assignments/assignment',
        tabMenu: [],
        hidden: true,
      },
      {
        id: 'instab-02-02',
        displayName: 'Assignments',
        iconName: '',
        route: '/assignments/overview',
        tabMenu: [],
        hidden: true,
      },
    ],
  },
];
