import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  API_COURSES_ARCHIVED,
  API_COURSES_DRAFT,
  API_COURSES_IN_REVIEW,
  API_COURSES_PUBLISHED,
  API_COURSES_REJECTED,
  API_COURSES_REVIEW_OR_PUBLISHED,
  API_COURSES_WITHDRAW,
  API_PROJECT_GET,
  Project,
} from '../../models';
import { HttpService } from '../http';
import { Logger } from '../logger';
import { ModalService } from '../modal.service';

const log = new Logger('ProjectService');

export type ToStateResult = { project: Project };

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpService, private modalService: ModalService, private router: Router) {}

  getProject(projectId: string): Observable<Project> {
    return this.http.get<Project>(API_PROJECT_GET.replace('<PROJECT_ID>', projectId));
  }

  // actor: instructor
  toStateDraft(projectId: string): void {
    this.modalService.confirm({
      title: 'Edit Course',
      message: 'Are you sure you want to edit and set this course to Draft?',
      onConfirm: () => {
        this.http.post<ToStateResult>(API_COURSES_DRAFT.replace('<PROJECT_ID>', projectId), {}).subscribe((data) => {
          log.debug('toStateDraft', data);
          this.router.navigate([`/instructor/course/${data.project.unpublished_course}/details`]);
          this.modalService.message({
            title: 'Draft Course',
            message: 'This course can now be edited',
          });
        });
      },
    });
  }

  // actor: instructor
  toStateInReview(projectId: string): Observable<ToStateResult> {
    return this.http.post<ToStateResult>(API_COURSES_IN_REVIEW.replace('<PROJECT_ID>', projectId), {});
  }

  // actor: instructor
  toStateReviewOrPublished(projectId: string): void {
    this.http
      .post<ToStateResult>(API_COURSES_REVIEW_OR_PUBLISHED.replace('<PROJECT_ID>', projectId), {})
      .subscribe((data) => {
        this.router.navigate([`/instructor/courses`]);
        // this.router.navigate([`/instructor/course/${data.project.unpublished_course}/details`]);
        this.modalService.message({
          title: 'Course Published',
          message: 'This course is now live and available to learners',
        });
      });
  }

  // actor: admin
  toStatePublished(projectId: string): void {
    this.modalService.confirm({
      title: 'Publish Course',
      message: 'Are you sure you want to publish this course?',
      onConfirm: () => {
        this.http
          .post<ToStateResult>(API_COURSES_PUBLISHED.replace('<PROJECT_ID>', projectId), {})
          .subscribe((data) => {
            log.debug('toStatePublished', data);
            this.router.navigate([`/course/${data.project.unpublished_course}/details`]);
            this.modalService.message({
              title: 'Course Published',
              message: 'This course is now live and available to learners',
            });
          });
      },
    });
  }

  // actor: admin
  toStateRejected(projectId: string): void {
    this.modalService.confirm({
      title: 'Reject Course',
      message: 'Are you sure you want to reject this course?',
      onConfirm: () => {
        this.http.post<ToStateResult>(API_COURSES_REJECTED.replace('<PROJECT_ID>', projectId), {}).subscribe((data) => {
          log.debug('toStateRejected', data);
          this.router.navigate([`/course/${data.project.unpublished_course}/details`]);
          this.modalService.message({
            title: 'Course Rejected',
            message: 'This course is now rejected and instructor will be notified',
          });
        });
      },
    });
  }

  toStateArchived(projectId: string): Observable<ToStateResult> {
    return this.http.post<ToStateResult>(API_COURSES_ARCHIVED.replace('<PROJECT_ID>', projectId), {});
  }

  withdrawSubmission(projectId: string): Observable<ToStateResult> {
    return this.http.post<ToStateResult>(API_COURSES_WITHDRAW.replace('<PROJECT_ID>', projectId), {});
  }
}
