import { User } from 'thkee-common';

// Initial State
export const initialQNAState: ResponseData = {
  next: null,
  previous: null,
  count: 0,
  results: undefined,
  qnaGroupByDate: [],
  qnaCourses: {
    course_id: '',
    course_title: '',
    course_image: '',
    total_qnas: 0,
    total_unread_count: 0,
    qnas: [],
  },
  qnaCoursesReply: {
    course_id: '',
    course_title: '',
    course_image: '',
    total_qnas: 0,
    total_unread_count: 0,
    qnas: {
      lecture_title: null,
      id: '',
      user: {
        id: '',
        email: '',
        fullname: '',
        language: '',
      },
      date_created: '',
      title: '',
      description: '',
      approved: false,
      not_relevant: false,
      reported_qna: false,
      subsection: null,
      upvoted_users_count: 0,
      qna_replies_count: 0,
      qna_reply: [],
    },
  },
};
export interface ResponseData {
  next: null | number;
  previous: null | number;
  count: number;
  results: any;
  qnaGroupByDate: QnAGroupByDate[];
  qnaCourses: {
    course_id: string;
    course_title: string;
    course_image: string;
    total_qnas: number;
    total_unread_count: number;
    qnas: QnA[];
  };
  qnaCoursesReply: {
    course_id: string;
    course_title: string;
    course_image: string;
    total_qnas: number;
    total_unread_count: number;
    qnas: QnA;
  };
}

// QnA Group By Date
export interface QnAGroupByDate {
  qna_date: string;
  courses: QnACourses[];
}

export interface QnACourses {
  course_id: string;
  project: string;
  course_title: string;
  course_image: string;
  total_qnas: number;
  total_unread_count: number;
  qnas: QnA[];
}

export interface QnA {
  lecture_title: string | null;
  id: string;
  user: Partial<User>;
  date_created: string;
  title: string;
  description: string;
  approved: boolean;
  not_relevant: boolean;
  reported_qna: boolean;
  subsection: string | null;
  project?: string;
  upvoted_users_count?: number;
  qna_replies_count?: number;
  qnareplys?: [];
  qna_reply?: QnAReply[];
  viewed?: boolean;
}

export interface QnAReply {
  id: string;
  user: Partial<User>;
  upvoted_users_count: number;
  reported_qna_reply: boolean;
  date_created: string;
  comment: string;
}

export interface DropDownAction {
  id: string;
  action: string;
}
