<div *ngIf="!chartStyle" i18n>
   No Chart Found!
</div>

<!-- Line chart -->
<ng-container *ngIf="chartStyle === 'lineChart'">
   <div class="flex items-center justify-between mb-4">
      <h3 class="text-2xl text-slate-800 font-medium" i18n="revenue_analytics|Title for the section displaying analytics chart on revenue">Revenue Analytics</h3>
      <div class="flex items-center gap-4">
         <div *ngFor="let dataset of lineChartData.datasets; let i = index">
            <div class="input-check">
               <input class="!border-slate-700 checked:!bg-slate-700" id="default2-checkbox" type="checkbox" checked
                  (change)="toggleDatasetVisibility($event ,i)">
               <label class="flex items-center gap-2" for="default2-checkbox">
                  {{ dataset.label }}
                  <span [style.background-color]="dataset.borderColor" class="w-4 h-1 block rounded-lg"></span>
               </label>
            </div>
         </div>
      </div>
      <div class="select2 size-sm relative w-44">
         <form [formGroup]="formFilterByDay">
            <formly-form [form]="formFilterByDay" [fields]="fieldsFilterByDayForm" [model]="modelFilterByDay">
            </formly-form>
         </form>
      </div>
   </div>
   <canvas #linechart baseChart class="chart max-h-[250px] w-full h-auto" [data]="lineChartData"
      [options]="lineChartOptions" [type]="lineChartType" (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)">
   </canvas>
</ng-container>


<!-- Bar chart -->
<ng-container *ngIf="chartStyle === 'barChart'">
   <div class="flex items-center justify-between mb-4">
      <h3 class="text-2xl text-slate-800 font-medium" i18n="instructor_rating_overview|Title for the section displaying a chart overview of instructor ratings">Instructor Rating Overview</h3>
      <div class="select2 size-sm relative w-44">
         <form [formGroup]="formFilterByDay">
            <formly-form [form]="formFilterByDay" [fields]="fieldsFilterByDayForm" [model]="modelFilterByDay">
            </formly-form>
         </form>
      </div>
   </div>
   <canvas baseChart class="chart" [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
      [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
   </canvas>
</ng-container>

<canvas baseChart class="chart max-h-[175px] w-full h-auto" [data]="pieChartData" [options]="pieChartOptions"
   [type]="pieChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"
   *ngIf="chartStyle === 'pieChart'">
</canvas>

<!-- Doughnut chart -->
<ng-container *ngIf="chartStyle === 'doughnutChart'">
   <div class="flex items-start mb-8">
      <h3 class="text-2xl text-slate-800 font-medium">{{doughnutChartTitle}}</h3>
   </div>
   <canvas #doughnutchart baseChart class="chart max-h-[175px] w-full h-auto" [data]="doughnutChartData"
      [options]="doughnutChartOptions" [type]="doughnutChartType" (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)">
   </canvas>
   <div class="flex flex-col items-center gap-3 mt-14">
      <div class="flex flex-row justify-between w-full gap-5" *ngFor="let dataset of doughnutChartInfo; let i = index">
         <div class="input-check">
            <input class="!border-slate-700 checked:!bg-slate-700" id="default2-checkbox" type="checkbox" checked
               (change)="toggleDonatDatasetVisibility($event, dataset ,i)">
            <label class="flex items-center gap-2" for="default2-checkbox">
               {{ dataset.labels }}
               <span [style.background-color]="dataset.backgroundColor" class="w-4 h-1 block rounded-lg"></span>
            </label>
         </div>
         <div class="flex items-center gap-5">
            <span class=" text-slate-700 text-xs font-normal">{{dataset.data}}%</span>
            <span class=" text-slate-700 text-xs font-normal">$53.65</span>
         </div>
      </div>
   </div>
</ng-container>

<!-- Circle Progressbar -->
<ng-container *ngIf="chartStyle === 'circleProgressbar'">
   <div class="w-full h-full relative flex items-center justify-center">
      <ng-content select="[content]"></ng-content>
      <circle-progress [responsive]="responsive" [outerStrokeLinecap]="outerStrokeLinecap" [showTitle]="showTitle"
         [showSubtitle]="showSubtitle" [showUnits]="showUnits" [percent]="percent" [radius]="radius"
         [outerStrokeWidth]="outerStrokeWidth" [innerStrokeWidth]="innerStrokeWidth"
         [outerStrokeColor]="outerStrokeColor" [innerStrokeColor]="innerStrokeColor" [animation]="true"
         [animationDuration]="animationDuration" [space]="space" [backgroundPadding]="backgroundPadding">
      </circle-progress>
   </div>
</ng-container>
<!-- <table>
   <tr>
       <th *ngFor="let label of lineChartData.labels">{{ label }}</th>
   </tr>
   <tr *ngFor="let d of lineChartData.datasets; let i = index" [class]="'line-' + i">
       <td *ngFor="let label of lineChartData.labels; let j = index">
           {{ d && d.data[j] }}
       </td>
   </tr>
</table> -->
<!-- <div class="button-row">
   <button mat-button mat-raised-button color="primary" (click)="randomize()">
       Randomize
   </button>
   <button mat-button mat-raised-button color="primary" (click)="pushOne()">
       Push
   </button>
   <button mat-button mat-raised-button color="primary" (click)="changeColor()">
       Recolor
   </button>
   <button mat-button mat-raised-button color="primary" (click)="hideOne()">
       Toggle Series B
   </button>
   <button mat-button mat-raised-button color="primary" (click)="changeLabel()">
       Change Label
   </button>
</div> -->
