<ng-container *ngIf="authUser$ | async as user">
  <div class="flex justify-between">
    <div class="flex flex-row gap-4 items-start">
      <thk-avatar [url]="data.user.photo_url || ''" [title]="data.user.fullname"></thk-avatar>
      <div class="flex flex-col gap-1">
        <div class="flex items-center gap-2">
          <h6 class="text-slate-800 font-medium text-xs">
            {{data.user.fullname}}
            <span *ngIf="data.user.email === user.email"
              class="font-medium text-xs bg-[#F97316] rounded-sm px-2 py-1 text-white"
              i18n="label_you|Label indicating the logged-in user">You</span>
            <span *ngIf="data.user.email !== user.email" class="font-normal text-slate-400" i18n>(Student)</span>
          </h6>
          <span class="w-1 h-1 rounded-full bg-slate-500"></span>
          <h6 class="text-slate-800 font-normal text-xs"> {{data.date_created | timeAgo}}</h6>
          <span class="w-1 h-1 rounded-full bg-slate-500"></span>
          <h6 class="text-slate-800 font-normal text-xs" i18n>Upvote ({{data.upvoted_users_count}})</h6>
        </div>
        <markdown [data]="data.comment" class="font-normal text-slate-500 text-sm mb-0"></markdown>
      </div>
    </div>
    <div class="flex flex-col justify-between items-end">
      <app-navigation position="right" contentClass="!min-w-[200px] w-[200px]">
        <ng-container button>
          <button class="btn btn-transparent p-0">
            <svg-icon src="@assets/images/icons/ellipsis-vertical.svg" class="text-slate-700 w-6">
            </svg-icon>
          </button>
        </ng-container>
        <ng-container content>
          <div class="max-w-full flex flex-col">
            <!-- <span (click)="emitClickEvent('report-abuse',data.id, '')" *ngIf="data.user.username!=auth.email"
                     class="text-slate-600 text-sm cursor-pointer border-b border-slate-200 w-full p-3">
                     <span i18n>Report Abuse</span>
                  </span> -->

            <span *ngIf="data.user.email !== user.email"
              class="text-sm cursor-pointer border-b border-slate-200 w-full p-3">
              <ng-container *ngIf="data.reported_qna_reply; else notReported">
                <span class="text-red-500 px-2 py-1 rounded-md bg-red-50"
                  i18n="already_reported|Indicates that the issue has already been reported">Reported!</span>
              </ng-container>
              <ng-template #notReported>
                <span class="text-slate-600" (click)="emitClickEvent('report-abuse',data.id, '')"
                  i18n="report_abuse|Prompt for the user to report an abuse">Report an Abuse</span>
              </ng-template>
            </span>
            <span (click)="emitClickEvent('edit-comment',data.id, data.comment)" *ngIf="data.user.email === user.email"
              class="text-slate-600 text-sm cursor-pointer border-b border-slate-200 w-full p-3">
              <span i18n>Edit Comment</span>
            </span>

            <!-- <span class="text-sm cursor-pointer border-b border-slate-200 w-full p-3">
                     <ng-container *ngIf="data.reported_qna_reply; else notMarked">
                        <span class="text-red-500 px-2 py-1 rounded-md bg-red-50">Marked As Not Relevant</span>
                     </ng-container>
                     <ng-template #notMarked>
                        <span class="text-slate-600" (click)="emitClickEvent('not-relevant', data.id, data.comment)" i18n>
                           Not Relevant
                        </span>
                     </ng-template>
                  </span> -->
          </div>
        </ng-container>
      </app-navigation>
    </div>
  </div>
</ng-container>
