import { createSelector } from '@ngrx/store';
import { AssignmentDetailV2, AssignmentQuestionV2 } from '../../models';
import { isDefined } from '../../utils';
import { selectAssignmentQuestionsByAssignmentId } from './assignment-question.selectors';
import { selectAssignmentsState } from './course.selectors';

export const selectAssignments = createSelector(selectAssignmentsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectAssignment = (assignmentId: string) =>
  createSelector(selectAssignmentsState, (state) => state.entities[assignmentId]);

export const selectAssignmentEntities = createSelector(selectAssignmentsState, (state) => state.entities);

export const selectAssignmentDetailByAssignmentId = (assignmentId: string) =>
  createSelector(
    selectAssignment(assignmentId),
    selectAssignmentQuestionsByAssignmentId(assignmentId),
    (assignment, assignmentQuestions) => ({
      ...(assignment as AssignmentDetailV2),
      questions: assignmentQuestions.map((assignmentQuestion) => assignmentQuestion as AssignmentQuestionV2),
    })
  );
