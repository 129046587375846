import { Component, OnInit } from '@angular/core';
import categoryData from '@app/data/category.json';
import coursesData from '@app/data/courses.json';
import topcategoryData from '@app/data/top-category.json';
import topData from '@app/data/top-courses.json';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { Category, Course } from 'thkee-common';

SwiperCore.use([Navigation]);
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  select: any = {
    selectMenu: false,
    selectLanguage: false,
  };
  topCourses = <Course[]>[];
  coursesData = <Course[]>[];
  categoriesList = <Category[]>[];
  topCategoriesList = <Category[]>[];
  faq: any = {
    one: true,
    two: false,
    three: false,
  };

  // Slider Config
  courseSlider = {
    slidesPerView: 1,
    spaceBetween: 32,
    grabCursor: false,
    autoHeight: true,
    speed: 1000,
    navigation: {
      prevEl: '.nextEl',
      nextEl: '.prevEl',
    },
    breakpoints: {
      '640': {
        slidesPerView: 2,
      },
      '1024': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
      '1620': {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  };
  categorySlider: SwiperOptions = {
    spaceBetween: 16,
    slidesPerView: 'auto',
    speed: 1000,
    grabCursor: true,
    navigation: {
      prevEl: '.prevCtg',
      nextEl: '.nextCtg',
    },
  };

  constructor() {
    this.coursesData = coursesData.results;
    this.topCourses = topData.results;
    this.categoriesList = categoryData;
    this.topCategoriesList = topcategoryData;
  }

  ngOnInit(): void {}
}
