<div class="tab-items relative">
  <div class="flex justify-between h-full -ml-[16px] -mr-[16px]">
    <button [class]="'prevCtg'" class="block md:hidden nav btn btn-transparent bg-white pr-0">
      <svg-icon class="btn-icon" src="@assets/images/icons/chevron-left.svg"></svg-icon>
    </button>
    <swiper class="w-full" [config]="categorySlider">
      <ng-template swiperSlide class="!w-auto">
        <!-- <li *ngFor="let item of tabItems; let i = index">
      <a
        [routerLink]="getLink(item.route)"
        [id]="'tabid_' + item.id"
        class="inline-block border-b-2 px-6 py-3"
        [ngClass]="activeTab === item.route ? 'border-slate-900' : 'border-transparent'"
        >{{ item.title }}</a
      >
    </li> -->
        <ul *ngIf="isPreviewCourse" class="flex flex-wrap text-center text-sm font-medium text-slate-500">
          <li class="block lg:hidden">
            <a [routerLink]="getLink(tabItems[0].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[0].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[0].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[0].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[1].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[1].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[1].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[1].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[2].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[2].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[2].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[2].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[3].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[3].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[3].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[3].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[4].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[4].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[4].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[4].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[5].route)" [queryParams]="{preview: true}" [id]="'tabid_' + tabItems[5].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[5].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[5].title }}</a>
          </li>
        </ul>
        <ul *ngIf="!isPreviewCourse" class="flex flex-wrap text-center text-sm font-medium text-slate-500">
          <li class="block lg:hidden">
            <a [routerLink]="getLink(tabItems[0].route)" [id]="'tabid_' + tabItems[0].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[0].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[0].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[1].route)" [id]="'tabid_' + tabItems[1].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[1].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[1].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[2].route)" [id]="'tabid_' + tabItems[2].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[2].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[2].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[3].route)" [id]="'tabid_' + tabItems[3].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[3].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[3].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[4].route)" [id]="'tabid_' + tabItems[4].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[4].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[4].title }}</a>
          </li>
          <li>
            <a [routerLink]="getLink(tabItems[5].route)" [id]="'tabid_' + tabItems[5].id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="activeTab === tabItems[5].route ? 'border-slate-900' : 'border-transparent'">
              {{ tabItems[5].title }}</a>
          </li>
        </ul>
      </ng-template>
    </swiper>
    <button [class]="'nextCtg'" class="block md:hidden nav btn btn-transparent bg-white ps-0">
      <svg-icon class="btn-icon" src="@assets/images/icons/chevron-right.svg"></svg-icon>
    </button>
  </div>
</div>
