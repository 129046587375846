import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TopicV2 } from '../../models';

export const topicAdapter: EntityAdapter<TopicV2> = createEntityAdapter<TopicV2>({
  selectId: (topic) => topic.id ?? 0,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialTopicState = topicAdapter.getInitialState();

export type TopicEntityState = EntityState<TopicV2>;
