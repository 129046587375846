<div class="container">
  <h1>Routes</h1>
  <ul>
    <ng-container *ngFor="let route of routes">
      <li>
        <a [routerLink]="route">{{ route }}</a>
      </li>
    </ng-container>
  </ul>
</div>
