import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { dragdropState } from 'thkee-common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-FileDefaultInfo',
  template: `
    <!-- Upload in progress -->
    <div class="inline-flex w-full items-center gap-3" *ngIf="uploadState.viewState === 'upload-progress'">
      <div class="rounded-sm bg-slate-200 p-[3px]">
        <svg-icon src="@assets/images/icons/file.svg" class="block w-3"></svg-icon>
      </div>
      <div class="ar:text-right grow truncate font-medium">{{ uploadState.fileName }}</div>
      <div class="progress max-w-[200px] grow">
        <div class="progress-container">
          <div class="progress" [style.width.%]="uploadState.currentProgress"></div>
        </div>
      </div>
      <div class="percent text-xs">{{ uploadState.currentProgress }}%</div>
      <div class="abort cursor-pointer hover:text-red-600" (click)="emitClickEvent('abort')">
        <svg-icon src="@assets/images/icons/x-mark.svg" class="block w-5"></svg-icon>
      </div>
    </div>
    <!-- Uploaded Preview or Success -->
    <div
      *ngIf="uploadState.viewState === 'complete' || uploadState.viewState === 'upload-success'"
      class="inline-flex w-full items-center gap-3"
    >
      <div class="rounded-sm bg-slate-200 p-[3px]">
        <svg-icon src="@assets/images/icons/file.svg" class="block w-3"></svg-icon>
      </div>
      <div class="ar:text-right grow truncate font-medium">{{ uploadState.fileName }}</div>
    </div>
  `,
})
export class FormlyFileDefaultInfoComponent implements OnInit {
  @Input() field!: FormlyFieldConfig;
  @Input() uploadState: dragdropState = {
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  };
  @Output() clickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
  }
}
