import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash';
import { Logger } from '../../services';
const log = new Logger('Formly Validator');

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  return !control.value ||
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      control.value
    )
    ? null
    : { email: true };
}

export function emailValidatorMessage(error: any, field: FormlyFieldConfig) {
  const fieldValue = field.formControl?.value; // Use optional chaining operator
  return `"${fieldValue}" is not a valid Email Address`;
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `Should have at least ${field.props?.['minLength']} characters`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `Should not exceed ${field.props?.['maxLength']} characters`;
}

export function minWordsValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  return !control.value || control.value.split(' ').length >= field.props?.['minWords'] ? null : { minWords: true };
}
export function minWordsValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Should have at least ${field.props?.['minWords']} words`;
}

export function maxWordsValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  return !control.value || control.value.split(' ').length <= field.props?.['maxWords'] ? null : { maxWords: true };
}
export function maxWordsValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Should not exceed ${field.props?.['maxWords']} words`;
}

export function urlValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const validUrl =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
      control.value
    );
  return validUrl ? null : { thkeeUrl: true };
}
export function urlValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Should enter a valid link`;
}

export function confirmationValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Should not exceed ${field.props?.['maxWords']} words`;
}

export function confirmationValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  const targetField = field.props && (field.props['thkeeConfirmation'] as { fields: string[]; value: string });
  if (targetField?.fields?.length !== 2) {
    log.debug('You have to defined 2 fields name when using this validators');
    return null;
  }

  const compareValue = targetField.value ?? get(control.value, targetField.fields[0]);

  const isSame = get(control.value, targetField.fields[1]) === compareValue;

  return isSame ? null : { thkeeConfirmation: true };
}

export function noInitialSpaceValidatorMessage(field: FormlyFieldConfig) {
  return `Text cannot start with a space or newline.`;
}

export function noInitialSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^(?! |\\n)[\\s\\S]*$/.test(control.value);
    return isValid ? null : { noInitialSpace: true };
  };
}
