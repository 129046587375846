import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface PageItems {
  totalPages: number;
  currentPage?: number;
  pageSize?: number;
  startPage?: number;
  endPage?: number;
  pages?: number[];
  totalRecords?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  private pageItems: PageItems = {
    totalPages: 0,
  };
  private _pageItems$ = new BehaviorSubject<PageItems>(this.pageItems);

  constructor() {}

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10): PageItems {
    // Calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // Ensure the current page is within the valid range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    // Calculate start and end page numbers for display in the pagination control
    const startPage: number = Math.max(1, currentPage - 2);
    const endPage: number = Math.min(totalPages, currentPage + 2);

    // Generate an array of page numbers
    const pages: number[] = Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage);

    // Return object with all pager properties required by the view
    let items = {
      currentPage: currentPage,
      totalPages: totalPages,
      pageSize: pageSize,
      startPage: startPage,
      endPage: endPage,
      pages: pages,
      totalRecords: totalItems,
    };
    this.pageItems = items;
    this._pageItems$.next(this.pageItems);
    return items;
  }

  getPageItems(): Observable<PageItems> {
    return this._pageItems$.asObservable();
  }
}
