<div class="my-auto">
  <div class="flex flex-col gap-2 pb-4">
    <div class="heading_part">
      <h3 class="mb-2 font-medium" i18n>Sign up</h3>
      <p class="text-slate-500" i18n>Let's start creating your Thkee account</p>
    </div>
  </div>

  <app-messages [messages]="messages" class="mb-4" *ngIf="messages"></app-messages>

  <form [formGroup]="form" (submit)="onSignup()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <div class="flex flex-col gap-y-3 pt-5">
      <button [thkLibProcessing]="isSubmitting" type="submit" class="btn btn-primary w-full" i18n>Create an
        account</button>
    </div>
    <div class="pt-4 text-sm font-normal text-slate-500" i18n>
      By signing up, you agree to our <span class="font-medium text-slate-700"><a href="https://thkee.com/terms-of-use/"
          target="_blank">Terms of Service</a></span>
      and <span class="text-sm font-medium leading-snug text-slate-700"><a href="https://thkee.com/privacy-policy/"
          target="_blank">Privacy Policy</a></span>.
    </div>
  </form>
</div>