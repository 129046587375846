import { createFeature, createReducer, on } from '@ngrx/store';
import { InstructorReviewsActions } from './reviews.actions';
import { initialReviewsState } from './reviews.model';

export const instructorReviewsReducer = createReducer(
  initialReviewsState,
  // All Courses Reviews
  on(InstructorReviewsActions.loadAllCoursesReviewsSuccess, (state, data) => {
    return {
      ...state,
      allCoursesReviews: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),

  // One Courses Reviews
  on(InstructorReviewsActions.loadOneCoursesReviewsSuccess, (state, data) => {
    return {
      ...state,
      oneCoursesReviews: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  })
);

export const instructorReviewsFeature = createFeature({
  name: 'instructorReviews',
  reducer: instructorReviewsReducer,
});
