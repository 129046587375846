import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thkLibCourseFilter',
})
export class CourseFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter((item) => {
      // Check if the item's title or any subsection title contains the searchText
      return (
        this.itemContainsText(item, searchText) ||
        (item.subsections && item.subsections.some((sub: any) => this.itemContainsText(sub, searchText)))
      );
    });
  }

  private itemContainsText(item: any, searchText: string): boolean {
    if (item.title && item.title.toLowerCase().includes(searchText)) {
      return true;
    }

    if (item.lecture && item.lecture.title && item.lecture.title.toLowerCase().includes(searchText)) {
      return true;
    }

    if (item.quiz && item.quiz.title && item.quiz.title.toLowerCase().includes(searchText)) {
      return true;
    }

    if (item.assignment && item.assignment.title && item.assignment.title.toLowerCase().includes(searchText)) {
      return true;
    }

    return false;
  }
}
