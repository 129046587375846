import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CourseQnA, PartialEntity } from '../../models';

export const courseQnAAdapter: EntityAdapter<PartialEntity<CourseQnA>> = createEntityAdapter<PartialEntity<CourseQnA>>({
  selectId: (e) => e.id,
});

export const initialcourseQnAState = courseQnAAdapter.getInitialState();

export type CourseQnAEntityState = EntityState<PartialEntity<CourseQnA>>;
