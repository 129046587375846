import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, StudentAssignmentV2 } from '../../models';

export const studentAssignmentAdapter: EntityAdapter<PartialEntity<StudentAssignmentV2>> = createEntityAdapter<
  PartialEntity<StudentAssignmentV2>
>({
  selectId: (e) => e.id,
});

export const initialStudentAssignmentState = studentAssignmentAdapter.getInitialState();

export type StudentAssignmentEntityState = EntityState<PartialEntity<StudentAssignmentV2>>;
