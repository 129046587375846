import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, StudentQuizV2 } from '../../models';

export const studentQuizAdapter: EntityAdapter<PartialEntity<StudentQuizV2>> = createEntityAdapter<
  PartialEntity<StudentQuizV2>
>({
  selectId: (e) => e.id,
});

export const initialStudentQuizState = studentQuizAdapter.getInitialState();

export type StudentQuizEntityState = EntityState<PartialEntity<StudentQuizV2>>;
