import { createFeature, createReducer, on } from '@ngrx/store';
import { TopicActions } from './topic.actions';
import { initialTopicState, topicAdapter } from './topic.entity';

export const topicReducer = createReducer(
  initialTopicState,
  on(TopicActions.loadTopicsSuccess, (state, { topics }) => topicAdapter.upsertMany(topics, state))
);

export const topicFeature = createFeature({
  name: 'topic',
  reducer: topicReducer,
});
