import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface CheckboxProps extends FormlyFieldProps {
  formCheck?: 'default' | 'inline' | 'switch' | 'inline-switch' | 'nolabel';
  indeterminate?: boolean;
}

export interface FormlyToggleFieldConfig extends FormlyFieldConfig<CheckboxProps> {
  type: 'checkbox' | Type<FormlyFieldToggle>;
}

@Component({
  selector: 'formly-field-checkbox',
  template: `
    <div
      class="input-toggle"
      [ngClass]="{
        'form-check-inline': props.formCheck === 'inline' || props.formCheck === 'inline-switch',
        'form-switch': props.formCheck === 'switch' || props.formCheck === 'inline-switch'
      }"
    >
      <!-- <input
        type="checkbox"
        [class.is-invalid]="showError"
        class="input-check"
        [class.position-static]="props.formCheck === 'nolabel'"
        [indeterminate]="props.indeterminate && formControl.value == null"
        [formControl]="formControl"
        [formlyAttributes]="field"
      /> -->

      <label class="relative inline-flex cursor-pointer items-center">
        <!-- <input type="checkbox" value="" class="peer sr-only" /> -->
        <input
          type="checkbox"
          [class.is-invalid]="showError"
          class="peer sr-only"
          [class.position-static]="props.formCheck === 'nolabel'"
          [indeterminate]="props.indeterminate && formControl.value == null"
          [formControl]="formControl"
          [formlyAttributes]="field"
        />
        <div
          class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
        ></div>
        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          <ng-container *ngIf="props.label">{{ props.label }}</ng-container>
          <ng-container *ngIf="!props.label">
            <ng-container *ngIf="formControl.value" i18n>On</ng-container>
            <ng-container *ngIf="!formControl.value" i18n>Off</ng-container>
          </ng-container>
        </span>
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldToggle extends FieldType<FieldTypeConfig<CheckboxProps>> {
  override defaultOptions = {
    props: {
      indeterminate: true,
      hideLabel: true,
      formCheck: 'default' as const,
    },
  };
}
