import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { CartAPIService } from 'thkee-common';
import { CartService } from './cart-store.service';
import { CartActions } from './cart.actions';

@Injectable()
export class CartEffects {
  constructor(
    private actions$: Actions,
    private cartService: CartService,
    private cartAPIService: CartAPIService,
    private router: Router,
    private store: Store
  ) {}

  initUserCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.initUserCart),
      switchMap(() =>
        this.cartService.initUserCart().pipe(
          map((cartItems) => {
            this.store.dispatch(CartActions.loadCartItems());
            return CartActions.initUserCartSuccess();
          }),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        )
      )
    )
  );

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCart),
      switchMap(() =>
        this.cartService.loadCart().pipe(
          map((cartItems) => CartActions.loadCartSuccess({ cartItems })),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        )
      )
    )
  );

  addToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addToCart),
      switchMap((courseData) =>
        this.cartService.addToCart(courseData).pipe(
          map((cartItems) => {
            return CartActions.addToCartSuccess({ cartItems });
          }),
          catchError((error) => {
            return of(CartActions.loadCartFail({ error }));
          })
        )
      )
    )
  );

  removeToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.removeToCart),
      switchMap(({ id }) =>
        this.cartService.removeToCart(id).pipe(
          map((cartItems) => CartActions.removeToCartSuccess({ cartItems })),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        )
      )
    )
  );

  applyCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.applyCredit),
      switchMap(({ credit }) =>
        this.cartService.applyCredit(credit).pipe(
          map((cartItems) => CartActions.applyCreditSuccess({ cartItems })),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        )
      )
    )
  );

  applyCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.applyCoupon),
      switchMap(({ code }) =>
        this.cartService.applyCoupon(code).pipe(
          map((cartItems) => CartActions.applyCouponSuccess({ cartItems })),
          catchError((error) => of(CartActions.applyCouponFail({ error })))
        )
      )
    )
  );

  computeAndDispatchCart$ = (sourceAction: any, successAction: any) =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(sourceAction),
        switchMap((data) => {
          if (data.cartItems) {
            this.cartService.setCart(data.cartItems);
          }
          return this.cartService.computeTotal().pipe(
            map((cartItems) => successAction({ cartItems })),
            catchError((error) => of(CartActions.computeCartFail({ error })))
          );
        })
      )
    );

  // loadCartSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(CartActions.loadCartSuccess),
  //       tap((data) => {
  //         // if (data.cartItems.coursesData) {
  //         //   this.cartService.setCart(data.cartItems);
  //         // }
  //       })
  //     ),
  //   { dispatch: false }
  // );

  loadCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartItems),
      switchMap(() =>
        this.cartService.loadItems().pipe(
          map((cartItems) => {
            if (this.router.url === '/checkout' || this.router.url === '/en/checkout') {
              if (cartItems.length === 0) {
                window.location.replace('./checkout?status=completed');
              }
            }
            return CartActions.loadCartItemsSuccess({ cartItems });
          }),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        )
      )
    )
  );

  loadCartItemsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartItemsSuccess),
      switchMap(({ cartItems }) => {
        if (cartItems.length > 0) {
          // Update the cookie State
          const coursesData = cartItems.map((course: any) => {
            let author = course.author;
            if (course.user) {
              author = typeof course.user === 'number' ? 'N/A' : `${course.user.first_name} ${course.user.last_name}`;
            }
            return {
              id: course.id,
              course_id: course.course_id,
              name: course.title,
              code: course.code,
              price: course.price ?? 0,
              sale: 0,
              image_url: course.image_url,
              sale_duration: '',
              author: author,
              rate: course.total_rate || '0',
              review: course.total_reviews || '0',
              star: Math.round(course.total_rate) || 0,
              favorite: course.favorite,
            };
          });
          const newCartD = {
            ...this.cartService.cart,
            coursesData,
          };
          this.cartService.setCart(newCartD);
          const codeIds = cartItems.map((item: any) => (item.code ? item.code : item.id));
          if (codeIds) this.store.dispatch(CartActions.loadCartPrice({ ids: codeIds }));
          return of();
          return this.cartService.getCoursePrices(codeIds).pipe(
            map((data) => CartActions.loadCartPriceSuccess({ data })),
            catchError((error) => of(CartActions.loadCartFail({ error })))
          );
        } else {
          return of(); // Return an empty observable if cartItems is empty
        }
      })
    )
  );

  loadCartPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartPrice),
      switchMap(({ ids }) => {
        return this.cartService.getCoursePrices(ids).pipe(
          map((data) => CartActions.loadCartPriceSuccess({ data })),
          catchError((error) => of(CartActions.loadCartFail({ error })))
        );
      })
    )
  );

  loadCartPriceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartPriceSuccess),
      switchMap(({ data }) => {
        // Update the cookie State
        if (data) {
          const coursesData = this.cartService.cart.coursesData.map((course: any) => ({
            ...course,
            price: data[course.code]?.price || 0,
            price_string: data[course.code]?.price_string || '',
            currency: data[course.code]?.currency || '',
            sale: 0,
            sale_duration: '', //TODO Update
          }));
          const newCartD = {
            ...this.cartService.cart,
            coursesData,
          };
          this.cartService.setCart(newCartD);
        }
        return this.cartService.computeTotal().pipe(
          map((cartItems) => CartActions.computeCartSuccess({ cartItems })),
          catchError((error) => of(CartActions.computeCartFail({ error })))
        );
        return of({});
      })
    )
  );

  // loadCartPriceSuccess$ = this.computeAndDispatchCart$(CartActions.loadCartPriceSuccess, CartActions.computeCart);

  // Call Compute as function dispatch
  computeCart$ = this.computeAndDispatchCart$(CartActions.computeCart, CartActions.computeCartSuccess);

  // loadCartSuccess$ = this.computeAndDispatchCart$(CartActions.loadCartSuccess, CartActions.computeCart);

  addToCartSuccess$ = this.computeAndDispatchCart$(CartActions.addToCartSuccess, CartActions.computeCart);

  removeToCartSuccess$ = this.computeAndDispatchCart$(CartActions.removeToCartSuccess, CartActions.computeCart);

  applyCreditSuccess$ = this.computeAndDispatchCart$(CartActions.applyCreditSuccess, CartActions.computeCart);

  applyCouponSuccess$ = this.computeAndDispatchCart$(CartActions.applyCouponSuccess, CartActions.computeCart);

  // loadCartItemsSuccess$ = this.computeAndDispatchCart$(CartActions.loadCartItemsSuccess, CartActions.computeCart);
}
