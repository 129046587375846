import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared';
import * as _ from 'lodash';
import { Observable, Subscription, firstValueFrom, map, of, take } from 'rxjs';
import {
  CourseDataV2,
  CourseStatus,
  CourseV2,
  InstructorService,
  Logger,
  ModalService,
  ProjectService,
  RouterStoreService,
  neededForReviewMessage,
  selectCourse,
} from 'thkee-common';

const log = new Logger('CourseSidebarComponent');

@Component({
  selector: 'app-course-sidebar',
  templateUrl: './course-sidebar.component.html',
  styleUrls: ['./course-sidebar.component.scss'],
})
export class CourseSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('submitForReviewMessagesModal')
  submitForReviewMessagesModal!: ModalComponent;
  @ViewChild('submitForReviewConfirmModal')
  submitForReviewConfirmModal!: ModalComponent;

  activeRoute: string = '';
  courseId$: Observable<string>;
  courseData?: CourseDataV2;
  course$!: Observable<CourseV2>;
  isSubmittedForReview: boolean = false;
  private subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly routerStoreService: RouterStoreService,
    private readonly router: Router,
    private readonly instructorService: InstructorService,
    private readonly projectService: ProjectService,
    private readonly modalService: ModalService
  ) {
    this.courseId$ = this.routerStoreService.getParam('courseId');
    this.routerStoreService.getUrl().subscribe((data) => {
      this.activeRoute = data;
    });
  }

  ngOnInit(): void {
    this.course$ = this.store.select(selectCourse);
  }

  isActive(route = 'intended-learners') {
    return this.activeRoute.endsWith(route) ? true : false;
  }

  isActiveChild(route = '/', slice: number) {
    const parts = this.activeRoute.split('/');
    const firstTwoParts = parts.slice(0, slice).join('/');
    return firstTwoParts.endsWith(route) ? true : false;
  }

  confirmSubmitForReview() {
    this.isSubmittedForReview = true;
    log.debug('confirmSubmitForReview');
    firstValueFrom(this.courseId$).then((courseId) => {
      this.instructorService.getCourseData(courseId).subscribe((data) => {
        this.courseData = data;

        const project = this.courseData.course_detail.project;

        if (!project.published_course || (project.published_course && project.has_substantial_changes)) {
          if (this.courseData?.course_detail.is_valid_for_review) {
            this.submitForReviewConfirmModal.open();
          } else {
            this.isSubmittedForReview = false;
            this.submitForReviewMessagesModal.open();
          }
        } else if (project.published_course && !project.has_substantial_changes) {
          this.isSubmittedForReview = false;
          this.modalService.confirm({
            title: $localize`Publish Course`,
            message: $localize`Course have no major changes and can be pulished directly. Do you want to publish this course?`,
            onConfirm: () => {
              this.projectService.toStateReviewOrPublished(project.id);
            },
          });
        } else {
          this.isSubmittedForReview = false;
          log.error('confirmSubmitForReview', 'Invalid project state');
          this.modalService.message({
            title: $localize`Error`,
            message: $localize`Invalid project state`,
          });
        }
      });
    });
  }

  submitForReview() {
    this.projectService.toStateInReview(this.courseData?.course.project ?? '').subscribe((data) => {
      log.debug('submitForReview', data);
      this.isSubmittedForReview = false;
      this.router.navigate([`/instructor/courses`]);
      // this.router.navigate([`/instructor/course/${data.project.unpublished_course}/details`]);
      this.modalService.message({
        title: $localize`Success`,
        message: $localize`Course submitted for review`,
      });
    });
  }

  submitReviewWithdraw() {
    this.subscriptions.add(
      this.course$?.pipe(take(1)).subscribe((course) => {
        this.modalService.confirm({
          title: $localize`Withdraw Submission`,
          message: $localize`Do you want to withdraw this submission?`,
          onConfirm: () => {
            this.submitForWithdrawConfirmed(course);
          },
        });
      })
    );
  }

  private submitForWithdrawConfirmed(course: CourseV2) {
    this.isSubmittedForReview = true;
    this.projectService.withdrawSubmission(course.project).subscribe((data: any) => {
      this.isSubmittedForReview = false;
      this.router.navigate([`/instructor/courses`]);
      this.modalService.message({
        title: $localize`Success`,
        message: $localize`Course review withdraw success!`,
      });
    });
  }

  get intendedLearnersErrors(): string[] {
    return _.keys(this.courseData?.course_detail.needed_for_review?.intended_learners)
      .filter((key) => this.courseData?.course_detail.needed_for_review?.intended_learners[key])
      .map((key) => neededForReviewMessage.intended_learners[key]);
  }

  get detailsErrors(): string[] {
    return _.keys(this.courseData?.course_detail.needed_for_review?.details)
      .filter((key) => this.courseData?.course_detail.needed_for_review?.details[key])
      .map((key) => neededForReviewMessage.details[key]);
  }

  get curriculumErrors(): string[] {
    return _.keys(this.courseData?.course_detail.needed_for_review?.curriculum)
      .filter((key) => this.courseData?.course_detail.needed_for_review?.curriculum[key])
      .map((key) => neededForReviewMessage.curriculum[key]);
  }

  get pricingErrors(): string[] {
    return _.keys(this.courseData?.course_detail.needed_for_review?.pricing)
      .filter((key) => this.courseData?.course_detail.needed_for_review?.pricing[key])
      .map((key) => neededForReviewMessage.pricing[key]);
  }

  get annotationErrors(): string[] {
    return _.keys(this.courseData?.course_detail.needed_for_review?.annotations)
      .filter((key) => this.courseData?.course_detail.needed_for_review?.annotations[key] === true)
      .map((key) => neededForReviewMessage.annotations[key]);
  }

  get canSubmitReview$(): Observable<boolean> {
    return (
      this.course$?.pipe(
        map(
          (course) =>
            !!course.status &&
            [CourseStatus.DRAFT, CourseStatus.REJECTED, CourseStatus.PUBLISHED].includes(course.status)
        )
      ) ?? of(false)
    );
  }

  get canWithdrawReview$(): Observable<boolean> {
    return (
      this.course$?.pipe(map((course) => !!course.status && [CourseStatus.IN_REVIEW].includes(course.status))) ??
      of(false)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
