import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LEARNINGLAB_TAB, TabItem } from '@shared/models/tabs-constant';
import * as _ from 'lodash';
import { SwiperOptions } from 'swiper';
import { RouterStoreService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-learninglab-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class LearninglabTabMenuComponent implements OnInit {
  @Input() tabItems: TabItem[] = LEARNINGLAB_TAB;
  params: any = {};
  activeTab: string = 'overview';
  isPreviewCourse: boolean = false;

  categorySlider: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 'auto',
    speed: 1000,
    grabCursor: false,
    navigation: {
      prevEl: '.prevCtg',
      nextEl: '.nextCtg',
    },
  };

  constructor(
    private routerStore: RouterStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      this.activeTab = params['tab'] ? params['tab'] : 'overview';
      if (this.activeTab) {
        //TODO: check if current tab is exist else set overview or redirect to 404
        let matchPatch = _.find(LEARNINGLAB_TAB, { route: this.activeTab });
        if (!matchPatch) {
          this.activeTab = 'overview';
        }
      }
    });
    this.innerWidth = window.innerWidth;
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params['preview'] === 'true') {
        this.isPreviewCourse = true;
      } else {
        this.isPreviewCourse = false;
      }
    });
  }

  getLink(tabRoute: string = '') {
    let url = ['/learning-lab', this.params.courseId];
    if (tabRoute) {
      url.push(tabRoute);
    }
    if (this.params.section) {
      url.push(this.params.section);
    }
    if (this.params.subsection) {
      url.push(this.params.subsection);
    }
    if (this.params.overviewcode) {
      url.push(this.params.overviewcode);
    }

    return url;
  }

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1024 && this.activeTab === 'course-content') {
      this.router.navigate(this.getLink('overview'));
    }
  }
}
