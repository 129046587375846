import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

// All types
export type Status = 'Draft' | 'Published' | 'Unlisted' | 'Pending' | 'Ended' | 'In Review' | 'Rejected' | 'Refund';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private http: HttpClient) {}

  // Check Object type and Expacted Output @true/false
  isObjEmpty(obj = {}) {
    return Object.values(obj).length === 0 && obj.constructor === Object;
  }

  // Caching service
  private cache: { [key: string]: { data: any; expiry: number } } = {};
  get<T>(url: string, options?: { forceRefresh?: boolean }): Observable<T> {
    if (!options?.forceRefresh && this.cache[url] && this.cache[url].expiry > Date.now()) {
      return of(this.cache[url].data);
    }
    return this.http.get<T>(url).pipe(
      tap((data) => {
        this.cache[url] = { data, expiry: Date.now() + 60000 * 5 }; // Cache expiry in milliseconds (e.g., 1 minute = 60000ms)
      })
    );
  }

  /**
   * Converts seconds to an object containing hours and minutes.
   * @param seconds - The number of seconds to be converted.
   * @returns An object with hours and minutes.
   */
  secondsToHoursAndMinutes(seconds: number): { hours: number; minutes: number } {
    if (seconds < 0 || isNaN(seconds)) {
      return { hours: NaN, minutes: NaN }; // or throw an error, depending on your use case
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes };
  }

  /**
   * Converts query object to an CSV string as quesry params.
   * @param queryObject - The key value pare object to be converted.
   * @returns A router query params.
   */
  convertQueryParams(obj: Record<string, Record<string, boolean>>): Record<string, string> {
    const result: Record<string, string> = {};
    for (const key in obj) {
      result[key] = Object.keys(obj[key])
        .filter((innerKey) => obj[key][innerKey])
        .join(',');
    }
    return result;
  }

  /**
   * Input a status
   * @returns Return color class based on the status.
   */
  getStatusColor(status: Status = 'Draft') {
    const colorMatch: Record<Status, string> = {
      Draft: 'blue',
      Published: 'green',
      Unlisted: 'red',
      Pending: 'yellow',
      Ended: 'gray',
      'In Review': 'yellow',
      Rejected: 'red',
      Refund: 'yellow',
    };
    return colorMatch[status];
  }
  isStatus(status: string): status is Status {
    return ['Draft', 'Published', 'Unlisted', 'Pending', 'Ended', 'In Review', 'Rejected', 'Refund'].includes(status);
  }
}
