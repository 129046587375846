import { createFeature, createReducer, on } from '@ngrx/store';
import { User } from 'thkee-common';
import { AuthActions } from './auth.actions';

export type AuthState = {
  user: User | undefined,
  isAuthenticated: boolean | undefined;
  cart:
    | {
        id?: number;
        items?: string[];
      }
    | undefined;
  courses: string[];
};
export const initialAuthState: AuthState = {
  isAuthenticated: undefined,
  user: undefined,
  cart: {},
  courses: [],
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, state => ({
    ...state,
    isAuthenticated: true,
  })),
  on(AuthActions.userLoaded, (state, { user }) => ({
    ...state,
    isAuthenticated: true,
    userId: user.id,
    user: user,
    cart: user.cart,
    courses: user.stats?.classes ? user.stats?.classes : [],
  })),
  on(AuthActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    user: { ...state.user!, ...user },
  })),
  on(AuthActions.logout, () => ({ ...initialAuthState, user: undefined, isAuthenticated: false })),
  on(AuthActions.useAsGuest, (state) => ({ ...state, user: undefined, isAuthenticated: false })),
  on(AuthActions.signupInstructorSuccess, (state) => ({
    ...state,
    user: { ...state.user!, is_instructor: true },
  }))
);

export const authFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
});
