<div class="carousel relative">
  <div>
    <swiper [config]="defaultSwiperConfig" class="!px-4 !py-5">
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card [isFeatured]="true"></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card [isFeatured]="true"></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
      <ng-template swiperSlide><app-course-card></app-course-card></ng-template>
    </swiper>
  </div>
  <app-common-carouselnav></app-common-carouselnav>
</div>
