import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, distinctUntilChanged, filter, map, of, startWith, take } from 'rxjs';
import {
  AnnotationDetail,
  ContentTypeModels,
  CourseActions,
  RouterStoreService,
  isDefined,
  selectSelectedAnnotation,
} from 'thkee-common';

type FeedbackType = 'danger' | 'warning' | 'normal';

@UntilDestroy()
@Component({
  selector: 'app-common-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  // NOTE: will pull data from state if this is not supplied
  @Input() annotation?: AnnotationDetail;

  annotation$!: Observable<AnnotationDetail>;
  feedbackType$!: Observable<FeedbackType>;
  courseId: string = '';
  private courseId$ = this.routerStore.getParam('courseId');

  constructor(private store: Store, private router: Router, private routerStore: RouterStoreService) {}

  ngOnInit(): void {
    if (!this.annotation) {
      this.annotation$ = this.store.select(selectSelectedAnnotation);
    } else {
      this.annotation$ = of(this.annotation);
    }

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    this.feedbackType$ = this.annotation$.pipe(
      filter(isDefined),
      map((annotation) => (annotation.required_action ? 'danger' : 'warning')),
      distinctUntilChanged(),
      startWith('normal' as FeedbackType)
    );
  }

  formatTitle(fieldKey: string): string {
    return _.startCase(fieldKey.replace(/_/g, ' ').toLowerCase());
  }

  onResolve() {
    this.annotation$.pipe(take(1)).subscribe((annotation) => {
      this.store.dispatch(CourseActions.resolveFeedback({ annotation }));
    });
  }

  onUnresolve() {
    this.annotation$.pipe(take(1)).subscribe((annotation) => {
      this.store.dispatch(CourseActions.unresolveFeedback({ annotation }));
    });
  }

  gotoFeedback(annotation: any) {
    console.log('annotation--', this.courseId, annotation);
    const modelMappings: Record<ContentTypeModels, string> = {
      [ContentTypeModels.COURSE]: 'details',
      [ContentTypeModels.OBJECTIVE]: 'intended-learners',
      [ContentTypeModels.LEARNER]: 'intended-learners',
      [ContentTypeModels.REQUIREMENT]: 'intended-learners',
      [ContentTypeModels.SECTION]: 'curriculum',
      [ContentTypeModels.SUBSECTION]: 'curriculum',
      [ContentTypeModels.QUIZ_QUESTION]: 'curriculum',
      [ContentTypeModels.ASSIGNMENT_QUESTION]: 'curriculum',
      [ContentTypeModels.LECTURE]: 'curriculum',
      [ContentTypeModels.QUIZ]: 'curriculum',
      [ContentTypeModels.ASSIGNMENT]: 'curriculum',
    };
    const model = modelMappings[annotation.model] || 'details';
    if (annotation && this.courseId) {
      this.router.navigate(['/instructor/course', this.courseId, model], {
        fragment: `feedback-${annotation.object_id}`,
      });
    }
  }
}
