import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, RequirementV2 } from '../../models';

export const requirementAdapter: EntityAdapter<PartialEntity<RequirementV2>> = createEntityAdapter<
  PartialEntity<RequirementV2>
>({
  selectId: (e) => e.id,
});

export const initialRequirementState = requirementAdapter.getInitialState();

export type RequirementEntityState = EntityState<PartialEntity<RequirementV2>>;
