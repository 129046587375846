<!-- Course image thumbnail -->
<section *ngIf="type === 'courseCover'">
   <div class="thumb-wrap flex items-center justify-center overflow-hidden rounded-lg bg-slate-600"
      [ngClass]="customClass">
      <img *ngIf="imageUrl" class="w-full" [src]="imageUrl" alt="" />
      <img *ngIf="!imageUrl" class="w-full" src="https://placehold.co/400x300" alt="" />
   </div>
</section>

<!-- User avatar -->
<section *ngIf="type === 'userAvatar'">
   <div class="thumb-wrap flex items-center justify-center overflow-hidden rounded-lg bg-slate-600"
      [ngClass]="customClass">
      <img *ngIf="imageUrl" class="w-full" [src]="imageUrl" alt="" />
      <img *ngIf="!imageUrl" class="w-full" src="https://placehold.co/44x44" alt="" />
   </div>
</section>

<!-- Clipboard button -->
<section *ngIf="type === 'clipboardButton'">
   <button tooltip="Copy Item" (click)="clipboardCopy()">
      <svg-icon src="@assets/images/icons/clipboard-document-check.svg" class="block w-6 h-6 text-neutral-600">
      </svg-icon>
   </button>
</section>