import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectRequirementsState } from './course.selectors';

export const selectRequirements = createSelector(selectRequirementsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectRequirement = (requirementId: string) =>
  createSelector(selectRequirementsState, (state) => state.entities[requirementId]);

export const selectRequirementEntities = createSelector(selectRequirementsState, (state) => state.entities);
