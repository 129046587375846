import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CourseContentActions, CourseSubSection, CoursesContent, MyClassService, selectCourseContent } from '@shared';
import { MyClassAssignmentAnswer } from '@shared/store/learning-lab';
import * as _ from 'lodash';
import { Observable, Subscription, combineLatest, filter, take } from 'rxjs';
import { CourseQuizAnswer, CourseQuizAnswerPayload, Logger, RouterStoreService } from 'thkee-common';
const log = new Logger('LearninglabActivityComponent');

@UntilDestroy()
@Component({
  selector: 'app-learninglab-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class LearninglabActivityComponent implements OnInit, OnDestroy {
  @Output() event = new EventEmitter<{ action: string; data: any }>();

  // TODO: Update this
  currentSection: string = '';
  activeSubsection: string = '';
  params: any;
  // currentActivity?: SubsectionDetailV2;
  currentActivity?: any;

  sectionsData: any[] = []; // TODO: Fix proper model here
  sectionData: any = {}; // TODO: fix proper model here
  prev!: string[];
  next!: string[];
  prevData!: any[];
  nextData!: any[];

  // courseDetail$!: Observable<CourseDetailV2>;
  courseDetail$!: Observable<CoursesContent>;

  completedActivity: boolean[] = [];
  activityModels: any[] = [];

  getAssignmentAnswers$!: Observable<any>;
  isLoading: boolean = true;
  private markedAsReadSubscription = new Subscription();

  constructor(
    private routerStore: RouterStoreService,
    private router: Router,
    private store: Store,
    private myClassService: MyClassService
  ) {}

  ngOnInit(): void {
    this.courseDetail$ = this.store
      .select(selectCourseContent)
      .pipe(filter((courseDetail) => (courseDetail.results?.length ?? 0) > 0));

    combineLatest([this.routerStore.getParams(), this.courseDetail$])
      .pipe(untilDestroyed(this))
      .subscribe(([params, courseDetail]) => {
        this.sectionsData = courseDetail.results ?? [];
        this.sectionData = _.groupBy(
          courseDetail.results?.flatMap((section) => section.subsections),
          (subsection) => subsection
        );

        this.params = params;
        this.currentSection = params['section'];
        this.activeSubsection = params['subsection'];
        if (!params['section']) {
          // Set Init Section
          if (this.sectionsData) {
            this.currentSection = this.sectionsData[0].chain_id;
          }
        }

        const sectionData: any = Object.values(this.sectionData)[0] || [];
        const currentData = sectionData.find((data: { chain_id: string }) => data.chain_id === this.activeSubsection);
        // Select first sub section if not selected
        if (!params['subsection'] && this.currentSection) {
          if (currentData) {
            this.activeSubsection = currentData.chain_id;
          }
        }

        this.currentActivity = currentData;
        if (!this.currentActivity) {
          this.isLoading = false;
        }

        if (this.currentActivity) {
          this.isLoading = false;
          if (this.currentActivity?.type == 'assignment') {
            this.initAssignment(this.currentActivity?.assignment);
          }
          if (this.currentActivity?.type == 'quiz') {
            this.initQuiz(this.currentActivity?.quiz);
          }
        }

        this.setPrevNext();
      });
  }

  ngOnDestroy(): void {
    this.markedAsReadSubscription.unsubscribe();
  }

  activityHandler(event: { action: string; data: Record<string, string> }) {
    this.event.emit(event);
    if (event.action == 'prev') {
      this.prevActivity();
    }
    if (['next'].includes(event.action)) {
      this.nextActivity();
    }
    // Assignment
    if (['assignment_start', 'assignment_submit'].includes(event.action)) {
      this.assignmentHandler(event);
    }
    if (['quiz_start', 'quiz_completed'].includes(event.action)) {
      this.quizHandler(event);
    }
    if (['ended'].includes(event.action)) {
      // this.nextActivity();
    }

    if (this.next.length === 0 && event.action === 'ended') {
      this.markedAsRead(true);
    }
  }

  prevActivity() {
    this.setPrevNext();
    this.router.navigate([
      'learning-lab',
      this.params.courseId,
      this.params.tab,
      ...this.prev,
      this.params.overviewcode,
    ]);
    setTimeout(() => {
      this.selectActivity(this.params['section'], this.params['subsection']);
    });
  }

  nextActivity() {
    this.setPrevNext();
    this.router.navigate([
      'learning-lab',
      this.params.courseId,
      this.params.tab,
      ...this.next,
      this.params.overviewcode,
    ]);
    this.store.dispatch(CourseContentActions.nextActivity());
    setTimeout(() => {
      this.selectActivity(this.params['section'], this.params['subsection']);
      this.markedAsRead();
    });
  }

  selectActivity(sectionId: string, subsectionId: string) {
    this.store.dispatch(
      CourseContentActions.selectActivity({
        sectionId,
        subsectionId,
      })
    );
  }

  setPrevNext() {
    const [previousItem, nextItem, nextSectionId, previousSectionId, nextData, prevData] =
      this.findPreviousAndNextItems(this.sectionsData ?? [], this.params['subsection']);
    this.prev = previousItem ? [previousSectionId, previousItem] : [];
    this.next = nextItem ? [nextSectionId, nextItem] : [];
    this.nextData = nextData;
    this.prevData = prevData;
  }

  markedAsRead(lastSection = false) {
    const subsections: any = Object.values(this.sectionData)[0];
    const index: number = subsections.findIndex((d: CourseSubSection) => d.chain_id === this.params['subsection']);
    let section: CourseSubSection;
    if (lastSection) {
      section = subsections[index];
    } else {
      section = subsections[index - 1];
    }
    if (section.type === 'quiz' || section.type === 'assignment' || section?.lecture?.type === 'article') {
      this.marked(section, index);
    }
    if (section.type === 'lecture' && section?.lecture?.type === 'video') {
      this.marked(section, index, 20);
    }
  }

  marked(data: CourseSubSection, index: number, duration: number = 0) {
    if (!data.is_completed) {
      const body = {
        position: index,
        subsection: data.id,
        is_completed: true,
        watched_duration: duration,
      };
      this.markedAsReadSubscription.add(
        this.myClassService
          .markedAsRead(body, this.params['overviewcode'])
          .pipe(take(1))
          .subscribe((res) => {
            this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
            // this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
            this.store.dispatch(CourseContentActions.loadCourseCertificate({ product_id: this.params['courseId'] }));
          })
      );
    }
  }

  findPreviousAndNextItems(data: any[], selectedItemId: string): any[] {
    let previousItem: string | null = null;
    let nextItem: string | null = null;
    let previousSectionId: string | null = null;
    let nextSectionId: string | null = null;
    let nextData: string | null = null;
    let prevData: string | null = null;

    for (let i = 0; i < data.length; i++) {
      const section = data[i];

      for (let j = 0; j < section.subsections.length; j++) {
        const subsection = section.subsections[j];

        if (subsection.chain_id === selectedItemId) {
          if (j > 0) {
            previousItem = section.subsections[j - 1].chain_id;
            prevData = section.subsections[j - 1][section.subsections[j - 1].type];
            previousSectionId = section.chain_id;
          } else if (i > 0 && data[i - 1].subsections.length > 0) {
            // If no previous item in the current subsection, check the previous section's last subsection
            const prevSubsections = data[i - 1].subsections;
            previousItem = prevSubsections[prevSubsections.length - 1].chain_id;
            prevData = prevSubsections[prevSubsections.length - 1][prevSubsections[prevSubsections.length - 1].type];
            previousSectionId = data[i - 1].chain_id;
          }

          if (j < section.subsections.length - 1) {
            nextItem = section.subsections[j + 1].chain_id;
            nextData = section.subsections[j + 1][section.subsections[j + 1].type];
            nextSectionId = section.chain_id;
          } else if (i < data.length - 1 && data[i + 1].subsections.length > 0) {
            // If no next item in the current subsection, check the next section's first subsection
            nextItem = data[i + 1].subsections[0].chain_id;
            nextData = data[i + 1].subsections[0][data[i + 1].subsections[0].type];
            nextSectionId = data[i + 1].chain_id;
          }

          return [previousItem, nextItem, nextSectionId, previousSectionId, nextData, prevData];
        }
      }
    }

    return [previousItem, nextItem, nextSectionId, previousSectionId, nextData, prevData];
  }

  // Assignment Init
  initAssignment(assignment: any) {
    if (assignment.id && assignment.questions) {
      // Add Dispatch Q Here
      // this.store.dispatch(
      //   LearningLabActions.getAssignmentAnswers({ courseId: this.params.courseId, assignId: assignment.id })
      // );
    }
  }

  // Assignement Event Handler
  assignmentHandler(event: any) {
    // if (event.action == 'assignment_start') {}
    if (event.action == 'assignment_submit') {
      if (this.next.length === 0) {
        this.markedAsRead(true);
      }
      const assignment_id = (event.data as any)?.data?.assignment?.id;
      if (assignment_id) {
        const answers = (event.data as any)?.model;
        if (answers) {
          const formatedAnswers: MyClassAssignmentAnswer[] = Object.keys(answers).map((question_id, index) => ({
            question_id,
            answer: answers[question_id],
          }));
          this.assignmentSubmit(assignment_id, formatedAnswers);
        }
      }
    }
  }

  // Assignment Submit
  assignmentSubmit(assignment_id: string, answers: MyClassAssignmentAnswer[]) {
    const formatedPayload: any = {
      content: answers,
    };
    // console.log('answers--', { courseId: this.params.courseId, assignId: assignment_id, payload: answers });
    this.store.dispatch(
      CourseContentActions.submitAssignment({
        courseId: this.params.courseId,
        assignId: assignment_id,
        payload: formatedPayload,
      })
    );
    this.nextActivity();
  }

  // Quiz Init
  initQuiz(quiz: any) {
    if (quiz.id && quiz.questions) {
      // console.log('initQuiz--', quiz);
      // Add Dispatch Q Here
      // this.store.dispatch(LearningLabActions.getQuizAnswers({ courseId: this.params.courseId, quizId: quiz.id }));
    }
  }

  // Submit / Complete Quiz
  quizHandler(event: any) {
    if (event.action == 'quiz_completed') {
      if (this.next.length === 0) {
        this.markedAsRead(true);
      }
      const quiz_id = (event.data as any)?.data?.quiz?.id;
      if (quiz_id) {
        const answers = (event.data as any)?.model;
        if (answers) {
          const formatedAnswers: CourseQuizAnswer[] = Object.keys(answers).map((question, index) => ({
            question_id: question,
            answer_id: answers[question],
          }));
          const formatedPayload: CourseQuizAnswerPayload = {
            content: formatedAnswers,
          };
          this.store.dispatch(
            CourseContentActions.submitQuiz({
              courseId: this.params.courseId,
              quizId: quiz_id,
              payload: formatedPayload,
            })
          );
          // this.nextActivity();
        }
      }
    }
  }
}
