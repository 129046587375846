<div class="instructor-shell flex">
  <div class="w-full max-w-[250px]" [class.hidden]="hideSidebar">
    <div [class.hidden]="!hasDynamic.sidebar">
      <ng-container #sidebarComponentContainer></ng-container>
    </div>
    <ng-container *ngIf="!hasDynamic.sidebar">
      <ng-container *ngTemplateOutlet="defaultSidebar"></ng-container>
    </ng-container>
  </div>
  <div class="grow">
    <div class="heading">
      <div [class.hidden]="!hasDynamic.header">
        <ng-container #headerComponentContainer></ng-container>
      </div>
      <ng-container *ngIf="!hasDynamic.header">
        <ng-container *ngTemplateOutlet="defaultHeader"></ng-container>
      </ng-container>
    </div>
    <div class="router-content h-screen">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #defaultSidebar>
  <app-instructor-sidebar></app-instructor-sidebar>
</ng-template>

<ng-template #defaultHeader>
  <app-instructor-header [fullSize]="hideSidebar"></app-instructor-header>
</ng-template>
