<ng-container
  *ngIf="{courseId: courseId$ | async, canViewIssues: canViewIssues$ | async, annotations: annotations$ | async } as vm">
  <div class="instructor-header course flex bg-slate-800 py-2 ltr:pl-2 ltr:pr-8 rtl:pr-2 rtl:pl-8 text-white">
    <div class="flex grow justify-between">
      <div class="left-content">
        <a routerLink="/instructor/courses">
          <button class="btn btn-dark pl-2">
            <svg-icon src="@assets/images/icons/chevron-left.svg" class="ar:rotate-180 h-4 w-4"></svg-icon>
            <span i18n>Back to Dashboard</span>
          </button>
        </a>
      </div>
      <div class="right-content flex">
        <div class="course-actions flex items-center gap-3">
          <button *ngIf="vm.canViewIssues" class="btn btn-sm btn-dark border-slate-700 bg-slate-700"
            (click)="annotationModal.open()">
            <span i18n="course_issues|Button label for issues that the instructor can see after the course review process">Issues</span>
            <svg-icon class="btn-icon" src="@assets/images/icons/exclamation-triangle.svg"></svg-icon>
          </button>

          <!-- Menu -->
          <div class="account-menu">
            <app-navigation position="right">
              <ng-container button>
                <button class="btn btn-sm btn-primary">
                  <span i18n="course_preview|Button label that allows the instructor to preview their course">Preview</span>
                  <ng-container *ngIf="isLoading">
                    <ng-container *ngTemplateOutlet="loaderSM"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isLoading">
                    <svg-icon class="btn-icon" src="@assets/images/icons/academic-cap.svg"></svg-icon>
                  </ng-container>
                </button>
              </ng-container>
              <ng-container content>
                <div class="content max-h-[400px] overflow-y-auto border-y border-y-slate-300 rounded-md">
                  <ul class="account-items">
                    <ng-container *ngIf="isLoading">
                      <li class="relative cursor-pointer opacity-5">
                        <a i18n>Learning lab</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                      <li class="relative cursor-pointer">
                        <a (click)="goToLearningLab(vm.courseId)" i18n>Learning lab</a>
                      </li>
                    </ng-container>
                    <li class="relative cursor-pointer">
                      <a (click)="previewCLP(vm.courseId)" i18n>Course Landing Page <span class="text-slate-400">(Public View)</span></a>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </app-navigation>
          </div>
          <a [routerLink]="'/instructor/course/' + vm.courseId + '/settings'" class="btn btn-dark px-2.5">
            <svg-icon class="btn-icon" src="@assets/images/icons/cog-solid.svg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

  <common-modal #annotationModal i18n-title title="Issues" alignment="right" customClass="sm:w-full sm:max-w-[620px]">
    <ng-container content *ngIf="annotationModal.isOpened">
      <div class="modal-container right scrollBar mr-2 h-screen justify-center overflow-y-scroll p-4 pb-[100px] pr-2">
        <div *ngIf="!vm.annotations?.length" i18n>No issues</div>
        <div *ngFor="let annotation of vm.annotations">
          <!-- <pre>{{ annotation | json }}</pre> -->
          <div class="my-2 rounded border border-slate-200 p-4">
            <app-common-feedback [annotation]="annotation"></app-common-feedback>
          </div>
        </div>
      </div>
    </ng-container>
  </common-modal>
</ng-container>

<ng-template #loaderSM>
  <svg class="text-slate-800 h-4 w-4 animate-spin !ml-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
</ng-template>
