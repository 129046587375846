import { Injectable } from '@angular/core';
import { Response } from '@shared/store';
import { Observable } from 'rxjs';
import {
  API_INSTRUCTOR_ASSIGNMENTS_BY_COURSES,
  API_INSTRUCTOR_ASSIGNMENTS_GROUP_BY_DATE,
  API_INSTRUCTOR_ASSIGNMENTS_REVIEW_ANSWER,
  API_INSTRUCTOR_ASSIGNMENTS_SUMMARY,
  HttpService,
  encodeURL,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService {
  constructor(private readonly http: HttpService) {}

  getAssignments(params = {} as object): Observable<Response> {
    return this.http.get<Response>(encodeURL(API_INSTRUCTOR_ASSIGNMENTS_SUMMARY, params));
  }

  getCoursesAssignments(params = {} as object, courseId: string): Observable<Response> {
    return this.http.get<Response>(
      encodeURL(API_INSTRUCTOR_ASSIGNMENTS_BY_COURSES.replace('<COURSE_ID>', courseId), params)
    );
  }

  getGroupByDateStudentsAssignments(params = {} as object, assignmentId: string): Observable<Response> {
    return this.http.get<Response>(
      encodeURL(API_INSTRUCTOR_ASSIGNMENTS_GROUP_BY_DATE.replace('<ASSIGNMENT_ID>', assignmentId), params)
    );
  }

  getAssignmentsReviews(assignmentId: string): Observable<Response> {
    return this.http.get<Response>(API_INSTRUCTOR_ASSIGNMENTS_REVIEW_ANSWER.replace('<ASSIGNMENT_ID>', assignmentId));
  }

  postAssignmentsReviews(body: any, assignmentId: string): Observable<any> {
    return this.http.post<any>(API_INSTRUCTOR_ASSIGNMENTS_REVIEW_ANSWER.replace('<ASSIGNMENT_ID>', assignmentId), body);
  }
}
