import { createFeature, createReducer, on } from '@ngrx/store';
import { CourseDetailsActions } from './course.actions';
import { initialCourseDetailsState } from './course.model';

export const courseDetailsReducer = createReducer(
  initialCourseDetailsState,
  on(CourseDetailsActions.loadCourseDetailsSuccess, (state, data) => {
    return {
      ...state,
      ...data,
    };
  })
);

export const courseDetailsFeature = createFeature({
  name: 'courseDetails',
  reducer: courseDetailsReducer,
});
