import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcurmbs',
  templateUrl: './breadcurmbs.component.html',
  styleUrls: ['./breadcurmbs.component.scss'],
})
export class BreadcurmbsComponent implements OnInit {
  @Input() breadcrumbItems: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
