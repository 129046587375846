import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsComponent } from './components/components.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PagesComponent } from './pages/pages/pages.component';

const routes: Routes = [
  { path: 'screens/pages', component: PagesComponent, data: { title: 'Pages' } },
  { path: 'screens/components', component: ComponentsComponent, data: { title: 'UI Components' } },
  { path: 'screens/landing-page', component: LandingPageComponent, data: { title: 'Landing Page' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScreensRoutingModule {}
