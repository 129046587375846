import { createFeature, createReducer, on } from '@ngrx/store';
import { initialInstructorAssignmentsState } from './assignment.model';
import { AssignmentsActions } from './assignments.actions';

export const instructorAssignmntsReducer = createReducer(
  initialInstructorAssignmentsState,
  // Assignments related
  on(AssignmentsActions.loadInstructorAssignmentsSuccess, (state, data) => {
    return {
      ...state,
      assignments: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),
  // Assignment by course
  on(AssignmentsActions.loadAssignmentsByCourseSuccess, (state, data) => {
    return {
      ...state,
      courses_assignments: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),
  // Student Assignments Group By Date
  on(AssignmentsActions.loadAssignmentsGroupByDateSuccess, (state, data) => {
    return {
      ...state,
      assignments_group: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  }),
  // Student Assignments Review
  on(AssignmentsActions.loadAssignmentsReviewSuccess, (state, data) => {
    return {
      ...state,
      assignments_review: data.results,
      next: data.next,
      previous: data.previous,
      count: data.count,
    };
  })
);

export const instructorAssignmentsFeature = createFeature({
  name: 'instructorAssignments',
  reducer: instructorAssignmntsReducer,
});
