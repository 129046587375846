import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // because this component is used in common project also =))
  // so if we want to change this component selector, need to check it with common and admin project also
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() title?: string | boolean = '';
  @Input() isOpened: boolean = false;
  @Input() customClass: string = 'sm:w-full sm:max-w-lg';
  @Input() alignment: 'center' | 'left' | 'right' = 'center';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('close') onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  open() {
    this.isOpened = true;
  }

  close() {
    if (this.isOpened) {
      this.isOpened = false;
      this.onClose.emit();
    }
  }
}
