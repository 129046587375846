import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PayoutService } from '@shared/services/payout.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PayoutActions } from './payout.actions';

@Injectable()
export class PayoutEffects {
  loadOverview = createEffect(() =>
    this.actions$.pipe(
      ofType(PayoutActions.loadOverview),
      switchMap(() =>
        this.payoutService.getOverview().pipe(
          map(overview => PayoutActions.loadOverviewSuccess({ payload: { overview } })),
          catchError(() => of(PayoutActions.ignore()))
        )
      )
    )
  );

  updatePayoutType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayoutActions.updatePayoutType),
      switchMap(({ payload: { instructorId, type } }) =>
        this.payoutService.updateType(instructorId, type).pipe(
          map(() => PayoutActions.updatePayoutTypeSuccess()),
          catchError(() => of(PayoutActions.ignore()))
        )
      )
    )
  );

  refreshPayoutOverviewWhenTypeChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayoutActions.updatePayoutTypeSuccess),
      switchMap(() => of(PayoutActions.loadOverview()))
    )
  );

  constructor(
    private actions$: Actions,
    private payoutService: PayoutService
  ) {}
}
