<ng-template #fieldTypeTemplate></ng-template>
<ng-container *ngIf="props.uploadType === 'default'">
  <ng-container *ngTemplateOutlet="default"></ng-container>
</ng-container>
<ng-container *ngIf="props.uploadType === 'dashboard'">
  <ng-container *ngTemplateOutlet="uppyDashboard"></ng-container>
</ng-container>
<ng-container *ngIf="props.uploadType === 'drag-drop'">
  <ng-container *ngTemplateOutlet="dragandDrop"></ng-container>
</ng-container>

<ng-template #uppyDashboard>
  <div class="field-file">
    <div class="inline-flex justify-start items-start gap-6">
      <!-- preview -->
      <div *ngIf="props.preview" class="preview overflow-hidden rounded-md"
        [ngClass]="props.previewResource !== 'avatar' ? 'h-40 w-72' : 'h-[84px] w-[84px] min-w-[84px]'">
        <ng-container *ngIf="!props.previewUrl || !field.formControl.value">
          <div *ngIf="props.previewResource === 'avatar'" class="bg-white flex h-full mx-auto rounded-full shadow h-[82px] w-[82px]">
            <img src="assets/images/image-placeholder.svg" class="m-auto h-11 w-11" alt="" defaultImage/>
          </div>

          <formly-imageplaceholder *ngIf="props.previewResource !== 'avatar'" text="Not Available"></formly-imageplaceholder>
        </ng-container>
        <ng-container *ngIf="props.previewUrl">
          <div *ngIf="props.previewType === 'image'" class="overflow-hidden" [ngClass]="props.previewResource === 'avatar' ? 'rounded-full w-full h-full' : 'rounded'">
            <img [src]="sanitizeUrl(props.previewUrl)" class="w-full h-full"/>
          </div>
          <div *ngIf="props.previewType === 'file'" class="overflow-hidden rounded">
            <img src="assets/images/image-placeholder.svg" class="m-auto h-11 w-11" alt="" />
          </div>
          <div *ngIf="props.previewType === 'video'" class="overflow-hidden rounded">
            <video [src]="sanitizeUrl(props.previewUrl)" controls></video>
          </div>
        </ng-container>
      </div>
      <!-- end preview -->
      <div class="flex flex-col gap-4 mb-2">
        <formly-fileDashboardInfo [field]="field" (clickEvent)="handleFileInfo($event)"></formly-fileDashboardInfo>
      </div>
    </div>
  </div>
  <uppy-drag-drop #dashboardUploader [uppy]="uppy" class="hidden h-[0] w-[0]"></uppy-drag-drop>
  <uppy-status-bar [uppy]="uppy" [props]="uppyStatusBarOptions" class="relative z-[999]"></uppy-status-bar>
  <common-modal #uploadModal customClass="sm:w-full sm:max-w-2xl" [title]="props.label">
    <ng-container content>
      <div class="h-[380px] p-4">
        <uppy-dashboard
          #uppyDashboard
          [uppy]="uppy"
          [props]="uppyDashboardOptions"
          class="uppy-dashboard"
        ></uppy-dashboard>
      </div>
    </ng-container>
  </common-modal>
</ng-template>

<ng-template #dragandDrop>
  <div class="uppyUpload">
    <uppy-drag-drop [uppy]="uppy" class="uppyUpload-dragdrop" *ngIf="!(state$ | async)?.viewState"></uppy-drag-drop>
    <div class="uppyUpload-dragdrop" *ngIf="(state$ | async)?.viewState">
      <formly-DragDropInfo
        [uploadState]="(state$ | async)!"
        (clickEvent)="handleFileInfo($event)"
      ></formly-DragDropInfo>
    </div>
  </div>
</ng-template>

<ng-template #default>
  <div class="uppyUpload">
    <div
      class="uppyUpload-default-container input-field items-center"
      type="file"
      *ngIf="!(state$ | async)?.viewState"
    >
      <uppy-drag-drop [uppy]="uppy" class="uppyUpload-default btn btn-sm p-0"></uppy-drag-drop>
    </div>
    <div class="uppyUpload-default-container input-field" type="file" *ngIf="(state$ | async)?.viewState">
      <formly-FileDefaultInfo
        [uploadState]="(state$ | async)!"
        (clickEvent)="handleFileInfo($event)"
        class="block w-full grow"
      ></formly-FileDefaultInfo>
    </div>
  </div>
</ng-template>
