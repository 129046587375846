import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectSectionsState } from './course.selectors';

export const selectSections = createSelector(selectSectionsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectSection = (sectionId: string) =>
  createSelector(selectSectionsState, (state) => state.entities[sectionId]);
