import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '@shared/store';
import { map, take } from 'rxjs';

import { Logger, skipUndefined } from 'thkee-common';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(selectIsAuthenticated).pipe(
      skipUndefined(),
      take(1),
      map(authenticated => {
        if (authenticated) {
          return true;
        }
        log.debug('Not authenticated, redirecting and adding redirect url...');
        this.router.navigate(['/account/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return false;
      })
    )

  }
}
