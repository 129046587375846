<app-learninglab-header></app-learninglab-header>

<div class="learninglab-shell">
  <div class="flex rtl:flex-row-reverse w-full">
    <div class="grow basis-0">
      <div class="grid">
        <!-- TODO: move this to /learning-lab/main.component.ts if ngrx is integrated -->
        <div class="activity">
          <app-learninglab-activity (event)="activityHandler($event)"></app-learninglab-activity>
        </div>
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div class="sidebar-wrap relative hidden lg:block" [class.hide]="!fullScreen">
      <button (click)="fullScreen = !fullScreen"
        class="btn btn-xs hover:bg-primary collapse-button absolute -left-3 top-8 z-10 h-6 w-6 rounded-full p-0 shadow-lg">
        <svg-icon src="@assets/images/icons/chevron-right.svg" class="w-3" [class.rotate-180]="!fullScreen"></svg-icon>
      </button>
      <div class="sidebar-content">
        <app-learninglab-sidebar></app-learninglab-sidebar>
      </div>
    </div>
  </div>
</div>
