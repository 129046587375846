import { Route, Routes } from '@angular/router';

import { AuthenticationGuard } from '@shared/services/auth/authentication.guard';
import { InstructorGuard } from '@shared/services/auth/instructor.guard';
import { AccountShellComponent } from './account-shell/account-shell.component';
import { GeneralShellComponent } from './general-shell/general-shell.component';
import { InstructorShellComponent } from './instructor-shell/instructor-shell.component';
import { LearninglabCertificateShellComponent } from './learninglab-certificate-shell/learninglab-certificate-shell.component';
import { LearninglabShellComponent } from './learninglab-shell/learninglab-shell.component';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutesForPublic(routes: Routes): Route {
    return {
      path: '',
      component: GeneralShellComponent,
      children: routes,
      canActivate: [],
    };
  }
  static childRoutesForStudent(routes: Routes): Route {
    return {
      path: 'student',
      component: GeneralShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
    };
  }
  static childRoutesForInstructor(routes: Routes): Route {
    return {
      path: '',
      component: InstructorShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
    };
  }
  static childRoutesForInstructorAssignment(routes: Routes): Route {
    return {
      path: '',
      component: InstructorShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
    };
  }
  static childRoutesForInstructorCourse(routes: Routes): Route {
    return {
      path: 'course',
      component: InstructorShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard, InstructorGuard],
    };
  }
  static childRoutesForAccount(routes: Routes): Route {
    return {
      path: 'account',
      component: AccountShellComponent,
      children: routes,
      canActivate: [],
    };
  }
  static childRoutesForLearningLab(routes: Routes): Route {
    return {
      path: 'learning-lab',
      component: LearninglabShellComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
    };
  }
  static childRoutesForLearningLabCertificate(routes: Routes): Route {
    return {
      path: 'certificate',
      component: LearninglabCertificateShellComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
    };
  }
}
