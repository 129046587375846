import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import {
  API_ADD_GUEST_CART,
  API_CART_ADD,
  API_CART_GUEST_SUMMERY,
  API_CART_REMOVE,
  API_CART_SUMMERY,
  API_COURSES_PRICING,
  API_GET_CART,
  API_GET_GUEST_CART,
  API_MERGED_GUEST_CART,
  API_RECOMMENDED_COURSES_CART,
  API_REMOVE_GUEST_CART,
  Course,
  CourseV2,
  Pagination,
  PartialEntity,
} from '../../models';
import { Coupon } from '../../models/cart.model';
import { HttpQueryParams, HttpService } from '../http';

let COUPONS: Coupon[] = [
  {
    code: 'sample50',
    amount: 50,
  },
  {
    code: 'sample20',
    amount: 20,
  },
  {
    code: 'sample70',
    amount: 70,
  },
];

export type Add_Remove_Cart = {
  course_uuid: string;
  message: string;
  created: boolean;
};

export type CartItems = {
  id: number;
  items: {
    id: number;
    course: PartialEntity<CourseV2>;
    date_added: string;
  }[];
};

export interface CartSummary {
  currency: string;
  subtotal: number;
  total_discount_amount: number;
  total_discount_percentage: number;
  final_total_amount: number;
  symbol: string;
}
export interface SummaryPayload {
  promo_code?: string;
  payables: Array<string>;
}
export interface GuestCartPayload {
  product: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonCartService {
  constructor(private readonly http: HttpService) {}

  validateCoupon(code: string): Observable<any> {
    let findCoupon = _.find(COUPONS, { code: code });
    if (findCoupon) {
      return of(findCoupon);
    } else {
      return of({ status: 404, message: 'Coupon not found' });
    }
  }

  addToCart(payload: any): Observable<Add_Remove_Cart> {
    return this.http.post<Add_Remove_Cart>(API_CART_ADD, payload);
  }

  removeToCart(payload: any): Observable<Add_Remove_Cart> {
    return this.http.post<Add_Remove_Cart>(API_CART_REMOVE, payload);
  }

  getCartItems(): Observable<CartItems> {
    return this.http.get<CartItems>(API_GET_CART);
  }

  // Guest cart
  addToGuestCart(payload: GuestCartPayload, token: string): Observable<Add_Remove_Cart> {
    const options = { guestCartToken: token };
    return this.http.post<Add_Remove_Cart>(API_ADD_GUEST_CART, payload, undefined, options);
  }

  getGuestCartItems(token: string): Observable<CartItems> {
    const options = { guestCartToken: token };
    return this.http.get<CartItems>(API_GET_GUEST_CART, undefined, options);
  }

  mergedGuestCart(token: string): Observable<Add_Remove_Cart> {
    const options = { guestCartToken: token };
    return this.http.post<Add_Remove_Cart>(API_MERGED_GUEST_CART, {}, undefined, options);
  }

  removeToGuestCart(payload: GuestCartPayload, token: string): Observable<Add_Remove_Cart> {
    const options = { guestCartToken: token };
    return this.http.post<Add_Remove_Cart>(API_REMOVE_GUEST_CART, payload, undefined, options);
  }

  recommendedCartItems(token: string, params = {} as HttpQueryParams): Observable<Pagination<Partial<Course>>> {
    const options = { guestCartToken: token };
    return this.http.get<Pagination<Partial<Course>>>(API_RECOMMENDED_COURSES_CART, params, options);
  }

  getCartSummary(payload: SummaryPayload): Observable<CartSummary> {
    return this.http.post<CartSummary>(API_CART_SUMMERY, payload);
  }

  getGuestCartSummary(payload: SummaryPayload, token: string): Observable<CartSummary> {
    const options = { guestCartToken: token };
    return this.http.post<CartSummary>(API_CART_GUEST_SUMMERY, payload, undefined, options);
  }

  getPrices(ids: string[]): Observable<any> {
    if (ids.length) {
      return this.http.get<any>(API_COURSES_PRICING + '?course_ids=' + ids.join(','));
    }
    return of();
  }
}
