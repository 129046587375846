<div class="navigation">
  <button (click)="navigation('prev')" [class]="classPrev" class="nav btn btn-outline-primary ltr:-ml-[50px] rtl:-mr-[50px]">
    <svg-icon class="rtl:hidden btn-icon" src="@assets/images/icons/arrow-left.svg"></svg-icon>
    <svg-icon class="ltr:hidden btn-icon" src="@assets/images/icons/arrow-right.svg"></svg-icon>
  </button>
  <button (click)="navigation('next')" [class]="classNext" class="nav btn btn-outline-primary ltr:-mr-[50px] rtl:-ml-[50px]">
    <svg-icon class="rtl:hidden btn-icon" src="@assets/images/icons/arrow-right.svg"></svg-icon>
    <svg-icon class="ltr:hidden btn-icon" src="@assets/images/icons/arrow-left.svg"></svg-icon>
  </button>
</div>
