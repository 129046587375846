<ng-container *ngIf="{ activeRoute: activeRoute$ | async } as vm">
  <div class="border-b border-slate-200 bg-slate-50">
    <div class="container">
      <div class="title py-6 pb-4 md:pb-6">
        <h3 class="font-medium">{{ title }}</h3>
      </div>
      <div class="tab-items">
        <ul class="flex flex-wrap text-center text-sm font-medium text-slate-500">
          <li *ngFor="let item of tabItems">
            <a
              [routerLink]="item.route"
              [id]="'tabid_' + item.id"
              class="inline-block border-b-2 px-6 py-3"
              [ngClass]="item.route === vm.activeRoute ? 'border-primary' : 'border-transparent'"
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
