import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, SubsectionV2 } from '../../models';
import { stringComparer } from '../store.util';

export const subsectionAdapter: EntityAdapter<PartialEntity<SubsectionV2>> = createEntityAdapter<
  PartialEntity<SubsectionV2>
>({
  selectId: (e) => e.id,
  sortComparer: (a, b) => stringComparer(a.date_created, b.date_created),
});

export const initialSubsectionState = subsectionAdapter.getInitialState();

export type SubsectionEntityState = EntityState<PartialEntity<SubsectionV2>>;
