<div class="tab-items">
   <ul class="flex flex-row">
      <li *ngFor="let item of items">
         <a (click)="emitData(item)"
            [class.active]="activeItem === '/'+(item.route.split('/')[item.route.split('/').length - 1])"
            [routerLink]="item.route"
            class="flex items-center gap-1.5 h-9 p-3 px-3 py-1.5 text-slate-600 text-sm cursor-pointer">
            {{item.title}}
         </a>
      </li>
   </ul>
</div>
