<ng-container *ngIf="{ course: course$ | async, certificate: courseCertificate$ | async } as vm">
  <header class="hidden lg:flex h-[70px] bg-slate-900 text-white">
    <div class="my-auto flex w-full items-center justify-between px-8 py-2">
      <div class="flex items-center gap-8">
        <div class="logo relative h-auto w-[105px]">
          <a [routerLink]="homeUrl">
            <svg-icon src="@assets/images/logo-white.svg" class="block h-auto w-[105px]"></svg-icon>
          </a>
        </div>
        <div class="course-title flex h-[45px] items-center ltr:border-l rtl:border-r border-slate-400 ltr:pl-8 rtl:pr-8">
          <div class="text-lg font-medium">{{ vm.course?.courseOrerview?.title }}</div>
        </div>
      </div>
      <div class="right">
        <div class="flex items-center justify-start gap-3">
          <ng-container *ngIf="!isPreviewCourse">
            <a *ngIf="params['subsection']" routerLink="/student">
              <button class="btn btn-transparent btn-sm bg-slate-900 hover:bg-slate-700">
                <svg-icon class="rtl:hidden btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-left.svg"></svg-icon>
                <svg-icon class="ltr:hidden btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-right.svg"></svg-icon>
                <span class="text-white" i18n="Button label|Button to go back to the learning lab">Back to Learning lab</span>
              </button>
            </a>
          </ng-container>
          <ng-container *ngIf="isPreviewCourse">
            <a *ngIf="params['subsection']" href="#" routerLink="/instructor/course/{{params['courseId']}}/{{queryParams['from']}}">
              <button class="btn btn-transparent btn-sm bg-slate-900 hover:bg-slate-700">
                <svg-icon class="rtl:hidden btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-left.svg"></svg-icon>
                <svg-icon class="ltr:hidden btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-right.svg"></svg-icon>
                <span class="text-white" i18n="Button label|Button to go back to the learning lab">Back to Instructor</span>
              </button>
            </a>
          </ng-container>
          <button (click)="back()" *ngIf="!params['subsection']"
            class="btn btn-transparent btn-sm bg-slate-900 hover:bg-slate-700">
            <svg-icon class="btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-left.svg"></svg-icon>
            <span class="text-white"  i18n="Button label|Button to go back to the course lab from the certificate page">Back to Course</span>
          </button>
          <app-navigation position="right" contentClass="!min-w-[200px] w-[300px]">
            <ng-container button>
              <div class="certificate flex items-center gap-2 cursor-pointer">
                <div>
                  <ng-container *ngTemplateOutlet="progressRadial"></ng-container>
                </div>
                <div *ngIf="progress < 80" class="font-medium text-slate-200" i18n="Course progress|Progress of course completion">
                  <span>{{progress}}</span>% Completed
                </div>
                <div *ngIf="progress > 80" class="font-medium text-green-600" i18n="Certificate prompt|Prompt to get certificate">Get certificate</div>
              </div>
            </ng-container>
            <ng-container content>
              <div class="p-4 mt-4">
                <h5 class="text-slate-800 font-semibold text-base mb-2.5" i18n="Lecture completion|Number of completed lectures out of total lectures">{{vm.certificate?.completed_subsections}} of
                  {{vm.certificate?.subsections_total}} lectures completed</h5>
                <ng-container *ngIf="progress > 80">
                  <a [routerLink]="'/certificate/'+params['courseId']">
                    <button class="btn btn-primary w-full">
                      <span i18n="Claim certificate button|Button to claim certificate">Claim your certificate</span>
                    </button>
                  </a>
                </ng-container>
              </div>
            </ng-container>
          </app-navigation>
          <a href="#" class="btn btn-transparent btn-sm block bg-slate-900 p-2 hover:bg-slate-700"
            tooltip="Note & Highlights">
            <svg-icon class="h-5 w-5 text-white" src="@assets/images/icons/document-text.svg"></svg-icon>
          </a>

          <!-- Menu -->
          <div class="account-menu">
            <app-navigation position="right">
              <ng-container button>
                <thk-avatar *ngIf="user" sizeClasses="h-10 w-10" [title]="user.fullname" [url]="user.photo_url"></thk-avatar>
              </ng-container>
              <ng-container header>
                <div class="heading flex items-center gap-4 p-4">
                  <thk-avatar *ngIf="user" sizeClasses="h-10 w-10" [title]="user.fullname" [url]="user.photo_url"></thk-avatar>

                  <div>
                    <ng-container *ngIf="user">
                      <div class="name font-medium">{{ user.first_name }} {{ user.last_name }}</div>
                      <div class="email text-xs text-slate-500">{{ user.email }}</div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container content>
                <div class="content max-h-[400px] overflow-y-auto border-y border-y-slate-300">
                  <ul class="account-items border-b border-b-slate-300">
                    <li class="relative">
                      <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>My Account</a>
                    </li>
                  </ul>
                  <ul class="account-items">
                    <li class="relative">
                      <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Public profile</a>
                    </li>
                    <li class="relative">
                      <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Edit profile</a>
                    </li>
                  </ul>
                </div>
              </ng-container>
              <ng-container footer>
                <div class="footer">
                  <ul class="account-items">
                    <li class="relative">
                      <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Help</a>
                    </li>
                    <li class="relative">
                      <a (click)="logout()" class="rounded-b-lg" i18n>Logout</a>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </app-navigation>
          </div>
        </div>
      </div>
    </div>
  </header>

  <header class="lg:hidden flex h-[70px] bg-slate-900 text-white">
    <div class="my-auto flex w-full items-center justify-between pr-4 py-2">
      <a routerLink="/student">
        <button class="btn btn-transparent btn-sm bg-slate-900 hover:bg-slate-700">
          <svg-icon class="btn-icon h-4 w-4 text-white" src="@assets/images/icons/arrow-left.svg"></svg-icon>
        </button>
      </a>
      <div class="course-title flex h-[45px] items-center">
        <div class="text-sm md:text-base lg:text-lg font-medium">{{ vm.course?.courseOrerview?.title }}</div>
      </div>
    </div>
  </header>

  <ng-template #progressRadial>
    <app-progress-circle [percent]="progress">
      <ng-container content>
        <div class="flex bg-slate-800">
          <svg class="mx-auto my-1 h-[14px] w-[14px]" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
            viewBox="0 0 15 15" fill="none">
            <g clip-path="url(#clip0_15664_50488)">
              <path
                d="M7.82975 11.5208C10.9459 11.5208 13.472 8.99471 13.472 5.87858C13.472 2.76245 10.9459 0.236328 7.82975 0.236328C4.71362 0.236328 2.1875 2.76245 2.1875 5.87858C2.1875 8.99471 4.71362 11.5208 7.82975 11.5208Z"
                fill="#FFB20D" />
              <path
                d="M3.25874 9.17871L1.31738 12.5439L3.58323 12.4236L4.61266 14.4461L6.40856 11.3327C5.12178 10.9998 4.01682 10.2249 3.25874 9.17871Z"
                fill="white" />
              <path
                d="M12.4119 9.16211C11.6594 10.2111 10.5545 10.9888 9.27051 11.3273L11.072 14.4463L12.1014 12.4238L14.3673 12.5441L12.4119 9.16211Z"
                fill="white" />
              <path
                d="M10.6315 5.43739C10.7574 5.31431 10.6874 5.09891 10.514 5.07374L8.89992 4.83876C8.82999 4.82757 8.77124 4.78561 8.74047 4.72127L8.01876 3.25826C7.94043 3.09881 7.71385 3.09881 7.63552 3.25826L6.9166 4.72127C6.88583 4.78281 6.82429 4.82757 6.75715 4.83876L5.14309 5.07374C4.96965 5.09891 4.89972 5.31431 5.0256 5.43739L6.19209 6.57591C6.24244 6.62627 6.26482 6.6962 6.25363 6.76333L5.97949 8.36901C5.94872 8.54245 6.13335 8.67672 6.29 8.5928L7.73343 7.83472C7.79497 7.80115 7.8705 7.80115 7.93204 7.83472L9.37547 8.5928C9.53212 8.67392 9.71395 8.54245 9.68598 8.36901L9.40904 6.76333C9.39785 6.6934 9.42023 6.62347 9.47058 6.57591L10.6315 5.43739Z"
                fill="#1E293B" />
            </g>
            <defs>
              <clipPath id="clip0_15664_50488">
                <rect width="14.2105" height="14.2105" fill="white" transform="translate(0.736328 0.236328)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </ng-container>
    </app-progress-circle>
  </ng-template>
</ng-container>
