import * as _ from 'lodash';
import * as moment from 'moment';
import { CategoryV2, CopiedEntity, SubCategoryV2, TopicV2 } from '../models';

// Example usage:
export const formatDate = (dateString: string = ''): { month: string; year: number; output: string } => {
  dateString = dateString ? dateString : '0000';
  const dateMoment = moment.utc(dateString);
  const month = dateMoment.format('MMMM'); // August (the month number, i.e., 7 for August, as it is zero-indexed)
  const year = dateMoment.year(); // 2023
  let output = month + ' ' + year;
  return { month: month, year, output };
};

// Possible common utils
export const formatDuration = (minutes: number = 0): { hours: number; minutes: number; output: string } => {
  minutes = minutes ? minutes : 0;
  if (minutes < 0) {
    throw new Error('Minutes cannot be negative.');
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  let output = '';
  if (hours) output += hours + 'h ';
  if (remainingMinutes) output += remainingMinutes + 'm';
  output = output ? output : 'Not Available';

  return { hours, minutes: remainingMinutes, output };
};

// Possible common utils
export const formatToPrice = (value: number): string => {
  // Convert the number to a fixed format with two decimal points
  const formattedValue = value.toFixed(2);
  // Add the currency symbol and any additional formatting if needed
  const currencySymbol = '$'; // Change this to the desired currency symbol
  return currencySymbol + formattedValue;
};

export const linkedListToArray = <T extends CopiedEntity<unknown>>(head: T): T[] => {
  const arr: T[] = [];
  let curr: T | undefined = head;
  while (curr) {
    arr.push(_.omit(curr, ['source']) as unknown as T);
    curr = curr.source as T;
  }
  return arr;
};

export const getCategoryBySlug = (slug: string, categories: CategoryV2[]): CategoryV2 | SubCategoryV2 | null => {
  for (const category of categories) {
    if (category.slug === slug) {
      return { ...category, type: 'category' };
    }
    if (category.subcategories) {
      for (const subcategory of category.subcategories) {
        if (subcategory.slug === slug) {
          return { ...subcategory, type: 'subcategory', parent: category };
        }
      }
    }
  }
  return null;
};

export const getTopicBySlug = (slug: string, topics: TopicV2[]): TopicV2 | null => {
  for (const topic of topics) {
    if (topic.slug === slug) {
      return topic;
    }
  }
  return null;
};
