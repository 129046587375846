// Initial State
export const initialInstructorAssignmentsState: Response = {
  next: null,
  previous: null,
  count: 0,
  results: undefined,
  assignments: {
    global_total_not_checked: 0,
    courses: [],
  },
  assignments_review: [],
  courses_assignments: {
    course_id: '',
    course_title: '',
    assignment: [],
  },
  assignments_group: {
    course_id: '',
    course_title: '',
    assignment_id: '',
    assignment_title: '',
    submissions: [],
  },
};

export interface Response {
  next: null | number;
  previous: null | number;
  count: number;
  results: any;
  assignments: {
    global_total_not_checked: number;
    courses: Overview[];
  };
  assignments_review: AssignmentsReview[];
  courses_assignments: {
    course_id: string;
    course_title: string;
    assignment: Assignments[];
  };
  assignments_group: {
    course_id: string;
    course_title: string;
    assignment_id: string;
    assignment_title: string;
    submissions: GroupByDateSubmissions[];
  };
}

export interface AssignmentsReview {
  course_id: string;
  course_title: string;
  assignment: {
    assignment_id: string;
    assignment_title: string;
    student_assignments: {
      user: {
        id: number;
        username: string;
        fullname: string;
        language: string;
        photo: string | null;
        photo_url: string;
      };
      questions: AssignmentsReviewQuestion[];
    };
  };
}

export interface AssignmentsReviewQuestion {
  question_id: string;
  question?: string;
  answer?: string;
  feedback: string;
  mark_as_correct: boolean;
}

// Params
export interface Params {
  page_size?: number;
  page?: number;
  read_by_instructor?: boolean;
  feedback?: boolean;
  sort?: string;
  sort_by?: string;
  status?: string;
  search?: string;
  answered?: boolean;
  reviews?: string;
  date_range?: string;
  star?: number;
}

// Overview & Assignments TAB
export interface Overview {
  course_id: string;
  course_title: string;
  course_image: string | null;
  assignment_count: number;
  total_not_checked: number;
  // assignment_title: string;
  student_assignments: StudentAssignments[];
}

export interface StudentAssignments {
  id: string;
  user: {
    id: string;
    username: string;
    fullname: string;
    language: string;
    avatar?: string;
    photo_url?: string;
  };
  questions: number;
  answered: number;
  assignment_id?: string;
  assignment_title?: string;
  checked: boolean;
}

// Courses assignments
export interface Assignments {
  id: string;
  assignment_title: string;
  questions: number;
  students: number;
  submissions: number;
  not_checked_count: number;
}

// Group by Submission Date
export interface GroupByDateSubmissions {
  submission_date: string;
  student_assignments: StudentAssignment[];
}
export interface StudentAssignment {
  student_assignment: StudentAssignments;
}
