import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectLearnersState } from './course.selectors';

export const selectLearners = createSelector(selectLearnersState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectLearner = (learnerId: string) =>
  createSelector(selectLearnersState, (state) => state.entities[learnerId]);

export const selectLearnerEntities = createSelector(selectLearnersState, (state) => state.entities);
