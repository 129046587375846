import { createSelector } from '@ngrx/store';
import { AnnotationDetail } from '../../models';
import { isDefined } from '../../utils';
import { annotationKey } from './annotation.entity';
import { selectAnnotationHistoryState, selectAnnotationsState, selectFeedback } from './course.selectors';

export const selectAnnotations = createSelector(selectAnnotationsState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectAnnotationByKey = (annotationKey: string) =>
  createSelector(
    selectAnnotationsState,
    selectAnnotationHistoryState,
    (annotations, annotationsHistory) =>
      (annotations.entities[annotationKey] ?? annotationsHistory.entities[annotationKey]) as AnnotationDetail
  );

export const selectSelectedAnnotation = createSelector(
  selectAnnotationsState,
  selectAnnotationHistoryState,
  selectFeedback,
  (annotations, annotationsHistory, feedback) => {
    const key = annotationKey({ model: feedback.fieldModel, field: feedback.fieldKey, object_id: feedback.fieldId });
    return (annotations.entities[key] ?? annotationsHistory.entities[key]) as AnnotationDetail;
  }
);
