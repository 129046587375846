import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeolocationService } from '@shared/services/public/geolocation.service';
import { Observable, switchMap, take } from 'rxjs';

@Injectable()
export class GeolocationAttachmentInterceptor implements HttpInterceptor {

  constructor(
    private geolocationCachedService: GeolocationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.geolocationCachedService.geolocation.pipe(
      take(1),
      switchMap(geo => {
        if (geo.country) {
          return next.handle(request.clone({ headers: request.headers.set('x-country', geo.country || '') }));
        }

        return next.handle(request);
      })
    )
  }
}
