export const ROUTE_HOME = '/';
export const ROUTE_INSTRUCTOR = '/instructor';
export const AUTO_SAVE_DEBOUNCE_TIME_MS = 1000;

// Instructor dashboard
export const REPORT_TOOLTIP_1 =
  'Your payout schedule is monthly. Expected earnings shown are baseYour current payout schedule is set to monthly. The displayed expected earnings are calculated based on your sales to date and may increase with additional sales or decrease with any refunds processed before the payout due date.d on current sales, subject to change with additional sales or refunds before payout.';
export const REPORT_TOOLTIP_2 =
  'This is your current available balance that is ready to be paid out. It reflects earnings from completed transactions that have cleared any necessary waiting periods.';
export const REPORT_TOOLTIP_3 =
  'This total represents all refunds that have been issued to customers. Refunds are subtracted from your expected earnings and can affect your available balance.';
export const REPORT_TOOLTIP_4 =
  'Total sales indicate the number of transactions completed successfully. This number does not account for any refunds or cancellations that may occur.';

export const GUEST_TOKEN_KEY = 'thk_guest_cart_token';
