<ng-container *ngIf="type==='assignments'">
   <div class="flex h-[42px] items-center gap-6 bg-slate-100 border-b-slate-300 border-b px-7">
      <div class="tab-menu" *ngIf="items">
         <ul class="items flex gap-[10px]">
            <li *ngFor="let item of items; let i = index" [id]="'item-' + item.id" class="item text-slate-500 px-6"
               [class.active]="activeItem === '/'+(item.route.split('/')[item.route.split('/').length - 1])"
               [ngClass]="'item-' + item.id">
               <a [routerLink]="item.route" [ngClass]="'item-link-' + item.id" class="flex items-center gap-2">
                  <svg-icon *ngIf="item.hasChanges" src="@assets/images/icons/exclamation-circle.svg"
                     class="block h-5 w-5 text-amber-600"></svg-icon>
                  {{ item.displayName }}
               </a>
            </li>
         </ul>
      </div>
   </div>
</ng-container>

<!-- Payout Tab -->
<ng-container *ngIf="type==='payout'">
   <div class="flex h-[42px] items-center gap-2 border-b-slate-400 border-b">
      <div class="tab-menu" *ngIf="items">
         <ul class="items flex gap-[10px]">
            <li *ngFor="let item of items; let i = index" [id]="'item-' + item.id" class="item text-slate-500 px-3"
               [class.active]="item.displayName === title" [ngClass]="'item-' + item.id">
               <a [routerLink]="item.route" [ngClass]="'item-link-' + item.id" class="flex items-center gap-2">
                  <svg-icon *ngIf="item.hasChanges" src="@assets/images/icons/exclamation-circle.svg"
                     class="block h-5 w-5 text-amber-600"></svg-icon>
                  {{ item.displayName }}
               </a>
            </li>
         </ul>
      </div>
   </div>
</ng-container>
