import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Course, CourseV2 } from 'thkee-common';

@Component({
  selector: 'app-course-grid',
  templateUrl: './course-grid.component.html',
  styleUrls: ['./course-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseGridComponent implements OnInit {
  // TODO JHJ !!! remove
  @Input() courseData: Partial<Course>[] = [];
  @Input() courses: CourseV2[] = [];
  @Input() disabledHover: boolean = false;
  @Input() mobileView: boolean = true;
  @Input() gridCols: number = 4;
  @Input() gridRows: number = 2;
  @Input() isLoading: boolean | null = false;

  constructor() {}

  ngOnInit(): void {}

  isOwned(course: CourseV2 | Partial<Course>): boolean {
    // TODO update course ownership
    return true;
  }

  getCourses() {
    return this.courseData ? this.courseData : this.courses;
  }

  groupData(array: any[], chunkCount: number): any[][] {
    if (chunkCount <= 0) {
      throw new Error('Chunk count must be greater than 0');
    }
    return _.chunk(array, Math.ceil(array.length / chunkCount));
  }
}
