<div
  class="course-grid grid grid-cols-1 gap-4 px-0 sm:grid-cols-2 sm:gap-6 md:grid md:grid-cols-3 md:gap-8 md:px-0 lg:grid-cols-3 lg:gap-8 lg:px-4 xl:grid-cols-{{
    gridCols
  }} xl:gap-8 xl:px-0"
  [ngClass]="{ 'hidden md:grid': mobileView }"
>
  <ng-container *ngIf="isLoading">
    <!-- Courses Grid loading state -->
    <div *ngFor="let course of [].constructor(4)" class="fadeIn">
      <app-course-card [isLoading]="true" [courseData]="course" [mobileView]="mobileView"></app-course-card>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="courses.length">
      <ng-container *ngTemplateOutlet="newType"></ng-container>
    </ng-container>
    <ng-container *ngIf="courseData.length">
      <ng-container *ngTemplateOutlet="oldType"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #oldType>
  <div *ngFor="let course of courseData" class="fadeIn">
    <a>
      <app-course-card [courseData]="course" [disabledHover]="disabledHover" [mobileView]="mobileView"></app-course-card>
    </a>
  </div>
</ng-template>

<ng-template #newType>
  <div *ngFor="let course of courses" class="fadeIn">
    <a>
      <app-course-card [course]="course" [disabledHover]="disabledHover" [mobileView]="mobileView"></app-course-card>
    </a>
  </div>
</ng-template>

<div class="course-grid-mobile md:hidden" *ngIf="mobileView">
  <div class="flex gap-6" *ngFor="let courseG of groupData(courseData, gridRows)">
    <ng-container *ngFor="let course of courseG; let i = index">
      <div>
        <a>
          <div>
            <app-course-card [courseData]="course" [disabledHover]="disabledHover" [mobileView]="mobileView"></app-course-card>
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
