import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AnnotationDetail, PartialEntity } from '../../models';

export const annotationAdapter: EntityAdapter<PartialEntity<AnnotationDetail>> = createEntityAdapter<
  PartialEntity<AnnotationDetail>
>({
  selectId: (e) => annotationKey(e),
});

export const initialAnnotationState = annotationAdapter.getInitialState();

export type AnnotationEntityState = EntityState<PartialEntity<AnnotationDetail>>;

export const annotationKey = (annotation: Pick<PartialEntity<AnnotationDetail>, 'model' | 'field' | 'object_id'>) => {
  return `${annotation.model}-${annotation.object_id}-${annotation.field}`;
};
