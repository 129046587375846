import { Component, ElementRef, Input, OnInit } from '@angular/core';
declare var videojs: any; // Declare videojs as a global variable

@Component({
  selector: 'app-lib-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() URL: string = '';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    const videoElement = this.el.nativeElement.querySelector('video');
    const options = {
      aspectRatio: '16:8',
      controls: true,
      playbackRates: [1, 1.5, 2],
      responsive: true,
      autoplay: true,
      // fluid: true,
    };

    const player = videojs(videoElement, options);
    const video_url = this.URL ? this.URL : 'https://placehold.co/1920x1080.mp4';
    player.src(video_url);
  }
}
