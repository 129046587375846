import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTE_HOME } from '@shared/models';
import { of, take } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CartActions } from '../cart';
import { CartService } from '../cart/cart-store.service';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ data: { email, password, remember }, trackerId }) =>
        this.authService.login({ email: email, password, remember }, trackerId).pipe(
          map((resp) => AuthActions.loginSuccess()),
          catchError((error) => of(AuthActions.loginError({ error: error.message })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap(() => {
        this.afterSuccess();
      }),
      switchMap(() => this.authService.getUser().pipe(map((user) => AuthActions.userLoaded({ user }))))
    )
  );

  userLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLoad),
      switchMap(() => {
        return this.authService.getUser().pipe(
          map((user) => AuthActions.userLoaded({ user })),
          catchError(() => {
            return of(AuthActions.useAsGuest());
          })
        );
      })
    )
  );

  userLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.userLoaded),
        tap((data) => {
          this.store.dispatch(CartActions.loadCart());
          // this.store.dispatch(CartActions.loadCartItems());
        })
      ),
    { dispatch: false }
  );

  updateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateUser),
        switchMap(({ user: userData, trackerId: contextId }) => {
          return this.authService.updateUser(userData, { contextId }).pipe(
            map((res) => res && this.store.dispatch(AuthActions.updateUserSuccess({ user: userData }))),
            catchError(() => of(undefined))
          );
        })
      ),
    { dispatch: false }
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signup),
      switchMap(({ email, password, name, geo, contextId }) =>
        this.authService.signup({ email, password, name, geo }, { contextId }).pipe(
          map(() => AuthActions.signupSuccess({ email, password })),
          catchError((error) => of(AuthActions.signupError({ error: error.message })))
        )
      )
    )
  );

  signupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signupSuccess),
        tap((data) => {
          // Login After successful signup
          this.cartService.mergedGuestCart();
          this.store.dispatch(
            AuthActions.login({ data: { email: data.email, password: data.password, remember: true } })
          );
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap(() => this.authService.logout()),
        tap(() => this.router.navigate([ROUTE_HOME]))
      ),
    { dispatch: false }
  );

  signupInstructorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signupInstructorSuccess)
      ),
    { dispatch: false }
  );

  afterSuccess() {
    this.store.dispatch(CartActions.initUserCart());
    this.authService.loginActionState.pipe(take(1)).subscribe((loginMetadata) => {
      if (loginMetadata.openLoginModal) {
        this.authService.loginActionState.next({ openLoginModal: false });
      }
      this.cartService.mergedGuestCart();
      this.router.navigate([loginMetadata.redirect || ROUTE_HOME]);
    });
  }

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
    private store: Store,
    private cartService: CartService
  ) { }
}
