import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import {
  AssignmentQuestionV2,
  CourseDetailV2,
  LectureResourceV2,
  PartialEntity,
  QuizAnswerV2,
  QuizQuestionDetailV2,
  SectionDetailV2,
  SubsectionDetailV2,
  SubsectionV2,
} from '../../models';
import { isDefined } from '../../utils';
import { selectAssignmentQuestionEntities } from './assignment-question.selectors';
import { selectAssignmentEntities } from './assignment.selectors';
import { selectCourse, selectCourseDetailed } from './course.selectors';
import { selectLectureResourceEntities } from './lecture-resource.selectors';
import { selectLectureEntities } from './lecture.selectors';
import { selectQuizAnswerEntities } from './quiz-answer.selectors';
import { selectQuizQuestionEntities } from './quiz-question.selectors';
import { selectQuizEntities } from './quiz.selectors';
import { selectSections } from './section.selectors';
import { selectSubsections } from './subsection.selectors';

export const selectCourseDetail = createSelector(
  selectCourse,
  selectCourseDetailed,
  selectSections,
  selectSubsections,
  selectQuizEntities,
  selectQuizQuestionEntities,
  selectQuizAnswerEntities,
  selectAssignmentEntities,
  selectAssignmentQuestionEntities,
  selectLectureEntities,
  selectLectureResourceEntities,
  (
    course,
    courseDetailed,
    sections,
    subsections,
    quizEntities,
    quizQuestionEntities,
    quizAnswerEntities,
    assignmentEntities,
    assignmentQuestionEntities,
    lectureEntities,
    lectureResourceEntities
  ): CourseDetailV2 => {
    const quizQuestionByQuizMap = _.groupBy(
      _.values(quizQuestionEntities).filter(isDefined),
      (quizQuestion) => quizQuestion.quiz
    );
    const quizAnswerByQuizQuestionMap = _.groupBy(
      _.values(quizAnswerEntities).filter(isDefined),
      (quizAnswer) => quizAnswer.quiz_question
    );

    const assignmentQuestionByAssignmentMap = _.groupBy(
      _.values(assignmentQuestionEntities).filter(isDefined),
      (assignmentQuestion) => assignmentQuestion.assignment
    );

    const lectureResourceByLectureMap = _.groupBy(
      _.values(lectureResourceEntities).filter(isDefined),
      (lectureResource) => lectureResource.lecture
    );

    return {
      ...course,
      project: courseDetailed.project!,
      source: courseDetailed.source,
      sections: sections.map<SectionDetailV2>((section) => ({
        ...section,
        subsections: subsections
          .filter((subsection) => subsection.section === section.id)
          .sort(
            (a: PartialEntity<SubsectionV2>, b: PartialEntity<SubsectionV2>) =>
              (a.position ? a.position : 0) - (b.position ? b.position : 0)
          )
          .map<SubsectionDetailV2>((subsection) => ({
            ...subsection,
            section: subsection.section,
            quiz: subsection.quiz
              ? {
                  ...quizEntities[subsection.quiz]!,
                  questions: quizQuestionByQuizMap[subsection.quiz]?.map(
                    (quizQuestion) =>
                      ({
                        ...quizQuestion,
                        answers: quizAnswerByQuizQuestionMap[quizQuestion.id] as QuizAnswerV2[],
                      } as QuizQuestionDetailV2)
                  ),
                }
              : undefined,
            assignment: subsection.assignment
              ? {
                  ...assignmentEntities[subsection.assignment]!,
                  questions: assignmentQuestionByAssignmentMap[subsection.assignment] as AssignmentQuestionV2[],
                }
              : undefined,
            lecture: subsection.lecture
              ? {
                  ...lectureEntities[subsection.lecture]!,
                  resources: lectureResourceByLectureMap[subsection.lecture] as LectureResourceV2[],
                }
              : undefined,
          })),
        article_count: 0,
        quiz_count: 0,
        total: 0,
        video_count: 0,
      })),
    };
  }
);
