import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectActiveSubsection } from '@shared';
import { distinctUntilChanged } from 'rxjs';
import { LectureDetailV2, PartialEntity } from 'thkee-common';
declare var videojs: any; // Declare videojs as a global variable

@UntilDestroy()
@Component({
  selector: 'app-learning-lab-activity-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class LearninglabVideoComponent implements OnInit {
  @Output() event = new EventEmitter<{ action: string; data: any }>();
  @Input() hidePrev: boolean = false;
  @Input() hideNext: boolean = false;
  @Input() prevData: any = null;
  @Input() nextData: any = null;
  isLoading: boolean = false;
  isLoadingPlayer: boolean = false;

  data?: PartialEntity<LectureDetailV2>;

  constructor(private el: ElementRef, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectActiveSubsection)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((res: any) => {
        this.data = res?.lecture;
        this.initPlayer();
      });
  }

  private initPlayer() {
    // Initialize Video.js
    const videoElement = this.el.nativeElement.querySelector('video');
    // Set up Video.js options
    const options = {
      aspectRatio: '16:7', // Set the aspect ratio to '16:9'
      controls: true,
      playbackRates: [1, 1.5, 2],
      responsive: true,
      autoplay: true,
      // fluid: true,
    };

    const player = videojs(videoElement, options);
    const video_url = this.data?.video_url ? this.data?.video_url : 'https://placehold.co/1920x1080.mp4';
    player.src(video_url);
    // player.src(this.data?.video_url);

    // Set video poster
    // player.poster('https://placehold.co/720x420');

    // Set the video current possition
    // player.on('ready', () => {
    //   player.currentTime(60);
    // });

    // Listen for the "loadedmetadata" event to ensure metadata is loaded
    player.on('loadedmetadata', () => {
      this.emitEvent('loadedmetadata', { currentTime: player.currentTime(), currentDuration: player.duration() });
    });
    // Listen for the "play" event
    player.on('play', () => {
      this.isLoading = false;
      this.isLoadingPlayer = false;
      this.emitEvent('play', { currentTime: player.currentTime(), currentDuration: player.duration() });
    });
    // Listen for the "pause" event
    player.on('pause', () => {
      this.emitEvent('pause', { currentTime: player.currentTime(), currentDuration: player.duration() });
    });
    // Listen for the "ended" event
    player.on('ended', () => {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoadingPlayer = true;
        this.emitEvent('ended', { currentTime: player.currentTime(), currentDuration: player.duration() });
      }, 2000);
    });
  }

  emitEvent(action: string, data: any = {}) {
    this.event.emit({ action, data });
  }
}
