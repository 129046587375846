import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssignmentV2, PartialEntity } from '../../models';

export const assignmentAdapter: EntityAdapter<PartialEntity<AssignmentV2>> = createEntityAdapter<
  PartialEntity<AssignmentV2>
>({
  selectId: (e) => e.id,
});

export const initialAssignmentState = assignmentAdapter.getInitialState();

export type AssignmentEntityState = EntityState<PartialEntity<AssignmentV2>>;
