import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, QuizV2 } from '../../models';

export const quizAdapter: EntityAdapter<PartialEntity<QuizV2>> = createEntityAdapter<PartialEntity<QuizV2>>({
  selectId: (e) => e.id,
});

export const initialQuizState = quizAdapter.getInitialState();

export type QuizEntityState = EntityState<PartialEntity<QuizV2>>;
