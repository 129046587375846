import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as apiConst from '../../models';

export const endpointKeys = [
  apiConst.API_USERS,
  apiConst.API_COURSES,
  apiConst.API_COURSES_CATEGORIES,
  apiConst.API_COURSES_TOPICS,
  apiConst.API_USERS_DETAIL,
  apiConst.API_PUBLISHED_COURSES,
  apiConst.API_COURSES_PUBLIC_TOPICS,
  apiConst.API_GET_CART,
  apiConst.API_PAYMENT_VERIFY_TOKEN,
  apiConst.API_CHECKOUT_ORDER,
  apiConst.API_COURSES_INSTRUCTOR,
] as const;

export type LoadingState = {
  [K in (typeof endpointKeys)[number]]?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingState: LoadingState = {};
  private loadingSubject = new BehaviorSubject<LoadingState>({});

  loading$ = this.loadingSubject.asObservable();

  show(endpointId: string) {
    this.loadingState = { ...this.loadingState, [endpointId]: true };
    this.updateLoadingState();
  }

  hide(endpointId: string) {
    this.loadingState = { ...this.loadingState, [endpointId]: false };
    this.updateLoadingState();
  }

  private updateLoadingState() {
    this.loadingSubject.next({ ...this.loadingState });
  }
}
