import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent } from '@shared';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Uppy, UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import DragDrop from '@uppy/drag-drop';
import GoldenRetriever from '@uppy/golden-retriever';
import { StatusBarOptions } from '@uppy/status-bar';
import * as _ from 'lodash';
import { uniqueId } from 'lodash';
import { BehaviorSubject, tap } from 'rxjs';
import { Logger } from '../../../services';
import { RouterStoreService, selectCourse } from '../../../store';

const log = new Logger('FormlyFieldFile');

const cloudfrontURL: string = 'https://d13439oyxvz2md.cloudfront.net/';

type UploadInfo = {
  name: string;
  type: string;
};

type ProgressInfo = {
  progressPercent: number;
  bytesUploaded: number;
  bytesTotal: number;
};
export interface dragdropState {
  fileName: string;
  currentProgress: number;
  viewState: 'file-added' | 'upload-progress' | 'upload-success' | 'complete' | '';
  fileURL: string;
}

export interface FileProps extends FormlyFieldProps {
  preview?: boolean;
  previewResource?: 'avatar';
  previewUrl?: string;
  previewType: 'image' | 'video' | 'file';
  allowedTypes?: string[];
  uploader?: 'dashboard' | 'device';
  uploadType: 'dashboard' | 'drag-drop' | 'default';
  metadata?: Record<string, string>;
  onUpload?: (upload: UploadInfo, field: FormlyFieldConfig) => void;
  onProgress?: (progress: ProgressInfo, field: FormlyFieldConfig) => void;
  onComplete?: (field: FormlyFieldConfig) => void;
  onAbort?: (field: FormlyFieldConfig) => void;
  componentRef?: any;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-field-file',
  templateUrl: './file.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormlyFieldFile extends FieldType<FieldTypeConfig<FileProps>> implements OnInit, AfterViewInit {
  @ViewChild('dashboardUploader', { read: ElementRef<HTMLElement> }) defaultDragDropContainer?: ElementRef<HTMLElement>;
  static uppyMap: Map<string, Uppy> = new Map();

  @ViewChild('uploadModal') uploadModal?: ModalComponent;
  showPlaceholder: boolean = false;

  uppyFileTypes: string[] = [];
  uppy!: Uppy;

  state$ = new BehaviorSubject<dragdropState>({
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  });
  private fileKey: string = '';

  readonly uppyStatusBarOptions: StatusBarOptions = {
    showProgressDetails: true,
    hideUploadButton: true,
  };
  readonly uppyDashboardOptions: DashboardOptions = {
    id: uniqueId('file'),
    hideUploadButton: true,
    hideRetryButton: true,
    showSelectedFiles: false,
    showRemoveButtonAfterComplete: false,
    browserBackButtonClose: true,
    showProgressDetails: true,
    closeAfterFinish: false,
    closeModalOnClickOutside: true,
    autoOpenFileEditor: true,
    proudlyDisplayPoweredByUppy: false,
    note: '.mp4, .mov or .mkv upto 1GB',
  };

  course$ = this.store.select(selectCourse);
  courseId$ = this.routerStore.getParam('courseId').pipe(tap((v) => log.debug('courseId$: ', v)));

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private readonly store: Store,
    private readonly routerStore: RouterStoreService
  ) {
    super();
  }

  ngOnInit() {
    this.state$.next({
      ...this.state$.value,
      fileURL: this.props.previewUrl ?? '',
      viewState: this.props.previewUrl ? 'complete' : '',
      fileName: this.props.previewUrl?.split('/').pop() ?? '',
    });
    this.props.componentRef = this;
    if (!this.props.uploadType) {
      this.props.uploadType = 'default';
    }
    if (this.props.allowedTypes?.length) {
      const fileExtensions = this.props.allowedTypes.map((mimeType) => {
        const parts = mimeType.split('/');
        return '.' + parts[1];
      });
      this.uppyFileTypes = fileExtensions;
    }

    const uppy = FormlyFieldFile.uppyMap.get(this.formControl.value);
    if (uppy) {
      this.uppy = uppy;
    } else {
      if (this.props.uploadType === 'dashboard') {
        // Uppy Dashboard
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
          },
        })
          .use(Dashboard, {})
          .use(GoldenRetriever, {
            serviceWorker: true,
          })
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      } else if (this.props.uploadType === 'drag-drop') {
        // Uppy Drag and Drop
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
          },
          locale: {
            strings: {
              dropHereOr: 'Upload a file %{browse}',
              browse: 'or drag and drop',
            },
          },
        })
          .use(DragDrop, {})
          .use(GoldenRetriever, {
            serviceWorker: true,
          })
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      } else {
        // Uppy Default
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
          },
          locale: {
            strings: {
              dropHereOr: '%{browse}',
              browse: 'Choose File',
            },
          },
        })
          .use(DragDrop, {})
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      }
    }
  }

  ngAfterViewInit(): void {
    this.updateNote();
    this.uploadModal?.close();
    this.initUppyCallbacks();
  }

  private initUppyCallbacks() {
    // Define common event handlers
    this.uppy.on('file-added', (file: UppyFile) => {
      this.validateFile(this.uppy, file);
      // this.state$.next({ ...this.state$.value, viewState: 'file-added' });
    });
    this.uppy.on('upload-progress', (file, progress) => {
      log.debug('upload-progress', file, progress);
      const progressPercent = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100);
      this.progress({
        bytesTotal: progress.bytesTotal,
        bytesUploaded: progress.bytesUploaded,
        progressPercent,
      });
      this.state$.next({ ...this.state$.value, viewState: 'upload-progress', currentProgress: progressPercent });
      log.debug('state$', this.state$.value);
    });
    this.uppy.on('upload', (...args: any[]) => {
      log.debug('upload', args[0]);
      this.printArgs('upload', ...args);
      this.uploadModal?.close();
      this.state$.next({ ...this.state$.value, viewState: 'upload-progress' });
      log.debug('state$', this.state$.value);
    });
    this.uppy.on('upload-success', (resp: any) => {
      this.printArgs('upload-success', resp);
      this.field.formControl.setValue(resp.s3Multipart.key);
      this.state$.next({ ...this.state$.value, viewState: 'upload-success' });
      this.cleanup();
      log.debug('state$', this.state$.value);
    });
    this.uppy.on('complete', (...args: any[]) => {
      this.printArgs('complete', ...args);
      this.complete();
      this.cleanup();
      setTimeout(() => {
        const previewUrl = cloudfrontURL + { ...this.state$.value }.fileURL;
        this.state$.next({
          ...this.state$.value,
          viewState: 'complete',
          fileURL: this.props?.previewUrl ?? previewUrl,
        });
        log.debug('state$', this.state$.value);
      }, 3000);
    });
    this.uppy.on('error', (...args: any[]) => {
      this.printArgs('error', ...args);
      this.cleanup();
    });
    this.uppy.on('upload-error', (...args: any[]) => {
      this.printArgs('upload-error', ...args);
      this.cleanup();
    });
    this.uppy.on('cancel-all', (...args: any[]) => {
      this.printArgs('cancel-all', ...args);
      this.props.onAbort?.(this.field);
      this.cleanup();
    });
  }

  validateFile(uppySetup: Uppy, file: UppyFile) {
    // Validate Type
    if (this.props.allowedTypes) {
      if (file?.type && !this.props.allowedTypes.includes(file?.type)) {
        uppySetup.removeFile(file.id);
        uppySetup.info(`Invalid file type: ${file.name}`, 'info');
        return;
      }
    }
    log.debug('file', file);

    // set formControl value
    this.fileKey = this.getFileKey(file);
    this.formControl.setValue(this.fileKey);

    // keep reference to uppy when reopening this specific file while upload is ongoing
    FormlyFieldFile.uppyMap.set(this.fileKey, uppySetup);

    // emit file info
    this.props.onUpload?.(
      {
        name: file.name,
        type: file.type ?? '',
      },
      this.field
    );
    this.state$.next({ ...this.state$.value, fileURL: this.fileKey, fileName: file.meta.name });
  }

  cleanup(): void {
    FormlyFieldFile.uppyMap.delete(this.fileKey);
  }

  handleFileInfo(event: string) {
    if (event == 'open') {
      if (this.props.uploader === 'device') {
        this.clickUploader();
      } else {
        this.uploadModal?.open();
      }
    }
    if (event == 'reset') {
      this.formControl.setValue('');
      this.props.previewUrl = '';
      this.uppy.cancelAll();
      this.state$.next({
        fileName: 'Uploading...',
        currentProgress: 0,
        viewState: '',
        fileURL: '',
      });
      this.updateNote();

      log.debug('formControl.value', this.formControl.value);
      log.debug('model', this.model);
    }
    if (event == 'abort') {
      this.uppy.cancelAll();
      this.state$.next({
        fileName: 'Uploading...',
        currentProgress: 0,
        viewState: '',
        fileURL: '',
      });
      this.updateNote();
    }
  }

  updateNote() {
    // Update uppy note to placeholder
    setTimeout(() => {
      const element = this.elementRef.nativeElement.querySelector('.uppy-DragDrop-note');
      if (element) {
        element.textContent = this.props.placeholder;
      }
    }, 500);
  }

  sanitizeUrl(url?: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url ?? '');
  }

  private clickUploader() {
    this.defaultDragDropContainer?.nativeElement.querySelector('input')?.click();
  }

  private printArgs = <T extends any[]>(source: string, ...args: T): void => {
    args.forEach((arg, idx) => {
      if (arg) {
        log.debug(`Param Idx: ${idx}; Source: ${source}; Type: ${typeof arg}; Value: `, arg);
      }
    });
  };

  private getFileKey(file: UppyFile): string {
    let folder = '';
    log.debug('file.meta: ', file.meta);
    Object.entries(_.omit(file.meta, ['name', 'type', 'relativePath'])).forEach(([key, value]) => {
      folder += `${key}=${value}/`;
    });

    return `${folder}${file.meta.name}`;
  }

  private readonly progress = _.throttle((progress: ProgressInfo) => {
    this.props.onProgress?.(progress, this.field);
  }, 500);

  private complete(): void {
    this.props.onComplete?.(this.field);
    // reset the old file to be able to upload the new file later
    this.uppy.cancelAll();
  }
}
