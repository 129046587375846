import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CURRENCY_LIST, PricingCurrencyList } from '../../../models';
import { encodeURL } from '../../../utils';
import { HttpService } from '../../http';
import { Logger } from '../../logger';

const log = new Logger('PricingCurrencyAPIService');

@Injectable({
  providedIn: 'root',
})
export class PricingCurrencyAPIService {
  constructor(private readonly http: HttpService) {}

  list(params = {} as object): Observable<any> {
    log.debug('API REQUEST pricing country - ', encodeURL(API_CURRENCY_LIST, params));
    return this.http.get<PricingCurrencyList[]>(encodeURL(API_CURRENCY_LIST, params));
  }
}
