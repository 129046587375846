import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, SectionV2 } from '../../models';
import { stringComparer } from '../store.util';

export const sectionAdapter: EntityAdapter<PartialEntity<SectionV2>> = createEntityAdapter<PartialEntity<SectionV2>>({
  selectId: (e) => e.id,
  sortComparer: (a, b) => stringComparer(a.date_created, b.date_created),
});

export const initialSectionState = sectionAdapter.getInitialState();

export type SectionEntityState = EntityState<PartialEntity<SectionV2>>;
