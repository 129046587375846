import * as _ from 'lodash';
import { Annotation, AnnotationDetail, ContentType, ContentTypeModels } from '../../models';
import { ModelMapper } from '../../models/mapper.model';
import { annotationKey } from './annotation.entity';

export const annotationMapper: ModelMapper<
  AnnotationDetail,
  Annotation,
  { contentTypes: Record<string, ContentType> }
> = {
  toStore: (apiModel, context) => {
    let { app_label, model } = context?.contentTypes[apiModel.content_type] ?? {
      app_label: undefined,
      model: undefined,
    };

    // Fixed if model is not found get content type from apiModel
    if (model === undefined) {
      let contentType = apiModel.content_type;
      if (Object.values(ContentTypeModels).includes(contentType as ContentTypeModels)) {
        model = contentType as ContentTypeModels;
      }
    }

    const data = {
      ...apiModel,
      app_label,
      model,
    };
    return {
      ...data,
      key: annotationKey(data),
    };
  },
  toApi: (model) => _.omit(model, ['app_label', 'model', 'key']),
};
