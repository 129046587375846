import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() openNavigation: boolean = false;
  @Input() position: string = 'left';
  @Input() contentClass: string = '';

  showDropdown: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  handleClickOutside() {
    this.showDropdown = false;
  }
}
