import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  templateUrl: './general-shell.component.html',
  styleUrls: ['./general-shell.component.scss'],
})
export class GeneralShellComponent implements OnInit {
  @HostBinding('class') private fullHeight = 'flex flex-col min-h-[100vh] kk';
  constructor() {}

  ngOnInit(): void {}
}
