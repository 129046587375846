import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface InputProps extends FormlyFieldProps {
  addonLeft: string;
  addonRight: string;
  allowedTypes?: string[];
  inputAppendClass?: string[];
}

export interface FormlyInputPasswordFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input-password' | Type<FormlyFieldInputPassword>;
}

@Component({
  selector: 'formly-field-input-password',
  template: `
    <ng-template #fieldTypeTemplate>
      <div class="relative w-full">
        <input
          class="w-full ltr:pr-8 rtl:pl-8"
          [type]="hide ? 'password' : 'text'"
          [formControl]="formControl"
          [class.input-field]="!props.addonLeft && !props.addonRight"
          [formlyAttributes]="field"
          [class.is-invalid]="showError"
          [accept]="props.allowedTypes"
          [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
          [placeholder]="props.placeholder"
        />
        <span class="absolute ltr:right-3 rtl:left-3 top-[13px] cursor-pointer" (click)="toggleVisibility()">
          <svg-icon
            [src]="hide ? '@assets/images/icons/eye-slash.svg' : '@assets/images/icons/eye.svg'"
            class="block w-[18px]"
          >
          </svg-icon>
        </span>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInputPassword extends FieldType<FieldTypeConfig<InputProps>> {
  get type() {
    return this.props.type || 'text';
  }

  hide = true;
  toggleVisibility(): void {
    this.hide = !this.hide;
  }
}
