import { createFeature, createReducer, on } from '@ngrx/store';
import { CategoryActions } from './category.actions';
import { categoryAdapter, initialCategoryState } from './category.entity';

export const categoryReducer = createReducer(
  initialCategoryState,
  on(CategoryActions.loadCategoriesSuccess, (state, { categories }) => categoryAdapter.upsertMany(categories, state))
);

export const categoryFeature = createFeature({
  name: 'category',
  reducer: categoryReducer,
});
