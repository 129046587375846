import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryEntityState, categoryAdapter } from './category.entity';

export const selectCategoryState = createFeatureSelector<CategoryEntityState>('category');

export const selectCategories = createSelector(selectCategoryState, categoryAdapter.getSelectors().selectAll);

export const selectCategoryEntities = createSelector(
  selectCategoryState,
  categoryAdapter.getSelectors().selectEntities
);

export const selectCategoryById = (categoryId: string) =>
  createSelector(selectCategoryEntities, (entities) => entities[categoryId]);
