import { Injectable } from '@angular/core';
import { TopCategory } from '@shared/models';
import {
  PurchaseHistory,
  PurchaseInvoice,
  RefundDetails,
  RefundHistory,
  RefundRequest,
} from '@shared/models/purchase-history.model';
import { CourseDetails, CoursesInstructor, ProductReviewsResponse, Section } from '@shared/store/courses';
import { Observable } from 'rxjs';
import {
  API_BEST_SALLER_COURSE,
  API_CART_RECENTLY_WISHLISTED,
  API_COURSE_DETAILS_COURSES_CONTENT,
  API_COURSE_DETAILS_COURSES_FAVORITE_TOGGLE,
  API_COURSE_DETAILS_COURSES_PRICE,
  API_COURSE_DETAILS_COURSES_REVIEW_POST,
  API_COURSE_DETAILS_COURSES_REVIEWS,
  API_COURSE_DETAILS_INFO_INSTRUCTOR_PREVIEW,
  API_COURSE_DETAILS_INSTRUCTOR_INFO,
  API_COURSE_DETAILS_PREVIEW,
  API_COURSE_DETAILS_REVIEW_PREVIEW,
  API_COURSE_DETAILS_SECTION_PREVIEW,
  API_COURSES_DETAILS_GET_PUBLIC,
  API_GET_PURCHASE_DETAILS,
  API_GET_PURCHASE_HISTORY,
  API_GET_PURCHASE_INVOICE,
  API_GET_PURCHASE_RECEIPT,
  API_GET_REFUND_REQUEST,
  API_GET_REFUND_VALIDATE,
  API_HOME_TOP_CATEGORIES,
  API_POST_SUBMIT_REFUND_REQUEST,
  API_PUBLISHED_COURSES,
  Course,
  CourseListPayload,
  encodeURL,
  HttpService,
  Logger,
  Pagination,
  UtilsService,
} from 'thkee-common';
const log = new Logger('CoursesService');

export interface IsEligible {
  is_eligible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CoursesDetailsService {
  constructor(private readonly http: HttpService, private utilsService: UtilsService) {}

  // Course details
  getCourseData(courseId: string): Observable<CourseDetails> {
    log.debug('API REQUEST getCourseData - ', encodeURL(API_COURSES_DETAILS_GET_PUBLIC, courseId));
    return this.http.get<CourseDetails>(API_COURSES_DETAILS_GET_PUBLIC.replace('<COURSE_ID>', courseId));
  }

  getCourseInstructorInfo(slug: string): Observable<CoursesInstructor> {
    log.debug('API REQUEST getCourseInstructorInfo - ', encodeURL(API_COURSE_DETAILS_INSTRUCTOR_INFO, slug));
    return this.http.get<CoursesInstructor>(API_COURSE_DETAILS_INSTRUCTOR_INFO.replace('<SLUG>', slug));
  }

  getCoursesPrice(params = {} as object): Observable<any> {
    log.debug('API REQUEST getCoursesPrice - ', encodeURL(API_COURSE_DETAILS_COURSES_PRICE, params));
    return this.http.get<any>(encodeURL(API_COURSE_DETAILS_COURSES_PRICE, params));
  }

  getCoursesContent(slug: string): Observable<any> {
    log.debug('API REQUEST getCoursesContent - ', encodeURL(API_COURSE_DETAILS_COURSES_CONTENT, slug));
    return this.http.get<any>(API_COURSE_DETAILS_COURSES_CONTENT.replace('<SLUG>', slug));
  }

  getCoursesReviews(slug: string): Observable<ProductReviewsResponse> {
    log.debug('API REQUEST getCoursesReviews - ', encodeURL(API_COURSE_DETAILS_COURSES_REVIEWS, slug));
    return this.http.get<ProductReviewsResponse>(API_COURSE_DETAILS_COURSES_REVIEWS.replace('<SLUG>', slug));
  }

  postCoursesReviews(course__pk: string, body: any): Observable<any> {
    log.debug('API REQUEST postCoursesReviews - ', API_COURSE_DETAILS_COURSES_REVIEW_POST, course__pk);
    return this.http.post<any>(API_COURSE_DETAILS_COURSES_REVIEW_POST.replace('<COURSE__PK>', course__pk), body);
  }

  coursesFavoriteToggle(body = {} as object): Observable<any> {
    log.debug('API REQUEST coursesFavoriteToggle - ', API_COURSE_DETAILS_COURSES_FAVORITE_TOGGLE, body);
    return this.http.post<any>(API_COURSE_DETAILS_COURSES_FAVORITE_TOGGLE, body);
  }

  // Category page
  getBestSellerCourse(params = {} as object): Observable<CourseDetails> {
    log.debug('API REQUEST getCourseData - ', encodeURL(API_BEST_SALLER_COURSE, params));
    return this.http.get<CourseDetails>(encodeURL(API_BEST_SALLER_COURSE, params));
  }

  // Home page
  getCourses(params = {} as CourseListPayload): Observable<Pagination<Partial<Course>>> {
    log.debug('API REQUEST getCourses - ', encodeURL(API_PUBLISHED_COURSES, params));
    return this.http.get<Pagination<Partial<Course>>>(encodeURL(API_PUBLISHED_COURSES, params));
  }

  getHomeTopCategories(params = {} as CourseListPayload): Observable<TopCategory[]> {
    return this.utilsService.get(encodeURL(API_HOME_TOP_CATEGORIES, params));
  }

  // Purchase history
  getPurchaseHistory(params = {} as CourseListPayload): Observable<PurchaseHistory> {
    return this.http.get<PurchaseHistory>(encodeURL(API_GET_PURCHASE_HISTORY, params));
  }

  getRefundeHistory(params = {} as CourseListPayload): Observable<RefundHistory> {
    return this.http.get<RefundHistory>(encodeURL(API_GET_REFUND_REQUEST, params));
  }

  getPurchaseInvoice(id: string): Observable<PurchaseInvoice> {
    return this.http.get<PurchaseInvoice>(API_GET_PURCHASE_INVOICE.replace('<ID>', id));
  }

  getPurchaseReceipt(id: string): Observable<PurchaseInvoice> {
    return this.http.get<PurchaseInvoice>(API_GET_PURCHASE_RECEIPT.replace('<ID>', id));
  }

  checkRefundAvailablity(params = {} as Object): Observable<IsEligible> {
    return this.utilsService.get(encodeURL(API_GET_REFUND_VALIDATE, params));
  }

  getRefundDetails(params = {} as Object): Observable<RefundDetails> {
    return this.http.get<RefundDetails>(encodeURL(API_GET_PURCHASE_DETAILS, params));
  }

  postRefundRequest(body = {} as object): Observable<RefundRequest> {
    return this.http.post<RefundRequest>(API_POST_SUBMIT_REFUND_REQUEST, body);
  }

  // Cart
  getRecentlyWishlisted(params = {} as CourseListPayload): Observable<Pagination<Partial<Course>>> {
    return this.http.get<Pagination<Partial<Course>>>(encodeURL(API_CART_RECENTLY_WISHLISTED, params));
  }

  // Preview course
  getPreviewCoursesDetails(slug: string): Observable<CourseDetails> {
    return this.http.get<CourseDetails>(API_COURSE_DETAILS_PREVIEW.replace('<SLUG>', slug));
  }
  getPreviewCourseInstructorInfo(slug: string): Observable<CoursesInstructor> {
    return this.http.get<CoursesInstructor>(API_COURSE_DETAILS_INFO_INSTRUCTOR_PREVIEW.replace('<SLUG>', slug));
  }
  getPreviewCourseContent(slug: string): Observable<Section[]> {
    return this.http.get<Section[]>(API_COURSE_DETAILS_SECTION_PREVIEW.replace('<SLUG>', slug));
  }
  getPreviewCoursesReviews(slug: string): Observable<ProductReviewsResponse> {
    return this.http.get<ProductReviewsResponse>(API_COURSE_DETAILS_REVIEW_PREVIEW.replace('<SLUG>', slug));
  }
}
