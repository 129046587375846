import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, QuizAnswerV2 } from '../../models';

export const quizAnswerAdapter: EntityAdapter<PartialEntity<QuizAnswerV2>> = createEntityAdapter<
  PartialEntity<QuizAnswerV2>
>({
  selectId: (e) => e.id,
});

export const initialquizAnswerState = quizAnswerAdapter.getInitialState();

export type QuizAnswerEntityState = EntityState<PartialEntity<QuizAnswerV2>>;
