import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { isDefined } from '../../utils';
import { selectQuizAnswersState } from './course.selectors';

export const selectQuizAnswers = createSelector(selectQuizAnswersState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectQuizAnswer = (quizQuestionId: string) =>
  createSelector(selectQuizAnswersState, (state) => state.entities[quizQuestionId]);

export const selectQuizAnswersByQuizQuestionId = (quizQuestionId: string) =>
  createSelector(selectQuizAnswersState, (state) =>
    _.values(state.entities).filter((quizAnswer) => quizAnswer?.quiz_question === quizQuestionId)
  );

export const selectQuizAnswerEntities = createSelector(selectQuizAnswersState, (state) => state.entities);
