import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CourseV2, PartialEntity } from '../../models';
import { stringComparer } from '../store.util';

export const courseAdapter: EntityAdapter<PartialEntity<CourseV2>> = createEntityAdapter<PartialEntity<CourseV2>>({
  selectId: (e) => e.id,
  sortComparer: (a, b) => stringComparer(b.version, a.version),
});

export const initialCourseState = courseAdapter.getInitialState();

export type CourseEntityState = EntityState<PartialEntity<CourseV2>>;
