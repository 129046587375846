import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoursesService } from '../../services';
import { TopicActions } from './topic.actions';

@Injectable()
export class TopicEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicActions.loadTopics),
      switchMap(() =>
        this.coursesService.getTopics().pipe(
          map((topics) => TopicActions.loadTopicsSuccess({ topics })),
          catchError(() => of(TopicActions.loadTopicsSuccess({ topics: [] })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private coursesService: CoursesService) {}
}
