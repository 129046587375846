import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encodeURL } from 'projects/thkee-common/src/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_COURSE_DETAILS_COURSES_PRICE } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CardPricingService {
  constructor(private http: HttpClient) {}

  // Pricing API
  getCoursesPrice(params = {} as object): Observable<any> {
    return this.http.get<any>(encodeURL(API_COURSE_DETAILS_COURSES_PRICE, params));
  }

  // Pricing loader
  fetchCoursesPrices(courses: any, isArray: boolean = true) {
    if (
      courses != null &&
      typeof courses === 'object' &&
      (Array.isArray(courses) ? courses.length > 0 : Object.keys(courses).length > 0)
    ) {
      let loadingPrices: { [key: string]: BehaviorSubject<boolean> } = {};
      const isLoading = Object.values(loadingPrices).some((loading) => loading.value);
      if (isArray) {
        const courseIds = courses.map((course: { code: any }) => course.code).join(',');
        if (!isLoading) {
          courses.forEach((course: any) => {
            if (!loadingPrices[course.course_id]) {
              loadingPrices[course.course_id] = new BehaviorSubject<boolean>(true);
            } else {
              loadingPrices[course.course_id].next(true);
            }
          });
          // recheck this also, because of loading twice
          this.getCoursesPrice({ course_ids: courseIds }).subscribe((res: any) => {
            courses.forEach((course: any) => {
              if (res.courses[course.code]) {
                course.priceDetails = res.courses[course.code];
              }
              loadingPrices[course.course_id].next(false);
            });
          });
        }
      } else {
        if (!loadingPrices[courses.course_id]) {
          loadingPrices[courses.course_id] = new BehaviorSubject<boolean>(true);
        } else {
          loadingPrices[courses.course_id].next(true);
        }
        // recheck it, loading twice with the above
        this.getCoursesPrice({ course_ids: courses.code }).subscribe((res: any) => {
          if (res.courses[courses.code]) {
            courses.priceDetails = res.courses[courses.code];
          }
          loadingPrices[courses.course_id].next(false);
        });
      }
      return { loadingPrices: loadingPrices, courses: courses };
    } else {
      return { loadingPrices: {}, courses: courses };
    }
  }
}
