import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, StudentProgressV2 } from '../../models';

export const studentProgressAdapter: EntityAdapter<PartialEntity<StudentProgressV2>> = createEntityAdapter<
  PartialEntity<StudentProgressV2>
>({
  selectId: (e) => e.id,
});

export const initialStudentProgressState = studentProgressAdapter.getInitialState();

export type StudentProgressEntityState = EntityState<PartialEntity<StudentProgressV2>>;
