<div *ngFor="let message of messages | async" class="flex relative min-w-[100vw] max-w-[100vw] px-10" [ngClass]="wrapperClasses[message.type]">
  <div class="inline-flex h-10 w-full items-center justify-center">
    <div class="text-xs font-normal leading-snug md:text-sm md:font-medium">
      <span [innerHTML]="message.message"></span>
    </div>
    <div>
      <button *ngFor="let action of message.actions" [thkLibProcessing]="processing[message.id || '']" size="xs" class="btn btn-primary btn-xs mx-1" (click)="clickAction(message, action)">{{action.title}}</button>
    </div>
  </div>
  <div class="absolute ltr:right-0 rtl:left-0 top-[50%] h-[100%] flex items-center transform translate-y-[-50%]">
    <span class="flex justify-center h-[100%] p-3" (click)="close(message)">
      <svg-icon src="@assets/images/icons/x-mark.svg" class="btn-icon h-3.5 w-3.5"></svg-icon>
    </span>
  </div>
</div>
