import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectQueryParam, selectRouteData, selectRouteNestedParam, selectRouteNestedParams, selectUrl } from './router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouterStoreService {
  constructor(private readonly store: Store) {}

  getParams(): Observable<{ [key: string]: string }> {
    return this.store.select(selectRouteNestedParams);
  }

  getParam(key: string): Observable<string> {
    return this.store.select(selectRouteNestedParam(key));
  }

  getRouteData(): Observable<any> {
    return this.store.select(selectRouteData);
  }

  getUrl(): Observable<string> {
    return this.store.select(selectUrl);
  }

  getQuery(key: string) {
    return this.store.select(selectQueryParam(key))
  }
}
