import { Inject, Injectable } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from 'thkee-common';
const DEFAULT_LANGUAGE = 'ar-SA';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  /**
   * @deprecated
   */
  supportedLanguages!: string[];
  readonly supportedLanguageObjects = [
    { code: 'en-US', name: 'English' },
    { code: 'ar-SA', name: 'Arabic' },
  ];

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private cookieService: CookieService
  ) {}

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguagesObject The list of supported languages.
   */
  init() {
    const lang = LocalStorage.getText('lang') || this.cookieService.get('lang') || DEFAULT_LANGUAGE;
    this.saveLang(lang);
  }

  private switchLang(lang: string) {
    if (this.language !== lang) {
      this.saveLang(lang);
      this.refreshApp();
    }
  }

  private refreshApp() {
    window.location.replace([
      window.origin,
      this.get2CodesLang(this.language || DEFAULT_LANGUAGE) === this.get2CodesLang(DEFAULT_LANGUAGE)
        ? ''
        : this.get2CodesLang(this.language),
      this.router.url.slice(1)
    ].filter(Boolean).join('/'));
  }

  private saveLang(lang: string) {
    LocalStorage.setText('lang', lang);
    const tenYearMs = 10 * 365 * 24 * 60 * 60 * 1000;
    // We need to make this cookie field last as long as possible
    this.cookieService.set('lang', lang, Date.now() + tenYearMs, '/');
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {}

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    this.switchLang(language);

    // FIXME:: Need to check and replace this logic
    this.changeCssFile(language);
  }

  get langName() {
    return this.supportedLanguageObjects.find(lang => lang.code === this.language)?.name
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.cookieService.get('lang') || LocalStorage.getText('lang') || DEFAULT_LANGUAGE;
  }

  /**
   * Load language-specific styles based on the provided language.
   * @param lang The language code.
   */
  changeCssFile(lang: string) {
    // Update the lang attribute of the HTML element
    this.document.documentElement.lang = lang.split('-')[0];

    let headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById('langCss') as HTMLLinkElement;
    let bundleName = `${lang}.css`;
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }

  private get2CodesLang(lang: string) {
    return lang.slice(0, 2);
  }
}
