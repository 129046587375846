import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@shared';
import { AuthActions, selectUser } from '@shared/store';
import { filter } from 'rxjs';
import { User } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-instructor-header',
  templateUrl: './instructor-header.component.html',
  styleUrls: ['./instructor-header.component.scss'],
})
export class InstructorHeaderComponent implements OnInit {
  heading: any;
  @Input() fullSize: boolean = false;

  user?: User;

  constructor(public viewContainerRef: ViewContainerRef, private store: Store) {}

  ngOnInit(): void {
    this.fetchUser();
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  switchToStudent() {
    window.location.replace('/student/dashboard');
  }

  private fetchUser() {
    this.store.select(selectUser)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(user => this.user = user);
  }
}
