import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CourseContentActions, selectCourseContent } from '@shared/store';
import * as _ from 'lodash';
import { Observable, Subscription, combineLatest, map, of, tap } from 'rxjs';
import {
  AnnotationDetail,
  CourseStatus,
  CourseV2,
  Logger,
  RouterStoreService,
  selectAnnotationHistory,
  selectCourse,
} from 'thkee-common';

const log = new Logger('CourseHeaderComponent');

@Component({
  selector: 'app-course-header',
  templateUrl: './course-header.component.html',
  styleUrls: ['./course-header.component.scss'],
})
export class CourseHeaderComponent implements OnInit, OnDestroy {
  course$!: Observable<CourseV2>;
  annotations$!: Observable<AnnotationDetail[]>;
  courseId$: Observable<string>;
  courseContentSubscription: Subscription = new Subscription();
  lestPath?: string;

  constructor(private store: Store, private readonly routerStoreService: RouterStoreService, private router: Router) {
    this.courseId$ = this.routerStoreService.getParam('courseId');
  }

  ngOnInit(): void {
    this.course$ = this.store.select(selectCourse);
    this.annotations$ = combineLatest([this.course$, this.store.select(selectAnnotationHistory)]).pipe(
      map(([course, annotations]) =>
        annotations.filter((annotation) => {
          return annotation.course === (typeof course.source === 'object' ? course.source?.id : course.source);
        })
      ),
      map((annotations) => {
        return annotations as AnnotationDetail[];
      }),
      tap((annotations) => log.debug('annotations', annotations))
    );

    const url = this.router.url.split('/');
    this.lestPath = _.last(url);
  }

  get canViewIssues$(): Observable<boolean> {
    return (
      this.course$?.pipe(
        map(
          (course) =>
            !!course.status &&
            [CourseStatus.REJECTED, CourseStatus.IN_REVIEW, CourseStatus.PUBLISHED].includes(course.status)
        )
      ) ?? of(false)
    );
  }

  // CLP preview
  previewCLP(id: string | null) {
    const queryParams: NavigationExtras = { queryParams: { preview: true, from: this.lestPath } };
    const url = this.router.serializeUrl(this.router.createUrlTree([`/course/${id}`], queryParams));
    window.open(url, '_blank');
  }

  // Learning lab preview
  isLoading: boolean = false;
  goToLearningLab(id: string | null) {
    if (id) {
      this.isLoading = true;
      this.store.dispatch(CourseContentActions.resetCourseOverview({ overview: {} }));
      this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: id, preview: true }));
      this.courseContentSubscription.add(
        this.store.select(selectCourseContent).subscribe({
          next: (courses) => {
            if (courses.courseOrerview.code) {
              const code = courses.courseOrerview.code;
              const section = courses.courseOrerview.last_recorded.section_chain_id;
              const subsection = courses.courseOrerview.last_recorded.subsection_chain_id;
              if (code) {
                this.isLoading = false;
                const queryParams: NavigationExtras = { queryParams: { preview: true, from: this.lestPath } };
                const url = this.router.serializeUrl(
                  this.router.createUrlTree(
                    [`/learning-lab/${id}/overview/${section}/${subsection}/${code}`],
                    queryParams
                  )
                );
                window.open(url, '_blank');
              }
            }
          },
          error: (err) => {
            this.isLoading = false;
          },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.courseContentSubscription.unsubscribe();
  }
}
