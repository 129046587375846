import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LectureV2, PartialEntity } from '../../models';

export const lectureAdapter = createEntityAdapter<PartialEntity<LectureV2>>({
  selectId: (e) => e.id,
});

export const initialLectureState = lectureAdapter.getInitialState();

export type LectureEntityState = EntityState<PartialEntity<LectureV2>>;
