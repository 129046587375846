import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DomRectService } from '../../services/dom-rect.service';
import { DropdownDirective } from './dropdown.directive';

@Directive({
  selector: '[thkDropdownMenu]',
  providers: [DomRectService]
})
export class DropdownMenuDirective implements AfterViewInit {
  rect = new ReplaySubject<DOMRect>(1);
  constructor(
    private dropdown: DropdownDirective,
    private elRef: ElementRef<HTMLElement>,
    private domRectService: DomRectService
  ) {
    if (this.dropdown.container === 'body') {
      this.elRef.nativeElement.classList.add('fixed');
    } else {
      this.elRef.nativeElement.classList.add('absolute');
    }
    this.elRef.nativeElement.classList.add('hidden');
  }

  ngAfterViewInit(): void {
    this.domRectService.rectChanges.subscribe(rect => this.rect.next(rect))
  }

  hide() {
    this.elRef.nativeElement.classList.add('hidden');
    this.elRef.nativeElement.classList.add('hide');
    this.elRef.nativeElement.classList.remove('block');
    this.elRef.nativeElement.classList.remove('show');
  }

  show() {
    this.elRef.nativeElement.classList.add('block');
    this.elRef.nativeElement.classList.add('show');
    this.elRef.nativeElement.classList.remove('hidden');
    this.elRef.nativeElement.classList.remove('hide');
  }

  updatePosition(rect: Partial<CSSStyleDeclaration>) {
    Object.assign(this.elRef.nativeElement.style, rect);
  }
}
