import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartialEntity, QuizQuestionV2 } from '../../models';

export const quizQuestionAdapter: EntityAdapter<PartialEntity<QuizQuestionV2>> = createEntityAdapter<
  PartialEntity<QuizQuestionV2>
>({
  selectId: (e) => e.id,
});

export const initialquizQuestionState = quizQuestionAdapter.getInitialState();

export type QuizQuestionEntityState = EntityState<PartialEntity<QuizQuestionV2>>;
