import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input() data: any = 0;
  @Input() size: string = 'sm' || 'md' || 'lg';
  @Input() type: string = 'fill' || 'outline';

  constructor() {}

  ngOnInit(): void {}
}
