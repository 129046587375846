<div *ngIf="isOpened" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fadeIn fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div
      [class]="alignment"
      class="modal-container flex min-h-full items-end items-center justify-center text-center"
      [ngClass]="{ slideInLeft: alignment === 'left' }"
    >
      <div
        [class]="alignment"
        class="modal-box relative transform overflow-hidden rounded-lg bg-white ltr:text-left rtl:text-right shadow-xl transition-all sm:my-8"
        [ngClass]="customClass"
      >
        <ng-container *ngIf="!title; else modalHeader">
          <div #header><ng-content select="[header]"></ng-content></div>
          <ng-container *ngIf="!header.hasChildNodes()">
            <ng-container *ngTemplateOutlet="modalCloseOnly"></ng-container>
          </ng-container>
        </ng-container>
        <div class="bg-white">
          <ng-content select="[content]"></ng-content>
        </div>
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #modalHeader>
  <div class="modal-header flex items-center border-b border-slate-200 px-6 py-4">
    <div class="font-semibold">{{ title }}</div>
    <a
      (click)="close()"
      class="ar:ml-0 ar:mr-auto ml-auto cursor-pointer rounded-sm text-slate-400 hover:bg-slate-100 hover:text-slate-800"
    >
      <svg-icon src="@assets/images/icons/x-mark.svg" class="h-6 w-6"></svg-icon>
    </a>
  </div>
</ng-template>

<ng-template #modalCloseOnly>
  <a
    (click)="close()"
    class="ar:right-[inherit] ar:left-6 absolute right-6 top-4 cursor-pointer rounded-sm text-slate-400 hover:bg-slate-100 hover:text-slate-800"
  >
    <svg-icon src="@assets/images/icons/x-mark.svg" class="h-6 w-6"></svg-icon>
  </a>
</ng-template>

<ng-template #modalFooter>
  <div class="modal-footer border-t border-slate-200">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>
