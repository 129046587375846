<div class="relative">
  <ng-container *ngIf="isLoading; else loadedReviews">
    <ng-container *ngTemplateOutlet="loader"></ng-container>
  </ng-container>
  <ng-template #loadedReviews>
    <ng-container *ngIf="currentActivity; else noActivity">
      <ng-container *ngTemplateOutlet="activity"></ng-container>
    </ng-container>
    <ng-template #noActivity>
      <ng-container *ngTemplateOutlet="emptyActivity"></ng-container>
    </ng-template>
  </ng-template>
</div>

<ng-template #activity>
  <app-learning-lab-activity-video (event)="activityHandler($event)"
    *ngIf="currentActivity.type === 'lecture' && currentActivity.lecture?.type === 'video'"
    [hidePrev]="prev.length ? false : true" [hideNext]="next.length ? false : true" [prevData]="prevData"
    [nextData]="nextData"></app-learning-lab-activity-video>
  <app-learning-lab-activity-article (event)="activityHandler($event)"
    *ngIf="currentActivity.type === 'lecture' && currentActivity.lecture?.type === 'article'"
    [hidePrev]="prev.length ? false : true" [hideNext]="next.length ? false : true"></app-learning-lab-activity-article>
  <app-learning-lab-activity-quiz (event)="activityHandler($event)" *ngIf="currentActivity.type === 'quiz'">
  </app-learning-lab-activity-quiz>
  <app-learning-lab-activity-assignment (event)="activityHandler($event)" *ngIf="currentActivity.type === 'assignment'">
  </app-learning-lab-activity-assignment>
</ng-template>
<ng-template #emptyActivity>
  <div class="py-12 text-center">
    <svg-icon src="@assets/images/not-found.svg" class="mx-auto my-8 block w-72"></svg-icon>
    <h3 class="mb-3" i18n>Oh man, You don't supposed to see this!</h3>
    <p i18n>Please find your way to the <a href="../">homepage</a> or get help <a href="#">here</a>. And remember you didn't see anything!</p>
  </div>
</ng-template>
<!-- Loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full h-[460px] bg-black bg-opacity-0 z-[99]">
    <div class="loader w-12 pt-2">
      <svg class="text-primary m-auto h-10 w-10 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg>
    </div>
  </div>
</ng-template>
