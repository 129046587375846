import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectIsInstructor } from '@shared';
import { skipUndefined } from 'thkee-common';

@Component({
  selector: 'app-new-course-header',
  templateUrl: './new-course-header.component.html',
  styleUrls: ['./new-course-header.component.scss'],
})
export class NewCourseHeaderComponent implements OnInit {
  isInstructor$ = this.store.select(selectIsInstructor)
    .pipe(skipUndefined());

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {}
}
