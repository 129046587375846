import { Injectable } from '@angular/core';
import { Subject, filter, map, share } from 'rxjs';
import { NotificationMessage } from '../components';

type ActionStatus = 'processing' | 'success' | 'failed';
interface ActionTracker {
  id: string;
  status: ActionStatus;
  metadata?: { messages?: NotificationMessage[] };
}

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerService {
  action$ = new Subject<ActionTracker>();
  constructor() { }

  /**
   *
   * @param id you only need to pass this param when need more uniq during random value,
   * currently test with 10M item without duplicating
   * @returns
   */
  static uniqToken(id?: string) {
    return ['action-tracker', Date.now(), id || '', Math.random()].join('_');
  }

  success(id: string) {
    this.next({ id, status: 'success' })
  }

  fail(id: string, metadata?: { messages?: NotificationMessage[] }) {
    this.next({ id, status: 'failed', metadata })
  }

  start(id: string) {
    this.next({ id, status: 'processing' })
  }

  getSuccess(id: string) {
    return this.get(id).pipe(filter(record => record.status === 'success'))
  }

  getFailed(id: string) {
    return this.get(id).pipe(filter(record => record.status === 'failed'))
  }

  isProcessing(id: string) {
    return this.get(id).pipe(map(v => v.status === 'processing'))
  }

  private get(id: string) {
    return this.action$.pipe(filter(record => record.id === id), share());
  }

  private next(record: ActionTracker) {
    this.action$.next(record)
  }
}
