import { createAction, props } from '@ngrx/store';
import { CheckoutPayload } from './checkout-state.model';

export const CheckoutActions = {
  loadPaymentMethods: createAction('[Checkout] Load payment methods'),
  loadPaymentMethodsSuccess: createAction('[Checkout] Load payment methods success', props<any>()),
  loadPaymentMethodsFail: createAction('[Checkout] Load payment methods fail', props<{ error: unknown }>()),
  processCheckout: createAction('[Checkout] Process checkout', props<CheckoutPayload>()),
  processCheckoutSuccess: createAction('[Checkout] Process checkout success', props<{ data: any }>()),
  processCheckoutFail: createAction('[Checkout] Process checkout fail', props<{ error: unknown }>()),
  verifyPayment: createAction('[Checkout] Verify Payment', props<{ token: string }>()), // TODO: Deprecated
  verifyPaymentSuccess: createAction('[Checkout] Verify Payment success', props<any>()), // TODO: Deprecated
  verifyPaymentFail: createAction('[Checkout] Verify Payment fail', props<{ error: unknown }>()), // TODO: Deprecated
  loadOrder: createAction('[Order] Load order', props<{ orderId: string }>()),
  loadOrderSuccess: createAction('[Order] Load order success', props<{ order: any }>()),
  loadOrderFail: createAction('[Order] Load order fail', props<{ error: unknown }>()),
  paypalCallback: createAction('[Checkout] Paypal Callback', props<{ token: string }>()),
  paypalCallbackSuccess: createAction('[Checkout] Paypal Callback success', props<{ order: any }>()),
  paypalCallbackFail: createAction('[Checkout] Paypal Callback fail', props<{ error: unknown }>()),

  tapCallback: createAction('[Checkout] Tap Payment Callback', props<{ tap_id: string }>()),
  tapCallbackSuccess: createAction('[Checkout] Tap Payment Callback success', props<{ order: any }>()),
  tapCallbackFail: createAction('[Checkout] Tap Payment Callback fail', props<{ error: unknown }>()),
};
