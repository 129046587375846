<div class="activity-heading h-[60px]">
  <div class="flex h-full items-center justify-between bg-[#2E0085] px-4 py-3 text-white">
    <div class="grow truncate text-lg font-medium">{{ title }}</div>
    <div class="action flex gap-4">
      <ng-container *ngIf="assignmentState === 'completed'">
        <button *ngIf="!hidePrev" class="btn btn-white btn-sm" (click)="emitEvent('prev')" i18n>Back</button>
        <button *ngIf="!hideNext" class="btn btn-primary btn-sm" (click)="emitEvent('next')" i18n>Next</button>
      </ng-container>
      <ng-container *ngIf="assignmentState === 'start'">
        <button *ngIf="!hidePrev" class="btn btn-white btn-sm" (click)="emitEvent('next')" i18n>Skip Assignment</button>
        <button *ngIf="!hideNext" class="btn btn-primary btn-sm" (click)="startAssignment()" i18n>Start Assignment</button>
      </ng-container>
      <ng-container *ngIf="assignmentState === 'edit'">
        <button class="btn btn-white btn-sm" (click)="previewAssignment()" i18n>Preview</button>
      </ng-container>
      <ng-container *ngIf="assignmentState === 'preview'">
        <button class="btn btn-white btn-sm" (click)="startAssignment()" i18n>Back to Edit</button>
        <button *ngIf="!hideNext" class="btn btn-primary btn-sm" (click)="confirmSubmitAssignment()" i18n>
          Submit
        </button>
      </ng-container>
    </div>
  </div>
</div>
<app-common-modal-confirm #confirmSubmit
  i18n-title
  i18n-message
  i18n-confirmText
  title="Confirm submission?"
  message="If you “Submit” your assignment you can’t edit or can’t do anything with your assignment."
  confirmText="Yes, I confirm" (confirm)="submitAssignment()">
</app-common-modal-confirm>
<div class="article-container flex max-h-[500px] min-h-[500px] flex-col bg-gradient-to-t from-[#31008A] to-purple p-4"
  [class.full-screen]="fullScreen">
  <div class="article-view relative flex h-full grow flex-col overflow-hidden rounded bg-white">
    <div *ngIf="assignmentState !== 'edit'"
      class="view-control flex max-h-[50px] items-center justify-between bg-slate-200 px-4 py-[10px]">
      <div></div>
      <div class="flex items-center gap-2">
        <div>
          <div>
            <svg-icon src="@assets/images/icons/minus.svg" class="h-5 w-5 cursor-pointer"
              (click)="emitEvent('zoom-out')"></svg-icon>
          </div>
        </div>
        <div>
          <input
            class="input-field input-xs block w-[50px] rounded-[1px] border-slate-800 bg-slate-800 px-[8px] py-[1px] text-[10px] font-semibold text-white"
            type="number" max="150" min="75" readonly [(ngModel)]="inputValue" />
        </div>
        <div>
          <svg-icon src="@assets/images/icons/plus.svg" class="h-5 w-5 cursor-pointer" (click)="emitEvent('zoom-in')">
          </svg-icon>
        </div>
      </div>
      <div>
        <div class="cursor-pointer" (click)="emitEvent('full-screen'); fullScreen = !fullScreen">
          <svg-icon *ngIf="fullScreen" src="@assets/images/icons/arrows-pointing-in.svg" class="h-[18px] w-[18px]">
          </svg-icon>
          <svg-icon *ngIf="!fullScreen" src="@assets/images/icons/arrows-pointing-out.svg" class="h-[18px] w-[18px]">
          </svg-icon>
        </div>
      </div>
    </div>
    <div class="flex h-full overflow-hidden">
      <app-scrollable-content>
        <div class="p-6" [ngStyle]="{ 'font-size': inputValue + '%' }">
          <ng-container *ngIf="assignmentState === 'start'">
            <ng-container *ngTemplateOutlet="questions"></ng-container>
          </ng-container>
          <ng-container *ngIf="assignmentState === 'edit'">
            <ng-container *ngTemplateOutlet="questionsEdit"></ng-container>
          </ng-container>
          <ng-container *ngIf="assignmentState === 'preview' || assignmentState === 'completed'">
            <ng-container *ngTemplateOutlet="questionsPreview"></ng-container>
          </ng-container>
        </div>
      </app-scrollable-content>
    </div>
  </div>
</div>

<ng-template #questions>
  <div class="question-list flex max-w-2xl flex-col gap-1">
    <div class="font-lg mb-2 font-bold" i18n="face_challenge_and_answer_questions|Title encouraging students to engage with the assignment questions in learing lab">Face the Challenge: Answer These Questions:</div>
    <ng-container *ngIf="data?.assignment?.questions?.length">
      <div *ngFor="let q of data?.assignment?.questions; let i = index">{{ i + 1 }}. {{ q.question }}</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #questionsEdit>
  <div class="question-edit">
    <form [formGroup]="form" #aForm="ngForm">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </div>
</ng-template>

<ng-template #questionsPreview>
  <!-- max-w-2xl -->
  <div class="question-preview flex flex-col gap-4">
    <ng-container *ngIf="data?.assignment?.questions?.length">
      <div *ngFor="let q of data?.assignment?.questions; let i = index"
        class=" border border-slate-200 rounded-md bg-slate-100">
        <div class="flex items-start px-4 pt-3">
          <span class="text-slate-900 font-normal w-32 flex-shrink-0">
            <ng-container i18n="Question label|Label for question number and text">Question ( {{ i + 1 }} ):</ng-container>
          </span>
          <span class="text-slate-700 font-semibold flex-1">{{ q.question }}</span>
        </div>
        <div class="flex items-start px-4 py-3">
          <span class="text-slate-400 font-normal w-32 flex-shrink-0">
            <ng-container i18n="Your answer label|Label for user's answer">Your Answer:</ng-container>
          </span>
          <span class="text-slate-600 font-normal flex-1">{{ model[q.id] }}</span>
        </div>
        <div *ngIf="q.feedback" class="flex flex-row gap-3 relative p-4 bg-white">
          <span class="w-1 rounded bg-purple"></span>
          <div class="flex flex-col gap-1">
            <span class="text-slate-700 font-semibold">
              <ng-container i18n="Instructor feedback label|Label for instructor's feedback">Instructor Feedback:</ng-container>
            </span>
            <span class="text-slate-600 font-normal">
              This question is not written appropriately. You need to improve this . And try to write some elaborately.
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
