<div class="mt-auto">
  <div class="flex flex-col gap-2 pb-4">
    <div class="heading_part">
      <h3 class="mb-2 font-medium" i18n>Sign in</h3>
      <p class="text-slate-500" i18n>Welcome back! please enter your credentials</p>
    </div>
  </div>

  <app-messages [messages]="messages" class="mb-4" *ngIf="messages"></app-messages>

  <form [formGroup]="form" (submit)="onLogin()">
    <formly-form [form]="form" [fields]="fields"></formly-form>
    <div class="flex justify-end">
      <a routerLink="/account/forgot-password" class="text-sm font-medium" i18n>Forgot Password?</a>
    </div>
    <div class="flex flex-col gap-y-3 pt-8">
      <button type="submit" class="btn btn-primary w-full" [thkLibProcessing]="!!(loggingIn$ | async)" i18n>Sign
        in</button>
      <button type="button" class="btn w-full">
        <span>
          <svg-icon src="@assets/images/google-icon.svg" class="block w-[18px]"></svg-icon>
        </span><span i18n>Sign in with Google</span>
      </button>
    </div>
  </form>
</div>