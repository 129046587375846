import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { environment } from '@env/environment';
import { CredentialsService } from '../auth/credentials.service';
import { GUEST_CART_TOKEN, PUBLIC_RESOURCES } from './http.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService, @Inject(LOCALE_ID) private localeId: string) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('@assets') && /@assets/.test(request.url)) {
      // This url replace for internal fetch
      request = request.clone({ url: request.url.replace('@assets', 'assets') });
    } else {
      if (!/^(http|https):/i.test(request.url)) {
        request = request.clone({
          url: environment.serverUrl + request.url,
          ...((this.localeId && { headers: request.headers.set('accept-language', this.localeId.slice(0, 2)) }) || {}),
        });

        // Custom header
        if (request.context.get(GUEST_CART_TOKEN)) {
          return this.credentialsService.getToken().pipe(
            switchMap((token) => {
              if (token) {
                request = request.clone({
                  headers: request.headers.set('Authorization', 'Bearer ' + token),
                });
                request = request.clone({
                  headers: request.headers.set('x-gt', request.context.get(GUEST_CART_TOKEN)),
                });
              } else {
                request = request.clone({
                  headers: request.headers.set('x-gt', request.context.get(GUEST_CART_TOKEN)),
                });
              }

              return next.handle(request);
            })
          );
        }

        if (!request.context.get(PUBLIC_RESOURCES)) {
          return this.credentialsService.getToken().pipe(
            switchMap((token) => {
              if (token) {
                request = request.clone({
                  headers: request.headers.set('Authorization', 'Bearer ' + token),
                });
              }

              return next.handle(request);
            })
          );
        }
      }
    }

    return next.handle(request);
  }
}
