// Initial State
export const initialReviewsState: ReviewsResponse = {
  next: null,
  previous: null,
  count: 0,
  results: undefined,
  allCoursesReviews: {
    global_average_rating: 0,
    total_reviews_count: 0,
    new_reviews_count_since_last_month: 0,
    percentage_growth_since_last_month: 0,
    global_star_count: {
      '1.0': 0,
      '2.0': 0,
      '3.0': 0,
      '4.0': 0,
      '5.0': 0,
    },
    courses: [],
  },
  oneCoursesReviews: {
    reviews: [],
    course_details: {
      course_info: {
        id: '',
        course_title: '',
        course_image: '',
        total_reviews: 0,
        average_rating: undefined,
      },
      global_star_count: {
        '1.0': 0,
        '2.0': 0,
        '3.0': 0,
        '4.0': 0,
        '5.0': 0,
      },
      average_rating: undefined,
      total_reviews_count: 0,
      new_reviews_count_last_30_days: 0,
      percentage_growth_last_30_days: 0,
    },
  },
};
export interface ReviewsResponse {
  next: null | number;
  previous: null | number;
  count: number;
  results: any;
  allCoursesReviews: {
    global_average_rating: number;
    total_reviews_count: number;
    new_reviews_count_since_last_month: number;
    percentage_growth_since_last_month: number;
    global_star_count: {
      '1.0': number;
      '2.0': number;
      '3.0': number;
      '4.0': number;
      '5.0': number;
    };
    courses: Courses[];
  };
  oneCoursesReviews: {
    course_details: {
      course_info: {
        id: string;
        course_title: string;
        course_image: string;
        total_reviews: number;
        average_rating?: number;
      };
      global_star_count: {
        '1.0': number;
        '2.0': number;
        '3.0': number;
        '4.0': number;
        '5.0': number;
      };
      average_rating?: number;
      total_reviews_count: number;
      new_reviews_count_last_30_days: number;
      percentage_growth_last_30_days: number;
    };
    reviews: Reviews[];
  };
}

export interface Courses {
  id: string;
  course_title: string;
  course_image: string;
  total_reviews: number;
  average_rating: number;
  reviews: Reviews[];
}

export interface Reviews {
  id: string;
  rate: number;
  user: {
    id: number;
    username: string;
    fullname: string;
    language: string;
    avatar?: string;
    photo_url?: string;
  };
  content: string;
  likes_count: number;
  dislikes_count: number;
  reported_count: number;
  date_created: string;
  replies: Replies[];
}

export interface Replies {
  id: string;
  user: {
    id: number;
    username: string;
    fullname: string;
    language: string;
    /**
     * Follow the following ticket to update this field https://eng-ibrahim.atlassian.net/browse/SA-1519
     * once this ticket is solved, we can delete this field
     */
    avatar?: string;
    photo_url?: string;
  };
  date_created: string;
  date_updated: string;
  description: string;
  review: string;
}
