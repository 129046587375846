import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  readonly routes = [
    '/screens/pages',
    '/screens/landing-page',
    '/screens/components',
    '/account/login',
    '/account/signup',
    '/account/forgot-password',
    '/account/password-reset',
    '/student/course',
    '/student/dashboard',
    '/instructor/courses',
    '/instructor/assignments',
    '/instructor/messages',
    '/instructor/question-answer',
    '/instructor/reports',
    '/instructor/reviews',
  ];

  constructor() {}

  ngOnInit(): void {}
}
