import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectObjectivesState } from './course.selectors';

export const selectObjectives = createSelector(selectObjectivesState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectObjective = (objectiveId: string) =>
  createSelector(selectObjectivesState, (state) => state.entities[objectiveId]);

export const selectObjectiveEntities = createSelector(selectObjectivesState, (state) => state.entities);
