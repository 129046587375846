import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-scrollable-content',
  templateUrl: './scrollable-content.component.html',
  styleUrls: ['./scrollable-content.component.scss'],
})
export class ScrollableContentComponent implements OnInit {
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  scrollPercentage: number = 0;

  constructor() {}

  ngOnInit(): void {}

  scrollTop() {
    const element = this.scrollableDiv.nativeElement;
    element.scrollTop = 0;
    this.scrollPercentage = 0;
  }

  onScroll() {
    const element = this.scrollableDiv.nativeElement;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight - element.clientHeight;
    this.scrollPercentage = (scrollTop / scrollHeight) * 100;
    // Check the scroll state
    if (element.scrollTop === 0) {
      // console.log('Scrolled to the top');
    } else if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // console.log('Scrolled to the bottom');
    } else {
      // console.log('Scrolling in between');
    }
  }
}
