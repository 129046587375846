import { ContentTypeModels, CourseDetailV2, CourseStatus, CourseV2, PartialEntity, Project } from '../../models';
import { AnnotationEntityState, initialAnnotationState } from './annotation.entity';
import { AssignmentQuestionEntityState, initialAssignmentQuestionState } from './assignment-question.entity';
import { AssignmentEntityState, initialAssignmentState } from './assignment.entity';
import { ContentTypeEntityState, initialContentTypeState } from './content-type.entity';
import { CourseEntityState, initialCourseState } from './course.entity';
import { initialLearnerState, LearnerEntityState } from './learner.entity';
import { initialLectureResourceState, LectureResourceEntityState } from './lecture-resource.entity';
import { initialLectureState, LectureEntityState } from './lecture.entity';
import { initialObjectiveState, ObjectiveEntityState } from './objective.entity';
import { initialquizAnswerState, QuizAnswerEntityState } from './quiz-answer.entity';
import { initialquizQuestionState, QuizQuestionEntityState } from './quiz-question.entity';
import { initialQuizState, QuizEntityState } from './quiz.entity';
import { initialRequirementState, RequirementEntityState } from './requirement.entity';
import { initialSectionState, SectionEntityState } from './section.entity';
import { initialSubsectionState, SubsectionEntityState } from './subsection.entity';

export type ActivityState = 'type_lecture' | 'type_quiz' | 'type_assignment' | 'lecture_video' | 'lecture_article';

export type UploadState = {
  /** id of the entity where `fileKey` is being saved i.e. course.promo_video, course_lecture.video, course_lecture_resource.file */
  referenceId: string;
  /** filepath in S3 that is saved in entities i.e. course_lecture.video, course_lecture_resource.file */
  fileKey: string;
  fileName: string;
  progressPercent: number;
  bytesUploaded: number;
  bytesTotal: number;
  isUploadComplete: boolean;
  fileType: string;
};

export type FeedbackState = {
  isFeedbackModalOpen: boolean;
  fieldId: string;
  fieldKey: string;
  fieldModel: ContentTypeModels | undefined;
  fieldValue: string;
};

export const initialFeedbackState: FeedbackState = {
  isFeedbackModalOpen: false,
  fieldId: '',
  fieldKey: '',
  fieldModel: undefined,
  fieldValue: '',
};

export type CourseState = {
  selectedSectionId: string;
  selectedSubsectionId: string;
  /** this can be quizId | lectureId | assignmentId */
  selectedActivityId: string;
  activityState: ActivityState | undefined;
  isActivityModalOpen: boolean;
  uploads: { [referenceId: string]: UploadState };
  annotations: AnnotationEntityState;
  contentTypes: ContentTypeEntityState;

  courses: CourseV2[];
  project: Project;
  course: CourseV2;
  courseDetailed: PartialEntity<CourseDetailV2>;
  courseHistory: CourseEntityState;
  annotationHistory: AnnotationEntityState;
  sections: SectionEntityState;
  subsections: SubsectionEntityState;

  quizzes: QuizEntityState;
  quizQuestions: QuizQuestionEntityState;
  quizAnswers: QuizAnswerEntityState;

  lectures: LectureEntityState;
  lectureResources: LectureResourceEntityState;

  assignments: AssignmentEntityState;
  assignmentQuestions: AssignmentQuestionEntityState;

  // TODO JHJ
  // - load to ngrx store - check instructor/admin intended pages
  // - this is only loaded after `loadCourseSuccess` action
  objectives: ObjectiveEntityState;
  learners: LearnerEntityState;
  requirements: RequirementEntityState;

  feedback: FeedbackState;
};

export const initialCourseDetail: CourseV2 = {
  id: '',
  project: '',
  version: '',
  user: 0,
  title: '',
  subtitle: '',
  description: '',
  category: '',
  status: CourseStatus.DRAFT,
  skill_level: '',
  language: '',
  topics: [],
};

export const initialCoursesState: CourseState = {
  selectedSectionId: '',
  selectedSubsectionId: '',
  selectedActivityId: '',
  activityState: undefined,
  isActivityModalOpen: false,
  uploads: {},
  feedback: initialFeedbackState,
  courses: [],
  courseHistory: initialCourseState,
  annotationHistory: initialAnnotationState,
  annotations: initialAnnotationState,
  contentTypes: initialContentTypeState,
  course: initialCourseDetail,
  project: { id: '' },
  courseDetailed: { id: '' },
  sections: initialSectionState,
  subsections: initialSubsectionState,
  quizzes: initialQuizState,
  quizQuestions: initialquizQuestionState,
  quizAnswers: initialquizAnswerState,
  assignments: initialAssignmentState,
  assignmentQuestions: initialAssignmentQuestionState,
  lectures: initialLectureState,
  lectureResources: initialLectureResourceState,
  objectives: initialObjectiveState,
  learners: initialLearnerState,
  requirements: initialRequirementState,
};
