import { createSelector } from '@ngrx/store';
import { isDefined } from '../../utils';
import { selectQuizzesState } from './course.selectors';
import { selectQuizQuestionDetailsByQuizId } from './quiz-question.selectors';

export const selectQuizzes = createSelector(selectQuizzesState, (state) =>
  state.ids.map((id) => state.entities[id]).filter(isDefined)
);

export const selectQuiz = (quizId: string) => createSelector(selectQuizzesState, (state) => state.entities[quizId]);

export const selectQuizDetail = (quizId: string) =>
  createSelector(selectQuiz(quizId), selectQuizQuestionDetailsByQuizId(quizId), (quiz, quizQuestions) => ({
    ...quiz!,
    questions: quizQuestions,
  }));

export const selectQuizEntities = createSelector(selectQuizzesState, (state) => state.entities);
