import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect } from '@ngrx/effects';
import * as _ from 'lodash';
import { of, switchMap } from 'rxjs';
import { HttpService } from '../../services';
import { errorActions } from './error.actions';
import { ErrorService } from './error.service';

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private errorService: ErrorService,
    private httpService: HttpService
  ) {}

  handleHttpErrors$ = createEffect(() =>
    this.httpService.handleHttpErrors$.pipe(
      switchMap((error) => {
        let section: string = '';
        if (error.path) {
          let lastPath = _.last(_.compact(_.split(error.path, '/')));
          section = lastPath ? lastPath : section;
        }
        if (error.status >= 400 && error.status <= 499) {
          return of(errorActions.clientError({ section, status: error.status, error: error.error }));
        } else if (error.status >= 500) {
          return of(errorActions.serverError({ section, status: error.status, error: error.error }));
        }
        return of(); // Handle other types of errors or statuses if needed
      })
    )
  );

  // clientError$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(errorActions.clientError),
  //       tap((sample) => {
  //         console.log('W2--', sample);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // clientError$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(comErrorActions.clientError),
  //     switchMap(({ section, errors }) =>
  //       this.ErrorService.applyError(section, errors).pipe(
  //         map((response) => {
  //           console.log('W1--', response);
  //           return comErrorActions.applyErrorLogSuccess({ response });
  //         }),
  //         catchError((error) => {
  //           console.log('W2--', error);
  //           return of({ error });
  //         })
  //       )
  //     )
  //   )
  // );
}
