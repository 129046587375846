import { Injectable } from '@angular/core';
import { catchError, delay, of } from 'rxjs';
import {
  API_INSTRUCTOR_PAYOUT_ACTIVATE_METHOD,
  API_INSTRUCTOR_PAYOUT_ACTIVITIES,
  API_INSTRUCTOR_PAYOUT_CONNECT,
  API_INSTRUCTOR_PAYOUT_CONNECT_CALLBACK,
  API_INSTRUCTOR_PAYOUT_DIRECTLY_BANK,
  API_INSTRUCTOR_PAYOUT_METHODS,
  API_INSTRUCTOR_PAYOUT_OVERVIEW,
  API_INSTRUCTOR_PAYOUT_REQUEST,
  API_INSTRUCTOR_PAYOUT_REQUEST_TRANSACTIONS,
  API_INSTRUCTOR_PAYOUT_REQUEST_WITHDRAW,
  API_INSTRUCTOR_PAYOUT_REQUESTS,
  API_INSTRUCTOR_PAYOUT_TRANSACTION_DETAILS,
  API_INSTRUCTOR_PAYOUT_TRANSACTIONS,
  API_INSTRUCTOR_PAYOUT_TYPE_UPDATE,
  API_INSTRUCTOR_PAYOUT_WITHDRAW_DETAILS,
  encodeURL,
  HttpService,
  Pagination,
  PayoutActivity,
  PayoutMethod,
  PayoutOverview,
  PayoutRequest,
  PayoutRequestBasic,
  PayoutTransaction,
  PayoutTransactionDetail,
  PayoutTransactionsQuery,
  PayoutType,
  WithdrawDetail
} from 'thkee-common';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {

  constructor(
    private http: HttpService
  ) { }

  updateType(userId: number, type: PayoutType) {
    return this.http.put(API_INSTRUCTOR_PAYOUT_TYPE_UPDATE(userId), { payout_type: type });
  }

  getOverview() {
    return this.http.get<PayoutOverview>(API_INSTRUCTOR_PAYOUT_OVERVIEW);
  }

  connectMethod(methodId: number) {
    return this.http.get<{ connect_url: string }>(API_INSTRUCTOR_PAYOUT_CONNECT(methodId));
  }

  exchangeCode(methodId: number, code: string) {
    return this.http.get(API_INSTRUCTOR_PAYOUT_CONNECT_CALLBACK(methodId), { code })
      // fake waiting for backend to make it ready
      .pipe(catchError(() => of(true)), delay(1000));
  }

  getMethods() {
    return this.http.get<PayoutMethod[]>(API_INSTRUCTOR_PAYOUT_METHODS);
  }

  updateDirectBank(methodId: number, bankDetail: PayoutMethod['bank_details']) {
    return this.http.post<PayoutMethod['bank_details']>(API_INSTRUCTOR_PAYOUT_DIRECTLY_BANK(methodId), bankDetail);
  }

  toggleActivatingMethod(methodId: number) {
    return this.http.get<PayoutMethod[]>(API_INSTRUCTOR_PAYOUT_ACTIVATE_METHOD(methodId));
  }

  getTransactions(payoutId: number, query?: { page?: number; page_size?: number; payout_status?: string[] }) {
    return this.http.get<Pagination<PayoutTransaction>>(
      encodeURL(
        API_INSTRUCTOR_PAYOUT_TRANSACTIONS(payoutId),
        query
      )
    );
  }

  getRequestTransactions(payoutRequestId: number, query?: { page?: number; page_size?: number; payout_status?: string[] }) {
    return this.http.get<Pagination<PayoutTransaction>>(
      encodeURL(
        API_INSTRUCTOR_PAYOUT_REQUEST_TRANSACTIONS(payoutRequestId),
        query
      )
    );
  }

  getTransaction(transactionId: number) {
    return this.http.get<PayoutTransactionDetail>(
      API_INSTRUCTOR_PAYOUT_TRANSACTION_DETAILS(transactionId)
    );
  }

  withdraw(payoutPeriodId: number) {
    return this.http.post<PayoutRequest>(API_INSTRUCTOR_PAYOUT_REQUEST_WITHDRAW(payoutPeriodId), {});
  }

  getWithdrawDetails(payoutPeriodId: number) {
    return this.http.get<WithdrawDetail>(API_INSTRUCTOR_PAYOUT_WITHDRAW_DETAILS(payoutPeriodId));
  }

  getPayoutRequests(data?: { page?: number, page_size?: number, status?: string[] }) {
    return this.http.get<Pagination<PayoutRequest>>(encodeURL(API_INSTRUCTOR_PAYOUT_REQUESTS, data));
  }

  getPayoutRequest(requestId: number) {
    return this.http.get<PayoutRequestBasic>(API_INSTRUCTOR_PAYOUT_REQUEST, { id: requestId });
  }

  getLastPayoutRequest() {
    return this.http.get<PayoutRequestBasic>(API_INSTRUCTOR_PAYOUT_REQUEST);
  }

  getActivities(paginationQuery?: PayoutTransactionsQuery) {
    return this.http.get<Pagination<PayoutActivity>>(
      encodeURL(
        API_INSTRUCTOR_PAYOUT_ACTIVITIES,
        paginationQuery
      )
    );
  }

  /**
   * Here is the logic for updating the `end_date` and `due_date` when changing the payout type (Monthly/On demand):
   * - If current_date is before the 10th:
   *    - end_date will be the last day of the current month (e.g., 30th or 31st, based on the month).
   *    - due_date will be the 3rd day of the next month.
   *      - *Example*: If the current month is August, the due date will be September 3rd.
   * - If current_date is after the 10th:
   *    - end_date will be the last day of the next month (e.g., 30th or 31st, based on the month).
   *    - due_date will be the 3rd day of the month after next.
   *      - *Example*: If the current month is July, the due date will be September 3rd.
   * @returns
   */
  estimatePayoutMonthlyRangeDates() {
    const startDate = new Date();
    const currentDate = startDate.getUTCDate();
    const deltaMonth = currentDate >= 10 ? 1 : 0;
    const startNextMonthDate = new Date(
      startDate.getUTCFullYear() + (startDate.getUTCMonth() + 1 + deltaMonth) / 12,
      (startDate.getUTCMonth() + 1 + deltaMonth) % 12,
      1
    );
    const endDate = new Date(startNextMonthDate.getTime() - 24 * 60 * 60 * 1000 /** 1 day */);

    const dueDate = new Date(endDate.getTime() + 3 * 24 * 60 * 60 * 1000 /** 3 days */);

    return { startDate: startDate, endDate, dueDate };
  }
}
