import { createFeature, createReducer, on } from '@ngrx/store';
import { initialCheckoutState } from './checkout-state.model';
import { CheckoutActions } from './checkout.actions';

export const checkoutReducer = createReducer(
  initialCheckoutState,
  // Checkout related
  on(CheckoutActions.loadPaymentMethodsSuccess, (state, { data }) => ({
    ...state,
    paymentMethods: data,
  })),
  on(CheckoutActions.verifyPaymentSuccess, (state, { data }) => ({
    ...state,
    verifiedPayment: data,
  })),
  on(CheckoutActions.loadOrderSuccess, (state, { order }) => {
    return {
      ...state,
      order: order,
    };
  }),
  on(CheckoutActions.paypalCallbackSuccess, (state, { order }) => {
    return {
      ...state,
      order: order,
    };
  }),
  on(CheckoutActions.tapCallbackSuccess, (state, { order }) => {
    return {
      ...state,
      order: order,
    };
  })
);

export const checkoutFeature = createFeature({
  name: 'checkout',
  reducer: checkoutReducer,
});
