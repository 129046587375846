import { ContentTypeApps, ContentTypeModels } from './constants';
import {
  Annotation,
  AssignmentQuestionV2,
  AssignmentV2,
  ContentType,
  CourseV2,
  Feedback,
  LearnerV2,
  LectureResourceV2,
  LectureV2,
  ObjectiveV2,
  Project,
  QuizAnswerV2,
  QuizQuestionV2,
  QuizV2,
  RequirementV2,
  SectionV2,
  SubsectionV2,
} from './course2.model';
import { CopiedEntity, PartialEntity } from './utils.model';

export type QuizQuestionDetailV2 = QuizQuestionV2 & {
  answers: QuizAnswerV2[];
};
export type QuizDetailV2 = QuizV2 & {
  questions: QuizQuestionDetailV2[];
};

export type AssignmentDetailV2 = AssignmentV2 & {
  questions: AssignmentQuestionV2[];
};

export type LectureDetailV2 = LectureV2 & {
  resources?: PartialEntity<LectureResourceV2>[];
};

export type SubsectionDetailV2 = PartialEntity<Omit<SubsectionV2, 'quiz' | 'assignment' | 'lecture'>> & {
  quiz?: PartialEntity<QuizDetailV2> & {
    feedback?: Feedback | boolean;
  };
  assignment?: PartialEntity<AssignmentDetailV2> & {
    feedback?: Feedback | boolean;
  };
  lecture?: PartialEntity<LectureDetailV2> & {
    feedback?: Feedback | boolean;
  };
};

export type SectionDetailV2 = PartialEntity<SectionV2> & {
  total: number;
  article_count: number;
  quiz_count: number;
  video_count: number;
  subsections: SubsectionDetailV2[];
  feedback?: Feedback | boolean;
};

export type CourseDataV2 = {
  project: Project;
  course: CourseV2;
  course_detail: CourseDetailV2;
  lectures: LectureV2[];
  lecture_resources: LectureResourceV2[];
  sections: SectionV2[];
  subsections: SubsectionV2[];
  learners: LearnerV2[];
  requirements: RequirementV2[];
  objectives: ObjectiveV2[];
  quiz_answers: QuizAnswerV2[];
  quiz_questions: QuizQuestionV2[];
  quizzes: QuizV2[];
  assignments: AssignmentV2[];
  assignment_questions: AssignmentQuestionV2[];
  annotations: Annotation[];
  content_types: ContentType[];
  slug: string;
};

export type NeededForReview<T> = {
  intended_learners: {
    objectives: T;
    requirements: T;
    learners: T;
  };
  details: {
    title: T;
    subtitle: T;
    topics: T;
    description: T;
    skill_level: T;
    language: T;
    category: T;
    subcategory: T;
    image: T;
  };
  curriculum: {
    video_length: T;
    lectures: T;
  };
  pricing: {
    price: T;
  };
  annotations: {
    annotations_required_unresolved_count: T;
    resolved_all_required: T;
  };
};

export const neededForReviewMessage: NeededForReview<string> = {
  intended_learners: {
    objectives: 'Specify at least 4 of your course’s learning objectives',
    learners: 'Specify who this course is for',
    requirements: 'Specify any course requirements or prerequisites',
  },
  details: {
    title: 'Have a course title',
    subtitle: 'Have a course subtitle',
    topics: 'Select what is primarily taught in your course (Topic)',
    description: 'Have a course description with at least 60 words',
    skill_level: 'Select the skill level of your course',
    language: 'Select the language of your course',
    category: 'Select the category of your course',
    subcategory: 'Select the subcategory of your course',
    image: 'Upload a course image',
  },
  curriculum: {
    lectures: 'Have at least 5 lectures',
    video_length: 'Have at least 30 minutes of video content',
    // ??: Have content for all lectures
  },
  pricing: {
    price: 'Select a price for your course',
  },
  annotations: {
    resolved_all_required: 'Resolve all feedback with required action (red)',
    annotations_required_unresolved_count: 'UNUSED', // just metadata for count
  },
};

export type CourseDetailV2 = Omit<CourseV2, 'project'> & {
  project: Project;
  sections?: SectionDetailV2[];
  needed_for_review?: NeededForReview<boolean>;
  is_valid_for_review?: boolean;
  source?: CopiedEntity<CourseV2>;
};

export type AnnotationDetail = Annotation & {
  /** model/table - object_id - field */
  key: string;
  app_label?: ContentTypeApps;
  model?: ContentTypeModels;
};
