<ng-container *ngIf="{
    isAuth: isAuth$ | async,
    isInstructor: isInstructor$ | async,
    categories: categories$ | async
  } as vm">
  <!-- Header -->
  <!-- Preview Header -->
  <header *ngIf="isPreviewCourse" class="fixed z-50 w-full bg-white shadow">
    <div class="container flex max-h-16 items-center justify-between gap-2 py-4 lg:gap-4">
      <div class="logo relative h-7 w-20 lg:w-24" [ngClass]="{ 'hidden sm:block': showSearchBar }">
        <a [routerLink]="homeUrl">
          <svg-icon src="@assets/images/logo.svg" class="block w-20 lg:w-24"></svg-icon>
        </a>
      </div>
      <ng-container *ngIf="vm.isAuth">
        <div class="flex items-center gap-4">
          <a href="#" routerLink="/instructor/course/{{params['course_slug']}}/{{queryParams['from']}}" class="btn bg-black text-white btn-sm hover:bg-black">
            <svg-icon class="btn-icon h-6 w-6" src="@assets/images/icons/arrow-left.svg"></svg-icon>
            Back to Instructor
          </a>
          <thk-avatar class="ltr:ml-2 rtl:mr-2" [title]="user?.fullname" [url]="user?.photo_url"></thk-avatar>
        </div>
      </ng-container>
    </div>
  </header>
  <!-- Public Header -->
  <header *ngIf="!isPreviewCourse" class="fixed z-50 w-full bg-white shadow">
    <div class="container flex max-h-16 items-center gap-2 py-4 lg:gap-4">
      <div class="menu-btn md:hidden">
        <button class="btn btn-transparent btn-sm px-0" (click)="mobileNav.open()">
          <svg-icon class="btn-icon h-6 w-6" src="@assets/images/icons/bars-3.svg"></svg-icon>
        </button>
      </div>
      <div class="logo relative h-7 w-20 lg:w-24" [ngClass]="{ 'hidden sm:block': showSearchBar }">
        <a [routerLink]="homeUrl">
          <svg-icon src="@assets/images/logo.svg" class="block w-20 lg:w-24"></svg-icon>
        </a>
      </div>
      <div
        class="md:ar:border-l-0 md:ar:border-r ar:pl-0 ar:pr-4 flex grow basis-0 items-center gap-2 border-slate-200 pl-2 md:border-l md:border-r-0 lg:gap-4 lg:pl-4">
        <ng-container *ngTemplateOutlet="category"></ng-container>
        <ng-container *ngTemplateOutlet="search"></ng-container>
        <ng-container *ngIf="!vm.isAuth; else accButtons">
          <ng-container *ngTemplateOutlet="publicAccButtons"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!vm.isAuth">
        <ng-container *ngTemplateOutlet="rightButtons"></ng-container>
      </ng-container>
    </div>
  </header>

  <ng-template #category>
    <!-- Menu -->
    <div class="category-menu hidden md:block">
      <div thkDropdown #testDropdown="thkDropdown" class="dropdown">
        <div class="button-container" thkDropdownToggle>
          <button
            class="btn btn-sm border-slate-100" type="button">
            <span i18n>Category</span>
            <svg-icon class="btn-icon transition-all" [class.rotate-180]="testDropdown.showing"
              src="@assets/images/icons/chevron-down.svg"></svg-icon>
          </button>
        </div>
        <ul class="dropdown-menu" thkDropdownMenu>
          <div class="dropdown-container">
            <li *ngFor="let category of vm.categories" thkDropdownItem thkDropdown trigger="hover" placement="rb">
              <div class="w-full" thkDropdownToggle>
                <a uisref="marketplace" [routerLink]="'/category/' + category.slug" class="dropdown-item flex items-center justify-between w-full">
                  {{ category.name }}
                  <svg-icon src="@assets/images/icons/chevron-right.svg"
                    class="ar:mr-auto ar:ml-0 ar:rotate-180 ml-auto" *ngIf="category.subcategories?.length">
                  </svg-icon>
                </a>
                <ul *ngIf="category.subcategories?.length" thkDropdownMenu class="dropdown-menu">
                  <li *ngFor="let sub_category of category.subcategories" class="" thkDropdownItem>
                    <a uisref="marketplace" [routerLink]="'/category/'+ category.slug+ '/' + sub_category.slug" class="dropdown-item flex items-center justify-between w-full">
                      {{ sub_category.name }}
                      <svg-icon src="@assets/images/icons/chevron-right.svg" class="ml-auto"
                        *ngIf="sub_category.related_topics?.length"></svg-icon>
                    </a>
                    <ul *ngIf="sub_category.related_topics?.length" class="dropdown-subitems lg:group-hover/subChild:visible lg:group-hover/subChild:translate-y-0 lg:group-hover/subChild:opacity-100">
                      <li>
                        <div class="heading" i18n>Related Topics</div>
                      </li>
                      <li *ngFor="let topic of sub_category.related_topics">
                        <a uisref="marketplace" class="capitalize"
                          [routerLink]="'/category/' + category.slug + '/' + sub_category.slug + '/' + topic.slug">
                          <div>
                            <span>{{ topic.name }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template #search>
    <!-- Search -->
    <div class="hidden grow justify-center lg:flex">
      <div class="input-group input-group-sm w-full max-w-[400px]">
        <div class="group">
          <svg-icon src="@assets/images/icons/search.svg"></svg-icon>
          <input i18n-placeholder placeholder="Search courses" type="text" [(ngModel)]="searchKeyword"
            (keyup.enter)="onSearch($event)" />
        </div>
      </div>
    </div>
    <div class="search-btn hidden md:block lg:hidden" *ngIf="!showSearchBar">
      <button class="btn btn-transparent btn-sm px-2.5" (click)="showSearchBar = !showSearchBar">
        <svg-icon class="btn-icon" src="@assets/images/icons/search.svg"></svg-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #searchMobile>
    <div class="flex items-center gap-0 md:gap-2">
      <div class="input-group input-group-sm w-full">
        <div class="group">
          <svg-icon src="@assets/images/icons/search.svg"></svg-icon>
          <input i18n-placeholder placeholder="Search courses" type="text" [(ngModel)]="searchKeyword"
            (keyup.enter)="onSearch($event)" />
        </div>
      </div>
      <button class="btn btn-transparent btn-sm px-2.5" (click)="showSearchBar = !showSearchBar">
        <svg-icon class="btn-icon" src="@assets/images/icons/x-mark.svg"></svg-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #publicAccButtons>
    <!-- Account Buttons -->
    <div class="hidden grow md:block" *ngIf="!showSearchBar">
      <div class="flex items-center justify-end gap-2 sm:gap-3 lg:grow-0">
        <button class="btn btn-sm border-transparent" routerLink="/account/signup">
          <span i18n>Teach on Thkee</span>
        </button>
        <button class="btn btn-sm btn-outline color-gray" routerLink="/account/signup">
          <span class="lg:hidden" i18n>Register</span>
          <span class="hidden lg:block" i18n>Create Account</span>
        </button>
        <button class="btn btn-primary btn-sm" routerLink="/account/login">
          <span i18n>Login</span>
        </button>
      </div>
    </div>
    <div class="search-mobile-container fadeIn grow" *ngIf="showSearchBar">
      <ng-container *ngTemplateOutlet="searchMobile"></ng-container>
    </div>
  </ng-template>

  <ng-template #accButtons>
    <!-- Account Buttons -->
    <div class="search-mobile-container fadeIn grow" *ngIf="showSearchBar">
      <ng-container *ngTemplateOutlet="searchMobile"></ng-container>
    </div>
    <div class="fadeIn grow lg:grow-0" [class.grow-0]="showSearchBar" [ngClass]="{ 'hidden sm:block': showSearchBar }">
      <div class="flex items-center justify-end gap-2 sm:gap-3">
        <div *ngIf="!showSearchBar" class="fadeIn hidden items-center gap-3 md:flex">
          <a routerLink="/become-an-instructor" *ngIf="!vm.isInstructor">
            <button class="btn btn-sm border-transparent">
              <span i18n>Become an Instructor</span>
            </button>
          </a>
          <a routerLink="/instructor" *ngIf="vm.isInstructor">
            <button class="btn btn-sm border-transparent">
              <span i18n>Switch to Instructor</span>
            </button>
          </a>
          <button class="btn btn-sm border-transparent" routerLink="/student">
            <span i18n>Learning lab</span>
          </button>
        </div>
        <a class="hover:text-primary p-1.5 md:hidden" (click)="showSearchBar = !showSearchBar" *ngIf="!showSearchBar">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/search.svg"></svg-icon>
        </a>
        <a routerLink="/cart" class="hover:text-primary relative p-1.5">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/cart.svg"></svg-icon>
          <div *ngIf="cartCount"
            class="absolute -right-1 -top-1 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-red-700 text-xs text-white">
            {{ cartCount }}
          </div>
        </a>

        <!-- Menu -->
        <div class="account-menu">
          <app-navigation position="right">
            <ng-container button>
              <thk-avatar class="ltr:ml-2 rtl:mr-2" [title]="user?.fullname" [url]="user?.photo_url"></thk-avatar>
            </ng-container>
            <ng-container header>
              <div class="heading flex items-center gap-4 p-4">
                <thk-avatar sizeClasses="h-10 w-10" [title]="user?.fullname" [url]="user?.photo_url"></thk-avatar>

                <ng-container *ngIf="user">
                  <div>
                    <div class="name font-medium">{{ user.first_name }} {{ user.last_name }}</div>
                    <div class="email text-xs text-slate-500">{{ user.email }}</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-container content>
              <div class="content max-h-[400px] overflow-y-auto border-y border-y-slate-300">
                <ul class="account-items border-b border-b-slate-300">
                  <li class="relative">
                    <a routerLink="/student/dashboard" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>My learning</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/cart" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" i18n>My
                      cart</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/student/wishlist" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Wishlist</a>
                  </li>
                  <li *ngIf="isInstructor$ | async" class="relative">
                    <a routerLink="/instructor/courses" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Instructor dashboard</a>
                  </li>
                </ul>
                <ul class="account-items border-b border-b-slate-300">
                  <li class="relative">
                    <a routerLink="/student/account/notifications" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Notifications</a>
                  </li>
                  <li class="relative">
                    <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Messages</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/student/account/profile" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Account Settings</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/student/account/payment-methodes" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Payment Methods</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/student/purchase/history" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" i18n>Purchase history</a>
                  </li>
                </ul>
                <ul class="account-items">
                  <li *ngIf="isInstructor$ | async" class="relative">
                    <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Public profile</a>
                  </li>
                  <li class="relative">
                    <a routerLink="/student/account/profile" i18n>Edit profile</a>
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-container footer>
              <div class="footer">
                <ul class="account-items">
                  <li class="relative">
                    <a href="/search?category__slug=syndicate-front-end-deliverables" i18n>Help</a>
                  </li>
                  <li class="relative">
                    <a href="#" class="rounded-b-lg" (click)="logout()" i18n>Logout</a>
                  </li>
                </ul>
              </div>
            </ng-container>
          </app-navigation>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #rightButtons>
    <!-- Search -->
    <div
      class="flex items-center justify-start gap-2 border-slate-200 ltr:pl-2 ltr:md:border-l ltr:lg:pl-3 ltr:pr-2 rtl:md:border-r rtl:lg:pr-3"
      [ngClass]="{ 'hidden sm:flex': showSearchBar }">
      <div class="flex items-center justify-start gap-2 lg:gap-3">
        <a class="hover:text-primary p-1.5 md:hidden" (click)="showSearchBar = !showSearchBar">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/search.svg"></svg-icon>
        </a>
        <a routerLink="/cart" class="hover:text-primary relative p-1.5">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/cart.svg"></svg-icon>
          <div *ngIf="cartCount"
            class="absolute -right-1 -top-1 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-red-700 text-xs text-white">
            {{ cartCount }}
          </div>
        </a>
        <a href="#" class="hover:text-primary hidden rounded border border-slate-200 p-1.5 md:block">
          <svg-icon class="h-5 w-5" src="@assets/images/icons/globe.svg"></svg-icon>
        </a>
      </div>
    </div>
  </ng-template>

  <common-modal #mobileNav i18n-title title="" customClass="mobile-nav w-full max-w-[70%] overflow-visible rounded-none"
    alignment="left">
    <ng-container header>
      <div class="fadeIn bg-slate-100" *ngIf="selectedCtg">
        <a class="block px-6 py-3.5 text-sm" (click)="selectedCtg = selectedCtg != 'all' ? 'all' : ''">
          <div class="flex items-center justify-start gap-3">
            <svg-icon class="block h-4 w-4" src="@assets/images/icons/chevron-left.svg"></svg-icon>
            <span i18n>Menu</span>
          </div>
        </a>
      </div>

      <div class="fadeIn p-6" *ngIf="!selectedCtg">
        <div class="logo relative h-7 w-28">
          <a [routerLink]="homeUrl">
            <svg-icon src="@assets/images/logo.svg" class="block w-28"></svg-icon>
          </a>
        </div>
      </div>
      <button class="btn btn-white btn-sm absolute -right-9 top-1 h-8 w-8 rounded-full px-2"
        (click)="mobileNav.close()">
        <svg-icon class="btn-icon" src="@assets/images/icons/x-mark.svg"></svg-icon>
      </button>
    </ng-container>
    <ng-container content>
      <div class="px-6 text-sm tracking-wider">
        <div *ngIf="!selectedCtg" class="fadeIn">
          <div class="flex flex-col gap-2.5 py-6">
            <ng-container *ngIf="!vm.isAuth">
              <a routerLink="/account/login" (click)="mobileNav.close()" i18n>Log in</a>
              <a routerLink="/account/signup" (click)="mobileNav.close()" i18n>Sign up</a>
            </ng-container>
            <ng-container *ngIf="vm.isAuth">
              <a routerLink="/instructor/register" *ngIf="!vm.isInstructor" (click)="mobileNav.close()" i18n>Become an
                Instructor</a>
              <a routerLink="/instructor" *ngIf="vm.isInstructor" (click)="mobileNav.close()" i18n>Switch to
                Instructor</a>
              <a routerLink="/student" (click)="mobileNav.close()" i18n>Learning lab</a>
            </ng-container>
          </div>
          <div class="border-y border-slate-300 py-6">
            <div class="mb-2.5 text-xs font-bold" i18n>Most Popular</div>
            <ul class="flex flex-col gap-2.5" *ngIf="vm.categories">
              <ng-container *ngFor="let category of mostPopular(vm.categories)">
                <li class="flex items-center justify-between">
                  <a [routerLink]="'category/' + category.slug" (click)="mobileNav.close()">{{ category.name }}</a>
                  <a (click)="selectedCtg = category.id" class="pl-5" *ngIf="category.subcategories?.length">
                    <svg-icon class="block h-4 w-4" src="@assets/images/icons/chevron-right.svg"></svg-icon>
                  </a>
                </li>
              </ng-container>

              <li class="flex items-center justify-between">
                <a (click)="selectedCtg = 'all'" i18n>All categories</a>
                <a (click)="selectedCtg = 'all'" class="pl-5">
                  <svg-icon class="block h-4 w-4" src="@assets/images/icons/chevron-right.svg"></svg-icon>
                </a>
              </li>
            </ul>
          </div>
          <div class="flex flex-col gap-2.5 py-6">
            <div class="text-xs font-bold" i18n>More from Thkee</div>
            <a href="#" i18n>Help</a>
          </div>
        </div>
        <div *ngIf="selectedCtg" class="fadeIn">
          <div class="py-6">
            <div class="mb-2.5 text-xs font-bold">{{ selectedCtg !== 'all' ? '' : ctgLabel }}</div>
            <ul class="flex flex-col gap-2.5" *ngIf="vm.categories">
              <ng-container *ngFor="let category of filterCategory(vm.categories)">
                <li class="flex items-center justify-between">
                  <a [routerLink]="'category/' + category.slug" (click)="mobileNav.close()">{{ category.name }}</a>
                  <a (click)="selectedCtg = category.id" class="pl-5" *ngIf="category.subcategories?.length">
                    <svg-icon class="block h-4 w-4" src="@assets/images/icons/chevron-right.svg"></svg-icon>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </common-modal>
</ng-container>
