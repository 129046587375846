import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TabItem } from '@shared/models/tabs-constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-qa-tab-menu',
  templateUrl: './qa-tab-menu.component.html',
  styleUrls: ['./qa-tab-menu.component.scss'],
})
export class QaTabMenuComponent implements OnInit {
  @Input() items: TabItem[] = [];
  // @Output() item = new EventEmitter<TabItem>();

  @Output() emitIteam: EventEmitter<string> = new EventEmitter<string>();

  parentRoute: string = '';
  activeItem: string = '/';

  constructor(private router: Router) {
    this.getActive();
    router.events.forEach((event) => {
      // TODO: replace by state route
      if (event instanceof NavigationEnd) this.getActive();
    });
  }

  ngOnInit(): void {}

  getActive() {
    this.activeItem = '/' + _.last(this.router.url.split('/'));
    // console.log(this.router.url.split('/')[this.router.url.split('/').length - 1]);
    this.parentRoute = this.router.url.replace(this.activeItem, '');
  }

  emitData(item: TabItem) {
    this.emitIteam.emit(item.route);
  }
}
