<div class="flex flex-row gap-4 items-center">
   <ng-container *ngIf="data.course_image; else noAvatar">
      <img class="rounded border border-slate-600" [src]="data.course_image" width="70" alt="" />
   </ng-container>
   <ng-template #noAvatar>
      <img class="rounded border border-slate-600" src="https://placehold.co/400x300" width="70" alt="" />
   </ng-template>
   <div class="flex flex-col gap-1">
      <h4 class="font-medium text-slate-800 text-base">{{data.course_title}}</h4>
      <!-- <p class="font-normal text-slate-400 text-sm mb-0">{{data.lecture}}</p> -->
   </div>
</div>