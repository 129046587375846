<div class="fixed right-5 top-5 z-50 rounded-xl" *ngIf="isOpened">
   <div id="toast-success"
      class="mb-4 flex w-full max-w-xs items-center rounded-lg p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
      role="alert" [class.bg-green-100]="type === 'message'" [class.bg-red-100]="type === 'error'">
      <div [ngClass]="type === 'message'?'bg-green-100 text-green-500':'bg-red-100 text-red-500'"
         class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg">
         <svg-icon *ngIf="type === 'message'" src="@assets/images/icons/check-circle-solid.svg" class="block h-6 w-6">
         </svg-icon>
         <svg-icon *ngIf="type === 'error'" src="@assets/images/icons/exclamation-circle.svg" class="block h-6 w-6">
         </svg-icon>
         <span class="sr-only">Check icon</span>
      </div>
      <div class="mr-1.5 ms-1 min-w-[100px] text-sm font-normal">{{ message }}</div>
      <button (click)="discard()" type="button"
         class="-my-1.5 ml-3 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-neutral-100 p-1.5 text-gray-400 hover:bg-neutral-200 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500"
         data-dismiss-target="#toast-success" aria-label="Close">
         <span class="sr-only">Close</span>
         <svg-icon src="@assets/images/icons/x-mark.svg" class="block h-3 w-3"></svg-icon>
      </button>
   </div>
</div>
