import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  getErrorMessage(error: any) {
    let errorMessages = [];
    for (const key in error) {
      if (error.hasOwnProperty(key)) {
        errorMessages.push(error[key]);
      }
    }
    return _.flattenDeep(errorMessages);
  }

  applyError(section: string, errors: any) {
    console.log('applyErrorQ1--');
    // if (errors) {
    // }
    //
    return of(true);
  }
}
