import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ToastData = {
  message: string;
  type?: 'message' | 'error' | 'info' | 'warning';
  onClose?: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toast$ = new BehaviorSubject<ToastData | undefined>(undefined);
  public toast$ = this._toast$.asObservable();

  constructor() {}

  message(data: ToastData) {
    this._toast$.next({
      ...data,
      type: 'message',
    });
  }

  error(data: ToastData) {
    this._toast$.next({
      ...data,
      type: 'error',
    });
  }
}
