import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input() tooltip: string | undefined; // Make the property optional with 'undefined'
  @Input() tooltip_position: string = '';

  private tooltipElement: HTMLElement | null = null; // Initialize to null
  showing = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hide();
  }

  show() {
    if (this.showing) {
      return;
    }

    this.showing = true;
    this.tooltipElement = this.renderer.createElement('div');

    this.renderer.addClass(this.tooltipElement, 'tooltip');
    if (this.tooltip_position) {
      this.renderer.addClass(this.tooltipElement, this.tooltip_position);
    }
    let spanElement = this.renderer.createElement('div');
    this.renderer.appendChild(spanElement, this.renderer.createText(this.tooltip!)); // Append text first
    this.renderer.setStyle(spanElement, 'margin-left', '-16px'); // Add margin to the <span>
    this.renderer.appendChild(this.tooltipElement, spanElement); // Append the <span> after the text
    this.renderer.appendChild(this.el.nativeElement, this.tooltipElement);

    // Adjust if position is top
    if (this.tooltip_position == 'top') {
      let parentElement = this.tooltipElement?.parentElement;
      if (parentElement) {
        let topOffset = 10;
        topOffset = parentElement.getBoundingClientRect().height + topOffset;
        if (this.tooltipElement?.offsetHeight) {
          topOffset = topOffset + this.tooltipElement?.offsetHeight;
        }
        this.renderer.setStyle(spanElement, 'top', `-${topOffset}px`);
      }
    }
  }

  hide() {
    if (!this.showing) {
      return;
    }
    if (this.tooltipElement) {
      this.renderer.removeChild(this.el.nativeElement, this.tooltipElement);
      this.tooltipElement = null;
    }
    this.showing = false;
  }
}
