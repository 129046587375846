import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
})
export class ComponentsComponent implements OnInit {
  select: any = {
    selectSmall: false,
    selectDefault: false,
    select2Small: false,
    select2Default: false,
    select2DefaultDisabled: false,
    select2DefaultMulti: false,
    selectMenu: false,
    selectAccount: false,
  };

  select2DefaultMulti: string[] = [];
  multioptions: any = [
    { label: 'Design', value: 'design' },
    { label: 'Programming', value: 'programming' },
    { label: 'Figma', value: 'figma' },
    { label: 'Software', value: 'software' },
    { label: 'Business', value: 'business' },
    { label: 'Management', value: 'management' },
  ];

  accordion: any = {
    one: false,
    two: false,
    three: false,
  };

  constructor() {}

  ngOnInit(): void {
    //this.initMultiDropdown()
  }

  // initMultiDropdown() {
  //   if (this.select2DefaultMulti) {

  //   }
  // }

  isSelected(value?: string) {
    if (value) {
      return _.includes(this.select2DefaultMulti, value);
    }
    return false;
  }
  selectMultiDropdown(value?: string) {
    if (value) {
      this.select2DefaultMulti.push(value);
    }
    this.select2DefaultMulti = _.uniq(this.select2DefaultMulti);
    this.select.select2DefaultMulti = true;
  }
  removeMultiDropdown(value?: string) {
    if (value) {
      this.select2DefaultMulti = _.remove(this.select2DefaultMulti, (q, w) => {
        return q != value;
      });
    }
    this.select.select2DefaultMulti = true;
  }

  form = new FormGroup({});
  model = { email: 'email@gmail.com' };
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 mb-4',
      fieldGroup: [
        {
          className: '',
          type: 'input',
          key: 'firstName',
          props: {
            label: 'First Name',
            placeholder: 'Sammple',
            required: true,
            type: 'email',
          },
        },
        {
          className: '',
          type: 'input',
          key: 'lastName',
          props: {
            label: 'Last Name',
          },
          expressions: {
            'props.disabled': '!model.firstName',
          },
        },
      ],
    },
    {
      className: 'section-label',
      template: '<h5>Custom Div Here</h5>',
    },
    {
      fieldGroupClassName: 'grid grid-cols-4 gap-4 mb-4',
      fieldGroup: [
        {
          className: 'col-span-2',
          type: 'input',
          key: 'street',
          props: {
            label: 'Street',
          },
        },
        {
          className: 'col-span-1',
          type: 'input',
          key: 'cityName',
          props: {
            label: 'City',
          },
        },
        {
          className: 'col-span-1',
          type: 'input',
          key: 'zip',
          props: {
            type: 'number',
            label: 'Zip',
            max: 99999,
            min: 0,
            pattern: '\\d{5}',
          },
        },
      ],
    },
    { template: '<div class="border-b border-slate-200"></div>' },
    {
      type: 'textarea',
      key: 'otherInput',
      props: {
        label: 'Other Input',
      },
    },
    {
      type: 'checkbox',
      key: 'otherToo',
      props: {
        label: 'Other Checkbox',
      },
    },
    {
      key: 'multicheckbox',
      type: 'multicheckbox',
      templateOptions: {
        label: 'Multi checkbox',
        placeholder: 'Placeholder',
        description: 'Description',
        required: true,
        options: [
          { value: 1, label: 'Option 1' },
          { value: 2, label: 'Option 2' },
          { value: 3, label: 'Option 3' },
          { value: 4, label: 'Option 4' },
          { value: 5, label: 'Option 5' },
          { value: 6, label: 'Option 6' },
          { value: 7, label: 'Option 7' },
          { value: 8, label: 'Option 8' },
        ],
      },
    },
    {
      key: 'gender',
      type: 'radio',
      templateOptions: {
        type: 'radio',
        label: 'Gender',
        required: true,
        name: 'gender',
        options: [
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
        ],
      },
    },
    {
      key: 'normalselect',
      type: 'select',
      props: {
        label: 'Select',
        options: [
          { label: 'Iron Man', value: 'iron_man' },
          { label: 'Captain America', value: 'captain_america' },
          { label: 'Black Widow', value: 'black_widow' },
          { label: 'Hulk', value: 'hulk' },
          { label: 'Captain Marvel', value: 'captain_marvel' },
        ],
        labelProp: 'label',
      },
    },
    {
      key: 'uiselect',
      type: 'select',
      props: {
        label: 'Select UI',
        options: [
          { label: 'Iron Man', value: 'iron_man' },
          { label: 'Captain America', value: 'captain_america' },
          { label: 'Black Widow', value: 'black_widow' },
          { label: 'Hulk', value: 'hulk' },
          { label: 'Captain Marvel', value: 'captain_marvel' },
        ],
        labelProp: 'label',
      },
    },
    {
      key: 'uiselect2',
      type: 'select',
      props: {
        label: 'Select UI multiple',
        options: [
          { label: 'Iron Man', value: 'iron_man' },
          { label: 'Captain America', value: 'captain_america' },
          { label: 'Black Widow', value: 'black_widow' },
          { label: 'Hulk', value: 'hulk' },
          { label: 'Captain Marvel', value: 'captain_marvel' },
        ],
        placeholder: 'Choose',
        stayPlaceholder: true,
        labelProp: 'label',
        multiple: true,
      },
    },
  ];

  onSubmit() {}
}
