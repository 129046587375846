import { createAction, props } from '@ngrx/store';
import { LoginContext } from '@shared/models';
import { User } from 'thkee-common';

export const AuthActions = {
  useAsGuest: createAction('[Auth] Use As Guest'),
  login: createAction('[Auth] Login', props<{ data: LoginContext, trackerId?: string }>()),
  loginSuccess: createAction('[Auth] Login Success'),
  loginError: createAction('[Auth] Login Error', props<{ error: string }>()),
  logout: createAction('[Auth] Logout'),
  signup: createAction('[Auth] Signup', props<{ email: string; password: string; name: string, geo: Partial<Geolocation>, contextId?: string }>()),
  signupSuccess: createAction('[Auth] Signup Success', props<{ email: string; password: string }>()),
  signupError: createAction('[Auth] Signup Error', props<{ error: string }>()),
  userLoad: createAction('[Auth] User Load'),
  updateUser: createAction('[Auth] User Update', props<{ user: Partial<User>, trackerId?: string }>()),
  updateUserSuccess: createAction('[Auth] User Update Success', props<{ user: Partial<User> }>()),
  userLoaded: createAction('[Auth] User Loaded', props<{ user: User }>()),
  signupInstructorSuccess: createAction('[Instructor] Signup Instructor Success'),
};
