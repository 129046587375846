import { createSelector } from '@ngrx/store';
import { CourseStatus } from '../../models';
import { courseFeature } from './course.reducer';

export const {
  selectCourses,
  selectCourseState,
  selectCourse,
  selectProject,
  selectCourseDetailed,
  selectSelectedSectionId,
  selectSelectedActivityId,
  selectSelectedSubsectionId,
  selectActivityState,
  selectIsActivityModalOpen,
  selectUploads,
  selectFeedback,
  selectCourseHistory: selectCourseHistoryState,
  selectAnnotationHistory: selectAnnotationHistoryState,
  selectSections: selectSectionsState,
  selectSubsections: selectSubsectionsState,
  selectQuizzes: selectQuizzesState,
  selectQuizQuestions: selectQuizQuestionsState,
  selectQuizAnswers: selectQuizAnswersState,
  selectLectures: selectLecturesState,
  selectLectureResources: selectLectureResourcesState,
  selectAssignments: selectAssignmentsState,
  selectAssignmentQuestions: selectAssignmentQuestionsState,
  selectAnnotations: selectAnnotationsState,
  selectContentTypes: selectContentTypesState,
  selectLearners: selectLearnersState,
  selectObjectives: selectObjectivesState,
  selectRequirements: selectRequirementsState,
} = courseFeature;

const EDITABLE_COURSE_STATUS = [CourseStatus.DRAFT, CourseStatus.REJECTED];

export const selectIsCourseEditable = createSelector(
  selectCourse,
  (course) => !!course.status && EDITABLE_COURSE_STATUS.includes(course.status)
);

export const selectIsCourseUneditable = createSelector(selectIsCourseEditable, (isEditable) => !isEditable);
