import { Directive, HostListener } from '@angular/core';
import { DropdownDirective } from './dropdown.directive';

@Directive({
  selector: '[thkDropdownItem]'
})
export class DropdownItemDirective {
  @HostListener('click')
  handleClick() {
    this.dropdown.handleItemClick();
  }

  constructor(private dropdown: DropdownDirective) { }
}
