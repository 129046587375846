<div class="activity-heading h-[60px]">
  <div class="flex h-full items-center justify-between bg-slate-800 px-4 py-3 text-white">
    <div class="grow truncate text-lg font-medium">{{ title }}</div>
    <div class="action flex gap-4">
      <button *ngIf="!hidePrev" class="btn btn-white btn-sm" (click)="emitEvent('prev')" i18n>Back</button>
      <button *ngIf="!hideNext" class="btn btn-primary btn-sm" (click)="emitEvent('next')" i18n>Next</button>
    </div>
  </div>
</div>
<div
  class="article-container flex max-h-[500px] min-h-[500px] flex-col bg-gradient-to-t from-[#1C2C41] to-[#8999AF] p-4"
  [class.full-screen]="fullScreen"
>
  <div class="article-view relative flex h-full grow flex-col overflow-hidden rounded bg-white">
    <div class="view-control flex max-h-[50px] items-center justify-between bg-slate-200 px-4 py-[10px]">
      <div></div>
      <div class="flex items-center gap-2">
        <div>
          <div>
            <svg-icon
              src="@assets/images/icons/minus.svg"
              class="h-5 w-5 cursor-pointer"
              (click)="emitEvent('zoom-out')"
            ></svg-icon>
          </div>
        </div>
        <div>
          <input
            class="input-field input-xs block w-[50px] rounded-[1px] border-slate-800 bg-slate-800 px-[8px] py-[1px] text-[10px] font-semibold text-white"
            type="number"
            max="150"
            min="75"
            readonly
            [(ngModel)]="inputValue"
          />
        </div>
        <div>
          <svg-icon
            src="@assets/images/icons/plus.svg"
            class="h-5 w-5 cursor-pointer"
            (click)="emitEvent('zoom-in')"
          ></svg-icon>
        </div>
      </div>
      <div>
        <div class="cursor-pointer" (click)="emitEvent('full-screen'); fullScreen = !fullScreen">
          <svg-icon
            *ngIf="fullScreen"
            src="@assets/images/icons/arrows-pointing-in.svg"
            class="h-[18px] w-[18px]"
          ></svg-icon>
          <svg-icon
            *ngIf="!fullScreen"
            src="@assets/images/icons/arrows-pointing-out.svg"
            class="h-[18px] w-[18px]"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div class="flex h-full overflow-hidden">
      <app-scrollable-content>
        <div class="p-6" [ngStyle]="{ 'font-size': inputValue + '%' }">
          <markdown [data]="content"></markdown>
        </div>
      </app-scrollable-content>
    </div>
  </div>
</div>
